import style from "./SideBar.module.css";
import { TRADING_RESOURCE } from "../../shared/variables";
import { Storage } from "../../config";
import {PortfolioType} from "../../pages/Algo/utils";

export const getLinkClassName = ({ resources, pathname, name }) => {
  const match = pathname.match(/^(\/[a-zA-Z-]+)\/(?:[a-z0-9-/]+)?$/);

  if (match) {

    if (pathname.includes(TRADING_RESOURCE.TRADINGS)) {
      const match = pathname.match(
        /^\/([a-zA-Z-]+)\/([a-z0-9-]+)(?:\/[a-zA-Z-]+)?(?:\/\d)?$/
      );
      if (`${match[1]}/${match[2]}` === name) return style.activeMenuItem;

      const trading = resources[match[1]]?.data[match[2]];
      const { trading_type } = trading || {};
      if (trading_type && name === TRADING_RESOURCE[trading_type])
        return style.activeMenuItem;
    }

    if (name === "screeners" && (match[1] === "/screener-backtests" ||  pathname.includes("/screeners")) )
      return style.activeMenuItem;

    if (match[1] === "/backtests") {
      const match = pathname.match( /^\/backtests\/([a-z0-9-]+)\/show(?:\/\d)?$/ );

      const backtest = resources.backtests?.data[match[1]];
      const { algo, portfolio_type } = backtest || {};
      const owner = algo?.id ? resources["algos"]?.data[algo?.id]?.owner : null;

      if (owner
          && owner?.id !== Storage.getItem("profile")
          && name === "users") {
        return style.activeMenuItem;
      }

      if (portfolio_type === PortfolioType.INTRADAY && name === "algos") {
        return style.activeMenuItem;
      }

      if (portfolio_type === PortfolioType.WATCHLIST && name === "watchlist-algos") {
        return style.activeMenuItem;
      }
    }

    if (name === "users"
        && match[1] === "/algos"
        && pathname.includes("show")) {
      return style.activeMenuItem;
    }

    if (match[1] === "/watchlist-algos" && name === "watchlist-algos") {
      return style.activeMenuItem;
    }

    if (pathname === "/algos/create" && name === "algos") {
      return style.activeMenuItem;
    }

    if (match[1] === "/algos") {
      const match = pathname.match( /^\/algos\/([a-z0-9-]+)$/ );
      const algo = resources.algos?.data[match[1]];
      const {id, portfolio_type} = algo || {};

      if (portfolio_type === PortfolioType.WATCHLIST && name === "watchlist-algos") {
        return style.activeMenuItem;
      } else if (portfolio_type === PortfolioType.INTRADAY && name === "algos") {
        return style.activeMenuItem;
      }
    }

  }
  return pathname === `/${name}` ? style.activeMenuItem : null;


};
