import React from "react";
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { useTranslate } from "react-admin";
import classes from "../CreateFromASample.module.css";
import { setGaId } from "../../../shared/utils";
import ScreenerIcon from "react-feather/dist/icons/activity";

const DesktopPopper = ({isOpen, anchorRef, onClickClose, onClickMenuItem, samples, ga, noResults, basePath }) => {
    const t = useTranslate();

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      className={classes.popper}
      placement={noResults ? "bottom" : "bottom-end"}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClickClose}>
          <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <Paper>
              {!noResults ? (
                <div className={classes.header}>
                  {t("create_from_a_sample.label")}
                </div>
              ) : null}
              <MenuList id="split-button-menu">
                {samples?.map((sample) => (
                  <MenuItem
                    key={sample.id}
                    onClick={onClickMenuItem(sample)}
                    className={classes.menuItem}
                    color="primary"
                    id={setGaId(ga?.category, "create-from-sample")}
                  >
                    {basePath?.includes("screeners") ? (
                      <ScreenerIcon className={classes.icon} />
                    ) : null}
                    {sample.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

export default DesktopPopper;
