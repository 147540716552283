import React from "react";
import styles from "./Toolbar.module.css";
import { SaveButton, Toolbar as RaToolbar, useTranslate, useRedirect } from "react-admin";
import { useFormState } from "react-final-form";
import SplitButton from "../../../../components/SplitButton/SplitButton";
import { setGaId } from "../../../../shared/utils";
import { getTradingsResource, isPaper } from "../../utils";
import { statuses } from "../../../../shared/statuses";
import Button from "@material-ui/core/Button";
import { ENTITY } from "../../../../shared/variables";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isDesktop, isMobile } from "react-device-detect";
import clsx from "clsx";
import useDisableByLimit from "../../../../shared/hooks/useDisableByLimit";

const TradingToolbar = ({ ga, handleSave, loadRecent, isLoadingRecent, ...props }) => {
  const t = useTranslate();
  const redirect = useRedirect();
  const { values } = useFormState();
  const disableByLimit = useDisableByLimit();

  const isPaperTrading = isPaper(props);
  const tradingsResource = getTradingsResource(isPaperTrading);

  const handleCancel = () => redirect(`/${tradingsResource}`);

  const disableSaveButton = isPaperTrading ? disableByLimit.paper_tradings : disableByLimit.live_tradings;

  return (
    <RaToolbar {...props} className={clsx(styles.toolbar, { [styles.mobile]: isMobile })}>
      <SplitButton
        buttons={[
          <SaveButton
            id={setGaId(ga?.category, "save-launch")}
            data-testid="trading-button-save-launch"
            label={t("tradings.saveLaunchTrading")}
            className={styles.saveLaunch}
            entity={isPaperTrading ? ENTITY.PAPER_TRADING : ENTITY.LIVE_TRADING}
            disabled={disableSaveButton}
          />,
          <Button
            id={setGaId(ga?.category, "save")}
            data-testid="trading-button-save"
            className={styles.save}
            onClick={() => handleSave({ values, status: statuses.draft.id })}
            entity={isPaperTrading ? ENTITY.PAPER_TRADING : ENTITY.LIVE_TRADING}
            disabled={disableSaveButton}
          >
            {t("tradings.save")}
          </Button>,
        ]}
      />
      {isDesktop && (
        <>
          <Button variant="text" onClick={handleCancel} className={styles.cancel} id={setGaId(ga?.category, "cancel")}>
            {t("backtest.cancel")}
          </Button>
          <div className={styles.recentWrapper}>
            {isPaperTrading &&
              (isLoadingRecent ? (
                <CircularProgress className={styles.spinner} />
              ) : (
                <Button
                  variant="text"
                  data-testid="trading-button-load-values"
                  onClick={loadRecent}
                  className={styles.recentButton}
                  id={setGaId(ga?.category, "load-recent-values")}
                >
                  {t("backtest.loadRecent")}
                </Button>
              ))}
          </div>
        </>
      )}
    </RaToolbar>
  );
};

export default TradingToolbar;
