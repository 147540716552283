export const DISCOUNT_BANNER = "discount";
export const EMAIL_BANNER = "email";
export const REFERRAL_BANNER = "referral";
export const PAYMENT_BANNER = "payment";
export const BANNER = "BANNER";

export default (
  previousState = { discount: false, email: false, referral: false, banner: false, payment: false },
  { type, payload }
) => {
  switch (type) {
    case DISCOUNT_BANNER: {
      return {
        ...previousState,
        discount: payload,
      };
    }
    case EMAIL_BANNER: {
      return {
        ...previousState,
        email: payload,
      };
    }
    case REFERRAL_BANNER: {
      return {
        ...previousState,
        referral: payload,
      };
    }
    case PAYMENT_BANNER: {
      return {
        ...previousState,
        payment: payload,
      };
    }

    default:
      return previousState;
  }
};
