import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNotify, useRedirect, useTranslate } from "react-admin";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { BenchmarkChart } from "../BenchmarkChart";
import { MomentField } from "../MomentField";
import { customFetch } from "../../DataProvider";
import CircularProgress from "@material-ui/core/CircularProgress";
import classes from "./SharedAlgoCard.module.css";
import DisableCreatingTooltip from "../DisableCreatingTooltip/DisableCreatingTooltip";
import { ENTITY, GA_CATEGORIES } from "../../shared/variables";
import { GET_USAGE } from "../../store/sagas/usageSaga";
import { useDispatch } from "react-redux";
import { formatNumberValue, setGaId } from "../../shared/utils";
import WideLabelVal from "../Rows/WideLabelVal";
import WideValue from "../Rows/WideValue";
import clsx from "clsx";
import { TOUR_ALGO, TOUR_WELCOME, TOUR_WELCOME_FIRST } from "../../store/reducers/tourReducer";
import SplitButton from "../SplitButton/SplitButton";
import AlgoType from "../AlgoType";
import getMDImages from "../Markdown/GetMDImages";
import TinySlider from "tiny-slider-react";
import carouselClasses from "../Carousel/Carousel.module.css";
import TickerBenchmarkField from "../TickerBenchmarkField/TickerBenchmarkField";
import {MarkdownDoc} from "../Markdown/MarkdownDoc";

export const SharedAlgoCard = (props) => {
  const { tour } = useSelector((state) => state);
  const { record: backtest, disabled, setCreatingInProgress } = props;
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [inProgress, setInProgress] = useState(false);
  const dispatch = useDispatch();
  const images = getMDImages(backtest.description);

  const viewAlgo = (id) => {
    redirect(`/backtests/${id}/show`);
  };

  const tryAlgo = (id) => {
    try {
      setInProgress(true);
      setCreatingInProgress(true);
      customFetch(`backtests/shared/${id}/clone`, "POST", null, "text").then((backtest) => {
        dispatch({ type: TOUR_WELCOME, payload: false });
        if (tour.tourWelcomeFirst) {
          customFetch("profile", "PUT", { guided_tour_completed: true }).then((res) => {
            if (res && res.guided_tour_completed) {
              dispatch({ type: TOUR_WELCOME_FIRST, payload: false });
              dispatch({ type: TOUR_ALGO, payload: true });
            }
          });
        }
        dispatch({ type: GET_USAGE });
        const algoId = JSON.parse(backtest).algo.id;
        redirect(`algos/${algoId}`);
      });
    } catch {
      setInProgress(false);
      setCreatingInProgress(false);
      dispatch({ type: GET_USAGE });
      notify(t("algos.try_algo_error"));
    }
  };
  return (
    <div>
      <Card className={classes.root}>
        <div className={`${carouselClasses.swiperWrapper} ${classes.dashboardChart}`}>
          <TinySlider settings={{ loop: true, navPosition: "top", controls: false }}>
            <div key={0}>
              <div className={classes.slide}>
                <div className={classes.chartCover} />
                <BenchmarkChart
                  height="150"
                  priceLineVisible={false}
                  scrollable={false}
                  timeScaleVisible={false}
                  priceAxisPosition="none"
                  onDashboard
                  {...props}
                />
                <div className={classes.chartDates}>
                  <MomentField record={backtest} format="MMM YYYY" source="start_date" />
                  <MomentField record={backtest} format="MMM YYYY" source="end_date" />
                </div>
              </div>
            </div>

            {images.map((image, id) => (
              <div key={id + 1}>
                <div className={classes.slide}>
                  <img src={image._destination} alt="" className={classes.dashboardImage} />
                </div>
              </div>
            ))}
          </TinySlider>
        </div>

        <CardContent className={classes.cardContent}>
          <Grid container spacing={0}>
            <div className={classes.algoName}>
              <AlgoType algoType={backtest.algo_type} />
              <span className={classes.algoNameText}>
                <span>{backtest.name}</span>
              </span>
            </div>
          </Grid>
          <Typography variant="body2" color="textSecondary" component="p" className={classes.algoDescription}>
            <span>
              <MarkdownDoc value={backtest.description}/>
            </span>
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <div className={classes.cardStats}>
            <WideLabelVal
              label={t("dashboard.ticker")}
              value={
                <WideValue>
                  <TickerBenchmarkField ticket="ticker" benchmark="benchmark_ticker" record={backtest} />
                </WideValue>
              }
            />
            <WideLabelVal
              label={t("dashboard.return")}
              value={
                <WideValue initialValueForColor={backtest.return_pct}>
                  {formatNumberValue(backtest.return_pct, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "percent",
                  })}
                </WideValue>
              }
            />
            <WideLabelVal
              label={t("dashboard.alpha")}
              value={
                <WideValue>
                  {formatNumberValue(backtest.alpha, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </WideValue>
              }
            />
          </div>

          {inProgress ? (
            <div className={classes.tryProgress}>
              <CircularProgress style={{ width: 20, height: 20 }} />
            </div>
          ) : (
            <div className={clsx(classes.buttons, "tryAlgo")}>
              <SplitButton
                buttons={[
                  <DisableCreatingTooltip disabled={disabled} entity={ENTITY.ALGO}>
                    <Button
                      className={classes.tryAlgo}
                      onClick={() => tryAlgo(backtest.id)}
                      disabled={disabled}
                      id={setGaId(GA_CATEGORIES.DASHBOARD, "try-algo")}
                      data-testid="try-algo"
                    >
                      {t("dashboard.try")}
                    </Button>
                  </DisableCreatingTooltip>,

                  <Button
                    size="medium"
                    className={classes.viewAlgo}
                    onClick={() => viewAlgo(backtest.id)}
                    id={setGaId(GA_CATEGORIES.DASHBOARD, "try-algo")}
                    data-testid="view-algo"
                  >
                    {t("dashboard.view_algo")}
                  </Button>,
                ]}
              />
            </div>
          )}
        </CardActions>
      </Card>
    </div>
  );
};
