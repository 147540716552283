import React from "react";
import {
  Edit,
  SaveButton,
  SelectInput,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useTranslate,
} from "react-admin";
import { ShowTermsAndConditions } from "./TermsAndConditions";
import { Subscriptions } from "./components/Subscriptions/Subscriptions";
import { Storage } from "../../config";
import {
  customMaxLength,
  customRequired,
  customTwitterChecker,
  customUrlChecker,
  setGaId,
  showReferral,
} from "../../shared/utils";
import { TimeZones } from "../../locales/timezones";
import LabeledField from "../../components/LabeledField/LabeledField";
import { PhoneField } from "../../components/PhoneField/PhoneField";
import styles from "./Profile.module.css";
import ProfileIcon from "react-feather/dist/icons/user";
import GiftIcon from "react-feather/dist/icons/gift";
import SubscriptionsIcon from "react-feather/dist/icons/credit-card";
import TermsIcon from "react-feather/dist/icons/edit-3";
import BrokersIcon from "react-feather/dist/icons/link-2";
import { Grid } from "@material-ui/core";
import GridShowLayout from "../Layout/GridShowLayout";
import { isMobile } from "react-device-detect";
import { GA_CATEGORIES } from "../../shared/variables";
import { UPDATE_PROFILE } from "../../store/reducers/profileReducer";
import { useDispatch } from "react-redux";
import DateInput from "../../components/DateInput/DateInput";
import clsx from "clsx";
import Referral from "./components/Referral/Referral";
import BrokersTable from "./components/BrokersTable/BrokersTable";
import { MarkdownInput } from "../../components/Markdown/MarkdownInput";

const Null = () => null;

const ProfileEditToolbar = ({ ga, ...rest }) => {
  return (
    <Toolbar {...rest} className={styles.toolbar}>
      <SaveButton
        id={setGaId(ga?.category, "profile-tab_save")}
        variant="contained"
        color="primary"
        disabled={rest.pristine}
        icon={<Null />}
      />
    </Toolbar>
  );
};

const SelectInputItem = ({ ga, record }) => {
  const id = setGaId(ga?.category, "profile-tab-time-zone");
  return (
    <span id={id} style={{ width: "100%" }}>
      {record?.text}
    </span>
  );
};

export const ProfileEdit = (props) => {
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const dispatch = useDispatch();

  const ga = { category: GA_CATEGORIES.PROFILE };

  //this is not ideal - I'm sure we can just let react-admin save everything, I just optimized for speed
  function updateProfile(profile) {
    dataProvider("UPDATE", "profile", { data: profile, id: "" })
      .then(({ data }) => {
        if (data.zone_id) {
          Storage.setItem("timezone", data.zone_id);
          dispatch({
            type: UPDATE_PROFILE,
            payload: { timezone: data.zone_id },
          });
        }
        // we have to update application cache. refresh() produces some glitches
        dataProvider.getOne("profile", { id: "" }).then(() => notify(t("profile.updated"), "info"));
      })
      .catch(() => {
        notify(t("profile.cant_save"), "error");
      });
  }

  return (
    <Edit resource="profile" title="Profile" id={Storage.getItem("profile")} basePath="/profile" {...props}>
      <TabbedShowLayout className={clsx(styles.profileContainer, { [styles.mobile]: isMobile })}>
        <Tab
          icon={isMobile ? <ProfileIcon /> : null}
          label={isMobile ? "" : t("profile.profile_tab")}
          id={setGaId(ga?.category, "profile-tab")}
        >
          <SimpleForm
            warnWhenUnsavedChanges
            toolbar={<ProfileEditToolbar ga={ga} {...props} />}
            save={updateProfile}
            variant="outlined"
            {...props}
          >
            <GridShowLayout className={styles.profileGrid}>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12} className={styles.textContainer}>
                  <LabeledField label="Email" source="email" />
                  <LabeledField label="Phone Number" source="phone">
                    <PhoneField />
                  </LabeledField>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    id={setGaId(ga?.category, "profile-tab-full-name")}
                    source="name"
                    validate={customMaxLength(100, t)}
                    variant="outlined"
                    className={styles.input}
                    label={t("authorization.full_name")}
                  />
                  <div>
                    <MarkdownInput
                      variant="outlined"
                      multiline
                      label={t("authorization.bio")}
                      source="bio"
                      className={clsx(styles.input, styles.bio)}
                    />
                  </div>
                  <TextInput
                    id={setGaId(ga?.category, "profile-tab-website")}
                    source="website"
                    validate={customUrlChecker(t)}
                    variant="outlined"
                    className={styles.input}
                    label={t("authorization.website")}
                  />
                  <TextInput
                    id={setGaId(ga?.category, "profile-tab-twitter")}
                    source="twitter"
                    validate={customTwitterChecker(t)}
                    variant="outlined"
                    className={styles.input}
                    label={t("authorization.twitter")}
                  />
                  <div>
                    <DateInput
                      id={setGaId(ga?.category, "profile-tab-date-of-birth")}
                      label={t("authorization.date_of_birth")}
                      source="date_of_birth"
                      className={clsx(styles.input, styles.date)}
                      minDate={"1901-01-01"}
                      validate={customRequired()}
                    />
                  </div>
                  <SelectInput
                    source="zone_id"
                    label={t("authorization.time_zone")}
                    optionText={<SelectInputItem ga={ga} {...props} />}
                    optionValue="text"
                    choices={TimeZones}
                    variant="outlined"
                    className={styles.input}
                    validate={customMaxLength(64, t)}
                  />
                </Grid>
              </Grid>
            </GridShowLayout>
          </SimpleForm>
        </Tab>
        <Tab
          icon={isMobile ? <SubscriptionsIcon /> : null}
          label={isMobile ? "" : t("profile.subscription_tab")}
          id={setGaId(ga?.category, "subscription-tab")}
        >
          <Subscriptions />
        </Tab>
        <Tab
          icon={isMobile ? <BrokersIcon /> : null}
          label={isMobile ? "" : t("profile.brokers_tab")}
          id={setGaId(ga?.category, "brokers-tab")}
        >
          {/*<Brokers />*/}
          <BrokersTable />
        </Tab>
        <Tab
          icon={isMobile ? <TermsIcon /> : null}
          label={isMobile ? "" : t("profile.tos_tab")}
          id={setGaId(ga?.category, "terms-tab")}
        >
          <ShowTermsAndConditions noAction />
        </Tab>
        {showReferral && (
          <Tab
            icon={isMobile ? <GiftIcon /> : null}
            label={isMobile ? "" : t("profile.gifts_tab")}
            id={setGaId(ga?.category, "gifts_tab")}
          >
            <Referral />
          </Tab>
        )}
      </TabbedShowLayout>
    </Edit>
  );
};

export default ProfileEdit;
