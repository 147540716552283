import React from "react";
import { AppBar, Link, usePermissions } from "react-admin";
import logo from "../../assets/logo.svg";
import m_logo from "../../assets/m_logo.svg";
import UserMenu from "../../components/UserMenu/UserMenu";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import PlanInfo from "../../components/PlanInfo/PlanInfo";
import { useSelector } from "react-redux";
import styles from "./AppBar.module.css";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";
import TourWelcome from "../../components/Tour/TourWelcome";
import TourAlgo from "../../components/Tour/TourAlgo";
import TourPaperTrading from "../../components/Tour/TourPaperTrading";
import TourLiveTrading from "../../components/Tour/TourLiveTrading";
import TourScreeners from "../../components/Tour/TourScreeners";
import TopHeader from "./components/TopHeader";

const BEAppBar = (props) => {
  const { tourWelcome, tourAlgo, tourPaperTrading, tourLiveTrading, tourScreeners } = useSelector(
    (state) => state.tour
  );

  const { permissions } = usePermissions();
  const hasPermissions = permissions && JSON.parse(permissions)?.length;
  const { discount, email, referral, payment } = useSelector((store) => store.header);

  return (
    <>
      {tourWelcome && <TourWelcome />}
      {tourAlgo && <TourAlgo />}
      {tourPaperTrading && <TourPaperTrading />}
      {tourLiveTrading && <TourLiveTrading />}
      {tourScreeners && <TourScreeners />}

      <TopHeader />

      <AppBar
        {...props}
        userMenu={<UserMenu />}
        className={clsx(
          { [styles.appBar]: !hasPermissions },
          (payment || email || discount || referral) && hasPermissions && styles.appBarMargin
        )}
      >
        <Link to="/" className={styles.logo}>
          <img src={isDesktop ? logo : m_logo} alt="logo" />
        </Link>
        <Breadcrumbs />
        {hasPermissions ? <PlanInfo /> : null}
      </AppBar>
    </>
  );
};
export default BEAppBar;
