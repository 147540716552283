import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NumberInput, useTranslate } from "react-admin";
import { Tooltip } from "@material-ui/core";
import classes from "../pages/Trading/components/TradingParameters/TradingParameters.module.css";
import Config from "../config";
import { customMustBePositive, customRequired } from "../shared/utils";

const InitialCapital = ({ ga }) => {
  const t = useTranslate();
  const { errorMessage: tradingErrorMessage } = useSelector((store) => store.trading);
  const [insufficientBuyingPower, setInsufficientBuyingPower] = useState();

  useEffect(() => {
    if (tradingErrorMessage?.includes("INSUFFICIENT_BUYING_POWER")) setInsufficientBuyingPower(true);
  }, [tradingErrorMessage]);

  return (
    <Tooltip
      title={t("helpers.input_capital")}
      PopperProps={{ className: classes.popper }}
      enterDelay={Config.TOOLTIP_DELAY}
      enterNextDelay={Config.TOOLTIP_DELAY}
    >
      <div>
        <NumberInput
          id={ga}
          label={t("fieldsName.initial_capital")}
          source="initial_capital"
          variant="outlined"
          style={{ width: "100%" }}
          inputProps={{ autoComplete: "off" }}
          onChange={() => setInsufficientBuyingPower(false)}
          {...(insufficientBuyingPower ? { error: true, helperText: t("common.tooLarge") } : {})}
          validate={[customMustBePositive(t("common.mustBePositive")), customRequired(t("common.required"))]}
        />
      </div>
    </Tooltip>
  );
};

export default InitialCapital;
