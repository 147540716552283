import * as React from "react";
import TVLightweightChart from "./TVLightweightChart";
import { colors } from "../shared/cssVariables";
import { ALERT } from "./Alert/variables";
import Alert from "./Alert/Alert";
import { useTranslate } from "react-admin";
import styles from "../pages/Backtest/Backtest.module.css";

export const BenchmarkChart = ({
  height,
  showLegend,
  marginTop,
  priceAxisPosition = "right",
  priceLineVisible,
  scrollable,
  timeScaleVisible,
  onDashboard = false,
  record: { benchmark_ticker, serialized_result, trading_days, initial_capital },
  resource,
  pressedMouseMove = false,
  showBenchmark = true
}) => {
  const isTrading = resource === "tradings";
  const t = useTranslate();

  const alert = {
    type: ALERT.BRAND,
    data: [
      {
        header: t("subscriptions.performance.heading"),
        description: [t("subscriptions.performance.text")],
      },
    ],
  };

  if (serialized_result || trading_days?.length) {
    const algoSeries = [
      //algo
      {
        data: [],
        legend: showLegend ? "Return" : "",
        options: {
          priceLineVisible: priceLineVisible,
          borderVisible: false,
          color: colors.blue,
          topColor: colors.blue,
          bottomColor: colors.white,
          lineColor: "#3c77be",
          lineWidth: 1,
          margins: {
            above: marginTop,
            below: 100,
          },
        },
      },
    ];

    const benchmarkSeries = [
      //benchmark
      {
        data: [],
        legend: showLegend ? "Benchmark" : "",
        options: {
          priceLineVisible: priceLineVisible,
          borderVisible: false,
          color: colors.gray_label,
          lineWidth: 1,
          margins: {
            above: marginTop,
            below: 100,
          },
        },
      },
    ];

    if (serialized_result && JSON.parse(serialized_result).days) {
      const days = JSON.parse(serialized_result)?.days || [];
      days.forEach(({ date, trading_value_close, benchmark_value }) => {
        //there seem to be a bug with 0 EOD while we should return initial_capital
        const eodValue = trading_value_close ? trading_value_close.toFixed(2) : initial_capital?.toFixed(2);
        algoSeries[0].data.push({
          time: date,
          value: eodValue,
        });
        if(benchmark_value) {
          benchmarkSeries[0].data.push({
            time: date,
            value: benchmark_value?.toFixed(2)
          });
        }
      });
    } else if (trading_days?.length) {
      trading_days.forEach(({ date, benchmark_value, trading_value_close }) => {
        algoSeries[0].data.push({
          time: date,
          value: trading_value_close?.toFixed(2),
        });

        benchmarkSeries[0].data.push({
          time: date,
          // value should be Number, toFixed(2) returns a String
          value: Math.round(benchmark_value * 100) / 100 || 0,
        });
      });
    }

    function createLegend() {
      const legendsData =
        !isTrading && benchmarkSeries[0]?.data?.length
          ? [{ ...benchmarkSeries[0] }, { ...algoSeries[0] }]
          : [{ ...algoSeries[0] }];
      let legendDiv = document.createElement("div");
      legendsData.forEach(({ data, legend, options: { color } }) => {
        if (data?.length && ((showBenchmark && legend === "Benchmark") || legend !== "Benchmark")) {
          let row = document.createElement("div");
          row.innerText = legend + ": ";
          let priceElem = document.createElement("span");
          priceElem.style.color = color;
          priceElem.innerText = " " + Number(data[data.length - 1].value).toLocaleString("en-EN");
          row.appendChild(priceElem);
          legendDiv.appendChild(row);
        }
      });
      return !onDashboard
        ? legendDiv.innerHTML
        : `<span><span class='Bechmark-Chart-Return'>Return</span> <span class='Bechmark-Chart-Benchmark'>vs. Benchmark (${benchmark_ticker})</span></span>`;
    }

    return (
      <TVLightweightChart
        lineSeries={!isTrading && showBenchmark && benchmarkSeries}
        areaSeries={algoSeries}
        priceScale={{
          position: priceAxisPosition,
          autoScale: true,
          mode: 1,
          alignLabels: false,
          invertScale: false,
          drawTicks: false,
          scaleMargins: { top: 0.3, bottom: 0.1 },
        }}
        grid={{
          vertLines: { visible: false },
          horzLines: { visible: false },
        }}
        handleScroll={{
          mouseWheel: scrollable,
          pressedMouseMove: pressedMouseMove || scrollable,
          horzTouchDrag: scrollable,
          vertTouchDrag: scrollable,
          pinch: scrollable,
        }}
        handleScale={{
          axisPressedMouseMove: scrollable,
          mouseWheel: scrollable,
          pinch: scrollable,
        }}
        timeScale={{
          visible: timeScaleVisible,
          rightOffset: 0,
        }}
        options={{ autoScale: true }}
        legend={createLegend()}
        autoWidth
        height={height}
      />
    );
  } else {
    return (
      <div className={styles.alertContainer}>
        <Alert data={alert} />
      </div>
    );
  }
};
