import React, {useEffect, useState} from "react";
import {useDataProvider, Loading} from "react-admin";
import Calendar from 'rc-year-calendar';
import classes from "./TradesCalendar.module.css";
import {kFormatter} from "../../../../../shared/utils";

function color(gain) {
  if(gain > 0) {
    return "green";
  } else if(gain < 0) {
    return "red";
  }
  return "white";
}

function toDate(str) {
  return new Date(str + "T00:00:00.000-05:00");
}


const TradesCalendar = (props) => {
  // const t = useTranslate();
  const dataProvider = useDataProvider();
  const resource = props.tradesListResource;

  const [events, setEvents] = useState([]);
  const [minDate, setMinDate] = useState({});
  const [maxDate, setMaxDate] = useState({});
  //gains
  let minGain = null;
  let maxGain = null;

  const [toolTip, setTooltip] = useState({});

  const customDayRenderer = (dayElement, date) => {
    if(events) {
      const day = events.filter((event, index) => {
        return event.endDate.toISOString().split('T')[0] === date.toISOString().split('T')[0];
      })[0];

      const gain = day?.gain || 0;

      if(!maxGain || !minGain) {
        const gains = events.map((event, index) => {
          return event.gain;
        });

        minGain = Math.min(...gains);
        maxGain = Math.max(...gains);
      }

      // day number wrapper
      dayElement.classList.add(classes.day);
      if(gain !== 0) {
        //raw number as title
        dayElement.title = gain.toLocaleString('en-US', { style: 'currency', currency: 'USD'});
      }

      //background with relative gain
      const gainBgElement = document.createElement("div");
      gainBgElement.textContent = kFormatter(gain);
      gainBgElement.classList.add(classes.gainBg);
      gainBgElement.classList.add(classes[color(gain)]);

      let size = (26 * gain / (gain > 0 ? maxGain : minGain))
      size = Math.max(4, size);

      gainBgElement.style.width = size + "px";
      dayElement.appendChild(gainBgElement);
    }
  }

  useEffect(() => {
    const filterName = (resource.includes("backtest") ? "backtest" : "trading");
    dataProvider.getList(resource, {
      pagination: {
        page: 1,
        perPage: 100000, //200+ years
      },
      sort: { field: "date", order: "ASC" },
      filter: {
       [filterName] : props.record.id
      },
    }).then(({ data }) => {

      const dates = data.map((record, index) => {
        return toDate(record.date);
      });

      setMinDate(new Date(Math.min(...dates)));
      setMaxDate(new Date(Math.max(...dates)));

      setEvents(
          data.map((record, index) => {
            //add TZ to date
            const date = toDate(record.date);

            return {
              id : index,
              gain: record.gain,
              startDate: date,
              endDate: date,
              color: color(record.gain)
            }
          })
      )
    })
  }, [props.record.id]);



  return events.length >  0 ? (
      <div className={classes.calendar}>
       <Calendar dataSource={events}
                 weekStart={1} //Mon
                 customDayRenderer={customDayRenderer}
                 minDate={minDate}
                 maxDate={maxDate}
                 style="custom"
                 defaultYear={minDate.getFullYear()}/>
      </div>

  ) : <Loading/>;
};

export default TradesCalendar;
