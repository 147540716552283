import React from "react";
import styles from "./SelectInputStandalone.module.css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import cn from "clsx";
import { setGaId } from "../../shared/utils";

const SelectInputStandalone = ({
  ga,
  value,
  onChange,
  choices,
  label,
  className,
}) => {
  return (
    <FormControl
      variant="outlined"
      className={cn(styles.formControl, className)}
    >
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        value={value}
        onChange={onChange}
        label={label}
      >
        {choices.map(({ id, name }) => (
          <MenuItem key={id} value={id} id={setGaId(ga?.category, "order-by")}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInputStandalone;
