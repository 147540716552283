import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { FirebaseAuthProvider } from "react-admin-firebase";
import config, { Storage } from "../../config";
import { LOCALSTORAGE } from "../../shared/variables";
import { app, auth } from "./firebaseConfig";

export const AuthProvider = FirebaseAuthProvider(undefined, { app });

const { TOKEN, PROFILE, TIMEZONE, PERMISSIONS, ROLES, ORDER_CHARTS } = LOCALSTORAGE;

/**
 * Login with username and password.
 */
AuthProvider.login = async ({ username, password }) => {
  const result = await signInWithEmailAndPassword(auth, username, password);

  await initializeContextAfterLogin(result.user);

  return result;
};

AuthProvider.getPermissions = () => {
  const permissions = Storage.getItem(PERMISSIONS);
  if (window.location.hash.includes("signup")) return Promise.resolve([]);
  return permissions?.length ? Promise.resolve(permissions) : Promise.reject();
};

AuthProvider.checkAuth = () => {
  const token = Storage.getItem(TOKEN);
  return token ? Promise.resolve() : Promise.reject();
};

AuthProvider.logout = () => {
  return signOut(auth).then(function () {
    Storage.removeItem(TOKEN);
    Storage.removeItem(PERMISSIONS);
    Storage.removeItem(ROLES);
    Storage.removeItem(PROFILE);
    Storage.removeItem(TIMEZONE);
    Storage.removeItem(ORDER_CHARTS);
    window.growsurf && window.growsurf.logout();
  });
};

export const initializeContextAfterLogin = async (user) => {
  const token = await user.getIdToken();

  const profile = await fetch(
    new Request(`${config.BACKEND_API}/profile`, {
      method: "GET",
      headers: new Headers({ Authorization: `Bearer ${token}` }),
    })
  ).then((response) => {
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    return response.text();
  });

  if (profile === "") {
    sessionStorage.setItem("prefilledData", JSON.stringify(user));
    document.location.replace("/#/marketplace-signup");
  } else {
    const currentUser = JSON.parse(profile);
    Storage.setItem(PROFILE, currentUser.id);
    Storage.setItem(ROLES, JSON.stringify(currentUser.roles.map((role) => role.name)));
    Storage.setItem(TIMEZONE, currentUser.zone_id);

    const permissions = await fetch(
      new Request(`${config.BACKEND_API}/profile/permissions`, {
        method: "GET",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
      })
    ).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    });

    if (permissions) {
      Storage.setItem(PERMISSIONS, JSON.stringify(permissions));
      if (currentUser.terms_accepted) {
        document.location.replace("/#/strategies");
      } else {
        document.location.replace("/#/terms");
      }
    } else {
      Storage.setItem(PERMISSIONS, "[]");
      document.location.replace("/#/marketplace-signup");
    }
  }
};
