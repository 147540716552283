import React from "react";
import styles from "./IntervalsBlock.module.css";
import { useTranslate } from "react-admin";
import ToggleButtons from "../../../../components/ToggleButtons/ToggleButtons";
import { useFormState } from "react-final-form";
import SelectField from "../../../../components/SelectField/SelectField";
import { CUTOFF_VALUES, DAY_INTERVALS, getTickerLimitValue, TIME_INTERVALS } from "../../../../shared/variables";
import { customFromTo, customRequired, makeInterval, setGaId } from "../../../../shared/utils";
import NarrowLabelVal from "../../../../components/Rows/NarrowLabelVal";
import NarrowRow from "../../../../components/Rows/NarrowRow";
import CollapseBlock from "../../../../components/CollapseBlock/CollapseBlock";
import StyledTextInput from "../../../../components/StyledTextInput/StyledTextInput";

export const IntervalsBlock = (props) => {
  const { values } = useFormState();
  return (
    <CollapseBlock
      id={setGaId(props.ga?.category, "additional-parameters-toggle")}
      short={<IntervalsLine {...props} values={values} />}
      full={<IntervalsFull {...props} />}
      className={styles.container}
    />
  );
};

export const IntervalsLine = (props) => {
  const { style, values } = props;
  const t = useTranslate();

  return (
    <div className={styles.shortContainer} style={style}>
      <NarrowRow>
        <NarrowLabelVal
          label={t("fieldsName.cutoff_method")}
          value={getTickerLimitValue(values.cutoff_method, values.cutoff_limit, t)}
        />
        <NarrowLabelVal label={t("fieldsName.filter_interval")} value={`${values.filter_criteria_interval}d`} />
        <NarrowLabelVal label={t("fieldsName.scoring_interval")} value={makeInterval(values.sort_criteria_interval)} />
        <NarrowLabelVal label={t("fieldsName.sort")} value={values.sorting_order} />
      </NarrowRow>
    </div>
  );
};

export const CutoffInput = (
  {
    ga = { category: "CutoffInput" },
    classes = {},
    limitSource,
    methodSource,
    methodOptions,
    className,
  }) => {
  const t = useTranslate();
  const options = methodOptions || [
    { label: t("cutoff_labels.volume_desc"), value: CUTOFF_VALUES.DAILY_VOLUME_DESC },
    { label: t("cutoff_labels.volume_asc"), value: CUTOFF_VALUES.DAILY_VOLUME_ASC },
    { label: t("cutoff_labels.price_desc"), value: CUTOFF_VALUES.SHARE_PRICE_DESC },
    { label: t("cutoff_labels.price_asc"), value: CUTOFF_VALUES.SHARE_PRICE_ASC },
    { label: t("cutoff_labels.range_desc"), value: CUTOFF_VALUES.DAILY_PRICE_MOVEMENT_DESC },
    { label: t("cutoff_labels.range_asc"), value: CUTOFF_VALUES.DAILY_PRICE_MOVEMENT_ASC },
  ];

  return (
    <div className={`${styles.limitBlock} ${classes?.container} ${className}`}>
      <StyledTextInput
        source={limitSource}
        id={setGaId(ga?.category, "cutoff_limit")}
        className={`${styles.limitInput} ${classes?.input}`}
        variant="outlined"
        label={t("fieldsName.cutoff_method")}
        defaultValue={1}
        validate={[customFromTo(1, 10), customRequired()]}
        InputLabelProps={{ shrink: true }}
      />
      <SelectField
        defaultValue={options[0].value}
        source={methodSource}
        options={options}
        className={`${styles.limitSelect} ${classes?.select}`}
        selectClassName={styles.dropDown}
        id={setGaId(ga?.category, methodSource)}
        noLabel
      />
    </div>
  );

};

export const IntervalsFull = ({ ga }) => {
  const t = useTranslate();
  return (
    <div className={styles.fullContainer}>
      <CutoffInput ga={ga} limitSource="cutoff_limit" methodSource="cutoff_method" />

      <SelectField
        id={setGaId(ga?.category, "filter-interval")}
        source="filter_criteria_interval"
        options={DAY_INTERVALS}
        className={styles.select}
        helperText={t("helpers.filter_interval")}
      />
      <SelectField
        id={setGaId(ga?.category, "score-interval")}
        source="sort_criteria_interval"
        options={TIME_INTERVALS}
        className={styles.select}
        helperText={t("helpers.score_interval")}
      />
      <ToggleButtons
        ga={ga}
        source="sorting_order"
        className={styles.toggleButtons}
        choices={[
          { id: "ASC", name: t("screeners.asc") },
          { id: "DESC", name: t("screeners.desc") },
        ]}
      />
    </div>
  );
};
