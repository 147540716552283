import React from "react";
import { Link } from "react-admin";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import BriefcaseIcon from "react-feather/dist/icons/briefcase";
import ScreenerIcon from "react-feather/dist/icons/activity";
import CloseIcon from "react-feather/dist/icons/x";
import styles from "./RenderTags.module.css";

const TagName = ({ val }) => (
  <span className={clsx(styles.tagLabel, { [styles.blue]: ["screeners", "indexes"].includes(val?.type) })}>
    {val?.name}
  </span>
);

const RenderTags = ({ value, onTagRemove, disabled }) => {
  return value.map((val, index) => (
    <div key={val?.name} className={styles.tag}>
      {val.type === "screeners" ? (
        <Link to={`/screeners/${val.id}`} className={styles.link}>
          <ScreenerIcon size={14} className={styles.icon} />
          <TagName val={val} />
        </Link>
      ) : (
        <>
          {val.type === "indexes" && <BriefcaseIcon size={14} className={styles.icon} />}
          <TagName val={val} />
        </>
      )}
      {!disabled &&
        <IconButton onClick={onTagRemove} data-tag-index={index} className={styles.tagButton}>
            <CloseIcon size={14} color={"var(--gray_icon)"}/>
        </IconButton>
      }
    </div>
  ));
};

export default RenderTags;
