export const RERUN_BACKTEST = "RERUN_BACKTEST";
export const RESET_RERUN_BACKTEST = "RESET_RERUN_BACKTEST";

export default (previousState = {}, { type, payload }) => {
  switch (type) {
    case RERUN_BACKTEST:
      return { ...payload.backtest };
    case RESET_RERUN_BACKTEST:
      return {};
    default:
      return previousState;
  }
};
