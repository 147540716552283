import { put, takeEvery, select } from "redux-saga/effects";
import { authFetchAPI } from "../../DataProvider";
import {
  SUBSCRIPTION_UPDATING,
  SUBSCRIPTION_UPDATE_SUCCESS,
  SUBSCRIPTION_UPDATE_FAIL,
} from "../reducers/subscriptionReducer";
import { GET_USAGE_POLLING, CANCEL_USAGE_POLLING } from "./usageSaga";

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";

export default function* subscriptionSaga() {
  yield takeEvery(UPDATE_SUBSCRIPTION, function* ({ payload: { id } }) {
    yield put({ type: SUBSCRIPTION_UPDATING });
    yield put({ type: GET_USAGE_POLLING });

    const { has_stripe_account } = yield select((store) => store.usage.data);

    if (has_stripe_account) {
      try {
        yield authFetchAPI(`billing/change-plan?planId=${id}`, {
          method: "POST",
        });
        yield put({
          type: SUBSCRIPTION_UPDATE_SUCCESS,
          payload: { sessionId: null },
        });
      } catch (e) {
        yield put({
          type: SUBSCRIPTION_UPDATE_FAIL,
          payload: { errorMessage: e.message },
        });
        yield put({ type: CANCEL_USAGE_POLLING });
      }
      return;
    } else {
      try {
        const res = yield authFetchAPI(
          `billing/checkout-session?planId=${id}`,
          {
            method: "POST",
          }
        );
        if (!res.ok) {
          throw new Error(res.message);
        }

        const sessionId = yield res.text();
        yield put({
          type: SUBSCRIPTION_UPDATE_SUCCESS,
          payload: { sessionId },
        });
      } catch (e) {
        yield put({
          type: SUBSCRIPTION_UPDATE_FAIL,
          payload: { errorMessage: e.message },
        });
        yield put({ type: CANCEL_USAGE_POLLING });
      }
    }
  });
}
