import { Storage } from "../../../config";
import { useEffect, useState } from "react";
import { LOCALSTORAGE } from "../../../shared/variables";

const { PROFILE, ITEMS_PER_PAGE } = LOCALSTORAGE;

export default function useGetItemsPerPage(tableName) {
  const [perPage, setPerPage] = useState();

  const profileId = Storage.getItem(PROFILE);
  const itemsPerPage = JSON.parse(Storage.getItem(ITEMS_PER_PAGE) || JSON.stringify({}));

  useEffect(() => {
    const perPageByEntity = itemsPerPage?.[profileId]?.[tableName];
    if (perPageByEntity) setPerPage(perPageByEntity);
  }, [profileId, tableName]);

  return perPage;
}