import { TopToolbar, useListContext, useTranslate } from "react-admin";
import React, { useEffect } from "react";
import { ToggleButtonsField } from "../ToggleButtonsField/ToggleButtonsField";
import { statuses } from "../../shared/statuses";
import CreateFromASample from "../CreateFromASample/CreateFromASample";
import { disableCreating } from "../../shared/utils";
import { isDesktop } from "react-device-detect";
import AddIcon from "@material-ui/icons/Add";
import classes from "../../pages/Dashboard/Dashboard.module.css";

const liveStatuses = [
  statuses.active.id,
  statuses.failed.id,
  statuses.draft.id,
  statuses.stopped.id,
];
const archivedStatuses = [statuses.archived.id];

export const ActionsToolbar = ({
  ga,
  basePath,
  setSwitch,
  samples,
  handleCreateFromASample,
  disableCreate,
  entity,
  createButtonLabel,
  limits,
  filterStatuses,
  additionalSwitchFilters = {},
  readOnly=false
}) => {
  const { loading, filterValues, setFilters } = useListContext();
  const t = useTranslate();

  const { usage, planLimit, status } = limits;

  useEffect(() => {
    if (filterValues.shared === true) {
      setSwitch(statuses.shared.id);
    } else if (
      JSON.stringify(filterValues.status) === JSON.stringify(archivedStatuses)
    ) {
      setSwitch(statuses.archived.id);
    } else {
      setSwitch(statuses.active.id);
    }
  }, [loading]);

  const selectedSwitch = () => {
    if (filterValues.shared === true) {
      return statuses.shared.id;
    } else if (
      JSON.stringify(filterValues.status) === JSON.stringify(archivedStatuses)
    ) {
      return statuses.archived.id;
    } else {
      return statuses.active.id;
    }
  };

  const handleRequestSwitch = (event) => {
    const checked = event.target.id;

    switch (checked) {
      case statuses.active.id: {
        setFilters({ statusNot: archivedStatuses, ...additionalSwitchFilters });
        break;
      }
      case statuses.archived.id: {
        setFilters({ status: archivedStatuses, ...additionalSwitchFilters });
        break;
      }

      case statuses.shared.id: {
        setFilters({
          shared: true,
          status: liveStatuses,
          ...additionalSwitchFilters,
        });
        break;
      }
      default:
        return;
    }
  };

  const disableButton = () => disableCreating(usage, planLimit, status);

  return (
    <TopToolbar>
      <ToggleButtonsField
        ga={ga}
        onChange={handleRequestSwitch}
        defaultValue={selectedSwitch()}
        inputNames={filterStatuses}
        inputLabels={filterStatuses}
      />
      {!readOnly && <CreateFromASample
        ga={ga}
        label={t(createButtonLabel)}
        samples={samples}
        basePath={basePath}
        handleCreateFromASample={handleCreateFromASample}
        entity={entity}
        disabled={disableCreate || disableButton()}
      >
        {!isDesktop ? <AddIcon className={classes.icon} /> : null}
      </CreateFromASample>}

    </TopToolbar>
  );
};
