import React from "react";
import { useTranslate } from "react-admin";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ChevronDownIcon from "react-feather/dist/icons/chevron-down";

import classes from "./TipsToStart.module.css";
import { Carousel } from "../../../components/Carousel/Carousel";
import { setGaId } from "../../../shared/utils";
import { GA_CATEGORIES } from "../../../shared/variables";

export const TipsToStart = ({ exploreAlgosRef, disableCreate }) => {
  const t = useTranslate();
  const storageKey = "showTipsToStart";
  const defaultExpanded = localStorage.getItem(storageKey);

  const handleExpansion = () => {
    if (defaultExpanded) {
      localStorage.removeItem(storageKey);
      return;
    }

    localStorage.setItem(storageKey, "false");
  };

  return (
    <Accordion
      defaultExpanded={!defaultExpanded}
      className={classes.accordion}
      onChange={handleExpansion}
    >
      <AccordionSummary
        expandIcon={<ChevronDownIcon size={14} fontSize={14} />}
        aria-controls="panel1a-content"
        id={setGaId(GA_CATEGORIES.DASHBOARD, "tips-toggle")}
        className={classes.accordionSummary}
      >
        <div className={classes.title}>{t("guidance.tipsToStart")}</div>

      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Carousel exploreAlgosRef={exploreAlgosRef} disableCreate={disableCreate} />
      </AccordionDetails>
    </Accordion>
  );
};
