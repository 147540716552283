import React from "react";
import { BacktestParameters } from "../BacktestParameters/BacktestParameters";
import ScreenerParameters from "../../../ScreenerBacktest/components/ScreenerParameters/ScreenerParameters";

const ScreenerMobile = ({ ga, ...props }) => {
  return (
    <>
      <ScreenerParameters {...props} />
      <BacktestParameters ga={ga} {...props} />
    </>
  );
};

export default ScreenerMobile;
