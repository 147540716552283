import { TimeZones } from "../../locales/timezones";
import { LOCALSTORAGE } from "../../shared/variables";

const timezone = localStorage.getItem(LOCALSTORAGE.TIMEZONE);

const makeIanaTimezone = (timezone) =>
  TimeZones.find((item) => item.text === timezone)?.utc[0];

const initialState = {
  timezone: timezone || "(UTC-05:00) Eastern Time (US & Canada)",
  ianaTimezone: makeIanaTimezone(timezone) || "America/New_York",
};

export const UPDATE_PROFILE = "UPDATE_PROFILE";

export default (previousState = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_PROFILE: {
      return {
        ...previousState,
        timezone: payload.timezone,
        ianaTimezone: makeIanaTimezone(payload.timezone),
      };
    }
    default:
      return previousState;
  }
};
