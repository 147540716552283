import React, { useEffect } from "react";
import { Create } from "react-admin";
import { GET_SCREENERS } from "../../store/sagas/screenersSaga";
import { useDispatch } from "react-redux";
import TradingForm from "./TradingForm";
import { GET_SCREENERS_ALL } from "../../store/sagas/screenersAllSaga";
import { isPaper } from "./utils";
import { ENGINES, TRADING_TYPE } from "../../shared/variables";
import { getAlgoInitialData } from "../Algo/CreateAlgo";

const CreateTrading = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_SCREENERS });
    dispatch({ type: GET_SCREENERS_ALL });
  }, []);

  const isPaperTrading = isPaper(props);

  const initialData = {
    name: isPaperTrading ? "New Paper Trading" : "New Live Trading",
    trading_type: isPaperTrading ? TRADING_TYPE.PAPER : TRADING_TYPE.LIVE,
    broker: isPaperTrading ? ENGINES.BE.id : ENGINES.CB.id,
  };
  Object.assign(initialData, getAlgoInitialData());

  return (
    <Create {...props} actions={null} record={initialData}>
      <TradingForm {...props} />
    </Create>
  );
};

export default CreateTrading;
