import classes from "./Dashboard.module.css";
import Typography from "@material-ui/core/Typography";
import DisableCreatingTooltip from "../../components/DisableCreatingTooltip/DisableCreatingTooltip";
import { ENTITY, GA_CATEGORIES } from "../../shared/variables";
import Button from "@material-ui/core/Button";
import { setGaId } from "../../shared/utils";
import AddIcon from "@material-ui/icons/Add";
import { isDesktop } from "react-device-detect";
import { SharedAlgoCard } from "../../components/SharedAlgoCard/SharedAlgoCard";
import React, { useEffect, useRef, useState } from "react";
import { useRedirect, useTranslate } from "react-admin";

// minWidth should be in the ASCENDING order
const GRID_COLUMNS_BREAKPOINTS = [
  { className: "algoContainer1Column", minWidth: 0 },
  { className: "algoContainer2Columns", minWidth: 712 },
  { className: "algoContainer3Columns", minWidth: 1056 },
  { className: "algoContainer4Columns", minWidth: 1400 },
  { className: "algoContainer5Columns", minWidth: 1744 },
  { className: "algoContainer6Columns", minWidth: 2088 },
];

const SharedAlgoCards = ({ exploreAlgosRef, disableCreate, data, ...props }) => {
  const t = useTranslate();
  const redirect = useRedirect();
  const [algoContainerClass, setAlgoContainerClass] = useState(GRID_COLUMNS_BREAKPOINTS[0].className);

  const containerRef = useRef(null);
  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      // https://stackoverflow.com/a/58701523/854386
      // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        const width = entries[0].contentRect.width;
        const { className } = GRID_COLUMNS_BREAKPOINTS.find(
          (item, index, array) =>
            width >= item.minWidth && (index + 1 < array.length ? width < array[index + 1].minWidth : true)
        );
        setAlgoContainerClass(className);
      });
    })
  );

  useEffect(() => {
    let observerRefValue = null;
    let observer = null;
    if (containerRef.current) {
      observerRefValue = containerRef.current;
      observer = resizeObserver.current;
    }
    return () => observerRefValue && observer.unobserve(observerRefValue);
  }, []);

  const containerRefHandler = (element) => {
    if (!element) return;
    containerRef.current = element;
    resizeObserver.current.observe(element);
  };

  const handleCreateNewAlgo = () => redirect("/algos/create");

  return (
    <div className="algos" ref={exploreAlgosRef}>
      <div className={classes.sharedHeader}>
        <Typography variant="h4" component="h2">
          {t("dashboard.explore")}
        </Typography>
        <DisableCreatingTooltip disabled={disableCreate} entity={ENTITY.ALGO}>
          <div>
            <Button
              size="medium"
              className={classes.button}
              data-testid="dashboard-createalgo"
              onClick={handleCreateNewAlgo}
              variant="contained"
              color="primary"
              disabled={disableCreate}
              id={setGaId(GA_CATEGORIES.DASHBOARD, "create-new-algo")}
            >
              <AddIcon className={classes.icon} />
              {isDesktop ? t("dashboard.create_new_algo") : t("dashboard.new_algo")}
            </Button>
          </div>
        </DisableCreatingTooltip>
      </div>
      <div className="shared-algos__content">
        <div className={`Dashboard-Algo-Container ${classes[algoContainerClass]}`} ref={containerRefHandler}>
          {data?.map((record) => (
            <div className="Dashboard-Algo" key={record.id}>
              <SharedAlgoCard record={record} disabled={disableCreate} {...props} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SharedAlgoCards;
