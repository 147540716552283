import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import TrendingUpIcon from "react-feather/dist/icons/trending-up";
import TrendingDownIcon from "react-feather/dist/icons/trending-down";

export const ALGO_TYPES = {
  LONG: "LONG",
  SHORT: "SHORT",
};

export const AlgoType = (props) => {
  const algoType = props?.algoType || props?.record?.[props?.source] || "";

  if (algoType) {
    return (
      <Tooltip title={algoType}>
        {algoType === ALGO_TYPES.LONG ? (
          <TrendingUpIcon color="var(--green)" size={20} />
        ) : (
          <TrendingDownIcon color="var(--red)" size={20} />
        )}
      </Tooltip>
    );
  } else {
    return null;
  }
};

export default AlgoType;
