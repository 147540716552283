import React, { useState } from "react";
import { useRedirect, useTranslate } from "react-admin";
import { useSelector } from "react-redux";
import Alert from "../Alert";
import { ALERT, SUBSCRIPTIONS_URL } from "../variables";
import styles from "../Alert.module.css";
import { getPlanState } from "../../../store/selectors";
import { PLANS } from "../../../shared/variables";

const UserReachedLimit = ({ entity }) => {
  const [isOpen, setOpenAlert] = useState(true);
  const { name: planName } = useSelector(getPlanState) || {};
  const isProfessional = planName === PLANS.PROFESSIONAL;
  const t = useTranslate();
  const redirect = useRedirect();
  const toSubscriptions = () => redirect(SUBSCRIPTIONS_URL);

  const data = {
    type: ALERT.WARNING,
    data: [
      {
        header: t("subscriptions.limit", { entity }),
        description: isProfessional
          ? [
              <span>
                {t("unexpected_error.want_more")}{" "}
                <a href={t("subscriptions.support_email")} className={styles.link}>
                  {t("unexpected_error.contact_support")}
                </a>
              </span>,
            ]
          : [t("subscriptions.limit_desc")],
      },
    ],
    action: isProfessional ? null : { label: "subscriptions.upgrade_plan", onClick: toSubscriptions },
  };

  return isOpen && planName ? <Alert data={data} setOpenAlert={setOpenAlert} /> : null;
};

export default UserReachedLimit;
