import { useEffect, useState } from "react";
import { getCountDownTimeValues } from "../shared/utils";

export default function useCountDown(expirationDate) {
  const [countDownMessage, setCountDownMessage] = useState(' ');

  useEffect(() => {
    if (expirationDate) {
      const countDownTimer = setInterval(() => {
        const { days, hours, minutes, seconds } = getCountDownTimeValues(expirationDate);
        setCountDownMessage(`${days}d ${hours}h ${minutes}m ${seconds}s`);

        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
          setCountDownMessage(null);
          clearInterval(countDownTimer);
        }
      }, 1000);

      return () => clearInterval(countDownTimer);
    }
  }, [expirationDate]);

  return countDownMessage;
}
