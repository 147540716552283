import { Pagination, useListContext, useTranslate } from "react-admin";
import React, { useEffect } from "react";
import { hasSearchParam } from "../../shared/utils";
import { statuses } from "../../shared/statuses";
import NoResultsFound from "../NoResultsFound/NoResultsFound";
import NoResultsFoundWithCreateFromASample from "../NoResultsFound/NoResultsFoundWithCreateFromASample";
import { LOCALSTORAGE } from "../../shared/variables";
import { Storage } from "../../config";

const { PROFILE, ITEMS_PER_PAGE } = LOCALSTORAGE;

const noResults = (props) => {
  const { location, filterValues, samples } = props;

  return hasSearchParam(location?.search) ||
    !samples?.length ||
    filterValues?.status?.includes(statuses.archived.id) ? (
    <NoResultsFound />
  ) : (
    <NoResultsFoundWithCreateFromASample {...props} />
  );
};

const ListPagination = (props) => {
  const { tableName } = props;
  const t = useTranslate();
  const { perPage, filterValues } = useListContext();

  const profileId = Storage.getItem(PROFILE);
  const itemsPerPage = JSON.parse(Storage.getItem(ITEMS_PER_PAGE) || JSON.stringify({}));

  useEffect(() => {
    if (profileId) {
      itemsPerPage[profileId] = { ...itemsPerPage[profileId] };
      itemsPerPage[profileId][tableName] = perPage;
      Storage.setItem(ITEMS_PER_PAGE, JSON.stringify(itemsPerPage));
    }
  }, [perPage]);

  return <Pagination limit={noResults({ ...props, filterValues, t })} />;
};

export default ListPagination;
