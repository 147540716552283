import React from "react";
import styles from "./TopTickers.module.css";
import cn from "clsx";

const TopTickers = (props) => {
  if (!props.record) {
    return [];
  }
  const values = Array.isArray(props.source)
    ? props.source
        .map((src) => props.record[src])
        .flat()
        .filter(Boolean)
    : props.record[props.source] || [];

  return values?.length ? (
    <div className={cn(styles.row, props.className, "tickers")}>
      {values.map((item, index) => (
        <span key={index} className={styles.item}>
          {item}
        </span>
      ))}
    </div>
  ) : null;
};

export default TopTickers;
