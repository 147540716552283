import React, { useCallback, useEffect, useState } from "react";
import classes from "./CriteriaInput.module.css";
import { useInput, useTranslate } from "react-admin";
import AceEditor from "react-ace";
import { BreakingEquityCompleter, onLoad } from "./BreakingEquityCompleter";
import clsx from "clsx";
import InfoIcon from "react-feather/dist/icons/info";
import { Tooltip } from "@material-ui/core";
import Config from "../../config";
import debounce from "lodash/debounce";
import { authFetch } from "../../DataProvider";
import { isMobile } from "react-device-detect";

const makeMarkers = (errors) => {
  return errors.map((error) => ({
    startRow: error.lineno - 1,
    endRow: error.lineno - 1,
    startCol: 0,
    endCol: 2000,
    type: "fullLine",
    className: classes.errorMarker,
  }));
};

const makeAnnotations = (errors) =>
  errors.map((error) => ({
    row: error.lineno - 1, // must be 0 based
    column: error.position, // must be 0 based
    type: "error",
    text: error.error,
  }));

const CriteriaInput = (props) => {
  const {
    input,
    meta: { error, touched },
  } = useInput(props);
  const t = useTranslate();
  const { id, className, label, helperText, readOnly, value, left, right, autocompleteBlacklist = [] } = props;

  const [markers, setMarkers] = useState([]);
  const [annotations, setAnnotations] = useState([]);

  const VALIDATE_MAX_LENGTH = 8000;

  useEffect(() => {
    const node = document.querySelectorAll(`#${input.name} > .ace_scroller`);
    if (node && node[0]) node[0].setAttribute("id", id);
  }, [input.name, id]);

  const validateHandler = useCallback(
    debounce(async (value) => {
      authFetch(Config.DSL_API_HOST + "/backtest/syntax-check", {
        method: "POST",
        body: JSON.stringify({ criteria: value }),
      })
        .then((res) => res.json())
        .then(({ is_valid: isValid, errors }) => {
          if (isValid) {
            setMarkers([]);
            setAnnotations([]);
          } else {
            setMarkers(makeMarkers(errors));
            setAnnotations(makeAnnotations(errors));
          }
        })
        .catch((e) => console.error(e));
    }, 500),
    []
  );

  useEffect(() => {
    setTimeout(() => {
      const el = document.querySelector(".ace_autocomplete");
      if ((input.value?.length && !el) || el?.style.display === "none") {
        validateHandler(input.value);
      } else {
        setMarkers([]);
        setAnnotations([]);
      }
    }, 500);
  }, [input.value]);

  return (
    <div
      className={clsx(
        classes.container,
        { [classes.readOnly]: readOnly },
        { [classes.error]: !!error && touched },
        { [classes.mobile]: isMobile },
        className
      )}
    >
      {label ? (
        <div className={clsx(classes.label, { [classes.readOnly]: readOnly })}>
          {!readOnly && <div className={classes.left} />}
          <div className={classes.right}>
            {label}
            {helperText && !readOnly ? (
              <Tooltip title={helperText} enterDelay={Config.TOOLTIP_DELAY} enterNextDelay={Config.TOOLTIP_DELAY}>
                <InfoIcon className={classes.info} />
              </Tooltip>
            ) : null}
          </div>
        </div>
      ) : null}
      <AceEditor
        mode="breakingequity"
        maxLines={props.fullScreen ? null : 19}
        minLines={isMobile ? 1 : 5}
        width="100%"
        theme="textmate"
        enableBasicAutocompletion={[BreakingEquityCompleter]}
        enableLiveAutocompletion={true}
        onLoad={onLoad}
        readOnly={readOnly}
        placeholder="Type $ sign to see suggestions for Breaking Equity language"
        defaultValue={value}
        showPrintMargin={false}
        annotations={annotations}
        markers={markers}
        editorProps={{ $autocompleteBlacklist: autocompleteBlacklist }}
        {...input}
        onChange={async (value) => {
          input.onChange(value);
        }}
        className={classes.editor}
      />
      {!!error && touched ? <div className={classes.errorLabel}>{t("criteria.required")}</div> : null}
      {input.value.length > VALIDATE_MAX_LENGTH && (
        <div className={classes.errorLabel}>{`${input.value.length}/${VALIDATE_MAX_LENGTH}`}</div>
      )}
      <div
        className={clsx(
          !!error && touched && classes.errorBorder,
          left && classes.errorBorderLeft,
          right && classes.errorBorderRight
        )}
      />
    </div>
  );
};

export default CriteriaInput;
