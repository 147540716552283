import { STATUSES } from "./statuses";

// export const TRADING_CLEAR = "TRADING_CLEAR";

export const SUBSCRIPTION_UPDATING = "SUBSCRIPTION_UPDATING";
export const SUBSCRIPTION_UPDATE_SUCCESS = "SUBSCRIPTION_UPDATE_SUCCESS";
export const SUBSCRIPTION_UPDATE_FAIL = "SUBSCRIPTION_UPDATE_FAIL";

const initialState = {
  status: STATUSES.INIT,
  sessionId: null,
  errorMessage: "",
};

export default (previousState = initialState, { type, payload }) => {
  switch (type) {
    // case TRADING_CLEAR: {
    //   return initialState;
    // }

    case SUBSCRIPTION_UPDATING: {
      return {
        ...initialState,
        status: STATUSES.UPDATING,
      };
    }
    case SUBSCRIPTION_UPDATE_SUCCESS: {
      return {
        ...previousState,
        status: STATUSES.UPDATE_SUCCESS,
        sessionId: payload.sessionId,
      };
    }
    case SUBSCRIPTION_UPDATE_FAIL: {
      return {
        status: STATUSES.UPDATE_FAIL,
        errorMessage: payload.errorMessage,
      };
    }

    default:
      return previousState;
  }
};
