import React, { useEffect, useState } from "react";
import {
  BulkDeleteButton,
  Datagrid,
  Filter,
  List,
  SearchInput,
  SimpleList,
  TextField,
  usePermissions,
  useRedirect,
  useTranslate,
} from "react-admin";
import ArchiveIcon from "react-feather/dist/icons/archive";
import { MomentField } from "../../components/MomentField";
import TopTickers from "../../components/TopTickers/TopTickers";
import { ScreenerToolbar } from "./components/Toolbar/ScreenerToolbar";
import { actions } from "../../shared/moreActions";
import { statuses } from "../../shared/statuses";
import Tooltip from "@material-ui/core/Tooltip";
import ShareIcon from "react-feather/dist/icons/share-2";
import styles from "./ShowScreeners.module.css";
import mobileStyles from "../../components/MobileList.module.css";
import MoreActions from "../../components/MoreActions/MoreActions";
import { CREATE_SHARED_SCREENERS } from "../../shared/permissions";
import { customFetch } from "../../DataProvider";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { formatLimitValue, getLimitRatio, setGaId } from "../../shared/utils";
import { colors } from "../../shared/cssVariables";
import { DISABLED_FIELDS, ENTITY, GA_CATEGORIES, RATIO_LIMITS, TABLES } from "../../shared/variables";
import { useSelector } from "react-redux";
import { getPlanState, getUsageState } from "../../store/selectors";
import LimitAlert from "../../components/Alert/alerts/LimitAlert";
import DatagridIdsWrapper from "../../components/DatagridIdsWrapper/DatagridIdsWrapper";
import { isDesktop } from "react-device-detect";
import { Link } from "ra-ui-materialui";
import clsx from "clsx";
import ListPagination from "../../components/ListPagination/ListPagination";
import useGetItemsPerPage from "../../components/ListPagination/hooks/useGetItemsPerPage";

const BulkActionButtons = ({ ga, hasList, hasEdit, hasShow, hasCreate, ...rest }) => {
  const t = useTranslate();
  return (
    <BulkDeleteButton
      {...rest}
      id={setGaId(ga?.category, "screener-checkbox-archive")}
      icon={<ArchiveIcon />}
      label={t("actions.archive")}
    />
  );
};

const ScreenerFilter = ({ ga, record }) => {
  return (
    <Filter variant="standard" record={record}>
      <SearchInput source="q" variant="standard" alwaysOn id={setGaId(ga?.category, "search")} />
    </Filter>
  );
};

const StatusBar = (props) => {
  const t = useTranslate();
  return props.record?.shared && props.record.status !== statuses.archived.id ? (
    <Tooltip title={t("screeners.shared")} className={styles.statusBar}>
      <ShareIcon color={colors.blue} size={20} />
    </Tooltip>
  ) : null;
};

const StatusBarAndMoreActionsWrapper = (props) => {
  const { ga, record } = props;
  const getGa = () => {
    const status = record?.shared ? statuses.shared.name : record?.status?.toLowerCase();

    return {
      category: ga?.category,
      action: ga?.action && status ? `${ga?.action}-${status}` : "screener",
    };
  };

  return (
    <div className={styles.wrapper}>
      <StatusBar label="" source="status" {...props} />
      <MoreActions {...props} ga={getGa()} items={[actions.clone, actions.archive, actions.share]} />
    </div>
  );
};

const NameField = ({ record, source }) => {
  return (
    <Tooltip title={record?.[source]}>
      <span>{record?.[source]}</span>
    </Tooltip>
  );
};

export const ScreenerList = (props) => {
  const [switcher, setSwitch] = useState(statuses.live.id);
  const { loaded, permissions } = usePermissions();
  const redirect = useRedirect();
  const t = useTranslate();
  const [sharedScreeners, setSharedScreeners] = useState([]);
  const [limitAlert, setLimitAlert] = useState({});

  const tableName = TABLES.SCREENERS;
  const perPage = useGetItemsPerPage(tableName);

  const { screeners, status } = useSelector(getUsageState) || {};
  const { screeners_limit } = useSelector(getPlanState) || {};

  const ga = { category: GA_CATEGORIES.SCREENERS, action: "screener" };

  const showAlert = () => {
    const limitRatio = getLimitRatio(screeners, screeners_limit, status);
    if (limitRatio !== RATIO_LIMITS.A_LOT) {
      const leftLimits = formatLimitValue(screeners_limit) - screeners;
      setLimitAlert({
        ratio: limitRatio,
        entity: ENTITY.SCREENERS,
        limits: leftLimits,
      });
    }
  };

  useEffect(() => {
    const sharedFilter = encodeURIComponent(JSON.stringify({ shared: true }));
    customFetch(`screeners?filter=${sharedFilter}`, "GET").then((screeners) => {
      setSharedScreeners(screeners);
    });
  }, []);

  useEffect(() => {
    showAlert();
  }, [screeners, screeners_limit]);

  const handleCreateFromASample = (sample) => {
    sample.id_for_getting_backtests = sample.id;
    redirect(
      props?.basePath +
        "/create?" +
        new URLSearchParams({
          source: JSON.stringify(_objectWithoutProperties(sample, DISABLED_FIELDS)),
        }).toString()
    );
  };

  const enableArchive = () => switcher === statuses.live.id;

  const screenersMobileClick = (id) => `/screeners/${id}`;

  return loaded ? (
    <div className={styles.mobileWrapper}>
      <LimitAlert {...limitAlert} />
      <List
        {...props}
        sort={{
          field: "created_date",
          order: "DESC",
        }}
        exporter={false}
        actions={
          <ScreenerToolbar
            ga={ga}
            setSwitch={setSwitch}
            samples={sharedScreeners}
            handleCreateFromASample={handleCreateFromASample}
          />
        }
        filterDefaultValues={{ status: [statuses.draft.id, statuses.live.id] }}
        filters={<ScreenerFilter ga={ga} {...props} />}
        bulkActionButtons={enableArchive() && <BulkActionButtons ga={ga} {...props} />}
        className={styles.list}
        perPage={perPage}
        pagination={
          <ListPagination
            {...props}
            tableName={tableName}
            entity={ENTITY.SCREENER}
            samples={sharedScreeners}
            handleCreate={handleCreateFromASample}
          />
        }
      >
        {isDesktop ? (
          <Datagrid style={{ tableLayout: "fixed" }} rowClick="edit" size="medium" className={styles.dataGrid}>
            <DatagridIdsWrapper ga={ga} headerClassName="IdsWrapper-Col" cellClassName="IdsWrapper-Col" />

            {enableArchive() ? null : <TextField headerClassName="Empty-Col" cellClassName="Empty-Col" />}

            <NameField source="name" cellClassName="Other-Col Name-Col" headerClassName="Other-Col Name-Col" />

            <MomentField
              source="created_date"
              label={t("algos.created")}
              fromNow={true}
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />

            <TopTickers
              label="Tickers / Indexes"
              cellClassName={styles.tickersHeader}
              headerClassName="Other-Header"
              source={["indexes", "tickers"]}
              sortable={false}
            />
            {permissions?.includes(CREATE_SHARED_SCREENERS) ? (
              <TextField
                source="owner.name"
                label="Author"
                cellClassName="Other-Col"
                headerClassName="Other-Col"
                sortable={false}
              />
            ) : null}
            <StatusBarAndMoreActionsWrapper ga={ga} cellClassName={clsx("Actions-Col", styles.actions)} />
          </Datagrid>
        ) : (
          <SimpleList
            linkType={false}
            className={mobileStyles.list}
            primaryText={(record) => (
              <div className={mobileStyles.container}>
                <Link to={() => screenersMobileClick(record?.id)}>
                  <div className={mobileStyles.name}>
                    <NameField record={record} source="name" />
                  </div>
                  <div className={mobileStyles.date}>
                    {t("common.created")}&nbsp;
                    <MomentField record={record} source="created_date" fromNow={true} />
                  </div>
                  <div className={styles.mobileTickers}>
                    <TopTickers record={record} source={["indexes", "tickers"]} />
                  </div>
                </Link>
                <div className={mobileStyles.actions}>
                  <StatusBarAndMoreActionsWrapper {...props} ga={ga} record={record} />
                </div>
              </div>
            )}
          />
        )}
      </List>
    </div>
  ) : null;
};
