import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { useTranslate } from "react-admin";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "react-feather/dist/icons/x";
import BriefcaseIcon from "react-feather/dist/icons/briefcase";
import ScreenerIcon from "react-feather/dist/icons/activity";
import classes from "./StockInput.module.css";
import { useForm, useFormState } from "react-final-form";
import cn from "clsx";
import RenderTags from "./components/RenderTags/RenderTags";
import { STATUSES } from "../../store/reducers/statuses";
import isEqual from "lodash/isEqual";
import { Tooltip } from "@material-ui/core";
import Config, { Storage } from "../../config";
import { setGaId } from "../../shared/utils";

const valueFromSources = ({ sources, state, formValues }) => {
  let value = [];
  const roles = Storage.getItem("roles");
  const isAdmin = roles?.includes("super_admin");

  for (const source of sources) {
    const tickers = Array.isArray(formValues[source]) ? formValues[source] : [formValues[source]];
    formValues[source] &&
    (value = [
      ...value,
      ...tickers?.map((item) => {
        const stateSource = state[source]?.find((stateItem) => stateItem.id === item);
        if (source === "screeners" && !stateSource && !isAdmin) return;
        return {
          type: source,
          id: item,
          name: stateSource?.name || item,
        };
      }),
    ]);
  }
  return value.filter(Boolean);
};

export const renderOption = ({ option, ga }) => {
  if (option.type === "indexes" && option.name) {
    return (
      <div className={classes.option} id={setGaId(ga?.category, ga?.action ? `${ga?.action}-add-index` : "add-index")}>
        <BriefcaseIcon className={classes.icon} />
        {option.name}
      </div>
    );
  } else if (option.type === "screeners" && option.name) {
    return (
      <div
        className={classes.option}
        id={setGaId(ga?.category, ga?.action ? `${ga?.action}-add-screener` : "add-screener")}
      >
        <ScreenerIcon className={classes.icon} />
        {option.name}
      </div>
    );
  } else {
    return (
      <div
        className={classes.plainoption}
        id={setGaId(ga?.category, ga?.action ? `${ga?.action}-add-ticker` : "add-ticker")}
      >
        {option.name ? option.name : option.toString()}
      </div>
    );
  }
};

export const buildChoices = (array, type) => array.map((item) => ({ ...item, type }));

const setFieldTouched = (args, state) => {
  const [fieldName] = args;
  const field = state.fields[fieldName];
  if (field) {
    field.touched = true;
  }
};

const resetSubmitErrors = () => {}

// Input update stores corresponding values to "indexes", "tickers", "screeners" form values,
// if they present in "sources" array.
// All the rest values are stored in "sources" "other" value. "Other" value should be the only one.
const StockInput = (props) => {
  const { ga, label, limitTags, validate: required, options, className, helperText } = props;
  const store = useSelector((store) => store);

  const [loading, setLoading] = useState(true);
  const [choices, setChoices] = useState([]);
  const [values, setValues] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState();

  const disabled = props.disabled;

  const form = useForm();
  const { values: formValues, errors, touched } = useFormState();
  const t = useTranslate();

  const SPECIALS = ["indexes", "tickers", "screeners"];
  const SOURCE_SPECIALS = SPECIALS.filter((item) => props.sources.includes(item));
  const SOURCE_REST = props.sources.find((item) => !SPECIALS.includes(item));

  //mount
  useEffect(() => {
    form.setConfig("mutators", { setFieldTouched, resetSubmitErrors });

    props.sources.forEach((source) => {
      form.registerField(source, (fieldState) => {
      }, {});
    });

    form.registerField(
      props.name,
      (fieldState) => {
      },
      {
        touched: true,
      },
      {
        getValidator: () => (value) => {
          if (required && Array.isArray(value) && !value?.length) {
            return t("autocomplete.required_message");
          }
          return undefined;
        },
      },
    );

    if (props.name in formValues) {
      setValues(formValues[props.name]);
    }
  }, []);

  useEffect(() => {
    if (store.app.loading || store.screeners.status !== STATUSES.LOAD_SUCCESS) return;

    const state = {
      tickers: store.tickers,
      indexes: store.indexes,
      screeners: store.screeners.data,
    };

    const value = valueFromSources({
      sources: props.sources,
      state,
      formValues,
    });
    if (!isEqual(value, values) || !formValues[props.name]) {
      form.change(props.name, value);
      setValues(value);
    }
  }, [formValues]);

  useEffect(() => {
    if (
      store.app.loading ||
      store.screeners.status !== STATUSES.LOAD_SUCCESS ||
      store.screeners.status !== STATUSES.LOAD_SUCCESS
    )
      return;

    const state = {
      tickers: store.tickers,
      indexes: store.indexes,
      screeners: store.screeners.data,
    };

    // try to use initialValue or calculate the value from the sources
    const value = valueFromSources({
      sources: props.sources,
      state,
      formValues,
    });
    form.change(props.name, value);
    setValues(value);

    const choices = options.reduce((acc, option) => {
      //options is ['ticker', 'indexes', 'screeners']
      let reference = state ? buildChoices(state[option], option) : [];
      return [...acc, ...reference];
    }, []);
    setChoices(choices);
    setLoading(false);
  }, [store.app.loading, store.screeners.status, store.screeners.status, store.tickers, store.indexes]);

  function onChange(values) {
    setValues(values);
    form.change(props.name, values);
    form.mutators.setFieldTouched(props.name);

    for (const sourceSpecial of SOURCE_SPECIALS) {
      const specialValue = values.filter((value) => value.type === sourceSpecial).map((value) => value.id);

      form.change(sourceSpecial, specialValue);
    }

    const restValue = values.filter((value) => !SOURCE_SPECIALS.includes(value.type)).map((value) => value.id);

    if (SOURCE_REST) {
      form.change(SOURCE_REST, restValue);
    }
  }

  function onPaste(e) {
    e.preventDefault();
    const text = e.nativeEvent.clipboardData.getData("Text");

    const unique = values;
    const tickers = text?.split(/[\s,]+/).forEach((ticker) => {
      const ucTicker = ticker.trim().toUpperCase();
      if (!unique.some((e) => e.id === ucTicker) && ucTicker) {
        unique.push({ type: "tickers", name: ucTicker, id: ucTicker });
      }
    });
    onChange(unique.slice(0, limitTags || unique.length));
  }

  const onTagRemove = (event) => {
    event.preventDefault();
    onChange(values.filter((val, index) => index !== Number(event.currentTarget.dataset.tagIndex)));
  };

  const filterOptions = createFilterOptions({
    matchFrom: props.matchFrom || "any",
    limit: 150,
  });

  const tooltipTimer = useRef(null);

  const handleTooltip = (value) => () => {
    if (value) {
      tooltipTimer.current = setTimeout(() => setTooltipOpen(value), Config.TOOLTIP_DELAY);
    } else {
      clearTimeout(tooltipTimer.current);
      setTooltipOpen(value);
    }
  };

  const id = `typeahead-input-${props.name}`;

  return (
    <div className={cn(classes.input, className)}>
      <Autocomplete
        disabled={disabled}
        loading={loading}
        closeIcon={<CloseIcon />}
        multiple={true}
        limitTags={limitTags}
        id={id}
        disableClearable
        renderTags={(value) => <RenderTags value={value} onTagRemove={onTagRemove} disabled={disabled} />}
        onChange={(e, value) => {
          onChange(value);
        }}
        onBlur={() => form.mutators.setFieldTouched(props.name)}
        options={choices}
        value={values}
        filterOptions={filterOptions}
        filterSelectedOptions
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionDisabled={() => values.length >= props.limitTags}
        getOptionLabel={(option) => (option.name ? option.name : option.toString())}
        renderOption={(option) => renderOption({ option, ga })}
        renderInput={(params) => {
          return (
            <Tooltip title={helperText} open={!!helperText && tooltipOpen === id}>
              <>
                <TextField
                  {...params}
                  disabled={disabled}
                  size="small"
                  label={`${label} ${values.length > 1 ? `(${values.length})` : ""}`}
                  variant="outlined"
                  error={!!touched[props.name] && !!errors[props.name]}
                  helperText={touched[props.name] && errors[props.name]}
                  InputProps={{ ...params.InputProps, type: "search" }}
                  className={classes.inputSearch}
                  onMouseEnter={handleTooltip(id)}
                  onPaste={onPaste}
                  onMouseLeave={handleTooltip()}
                  onClick={handleTooltip()}
                />
              </>
            </Tooltip>
          );
        }}
      />
    </div>
  );
};

export default StockInput;
