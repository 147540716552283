import React, { useEffect, useState } from "react";
import classes from "./StrategiesDashboard.module.css";
import { ENTITY, GA_CATEGORIES, RATIO_LIMITS, TABLES } from "../../shared/variables";
import TradesDetails from "../../components/TradesDetails/TradesDetails";
import PerformanceDetails from "../../components/PerformanceDetails/PerformanceDetails";
import { StrategyCard } from "./StrategiesDashboard";
import { formatLimitValue, getLimitRatio } from "../../shared/utils";
import LimitAlert from "../../components/Alert/alerts/LimitAlert";
import { getPlanState, getUsageState } from "../../store/selectors";
import { useSelector } from "react-redux";
import { isPaper } from "../Trading/utils";


const StrategyView = (props) => {
  const strategy = props.record;
  const ga = { category: GA_CATEGORIES.LIVE_TRADING_VIEW };

  const [limitAlert, setLimitAlert] = useState({});
  const { money_tradings, paper_tradings, status } = useSelector(getUsageState) || {};
  const { money_tradings_limit, paper_tradings_limit } = useSelector(getPlanState) || {};
  const isPaperTrading = isPaper(props);

  const [showLimitAlert, setShowLimitAlert] = useState(false);

  function displayLimitAlert() {
    setShowLimitAlert(true);
  }

  useEffect(() => {
    const limitRatio = isPaperTrading
      ? getLimitRatio(paper_tradings, paper_tradings_limit, status)
      : getLimitRatio(money_tradings, money_tradings_limit, status);
    if (limitRatio !== RATIO_LIMITS.A_LOT) {
      const leftLimits = isPaperTrading
        ? formatLimitValue(paper_tradings_limit) - paper_tradings
        : formatLimitValue(money_tradings_limit) - money_tradings;
      setLimitAlert({
        ratio: limitRatio,
        entity: isPaperTrading ? ENTITY.PAPER_TRADINGS : ENTITY.LIVE_TRADINGS,
        limits: leftLimits,
      });
    }
  }, [isPaperTrading, paper_tradings, paper_tradings_limit, money_tradings, money_tradings_limit]);

  return (
    <div>
      {showLimitAlert &&
        <LimitAlert {...limitAlert} />
      }

      <div>
        <StrategyCard strategy={strategy}
                      showViewAction={false}
                      showTradeAction={true}
                      showImage={false}
                      descriptionClassName={classes.descriptionFullText}
                      actionsClassName={classes.actionsLeft}
                      onLaunchFail={displayLimitAlert}
        />
      </div>

      <PerformanceDetails showBenchmark={false} {...props} />

      <TradesDetails
        {...props}
        ga={ga}
        filter={{ trading: props.record.id }}
        tableName={TABLES.LIVE_TRADING_TRADES}
        tradesOrdersResource="trading_orders"
        tradesListResource="trading-days/trading"
        tickersResource="trading/tickers"
      />


    </div>
  );
};

export default StrategyView;
