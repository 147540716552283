import * as React from "react";
import { widget } from "./charting_library";
import config from "../../../../../../config";
import Config, { Storage } from "../../../../../../config";
import { LOCALSTORAGE } from "../../../../../../shared/variables";
import moment from "moment-timezone";
import { UDFCompatibleDatafeed } from "./datafeeds/udf/lib/udf-compatible-datafeed";
import styles from "./TVWidget.module.scss";
import { mappedIndicators } from "./mapCriteriasToIndicators";
import isEqual from "lodash/isEqual";

export const formatTimeByTZ = (time) => {
  return moment(time).tz(Config.STOCK_TZ);
};

export const chartDates = (date) => {
  let currentDate = formatTimeByTZ(date);
  const dates = [date];
  //x2 to make sure we account for weekends and holidays
  for (let i = 0; i < CHART_MAX_DAYS * 2; i++) {
    currentDate = currentDate.subtract(1, "days");
    dates.push(currentDate.format("YYYY-MM-DD"));
  }
  return dates;
};

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function updateActiveTicker(symbolName, chartId) {
  const lsOrders = Storage.getItem(LOCALSTORAGE.ORDER_CHARTS);
  const orders = lsOrders ? JSON.parse(lsOrders) : {};
  orders[chartId].forEach((order) => {
    order.active = order?.ticker === symbolName;
  });
  Storage.setItem(LOCALSTORAGE.ORDER_CHARTS, JSON.stringify(orders));
}

function getActiveOrder(chartId) {
  const lsOrders = Storage.getItem(LOCALSTORAGE.ORDER_CHARTS);
  const orders = lsOrders ? JSON.parse(lsOrders) : {};
  return orders[chartId] ? orders[chartId].find((order) => order.active) : {};
}

const shapeConfig = { lock: true, zOrder: "bottom", disableSave: true, disableUndo: true };

function removeAllShapes(chart, name) {
  const allShapes = chart.getAllShapes();
  allShapes.forEach((shape) => {
    if (shape.name === name) chart.removeEntity(shape.id);
  });
}

function removePreviousStudies(chart) {
  const allStudiesId = chart.getAllStudies().map((study) => study.id) || [];
  if (Storage.getItem(LOCALSTORAGE.LAST_CHARTS_ID)) {
    allStudiesId.forEach((study) => {
      let ids = Storage.getItem(LOCALSTORAGE.LAST_CHARTS_ID);
      let resultIds = JSON.parse(ids);
      if (resultIds.includes(study)) {
        chart.removeEntity(study);
        Storage.setItem(LOCALSTORAGE.LAST_CHARTS_ID, JSON.stringify(resultIds.filter((id) => id !== study)));
      }
    });
  }
}

function addIndicatorsFromCriteria(chart, chartSettings) {
  removePreviousStudies(chart);

  chartSettings.indicators.forEach((ind) => {
    const indicator = mappedIndicators(chartSettings.neededIndicatorsForMapping, ind);
    indicator &&
      chart.createStudy(...indicator).then((res) => {
        if (!Storage.getItem(LOCALSTORAGE.LAST_CHARTS_ID)) {
          Storage.setItem(LOCALSTORAGE.LAST_CHARTS_ID, JSON.stringify([res]));
        } else if (Storage.getItem(LOCALSTORAGE.LAST_CHARTS_ID)) {
          let ids = Storage.getItem(LOCALSTORAGE.LAST_CHARTS_ID);
          let resultIds = JSON.parse(ids);
          resultIds.push(res);
          Storage.setItem(LOCALSTORAGE.LAST_CHARTS_ID, JSON.stringify(resultIds));
        }
      });
  });
}

function addNumbersFromCriteria(chart, chartSettings) {
  chartSettings.numbers.forEach((number) => {
    chart.createShape({ price: parseFloat(number) }, { shape: "horizontal_line", ...shapeConfig });
  });
}

function toggleIndicators(tvWidget, chart) {
  const lsStudies = Storage.getItem(LOCALSTORAGE.TV_STUDIES);
  const parsedStudies = lsStudies ? JSON.parse(lsStudies) : {};
  const studies = parsedStudies?.[chart.parentId];

  studies && tvWidget.activeChart().applyStudyTemplate(studies);

  tvWidget.subscribe("undo_redo_state_changed", ({ enableUndo }) => {
    if (enableUndo) {
      parsedStudies[chart.parentId] = tvWidget
        .activeChart()
        .createStudyTemplate({ saveSymbol: false, saveInterval: false });
      Storage.setItem(LOCALSTORAGE.TV_STUDIES, JSON.stringify(parsedStudies));
    }
  });

  addNumbersFromCriteria(tvWidget.activeChart(), chart);
  addIndicatorsFromCriteria(tvWidget.activeChart(), chart);
}

//max days shown on a chart
export const CHART_MAX_DAYS = 8;

function colorHours(chart, lastBarTime, maxPrice = 0) {
  const toTime = formatTimeByTZ(lastBarTime);
  toTime.set({ hour: 16, minute: 1, second: 0 });

  const afterShapes = [];
  const preShapes = [];

  for (let i = 0; i < CHART_MAX_DAYS; i++) {
    //after hours
    const afterStart = toTime.clone();
    afterStart.set({ date: afterStart.date() - i });
    const afterEnd = afterStart.clone();
    afterEnd.set({ hour: 23, minute: 59, second: 59 });

    afterShapes.push([
      { time: afterStart.valueOf() / 1000, price: 0 },
      { time: afterEnd.valueOf() / 1000, price: maxPrice * 2 },
    ]);

    //pre market hours
    const preStart = toTime.clone();
    preStart.set({ hour: 0, minute: 0, second: 0, date: preStart.date() - i });
    const preEnd = preStart.clone();
    preEnd.set({ hour: 9, minute: 29, second: 59 });

    preShapes.push([
      { time: preStart.valueOf() / 1000, price: 0 },
      { time: preEnd.valueOf() / 1000, price: maxPrice * 2 },
    ]);
  }

  removeAllShapes(chart, "rectangle");

  afterShapes.forEach((shape) => {
    chart.createMultipointShape(shape, {
      shape: "rectangle",
      ...shapeConfig,
      disableSelection: true,
      overrides: { backgroundColor: "#eff3fe", color: "#eff3fe" },
    });
  });

  preShapes.forEach((shape) => {
    chart.createMultipointShape(shape, {
      shape: "rectangle",
      ...shapeConfig,
      disableSelection: true,
      overrides: { backgroundColor: "#fef7ec", color: "#fef7ec" },
    });
  });
}

function drawEnterExitMarks(chart, containerId) {
  const interval = chart.resolution();
  const intervalToSeconds = interval * 60;

  const { ordersInfo } = getActiveOrder(containerId);

  const shapes = [];
  ordersInfo.forEach((order, index) => {
    const orderOpenTimeTZ = formatTimeByTZ(order?.created_datetime);
    const orderOpenTime = orderOpenTimeTZ.format(Config.TIME_FORMAT);
    const orderCloseTimeTZ = formatTimeByTZ(order?.datetime);
    const orderCloseTime = orderCloseTimeTZ.format(Config.TIME_FORMAT);
    const orderCloseTimeUnix = orderCloseTimeTZ.unix();

    const color = order?.type === "Enter" ? "#A8D7D2" : "#F5B1B0";
    const text = `${order?.type} ${orderOpenTime} / ${orderCloseTime}\n${order?.size} @ ${order?.price}\n\n`;

    const shape = { time: orderCloseTimeUnix, color, text };

    if (index === 0) {
      shapes.push(shape);
    } else {
      const prevOrderCloseTimeTZ = formatTimeByTZ(ordersInfo[index - 1]?.datetime);
      const prevOrderCloseTimeUnix = prevOrderCloseTimeTZ.unix();
      let prevShape = shapes[shapes?.length - 1];

      if (Math.abs(orderCloseTimeUnix - prevOrderCloseTimeUnix) < intervalToSeconds) {
        shapes[shapes?.length - 1] = { ...prevShape, color: "#C8CDDA", text: prevShape.text + text };
      } else {
        shapes.push(shape);
      }
    }
  });

  removeAllShapes(chart, "note");
  shapes.forEach(({ time, text, color }) => {
    chart.createMultipointShape([{ time, channel: "high" }], {
      shape: "note",
      ...shapeConfig,
      text,
      overrides: {
        fontsize: 12,
        textColor: "#131722",
        markerColor: color,
        backgroundColor: "#fff",
        borderColor: color,
      },
    });
  });
}

function dataReadyCallback(activeChart, lastBarTime, containerId, tvWidget, chartProps) {
  return function () {
    const maxPrice = activeChart.getPanes()[0].getMainSourcePriceScale().getVisiblePriceRange()?.to;
    drawEnterExitMarks(activeChart, containerId);
    colorHours(activeChart, lastBarTime, maxPrice);
    toggleIndicators(tvWidget, chartProps);
  };
}

export class TVWidget extends React.PureComponent {
  static defaultProps = {
    width: "100%",
    height: 600,
    symbol: "AAPL",
    interval: "1",
    container: "tv_chart_container",
    datafeedUrl: config.UDF_HOST,
    libraryPath: "/tradingview/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: false,
    studiesOverrides: {},
    studies: [],
  };

  tvWidget = null;

  componentDidMount() {
    const widgetOptions = {
      width: this.props.width,
      height: this.props.height,
      symbol: getActiveOrder(this.props.containerId)?.ticker,
      datafeed: new UDFCompatibleDatafeed(config.UDF_HOST, this.props.containerId),
      interval: this.props.chart?.interval,
      container: this.props.containerId,
      library_path: this.props.libraryPath,

      locale: getLanguageFromURL() || "en",
      enabled_features: ["use_localstorage_for_settings"],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      disabled_features: [
        "go_to_date",
        "study_templates",
        // "left_toolbar",
        "header_screenshot",
        "header_compare",
        "header_undo_redo",
        "header_saveload",
        "control_bar",
      ],
      enable_publishing: false,
      timezone: "exchange",
      allow_symbol_change: true,
      //changed to 7D to show all the data
      timeframe: "7D",
      time_frames: [
        { text: "1D", resolution: "1" },
        { text: "3D", resolution: "3" },
      ],
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;
    window.widget = tvWidget;

    tvWidget.onChartReady(() => {
      const chart = tvWidget.activeChart();

      const lastBarTime = chart.getVisibleRange()?.to * 1000;

      chart.onSymbolChanged().subscribe(null, ({ ticker }) => {
        updateActiveTicker(ticker, this.props.containerId);
      });

      chart.dataReady(dataReadyCallback(chart, lastBarTime, this.props.containerId, tvWidget, this.props.chart));
      chart
        .onDataLoaded()
        .subscribe(null, dataReadyCallback(chart, lastBarTime, this.props.containerId, tvWidget, this.props.chart));
    });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.chart.indicators, prevProps.chart.indicators)) {
      addIndicatorsFromCriteria(this.tvWidget.activeChart(), this.props.chart);
    }
    if (this.props.chart?.interval !== prevProps.chart?.interval) {
      this.tvWidget.activeChart().setResolution(this.props.chart?.interval);
    }
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      removePreviousStudies(this.tvWidget.activeChart());
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div id={this.props.containerId} className={"TVWidget"} />
        <a href="https://www.algoseek.com/" target="_blank" rel="noopener noreferrer" className={styles.link}>
          data by algoseek.com
        </a>
      </div>
    );
  }
}
