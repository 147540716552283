import React from "react";
import { useDispatch } from "react-redux";
import styles from "./BrokerTerms.module.css";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-admin";
import { formatNumberValue, setGaId } from "../../../../../../shared/utils";
import { Collapse } from "@material-ui/core";
import { LINK_BROKER_ACCOUNT } from "../../../../../../store/sagas/brokersSaga";
import { LINK_STATUSES } from "../../../../../../store/reducers/brokersReducer";

export default ({ isOpen, onAgree, onCancel, ga, brokerData, brokerName }) => {
  const t = useTranslate();

  const dispatch = useDispatch();

  const agreeButtonId = setGaId(
    ga?.category,
    ga?.action ? `${ga?.action}-agree-and-link-account` : "agree-and-link-account"
  );

  const linkAccount = (id) => {
    dispatch({ type: LINK_BROKER_ACCOUNT, payload: { brokerName, id } });
    onCancel();
  };

  const hasSubAccounts = brokerData?.data?.length && brokerData?.status === LINK_STATUSES.ACCOUNT_NOT_LINKED;

  return (
    <Collapse in={isOpen}>
      <div className={styles.agreement}>
        <div className={styles.disclaimer}>
          {t("profile.broker_disclaimer")}
          {/*<div>{t("profile.see_tos")}</div>*/}
        </div>
        {!hasSubAccounts && (
          <div className={styles.agreementButtons}>
            <Button onClick={onAgree} className={styles.agreeButton} id={agreeButtonId}>
              {t("profile.agreeAndLink")}
            </Button>

            <Button onClick={onCancel} className={styles.cancelButton}>
              {t("profile.cancel")}
            </Button>
          </div>
        )}

        {hasSubAccounts ? (
          <div className={styles.multiAcc}>
            <span className={styles.multiAccHead}>{t("multiAccounts.chooseAcc")}</span>
            {brokerData?.data?.map((el) => {
              return (
                <div onClick={() => linkAccount(el.id)} className={styles.multiAccItem} key={el.id}>
                  <span className={styles.multiAccLink}>
                    {brokerData?.shortName} {el.type} #{el.id}
                  </span>
                  <span className={styles.multiAccBuyingPower}>{`${t("brokers.buying_power")} ${formatNumberValue(
                    el.buying_power,
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 0,
                      style: "currency",
                      currency: "USD",
                    }
                  )}`}</span>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </Collapse>
  );
};
