import React from "react";
import CompactShowLayout, {
  compactShowLayoutPropTypes,
} from "./CompactShowLayout";

const GridShowLayout = (props) => {
  return (
    <CompactShowLayout
      {...props}
      layoutComponentTypeCheck={(field) =>
        !field.type || field.type.displayName !== "WithStyles(ForwardRef(Grid))"
      }
    />
  );
};

GridShowLayout.propTypes = {
  ...compactShowLayoutPropTypes,
};

export default GridShowLayout;
