export const en = {
  common: {
    required: "Required",
    maximum: "Maximum",
    minimum: "Minimum",
    cant_save: "Something went wrong, cannot perform operation",
    created: "Created",
    maxLength: "Value is too long",
    tooLarge: "Value is too large",
    mustBePositive: "Must be a positive value",
    not_available: "n/a",
    market: "Market",
    doubleclick_to_open: "Double click to expand",
    wrong_url: "Wrong url address",
    wrong_twitter: "The link must start with https://twitter.com/...",
    clear: "Clear",
    select_all: "Select All"
  },
  autocomplete: {
    required_message: "Required",
    unavailable_screener:
      "Screener(s) used in this Algo is not available any more, you can still use the Algo with other Tickers/Screeners",
  },

  intervals: {
    only_one: "Showing %{interval} and Indicators because of different timeframes in criteria. Show ",
  },

  unexpected_error: {
    header: "An Unexpected Error Has Occurred",
    contact_support: "support@breakingequity.com",
    summary1: "Execution failed due to an unexpected error. You may try to relaunch it or email ",
    summary2: " if the issue persists.",
    click_here: " Click here",
    to_copy: " to copy the detailed information on the exception and share it with your request.",
    clipboard: "Error details are copied to clipboard",
    want_more: "Want more? Contact us at",
  },

  cutoff_labels: {
    volume_desc: "Highest Daily Volume",
    volume_asc: "Lowest Daily Volume",
    price_desc: "Highest Share Price",
    price_asc: "Lowest Share Price",
    range_desc: "Highest Price Range",
    range_asc: "Lowest Price Range",
  },

  cutoff_values: {
    volume_desc: "TOP %{num} | HIGHEST DAILY VOLUME",
    volume_asc: "TOP %{num} | LOWEST DAILY VOLUME",
    price_desc: "TOP %{num} | HIGHEST SHARE PRICE",
    price_asc: "TOP %{num} | LOWEST SHARE PRICE",
    range_desc: "TOP %{num} | HIGHEST PRICE RANGE",
    range_asc: "TOP %{num} | LOWEST PRICE RANGE",
  },

  fieldsName: {
    description: "Description",
    enter_interval: "Enter Interval",
    exit_interval: "Exit Interval",
    filter_interval: "Filter Interval",
    max_risk_per_day: "Risk/Day",
    max_risk_per_trade: "Risk/Trade",
    cutoff_method: "Ticker Limit",
    filter_criteria_interval: "Filter Interval",
    interval: "Interval",
    name: "Name",
    tags: "Tags",
    not_name: "Name field should not be empty",
    order_validity: "Valid For",
    scoring_interval: "Score Interval",
    sort_criteria_interval: "Score Interval",
    sort: "Sort",
    broker: "Broker",
    regular_days: "Regular Trading Hours",
    early_close_days: "Early Close Trading Hours",
    error_details: "Error Details",
    initial_capital: "Initial Capital, $",
    force_exit_at: "Auto Exit",
  },
  data_grid: {
    configure_columns: "Configure columns",
    show_columns: "SHOW COLUMNS",
  },
  screeners: {
    menu: "Screeners",
    clone: "This is a cloned Screener, it is not saved yet, click “Save” or “Save & Launch” to save",
    blocks: {
      screener_params: "Screener Parameters",
      backtest_params: "Backtest Parameters",
      daily_result: "Daily Results",
      backtest_list: "Backtests",
    },
    daily_criteria: "Criteria on Daily Bars",
    dateRange: "Date Range",
    successfully_shared: "Screener has been successfully shared",
    successfully_archived: "Screener has been successfully archived",
    shared: "Shared",
    create: "Create Screener",
    screener: "New",
    filter_criteria: "Filter criteria",
    filter_interval: "Filter interval",
    filter_criteria_helper:
      "Filter criteria is executed on daily bars and filters out the tickers based on the criteria defined",
    interval: "Interval",
    sort_criteria: "Score criteria",
    sort_interval: "Score interval",
    sort_criteria_helper: "Sort criteria allows to sort/prioritize multiple tickers found through Filter Criteria",
    asc: "Ascending",
    desc: "Descending",
    save_screener: "Save Screener",
    on_save: "Screener has been successfully saved",
    on_update: "Screener has been successfully updated",
    sort: "Sort",
    screenerParameters: "Screener Parameters",
    date: "Date",
    highestScore: "Highest Score",
    lowestScore: "Lowest Score",
    numberOfTickers: "Tickers",
    topTickers: "Top Tickers",
    filter: {
      days: "Filter Days",
    },
    tickers_indexes: "Tickers / Indexes",
  },
  force_exit: {
    end_of_trading_hours: "END OF TRADING HOURS",
    end_of_market_hours: "END OF MARKET HOURS",
    never: "NEVER (HOLD OVERNIGHT)",

    end_of_trading_hours_short: "ETH",
    end_of_market_hours_short: "EOD",
    never_short: "HOLD",
  },
  order: {
    type: "Order Type",
    valid: "Valid",
    orders: "Orders",
    order: "ORDER",
    interval: "Interval",
    validity: "Valid For",
  },
  phone: {
    validate: {
      format: "Must be a valid phone",
      required: "Phone Number is required",
    },
  },
  password: {
    validate: {
      required: "Password is required",
      format: "Must have at least 6 characters",
    },
  },
  email: {
    validate: {
      required: "Email is required",
      format: "Must be a valid email",
    },
  },
  name: {
    validate: {
      required: "Name is required",
    },
  },
  number: {
    natural: "Natural number required",
    max2decimals: "2 decimals max",
    naturalRange: "TD: whole number from 1 to 99 required",
    decimalRange: "Number from 0.1 to 99.9 required",
  },
  "screener-backtests": {
    successfully_archived: "Backtest has been successfully archived",
  },
  backtest: {
    blocks: {
      params: "Algo & Backtest Parameters",
      benchmark: "Performance",
      trades: "Trades Details",
    },
    reconciliation: "Backtest execution may take longer than usual due to reconciliation logic",
    win_loss: "Win/Loss",
    winsLossRatio: "Win/Loss",
    losses: "Loss",
    wins: "Win",
    winRatio: "Win, %",
    lossRatio: "Loss, %",
    reward_risk: "Reward/Risk",
    reward: "Reward",
    money_made: "Reward",
    risk: "Risk",
    money_lost: "Risk",
    reward_risk_helper: "Reward is cumulative amount of money made where Risk is cumulative amount of money lost",
    active_days: "Active Days",
    alpha: "Alpha",
    backtests: "Backtests",
    beta: "Beta",
    cancel: "Cancel",
    cant_load: "Something went wrong, please try refreshing the page",
    capital: "Capital",
    initial_capital: "Capital",
    completed_atypically: "Has expired, canceled orders or order slippage",
    created: "Created",
    created_date: "Created",
    date_range: "Date Range",
    enter_criteria: "Enter Criteria",
    exit_criteria: "Exit Criteria",
    from: "From",
    from_date: "From Date",
    start_date: "From Date",
    heatmap: "Heatmap",
    max_return_pct: "Max Gain, %",
    mdd: "MDD",
    moneyTraded: "Money Traded",
    numOfTrades: "Number of Trades",
    trades: "Trades",
    screener_not_shared: "Used Screener is not shared",
    screener_not_owned_shared: "Used Screener is not owned or shared",
    operation_error: "Something went wrong, cannot perform operation",
    orderBy: "Order by",
    return: "Gain",
    return_value: "Gain",
    return_pct: "Gain, %",
    shared: "Shared",
    status: "Status",
    successfully_archived: "Backtest has been successfully archived",
    successfully_launched: "Backtests have been successfully launched",
    successfully_shared: "Backtest has been successfully shared",
    ticker: "Ticker",
    ticker_benchmark: "Ticker/Benchmark",
    to: "To",
    to_date: "To Date",
    end_date: "To Date",
    eod: "EOD",
    eod_value: "EOD Value",
    sharpe_ratio: "Sharpe Ratio",

    regular_days_from: "Regular From",
    regular_days_to: "Regular To",
    early_close_days_from: "Early From",
    early_close_days_to: "Early To",

    total_tickers: "Tickers",
    validate: {
      date_invalid: "Must be a valid date",
      date_required: "Date is required",
      min_date: "Min date %{date}",
      max_date: "Max date %{date}",
      date_future: "Date cannot be in future",
      date_start_after_end: '"From" cannot be after "To"',
      init_required: "Initial capital cannot be empty",
      ticker_error: "Tickers cannot be empty",
    },
    volume: "Volume",
    saveLaunchBacktests: "Save & Launch",
    save: "Save",
    loadRecent: "Load recent values",
    benchmark_ticker: "Benchmark Ticker",
    tickers_screeners: "Tickers / Screeners",
    load_to_current_algo: "Backtest params have been copied to the Algo",
    load_to_current_screener: "Backtest params have been copied to the Screener",
    copy_to_new_algo: "Algo is created from the Backtest",
  },
  breadcrumb: {
    //  we decided to rename -> looks a bit weired
    Algos: "Algos",
    algos: "Algos",
    createAlgo: "New Algo",
    paperTrading: "Paper Trading (Demo)",
    moneyTrading: "Money Trading (Demo)",
  },
  trading_statuses: {
    failed: "failed",
    stopped: "stopped",
    paper_live: "paper live",
    money_live: "money live",
  },
  trading_orders: {
    active: "active order",
  },
  strategies: {
    trade_live: "Launch Live",
    view_more: "View More"
  },
  tradings: {
    successfully_shared : "Trading has been successfully shared/unshared",
    clone_failed: "Could not create trading. Upgrade your plan to gain full access.",
    tradings: "Tradings",
    clone: "This is a cloned Trading, it is not saved yet, click “Save” or “Save & Launch” to save",
    create_paper: "Create Paper Trading",
    paper: "New",
    create_money: "Create Live Trading",
    money: "New",
    algo_type: "Type",
    created: "Created",
    last_trade: "Last Trade",
    trades: "Trades",
    trades_today: "Trades Today",
    return: "Gain",
    return_pct: "Gain, %",
    gain_today: "Gain Today",
    gain_today_pct: "Gain Today, %",
    gain_prior_day_pct: "Gain Prior Day, %",
    gain_prior_day: "Gain Prior Day",
    capital: "Initial Capital",
    win_loss: "Win/Loss",
    losses: "Loss",
    wins: "Win",
    winRatio: "Win, %",
    lossRatio: "Loss, %",
    reward_risk: "Reward/Risk",
    reward: "Reward",
    risk: "Risk",
    engine: "Engine",
    broker: "Broker",
    date: "Date",
    saved: "Trading has been successfully saved",
    start: "Trading is successfully started",
    stop_exit: "Stopping the Trading and exiting positions",
    save: "Save",
    saveLaunchTrading: "Save & Launch",
    cancel: "Cancel",
    ticker: "Ticker",
    ticker_screener: "Ticker / Screener",
    alpha: "Alpha",
    beta: "Beta",
    mdd: "MDD",
    sharpe_ratio: "Sharpe Ratio",
    successfully_stopped: "Trading is successfully stopped",
    successfully_stopped_and_archived: "Trading is successfully stopped & archived",
    successfully_archived: "Trading is successfully archived",
    select_paper_broker: "Select a BROKER ACCOUNT to LAUNCH PAPER TRADING",
    select_money_broker: "Select a BROKER ACCOUNT to LAUNCH LIVE TRADING",
    stop_to_edit: "Stop Trading in order to Edit",
    not_linked: "BROKER ACCOUNT is not linked",
    link_paper_broker: "You need to LINK BROKER ACCOUNT to LAUNCH PAPER TRADING",
    link_money_broker: "You need to LINK BROKER ACCOUNT to LAUNCH LIVE TRADING",
    breaking_equity_engine: "Breaking Equity Engine",
    trading_parameters: "Trading parameters",
    custom_broker: "Existing broker account",
    no_paper: " (No Paper Trading)",
    exit_estimation: {
      message: "Cleaning up orders and positions to perform Exit. The process may take up to %{estimate} or so",
      MINUTE: "minute",
      HOUR: "hour",
      DAY: "day",
    },
    broker_validation: {
      TRADING_SUSPENDED:
        'Cannot start Trading. Please enable trading (uncheck "Trades Suspended") under "Account Configuration" in order to run Algo.',
      ACCOUNT_IS_NOT_ACTIVE: "Cannot start Trading. Broker account does not support Live Trading.",
      INSUFFICIENT_BUYING_POWER: "Cannot start Trading. Insufficient broker account buying power to run Algo.",
      LONG_SHORT_SAME_TICKER: "Cannot start Trading. Running Short and Long Algo for the same ticker is not supported.",
      SHORTING_NOT_ENABLED:
        'Cannot start Trading. Please make sure you have more than $2,000 in your broker account and shorting is enabled ("No Shorting" unchecked) under "Account Configuration" in order to run SHORT Algo.',
    },
    quick_stats: {
      gain_total: "total gain",
      gain_today: "today",
      gain7_days: "7 days",
      gain30_days: "30 days",
      include_archived: "Include Archived",
      header: "Cumulative performance",
    },
  },
  subscription: {
    subscription_changed: "You have successfully selected a Subscription",
  },
  button_labels: {
    select: "Switch to Plan",
    downgrade: "Downgrade",
    select_again: "Select again",
    start_trial: "Try for %{days} days",
  },
  odd: {
    or: "or",
  },
  action: {
    save: "Save",
    cancel: "Cancel",
    nextStep: "next step",
    apply: "Apply Changes",
    launchBacktests: "Save & Launch",
  },
  not_found: {
    title: "Not Found",
    message: "404: Page not found",
    back: "Go Back",
    no_records: "No Records",
    //we broke backward compatibility in https://breakingequity.atlassian.net/browse/MVP1-1311 and hence needed to guide Users
    backtest_no_trades_rerun: "Seems likes this is an old Backtest that uses deprecated data format.",
    backtest_no_trades_rerun2: " to the current Algo to rerun the Backtest and see the trades list.",
    load_to_current_algo_btn: "Copy Backtest Params ",
    no_screeners: "Looks like you do not have any Screeners.",
    no_algos: "Looks like you do not have any Algos.",
    no_paper: "Looks like you do not have any Paper Tradings.",
    no_money: "Looks like you do not have any  Live Tradings.",
  },
  profile: {
    agreeAndLink: "Agree & Link Account",
    relink: "Relink Account",
    relinkDescription: "There are problems linking your broker account. Please try again.",
    broker_disclaimer:
      "Breaking Equity is neither a broker, nor provides investment advice." +
      " Breaking Equity is a platform designed and built for user generated trade testing and algorithms." +
      " It is not intended to be trading or investing advice. Breaking Equity does not recommend stocks, equities," +
      " or any other vehicle to buy or sell. Breaking Equity is a platform to enable you to make your own investment" +
      " decisions. All data, analysis, tests, metrics, transactions, signals, and platform are presented" +
      " ‘AS-IS’ and without warranty. Breaking Equity does not guarantee performance and holds no liability" +
      " for your trading, algorithms or executions via broker services. Please invest wisely." +
      " Please do your own research and due diligence before trading or investing.",
    brokers_tab: "Brokers",
    cancel: "Cancel",
    cant_save: "Cannot save profile",
    error: "Error",
    linkAccount: "Link",
    linked: "Linked",
    profile_tab: "Account",
    connectionErrorText:
      "Could not connect to the broker. You may fix the issue by re-linking your account." +
      " If the error does not resolve please contact the Breaking Equity administrator. ",
    connectionErrorTitle: "Broker connection error",
    connectionValid: "Account is valid and has no problems",
    see_tos: "See TERMS OF SERVICE tab for complete disclaimer information",
    subscription_tab: "Subscription",
    gifts_tab: "Referrals",
    tos: "Terms of Service Agreement",
    tos_tab: "Terms",
    updated: "Profile has been successfully updated",
    coming_soon: "Coming Soon",
    under_consideration: "Under Review",
    coming_jan_2022: "Coming Jan 2022",
  },
  brokers: {
    name: "Name",
    buying_power: "Buying Power",
    live: "Live",
    live_trading: "Live Trading",
    paper: "Paper",
    paper_trading: "Paper Trading",
    shorting: "Shorting",
    acc_is_unlinked: "%{name} account is unlinked",
  },
  authorization: {
    full_name: "Full Name",
    email: "Email",
    phone: "Phone Number",
    password: "Password",
    date_of_birth: "Date of birth",
    time_zone: "Time Zone",
    country: "Country",
    bio: "Bio",
    website: "Website",
    twitter: "Twitter",
    roles: "Roles",
    timezone: "Time Zone",
    cant_login: `Can't log in?`,
    sign_up: `Sign up for an account`,
    login: "Log in",
    have_account: "Already have an account?",
    with_google: "Continue with Google",
    with_facebook: "Continue with Facebook",
    auth_error: "Something went wrong, please try again",
    profile_error: "Please, sign up.",
    forgot_password: {
      reset_pass: "Reset your password",
      message:
        "Enter the email address to which the account is registered. We will send you a link to reset your password.",
      success: "We have sent you a link to reset your password. Check your email.",
      btn_label: "Send reset link",
    },
    created: "Created",
    last_run: "Last Run",
    type: "Type",
    author: "Author",
    launch: "Launch",
    ended_session: "Your session has ended, please reconnect.",
    resend_email: "Resend Email",
    mobile_resend_email: "Resend",
    check_email: "Please verify your account.",
  },
  users: {
    create: "Create User",
    enabled: "Enabled",
    joined: "Joined",
  },
  leaderboard: {
    rank: "Rank",
    user_name: "User",
    trades_made: "Trades Made",
    capital_traded: "Capital Traded",
    traded: "traded",
    trades: "trades",
    start_date: "Started"
  },
  watchlists: {
    filters_menu: "SHOW FILTERS",
    menu: "Watchlists (beta)",
    filter_tooltip: "Filter values for '%{filter_name}'. Leave empty to use all values",
  },
  algos: {
    menu: "Backtesting",
    clone: "This is a cloned Algo, it is not saved yet, click “Save” or “Save & Launch” to save",
    filter: {
      mine: "MY ALGOS",
      archived: "ARCHIVED",
      all: "ALL USERS",
    },
    blocks: {
      algo_params: "Algo Parameters",
      backtest_params: "Backtest Parameters",
      backtest_list: "Backtests",
    },
    validate: {
      time_required: "Time is required",
      time_invalid: "Invalid time",
      time_min: "Minimum 09:30 AM",
      time_max: "Maximum 04:00 PM",
      time_max_early: "Maximum 01:00 PM",
    },
    criteria: {
      regular_days: "Regular Day",
      early_close_days: "Early Close Day",
    },
    helpers: {
      expand_criteria: "Expand to see enter and exit criteria details",
      type: "Hola amigo! Here will be placed some tooltip",
      order_type: "Hola amigo! Here will be placed some tooltip",
      description: "You may want to describe your Algo here for future references...",
      stop_loss: "Hola amigo! Here will be placed some tooltip",
      enter_criteria: {
        lbl: "Enter Criteria",
        msg: "When criteria is met the engine enters the market purchasing maximum number of whole shares",
      },
      exit_criteria: {
        lbl: "Exit Criteria",
        msg: "When criteria is met the engine exits the market",
      },
      reg_days: "Hola amigo! Here will be placed some tooltip",
      early_close_days: "Hola amigo! Here will be placed some tooltip",
    },
    additCriteria: "No additional criteria set",
    try_algo_error: "Could not try the selected Algo. If you believe this is a mistake please contact administrator.",
    enabled: "Enabled",
    enter_interval: "Enter Interval",
    exit_interval: "Exit Interval",
    roles: "Roles",
    algo_type: "Type",
    created: "Created",
    create: "Create Algo",
    algo: "Create",
    last_run: "Last run",
    author: "Author",
    launch: "Launch",
    stop_loss: "Stop loss at",
    maxRiskTrade: "Max risk per trade",
    maxRiskHint: "When set in $, the amount is divided by number of shares in a position",
    maxRiskDay: "Max risk per day",
    maxTrades: "Max Trades per Day",
    saved: "Algo has been successfully saved",
    name: "Name",
    descr: "Description (optional)",
    enter_criteria: "Enter Criteria",
    enter_criteria_helper:
      "When criteria is met the engine enters the market purchasing maximum number of whole shares",
    exit_criteria: "Exit Criteria",
    exit_criteria_helper: "When criteria is met the engine exits the market",
    limit_order: "Limit Orders",
    market_price: "Market Orders",
    useStopTrail: "Trailing",
    tsl: "TSL",
  },
  resources: {
    users: {
      roles: "Roles",
      fields: {
        "roles-outlined-label": "Roles",
      },
    },
  },
  alpaca: {
    connect_error: "Alpaca Authentication Invalid",
  },
  dashboard: {
    explore: "Explore Sample Algos",
    explore_algos: "Explore Algos",
    create_algo: "Create Algo",
    create_new_algo: "Create New Algo",
    new_algo: "New Algo",
    try_algo: "Try Algo",
    try: "Try",
    view_algo: "View",
    ticker: "Trading",
    asset: "Asset",
    return: "Return",
    alpha: "Alpha",
  },
  subscriptions: {
    paid_billing: "Auto-renews, billed monthly",
    free_billing: "Free forever",

    active: "Active",
    free: "Free",
    no_auto_renew: "Does not auto-renew",
    auto_renews: "Auto-renews, limits refreshed monthly",
    unlimited: "Everything is unlimited.",

    payment_on: "Renews on",
    trial_ends: "Trial ends",

    professional_description: "Everything in Marketplace + full access to Algo Lab, including Screeners, Backtesting, Paper Trading",
    algolab_description: "Everything in Marketplace + full access to Algo Lab, including Screeners, Backtesting, Paper Trading",
    algolabpro_description: "Everything in Algo Lab + additional Live and Paper Tradings",
    marketplace_description: "Full access to Marketplace and one click Broker integrations with Live Trading",
    basic_description: " For those who are looking to improve their trading",
    starter_description: "For those who start learning algo-trading",
    free_description: "Free access to explore Marketplace and automated trading capabilities",

    no_info: "No info",
    scheduled_update: "Subscription will auto-renew on %{date}",
    current_limits: "Remaining Quotas:",
    backtests: "Backtests / month",
    algo: "Algo",
    screener: "Screener",
    paper_trading: "Paper Strategy",
    money_trading: "Live Strategy",
    strategies: "Strategies",

    algos: "DIY Algos",
    screeners: "Screeners",
    paper_tradings: "Paper Strategies",
    money_tradings: "Live Strategies",
    higlight_type: {
      popular: "Most Popular",
      current: "Current Plan",
    },
    details: {
      backtests: "BACKTESTS/MONTH",
      screeners_limit: "TICKERS SCREENING",
      algos_limit: "LIVE ALGOS",
    },
    features: {
      paper_trading: "Paper Trading",
      real_money_trading: "Real Money Trading",
      priority_support: "Priority support",
    },
    choose_plan: "Choose Plan",
    fix_payment: "Fix Payment",
    free_tier: "Subscription has been successfully updated",
    free_tier_doc:
      "You are on a Free plan. The plan allows %{algos_limit} Algo(s) and %{backtests_limit} Backtests / month. It’s completely free and limits refresh every month. To continue developing, testing and automating your trades, upgrade to a paid plan below.",

    successfully_updated: "Subscription has been successfully updated",
    successfully_updated_doc:
      "Congrats! You’re on your way to automating your trades. Any time you need more you can come back to the page an upgrade your plan.",

    plan_update_failed: "Operation failed, please try again or contact support if the issues persists",
    payment_declined: "Your payment got declined",
    payment_declined_doc: "You may try refreshing the page and repeating the action. If that does work please ",
    payment_failed: "Payment Failed",
    payment_failed_subscription: "Payment failed for your current plan",
    payment_failed_message_subscription: "Process payment or choose Free plan",
    payment_failed_message_hard: "Subscription payment issues detected.",
    payment_failed_message_soft: "Subscription payment issues detected.",
    contact_support: "contact support.",
    support_email: "mailto:support@breakingequity.com",

    upgrade_info: "Upgrade Information",
    upgrade_info_doc:
      "When you chose to upgrade the new limits and billing period are updated immediately. In case of the downgrade unused limit will be available until the end of the billing period",

    can_cancel: "Cancellation Information",
    can_cancel_doc:
      "We hope you enjoy the platform and find it useful. However we understand tha you may need to cancel. Please pay attention to the remaining limits which are available in the top right of your screen.",
    cancel_subscription: "Cancel Subscription",
    upgrade_cancellation: "Upgrade & Cancellation",

    sub_mechanics: "Subscription Information",

    algo_screeners_charges: "Algo, Screener, Trading Limits",
    algo_screeners_charges_doc_1:
      "• Affects the number of Algos, Screeners, Paper Tradings or Live Tradings you can have",
    algo_screeners_charges_doc_2: "• The limits are static and do not refresh monthly",
    algo_screeners_charges_doc_3:
      "• If you downgrade to a plan with lower limits only most recent Algos, Screeners, Paper Tradings or Live Tradings would be usable",

    backtest_charges: "Backtest Monthly Limits",
    backtest_charges_doc_1: "• Limits the number of Backtests you can run, refreshed monthly",
    backtest_charges_doc_2: "• Accounts for Algo and Screener Backtests",
    backtest_charges_doc_3: "• Launching a Backtest immediately affects the limits",
    backtest_charges_doc_4: "• Failed Backtests do not affect the limits",

    sub_help: "Getting Help",
    sub_help_doc1:
      "Should you need any help regarding the subscription please email us at support@breakingequity.com we would be glad to assist you",

    limit_close: "You are close to your limit",
    limit_close_desc: "You have only %{limits} %{entity} left.",
    limit: "You have reached a maximum number of %{entity}",
    limit_desc: "Navigate to the Profile page and upgrade to a paid Plan to gain full access",
    plan_includes_no_limit: "Upgrade the Subscription to access %{entity}",
    upgrade_plan: "Upgrade Subscription",
    disable_creating: "Current subscription limits do not allow creating a new %{entity}",
    performance: {
      heading: "There are no trades yet",
      text: "The chart will show up once there are executed trades",
    },
    see_the_deal: "See the Deal!",
    ends_in: "Ends in",
    discount_duration_once: "%{price} after one month",
  },
  errors: {
    http_load_err: "something went wrong",
  },
  guidance: {
    tipsToStart: "Tips to start",
    slide1: {
      heading: "Explore Samples",
      text: "Algo is basis for your Backtests and Tradings. Explore samples of create your own Algo.",
    },
    slide2: {
      heading: "Backtest your Algos",
      text: "Backtest you Algos against 15 year of historical data to make sure you all set to launch Trading.",
    },
    slide3: {
      heading: "Paper Trade",
      text: "Virtually test your trading-algo’s performance & profit potential in the Live Market using paper money.",
    },
    slide4: {
      heading: "Deploy Algo Live",
      text: "Link Breaking Equity with your existing broker and use your Algo to make real trades.",
    },
  },
  criteria: {
    required: "Required",
  },
  fullScreenEditor: {
    exit: "Exit",
    save_exit: "Save & Exit",
    enter: "Click to edit in full screen mode",
    search: "Search",
    clipboard: "Code is copied to clipboard",
    enter_fullscreen: "Enter fullscreen",
  },
  create_from_a_sample: {
    label: "Create from a sample",
    screeners_hint: " Create one from a sample or start from scratch by adding a new Screener.",
    algos_hint: " Create one from a sample or start from scratch by adding a new Algo.",
  },
  actions: {
    edit: "Edit",
    launch_live: "Launch Live",
    launch_paper: "Launch Paper",
    clone: "Clone",
    start: "Launch",
    stop: "Stop & Exit Market",
    rerun_backtest: "Rerun Backtest",
    copy_to_new_algo: "Export as Algo",
    copy_to_new_screener: "Export as Screener",
    archive: "Archive",
    share: "Share",
    stop_sharing: "Stop Sharing",

    // deep copy into trading
    bootstrap_paper: "Bootstrap Paper Trading",
    bootstrap_live: "Bootstrap Live Trading",

    unlink: "Unlink",
    validate: "Validate",
    link_more: "Link more accounts",
    copy_clipboard: "Copy to Clipboard"
  },
  helpers: {
    help: "Help",
    algo_parameters: "Click to see help and suggestions",
    algo_backtest_parameters: "Backtest Parameters",
    algo_backtests: "Backtests",
    input_tickers_screeners: "List of Tickers or Screeners. Each will launch a separate Backtest",
    input_ticker_screener_trading: "A Ticker or Screener to trade",
    input_from: "The start date of a Backtest",
    input_to: "The end date of a Backtest",
    input_capital:
      "Initial amount of capital in $ available to an Algo. All the money accumulated over time used daily for trading",
    input_benchmark:
      "Benchmark is a buy and hold long-term passive strategy where the Benchmark Ticker is held over the entire period of the Backtest",
    order_validity: "The amount of time the order is Valid For until expired",

    filter_interval: "Scale at which Screener evaluates the market and executes Filter Criteria",
    score_interval: "Scale at which Screener evaluates the market and executes Score Criteria",
    tickers_limit:
      "Number of tickers retained after Filter Criteria. Currently limited to top 10 tickers with most daily volume. In future will be configurable, including the number of tickers and cut off parameters",

    regular_days: "A time period when an Algo can enter a position on a Regular Day  ",
    early_close_days:
      "A time period when an Algo can enter a position on an Early Close Day like a day after Thanksgiving",
    limit_order:
      "Order type a Backtest places. Limit Order is a type of order to purchase or sell a security at a specified price or better",
    market_price:
      "Order type an Algo places. Market Order is a a type of order to purchase or sell a security at the best available price in the current market",
    backtest_user_guide: "Backtesting User Guide",
    screener_user_guide: "Screener User Guide",
    help_analasyng_backtests: "Backtesting 101",
    help_analasyng_screeners: "Screenering 101",
    max_risk_per_trade:
      "Maximum draw down per trade/position an Algo would tolerate before exiting the position. An exit is performed via a Stop Loss Order. When set in $, the amount is divided by number of shares in a position.",
    max_risk_per_day:
      "Maximum draw down per day an Algo would tolerate before exiting the position. An exit is performed via a Stop Loss Order. When set in $, the amount is divided by number of shares in a position.",
    max_entry_trades:
      "Maximum number of trades per day an Algo would execute. If Max Trades Per Day reached the Backtest would stop trading for the day",
    interval: "Scale at which an Algo evaluates the market and executes Enter and Exit Criteria",

    enter_interval: "Scale at which an Algo evaluates the market and executes Enter Criteria",
    exit_interval: "Scale at which an Algo evaluates the market and executes Exit Criteria",
    algo_doc_1:
      "Backtesting is a general method for seeing how well an Algo would have done ex-post. Backtesting assesses the viability of a trading strategy by discovering how it would play out using historical data. Backtesting is an important step to gain confidence before launching a Live Trading Algo.",
    algo_doc_2:
      "Runing a Backtest is safe and does not involve any real money. Go ahead and give it a try. If you are interested in leaning more about Backtesting take a look at ",
    screener_doc_1:
      "Screener is an instrument for choosing stocks based on a criteria. Screeners allow to find stocks that behave in a certain way.",
    screener_doc_2:
      "Runing a Screener is safe and does not involve any real money. Go ahead and give it a try. If you are interested in leaning more about Screeners take a look at ",
    need_help: "Need help?",
    start_overview_tour: "Overview Tour",
    start_algo_lab_tour: "Algo Lab Tour",
    start_paper_trading_tour: "Trading Tour",
    start_live_trading_tour: "Trading Tour",
    start_screeners_tour: "Screeners Tour",
    check_our_docs: "Check Docs",
    join_discord_community: "Join Discord",
    join_discord_trial1: "Not quite sure which option is right for you? ",
    join_discord_trial2: " to get free trial for any plan.",
    long: "Position an Algo enters. Long position refers to the purchase of an asset with the expectation it will increase in value — a bullish attitude",
    short:
      "Position an Algo enters. Short position refers to a trading technique in which an investor sells a security with plans to buy it later",
    enter_criteria:
      "Condition to enter the market. The condition is evaluated every interval. If Enter Criteria returns TRUE or a numeric value > 0 an Algo enters opens a position using all the capital available",
    exit_criteria:
      "Condition to exit the position. The condition is evaluated every interval. If Exit Criteria returns TRUE or a numeric value > 0 an Algo closes a position selling all the securities it’s holding",
    load_recent_values: "Loads recent values used in previous Backtest(s)",
    shared_screener:
      "This is a shared Screener available in read only mode. You can create a clone and modify it to your needs.",
    clone_screener: "Clone Screener",
    mobile_version:
      "You currently use a simplified mobile version of the platform. Please consider switching to desktop to unlock all the capabilities.",
    force_exit:
      "A time when an Algo will exits all the open positions automatically. The actual order will be created 3min in advance to ensure execution",
  },
  tour: {
    skip_tour: "Skip tour",
    start_tour: "Start tour",
    rerun_mobile: "You can always rerun the tour via link in main menu",
    rerun_desktop: "You can always rerun the tour",
    finish_tour: "Finish tour",
    next: "Next",
  },
  tourWelcome: {
    step0: {
      title: "Welcome Trader!",
      text: "It’s great to have you here. Let’s take a look at the features Breaking Equity has to offer, and how to create your first Algo.",
    },
    step1: {
      title: "Dashboard",
      text: "This is the Dashboard. Here you will find a library of pre-built Algo templates to help you get started.",
    },
    step2: {
      title: "Algo Lab",
      text: "This is your central hub from which  you can backtest ideas, scan thousands of stocks and find the best trading opportunities, and launch your Algo when it’s ready.",
    },
    step3: {
      title: "Ready to give it a try?",
      text: "Clicking the button will copy this pre-built Algo over to your Algo Lab where you can tune it further or use it as a template to create new Algos.",
    },
  },
  tourAlgo: {
    step0: {
      title: "Algo Lab Tour",
      text: "In this tour, you will learn how to build or modify your Algo and run Backtests.",
    },
    step1: {
      title: "Algo Parameters",
      text: "Here are all the parameters related to Algo. It consists of 4 sections.",
    },
    step2: {
      title: "Type and Name",
      text: "Here, you can name your Algo and chose between Short or Long Algo types.",
    },
    step3: {
      title: "Enter and Exit Criteria",
      text: "Enter and Exit Criteria are the heart of any Algo. These tell the Algo when to enter and exit the market. When the Enter Criteria is met, the Algo will enter the position. When the Exit Criteria is met, the Algo will exit. Check the full list of indicators available under the “Full Screen” editor.",
    },
    step4: {
      title: "Max Risk and Intervals",
      text: "Here, you can modify your Algo’s risk tolerance and market intervals.",
    },
    step5: {
      title: "Order Types and Trading Hours",
      text: "Here, you can control order types and define when the Algo trades.",
    },
    step6: {
      title: "Backtest Parameters",
      text: "Here, you can further define the Backtest by selecting the tickers or Screeners to trade. Once done, either Save the Algo for another time or Launch backtests.",
    },
    step7: {
      title: "Backtests List",
      text: "In this section, you can manage and view the results of all the completed Backtests.",
    },
  },
  tourAlgoMobile: {
    step0: {
      title: "Algo Lab Tour",
      text: "In this tour, you will learn how to use Algo Lab on a mobile device. You may consider switching to desktop to unlock all the capabilities.",
    },
    step1: {
      title: "Algo Parameters",
      text: "Here are all the parameters related to Algo. They are read-only in mobile version.",
    },
    step2: {
      title: "Backtest Parameters",
      text: "Here, you can further define the Backtest by selecting the tickers or Screeners to trade. Once done, either Save the Algo for another time or Launch backtests.",
    },
    step3: {
      title: "Backtests List",
      text: "In this section, you can manage and view the results of all the completed Backtests.",
    },
  },
  tourScreeners: {
    step0: {
      title: "Screener Tour",
      text: "In this tour, you will learn how to build or modify your Screener and run a Backtest.",
    },
    step1: {
      title: "Tickers or Indexes",
      text: "Here, you can define the universe of tickers to screen by selecting individual names or all tickers from an index such is S&P 500.",
    },
    step2: {
      title: "Filter and Score Criterias",
      text: "Filter and Score Criteria are the heart of any Screener. Filter Criteria is used to scan for tickers that match the condition defined. It only retains those ticker that matched the criteria. Score Criteria orders (scores) tickers matching the filter criteria either ascending or descending based on the criteria provided. ",
    },
    step3: {
      title: "Backtest Parameters",
      text: "Here, you can define the dates and either Save the Screener for another time or Launch Backtests.",
    },
    step4: {
      title: "Backtests List",
      text: "In this section, you can manage and view the results of all the completed Backtests.",
    },
  },
  tourScreenersMobile: {
    step0: {
      title: "Screener Tour",
      text: "In this tour, you will learn how to use Screeners on a mobile device. You may consider switching to desktop to unlock all the capabilities.",
    },
    step1: {
      title: "Screener Parameters",
      text: "Here, you can view all Screener parameters, including the universe of tickers or indexes to screen, Filter and Score Criteria and intervals.",
    },
    step2: {
      title: "Backtest Parameters",
      text: "Here, you can define the dates and either Save the Screener for another time or Launch Backtests.",
    },
    step3: {
      title: "Backtests List",
      text: "In this section, you can manage and view the results of all the completed Backtests.",
    },
  },
  tourPaperTrading: {
    step0: {
      title: "Paper Trading Tour",
      text: "In this tour, you will learn how to build or adjust your Paper Trading and how to run it.",
    },
    step1: {
      title: "Algo Parameters",
      text: "You should be familiar with Algo parameters from Algo Lab. They are exactly the same in Paper Trading.",
    },
    step2: {
      title: "Trading Parameters",
      text: "Here, you can further define the Paper Trading by selecting the tickers or Screeners to trade, allocate initial capital. Once done, either Save the Trading for another time or Launch it.",
    },
  },
  tourPaperTradingMobile: {
    step0: {
      title: "Paper Trading Tour",
      text: "In this tour, you will learn how to use Paper Trading on a mobile device. You may consider switching to desktop to unlock all the capabilities.",
    },
    step1: {
      title: "Algo Parameters",
      text: "Here are all the parameters related to Algo. They are read-only in mobile version.",
    },
    step2: {
      title: "Trading Parameters",
      text: "Here, you can further define the Paper Trading by selecting the tickers or Screeners to trade, allocate initial capital. Once done, either Save the Trading for another time or Launch it.",
    },
  },
  tourLiveTrading: {
    step0: {
      title: "Trading Tour",
      text: "In this tour, you will learn how to build or adjust your Trading and how to run it.",
    },
    step1: {
      title: "Algo Parameters",
      text: "Algo parameters allow to customize your trading logic, intervals and risk.",
    },
    step2: {
      title: "Trading Parameters",
      text: "Here, you can further define the Trading by selecting the tickers or Screeners to trade, allocate initial capital. Once done, either Save the Trading for another time or Launch it.",
    },
  },
  tourLiveTradingMobile: {
    step0: {
      title: "Live Trading Tour",
      text: "In this tour, you will learn how to use Live Trading on a mobile device. You may consider switching to desktop to unlock all the capabilities.",
    },
    step1: {
      title: "Algo Parameters",
      text: "Here are all the parameters related to Algo. They are read-only in mobile version.",
    },
    step2: {
      title: "Trading Parameters",
      text: "Here, you can further define the Live Trading by selecting the tickers or Screeners to trade, allocate initial capital. Once done, either Save the Trading for another time or Launch it.",
    },
  },
  verification: {
    check_email: "Take a moment to check your email and verify your account",
    resend_btn: "Resend Email",
  },
  referral: {
    error_header: "Cannot load referral details",
    error_desc:
      "There was an error loading referral details and stats, you may try refreshing the page or email support@breakingequity.com if the issue persists.",
    refer_friends: "Refer your friends to earn rewards.",
    learn_more: "Learn More",
    copy_link: "Copy Link",
    social_message: "Join me on Breaking Equity, the all-in-one platform for algo-trading. Sign up at {{shareUrl}}",
    explanation_1: "Sharing pays off with Breaking Equity",
    explanation_2:
      "Refer people you know by sharing your unique link. Give $10 off to a person you refer, and receive $10 credit for each referral made + $20 extra for each 10 referrals. Credits are automatically applied at any charge, whether it's a recurring subscription payment or an upgrade. Rewards are unlimited.",
  },
  multiAccounts: {
    chooseAcc: "Сhoose an account to link",
  },
};

export const ra = {
  auth: {
    username: "Email",
    sign_up: "Sign up",
  },
  notification: {
    deleted: "Record(s) successfully archived",
  },
  message: {
    unsaved_changes: "Leaving the page will result in loosing changes that were not saved.",
  },
};
