// public dev urls
export default {
    DSL_API_HOST: process.env.REACT_APP_DSL_API_HOST,
    UDF_HOST: process.env.REACT_APP_UDF_HOST,
    BACKEND_API: process.env.REACT_APP_BACKEND_API,

    DATE_FORMAT: 'MMM DD, \'YY',
    US_DATE_FORMAT: 'MM/DD/YYYY',
    EU_DATE_FORMAT: 'DD/MM/YYYY',
    INT_DATE_FORMAT: 'YYYY-MM-DD',
    DATETIME_FORMAT: 'MMM DD, \'YY h:mm a',
    TIME_FORMAT: 'h:mm a',
    STOCK_TZ: 'America/New_York',
    TOOLTIP_DELAY: 1000,
    REACT_APP_STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
}

//maybe we'll rewrite this to a better storage later
export const Storage = localStorage;