import React from "react";
import styles from "./DayResults.module.css";
import {
  Datagrid,
  Filter,
  List,
  SearchInput,
  TopToolbar,
  useListContext,
  useTranslate,
  NumberField,
} from "react-admin";
import Card from "../../../../components/Card/Card";
import { MomentField } from "../../../../components/MomentField";
import TopTickers from "../../../../components/TopTickers/TopTickers";
import { setGaId } from "../../../../shared/utils";
import { GA_CATEGORIES, TABLES } from "../../../../shared/variables";
import { ToggleButtonsField } from "../../../../components/ToggleButtonsField/ToggleButtonsField";
import ListPagination from "../../../../components/ListPagination/ListPagination";
import useGetItemsPerPage from "../../../../components/ListPagination/hooks/useGetItemsPerPage";

export const filterStatuses = Object.freeze({
  ALL: "ALL",
  EMPTY: "EMPTY",
  NOT_EMPTY: "NOT EMPTY",
});

export const DayResultsFilter = (props) => {
  return (
    <Filter variant="standard" {...props}>
      <SearchInput source="q" variant="standard" alwaysOn id={setGaId(GA_CATEGORIES.SCREENER_BACKTEST, "search")} />
    </Filter>
  );
};

const DayResultsToolbar = () => {
  const ga = { category: GA_CATEGORIES.SCREENER_BACKTEST };
  const { filterValues, setFilters } = useListContext();

  const statuses = Object.values(filterStatuses);
  const { ALL, EMPTY, NOT_EMPTY } = filterStatuses;

  const selectedSwitch = () => {
    if (filterValues.not_empty === true) return NOT_EMPTY;
    if (filterValues.not_empty === false) return EMPTY;
    return ALL;
  };

  const handleRequestSwitch = (event) => {
    const checked = event.target.id;
    switch (checked) {
      case EMPTY: {
        setFilters({ not_empty: false });
        break;
      }
      case NOT_EMPTY: {
        setFilters({ not_empty: true });
        break;
      }
      default:
        setFilters({});
    }
  };

  return (
    <TopToolbar>
      <ToggleButtonsField
        ga={ga}
        onChange={handleRequestSwitch}
        defaultValue={selectedSwitch()}
        inputNames={statuses}
        inputLabels={statuses}
      />
    </TopToolbar>
  );
};

const DayResults = (props) => {
  const t = useTranslate();

  const tableName = TABLES.SCREENER_DAY_RESULTS;
  const perPage = useGetItemsPerPage(tableName);

  return (
    <Card header={t("screeners.blocks.daily_result")}>
      <List
        actions={<DayResultsToolbar />}
        filters={<DayResultsFilter />}
        filter={{ screener_backtest: props.record.id }}
        basePath="screener_backtest_day_result"
        resource="screener_backtest_day_result"
        exporter={false}
        sort={{
          field: "date",
          order: "ASC",
        }}
        filterDefaultValues={{ not_empty: true }}
        bulkActionButtons={false}
        perPage={perPage}
        pagination={<ListPagination {...props} tableName={tableName} />}
        className={styles.resultList}
        hasShow={false}
        hasList={false}
        hasEdit={false}
        hasCreate={false}
      >
        <Datagrid size="medium" className={styles.datagrid}>
          <MomentField
            source="date"
            label={t("screeners.date")}
            cellClassName={`${styles.date} Other-Col Very-Short-Col`}
            headerClassName={styles.date}
          />
          <NumberField
            label={t("screeners.highestScore")}
            source="sort_highest_score"
            locales="en-EN"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }}
            cellClassName={`${styles.score} Other-Col Very-Short-Col`}
            headerClassName={styles.score}
          />
          <NumberField
            label={t("screeners.lowestScore")}
            source="sort_lowest_score"
            locales="en-EN"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }}
            cellClassName={`${styles.score} Other-Col Very-Short-Col`}
            headerClassName={styles.score}
          />

          <NumberField
            label={t("screeners.numberOfTickers")}
            source="number_of_tickers"
            cellClassName={`${styles.score} Other-Col Very-Short-Col`}
            headerClassName={styles.score}
            options={{
              maximumFractionDigits: 2,
            }}
          />
          <TopTickers
            label=""
            source="top_tickers"
            cellClassName={styles.tickersHeader}
            headerClassName={styles.tickersHeader}
          />
        </Datagrid>
      </List>
    </Card>
  );
};

export default DayResults;
