import React from "react";
import WideValue from "../../Rows/WideValue";
import { formatNumberValue } from "../../../shared/utils";

const QuickStatsValue = ({ value = 0, className }) => (
  <WideValue className={className} initialValueForColor={value}>
    {value > 0 ? "+" : ""}
    {formatNumberValue(value, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      style: "currency",
      currency: "USD",
    })}
  </WideValue>
);

export default QuickStatsValue;
