import React from "react";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import { Menu } from "@material-ui/core";
import MoreVertIcon from "react-feather/dist/icons/more-vertical";
import classes from "./PopupMenu.module.css";
import PopupMenuItem from "./components/PopupMenuItem/PopupMenuItem";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const PopupMenu = ({ button, menuItems = [], id = "demo" }) => {
  const availableMenuItems = menuItems.filter((item) => item?.condition);
  return availableMenuItems?.length ? (
    <div
      className={classes.wrapper}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <PopupState variant="popover" popupId={`${id}-popup-menu`}>
        {(popupState) => (
          <React.Fragment>
            {button ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                data-testid={`${id}-popup-button`}
                className={classes.button}
                {...bindTrigger(popupState)}
              >
                <ArrowDropDownIcon />
              </Button>
            ) : (
              <IconButton data-testid={`${id}-popup-button`} className={classes.icon} {...bindTrigger(popupState)}>
                <MoreVertIcon />
              </IconButton>
            )}
            <Menu
              {...bindMenu(popupState)}
              className={classes.menu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              {availableMenuItems.map((props, index) => (
                <PopupMenuItem key={index} {...props} popupState={popupState} />
              ))}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </div>
  ) : null;
};

export default PopupMenu;
