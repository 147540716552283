import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import { getBracket } from "../../../../../CriteriaInput/utils";

export const indicatorsForMapping = [
  "$BollingerBands",
  "$ExponentialMovingAverage",
  "$MACD",
  "$MACDHisto",
  "$MovingAverageSimple",
  "$RelativeStrengthIndex",
  "$StochasticFast",
  "$StochasticRSI",
  "$ConnorsRSI",
  "$SuperTrend",
  "$PivotPoint",
  "$CommodityChannelIndex",
  "$VWAP",
];

export function removeChartIndicatorsDuplicates(indicators) {
  if (!indicators.length) return indicators;
  let res = [...indicators];

  res.map((el) => {
    switch (el.name) {
      case "BBands": {
        return (el.name = "BollingerBands");
      }
      case "EMA": {
        return (el.name = "ExponentialMovingAverage");
      }
      case "MACDHisto": {
        return (el.name = "MACD");
      }
      case "SMA":
      case "SimpleMovingAverage": {
        return (el.name = "MovingAverageSimple");
      }
      case "RSI":
      case "RSI_SMMA":
      case "RSI_Wilder": {
        return (el.name = "RelativeStrengthIndex");
      }
      case "CCI": {
        return (el.name = "CommodityChannelIndex");
      }
    }
  });
  return res;
}

export function mappedIndicators(allIndicators, indicator) {
  allIndicators = allIndicators?.filter((el) => el.name === `$${indicator.name}`)[0];
  let params = [];
  allIndicators && allIndicators.params.forEach((el) => params.push([...Object.values(el)]));
  params = Object.fromEntries(params);
  switch (indicator.name) {
    case "BollingerBands": {
      let length = indicator.period || params.period;
      let mult = indicator.devfactor || params.devfactor;
      return ["Bollinger Bands", false, false, [length, mult]];
    }
    case "ExponentialMovingAverage": {
      let length = indicator.period || params.period;
      return ["Moving Average Exponential", false, false, [length]];
    }
    case "MACD": {
      let fastLength = indicator.period_me1 || params.period_me1;
      let slowLength = indicator.period_me2 || params.period_me2;
      return ["MACD", false, false, [fastLength, slowLength]];
    }
    case "MovingAverageSimple": {
      let length = indicator.period || params.period;
      return ["Moving Average", false, false, [length]];
    }
    case "RelativeStrengthIndex": {
      let length = indicator.period || params.period;
      return ["Relative Strength Index", false, false, [length]];
    }
    case "StochasticFast": {
      let length = indicator.period || params.period;
      return ["Stochastic", false, false, [length]];
    }
    case "StochasticRSI": {
      let lengthRSI = indicator.rsiperiod || params.rsiperiod;
      let lengthStock = indicator.period || params.period;
      let smoothK = indicator.kperiod || params.kperiod;
      let smoothD = indicator.dperiod || params.dperiod;
      return ["Stochastic RSI", false, false, [lengthRSI, lengthStock, smoothK, smoothD]];
    }
    case "ConnorsRSI": {
      let rsiLength = indicator.prsi || params.prsi;
      let upDownLength = indicator.pstreak || params.pstreak;
      let rocLength = indicator.prank || params.prank;
      return ["Connors RSI", false, false, [rsiLength, upDownLength, rocLength]];
    }
    case "SuperTrend": {
      let length = indicator.period || params.period;
      let factor = indicator.multiplier || params.multiplier;
      return ["SuperTrend", false, false, [length, factor]];
    }
    case "CommodityChannelIndex": {
      let length = indicator.period || params.period;
      return ["Commodity Channel Index", false, false, [length]];
    }

    case "VWAP": {
      let length = indicator.period || params.period;
      return ["VWAP"];
    }
  }
}

export function exportIndicators(crit) {
  if (!crit) return [];

  let flag = true;
  let notClosedFlag = false;
  let indicators = [];
  let noCloseIndicators = [];

  while (flag) {
    flag = false;
    //MVP1-2752: remove comments
    let parts = crit.replace(/#.*$/gm, "").split("$");

    parts.forEach(function (part, index) {
      if (getBracket(part) === "close") notClosedFlag = true;
      let subparts = part.split(")");
      if (subparts.length >= 2) {
        // let matches = [...part.matchAll(/(\w+)\s*=\s*(\w+)/g)];
        //extracting indicator params and their values
        //only taking the values before the 1st ) as params
        let matches = [...subparts[0].matchAll(/(\w+)\s*=\s*([.\w]+)/g)];

        if (matches.length) {
          let ind = {};
          ind.name = [subparts[0].match(/^\w+/g)][0][0];

          console.log(ind.name);
          console.log(matches);

          //what the hell is this?
          if (subparts[0].split(ind.name)[1][0] !== ".") {

            matches.forEach(match => {
              const paramName = match[1];
              const paramValue = match[2];
              ind[paramName] = paramValue;
              console.log(paramName + " = " + paramValue);
            });


          } else if (subparts[0].split(ind.name)[1][0] === ".") {
            let closeInd = [...subparts[1].matchAll(/\.(\w+)/g)];
            if (closeInd.length) {
              let resInd = window.INDICATORS.find((el) => el.series.includes(closeInd[0][1]));
              noCloseIndicators &&
                noCloseIndicators.forEach((el, idx) => {
                  if (el.name === resInd.name.replace("$", "")) {
                    noCloseIndicators[idx] = {
                      ...el,
                      [matches[0][1]]: matches[0][2],
                    };
                  }
                });
              indicators = [...indicators, ...noCloseIndicators];
              noCloseIndicators = [];
            }
          }
          indicators.push(ind);
          delete subparts[0];
          this[index] = subparts.join("");
          flag = true;
          notClosedFlag = false;
          return;
        }
      }
      if (subparts[0].length) {
        let ind = {};
        if ([subparts[0].match(/^\w+/g)][0] !== null) {
          ind.name = [subparts[0].match(/^\w+/g)][0][0];
          let neededIndicator = window.INDICATORS.filter((el) => el.name === `$${ind.name}`)?.[0];
          (neededIndicator?.params || []).forEach((el) => {
            if (subparts[0].includes(el.name)) {
              let param = subparts[0].split(el.name);
              ind[el.name] = +param[1].match(/=(\d+)/)?.[1] || "";
            }
          });
        } else {
          if (!ind.name && subparts[0]) {
            let resParam = [...subparts[0].matchAll(/(\w+)\s*=\s*(\w+)/g)];
            if (!!resParam.length && subparts[0].split(resParam[0][0])[1][0] === ".") {
              let neddedPart = subparts[0].split(resParam[0][0])[1];
              let closeInd = [...neddedPart.matchAll(/\.(\w+)/g)];
              let resInd = window.INDICATORS.find((el) => el.series.includes(closeInd[0][1]));
              noCloseIndicators &&
                noCloseIndicators.forEach((el, idx) => {
                  if (el.name === resInd.name.replace("$", "")) {
                    noCloseIndicators[idx] = {
                      ...el,
                      [resParam[0][1]]: resParam[0][2],
                    };
                  }
                });
              indicators = [...indicators, ...noCloseIndicators];
              noCloseIndicators = [];
            }
          }
          return;
        }
        if (getBracket(subparts[0]) === "open") {
          noCloseIndicators.push(ind);
        } else {
          indicators.push(ind);
          if (notClosedFlag) {
            indicators = [...indicators, ...noCloseIndicators];
            noCloseIndicators = [];
          }
        }
        delete this[index];
        flag = true;
        notClosedFlag = false;
        return;
      } else {
        delete this[index];
        return;
      }
    }, parts);
    crit = parts.join("$");
  }

  if (indicators && noCloseIndicators) indicators = [...indicators, ...noCloseIndicators];

  return uniqWith(indicators, isEqual);
}

export function getLinesValue(crit) {
  if (!crit || !crit.length) return [];
  let withoutComments = crit.replace(/#.*$/gm, "");
  let all = withoutComments
    .match(/\s*[<>]\s*\d+\.?\d*|\s*!=\s*\d+\.?\d*|\s*==\s*\d+\.?\d*/g)
    ?.map((el) => el?.match(/\d+\.?\d*/g)?.[0].trim());

  let inCross = withoutComments
    .match(/\$CrossDown(.*)\.cross|\$CrossUp(.*)\.cross/g)
    ?.map((el) => el?.match(/[,*+-/]\s*\d+\.?\d*\s*\)\.cross/g)?.[0])
    ?.map((el) => el?.match(/\s*\d+\.?\d*\s*/g)?.[0].trim());

  return [...(all || []), ...(inCross || [])];
}
