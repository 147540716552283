import React from "react";
import { Tab, TabbedShowLayout, useTranslate } from "react-admin";
import styles from "./TradesDetails.module.css";
import Card from "../Card/Card";
import { TradesList } from "./Trades/Trades";
import TickersTab from "./TickersTab/TickersTab";
import { setGaId } from "../../shared/utils";
import TradesCalendar from "./Trades/components/TradesCalendar/TradesCalendar";
import TradingEvents from "./Trades/components/TradingEvents/TradingEvents";

const TradesDetails = (props) => {
  const t = useTranslate();
  return props?.record?.id ? (
    <div className={styles.container}>
      <Card header={t("backtest.blocks.trades")}>
        <TabbedShowLayout {...props}>
          <Tab
            label="Trades"
            contentClassName={styles.tradesTickerTab}
            id={setGaId(props.ga?.category, "trades-tab")}
          >
            <TradesList {...props} />
          </Tab>
          <Tab
            label="Tickers"
            contentClassName={styles.tradesTickerTab}
            id={setGaId(props.ga?.category, "tickers-tab")}
          >
            <TickersTab {...props} />
          </Tab>
          <Tab label="Calendar" id={setGaId(props.ga?.category, "trades-calendar-tab")}>
            <TradesCalendar {...props}/>
          </Tab>

          <Tab label="Events" id={setGaId(props.ga?.category, "trades-events-tab")}>
            <TradingEvents {...props}/>
          </Tab>
        </TabbedShowLayout>
      </Card>
    </div>
  ) : null;
};

export default TradesDetails;
