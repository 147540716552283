import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import styles from "./ToggleType.module.css";
import { useInput, useTranslate } from "react-admin";
import clsx from "clsx";
import { Tooltip } from "@material-ui/core";
import Config from "../../../../config";
import { setGaId } from "../../../../shared/utils";
import AlgoType, { ALGO_TYPES } from "../../../../components/AlgoType";

const ToggleType = (props) => {
  const {
    input: { onChange, value },
  } = useInput(props);
  const t = useTranslate();

  return (
    <ButtonGroup className={clsx(styles.buttonGroup, props.className)}>
      <Tooltip title={t("helpers.long")} enterDelay={Config.TOOLTIP_DELAY} enterNextDelay={Config.TOOLTIP_DELAY}>
        <Button
          id={setGaId(props.ga?.category, "long")}
          data-testid={"algo-type-long"}
          onClick={() => onChange(ALGO_TYPES.LONG)}
          className={clsx(value === ALGO_TYPES.LONG ? styles.long : styles.inactive)}
        >
          <AlgoType algoType={ALGO_TYPES.LONG} />
          <span className={styles.text}>{ALGO_TYPES.LONG}</span>
        </Button>
      </Tooltip>
      <Tooltip title={t("helpers.short")} enterDelay={Config.TOOLTIP_DELAY} enterNextDelay={Config.TOOLTIP_DELAY}>
        <Button
          id={setGaId(props.ga?.category, "short")}
          data-testid={"algo-type-short"}
          onClick={() => onChange(ALGO_TYPES.SHORT)}
          className={clsx(value === ALGO_TYPES.SHORT ? styles.short : styles.inactive)}
        >
          <AlgoType algoType={ALGO_TYPES.SHORT} />
          <span className={styles.text}>{ALGO_TYPES.SHORT}</span>
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};

export default ToggleType;
