export const actions = {
  share: "Share",
  clone: "Clone",
  archive: "Archive",
  launch_live: "Launch Live",
  launch_paper: "Launch Paper",
  start_stop: "Start / Stop",
  edit: "Edit",
  copy_to_current_algo: "Copy to Current Algo",
  copy_to_new_algo: "Copy to New Algo",
  copy_to_current_screener: "Copy to Current Screener",
  copy_to_new_screener: "Copy to New Screener",
  unlink: "Unlink",
  link_more: "Link more accounts",
  validate: "Validate",
  copy_clipboard: "Copy to Clipboard",
};
