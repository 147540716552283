import React, { Fragment } from "react";
import classes from "./Alert.module.css";
import CloseIcon from "react-feather/dist/icons/x";
import { Button, useTranslate } from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import InfoIcon from "react-feather/dist/icons/info";
import CheckCircleIcon from "react-feather/dist/icons/check-circle";
import XCircleIcon from "react-feather/dist/icons/x-circle";
import { colors } from "../../shared/cssVariables";
import { ALERT } from "./variables";
import { setGaId } from "../../shared/utils";
import { isMobile } from "react-device-detect";
import DisableCreatingTooltip from "../DisableCreatingTooltip/DisableCreatingTooltip";

const Alert = ({ ga, data: { type, icon, action, data } = { type: ALERT.DEFAULT, data: [] }, setOpenAlert }) => {
  const t = useTranslate();

  const getTypeIcon = () => {
    const size = 19;
    switch (type) {
      case ALERT.BRAND:
        return <InfoIcon size={size} color={colors.blue} />;
      case ALERT.WARNING:
        return <InfoIcon size={size} color={colors.orange} />;
      case ALERT.SUCCESS:
        return <CheckCircleIcon size={size} color={colors.green} />;
      case ALERT.DANGER:
        return <XCircleIcon size={size} color={colors.red} />;
      case ALERT.DEFAULT:
      default:
        return <InfoIcon size={size} color={colors.gray_icon} />;
    }
  };

  const buttonLabel = t(action ? action.label : "");

  return (
    <div className={clsx(classes.container, { [classes.mobile]: isMobile }, classes[type])}>
      {icon ? <div className={classes.typeIcon}>{getTypeIcon()}</div> : null}
      <div>
        {data.map(({ header, description }, index) => (
          <Fragment key={header + index}>
            {header ? <div className={classes.header}>{t(header)}</div> : null}
            <div className={classes.description}>
              {description?.map((item, index) => (
                <p key={index}>{typeof item === "string" ? t(item) : item}</p>
              ))}
            </div>
          </Fragment>
        ))}
        {action ? (
          <div className={classes.actionButtonWrapper}>
            <DisableCreatingTooltip disabled={action?.disabled} entity={action?.entity}>
              <Button
                variant="text"
                label={buttonLabel}
                onClick={action?.onClick}
                classes={{ smallIcon: classes.mobileLabel }}
                className={clsx(classes.action, classes[type], {
                  [classes.disabled]: action?.disabled,
                })}
                id={setGaId(ga?.category, action?.id)}
                disabled={action?.disabled}
              >
                <span>{buttonLabel}</span>
              </Button>
            </DisableCreatingTooltip>
          </div>
        ) : null}
      </div>
      {setOpenAlert ? (
        <IconButton
          data-testid="documentation-button-close"
          edge="start"
          color="inherit"
          onClick={() => setOpenAlert(false)}
          aria-label="maximize"
          className={classes.icon}
          id={setGaId(ga?.category, `${action?.id}-close`)}
        >
          <CloseIcon size={18} color={colors.gray_icon} />
        </IconButton>
      ) : null}
    </div>
  );
};

export default Alert;
