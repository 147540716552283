import React from "react";
import clsx from "clsx";
import classes from "./Rows.module.css";

const NarrowRow = ({ children, className }) => {
  return (
    <div className={clsx(classes.wrapper, classes.borderBottom, className)}>
      <div className={classes.narrowRow}>{children}</div>
    </div>
  );
};

export default NarrowRow;
