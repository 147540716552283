import React, { cloneElement } from "react";
import styles from "./LabeledField.module.css";
import { useFormState } from "react-final-form";

const LabeledField = (props) => {
  const { children, label, source } = props;
  const { values } = useFormState();

  const content = children ? cloneElement(children, { record: values, source }) : values?.[source];

  return values?.[source] ? (
    <div className={styles.container}>
      <span className={styles.label}>{label}</span>
      <div className={styles.content}>{content}</div>
    </div>
  ) : null;
};

export default LabeledField;
