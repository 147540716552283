import React from "react";
import styles from "./StyledTextInput.module.css";
import { TextInput, useInput } from "react-admin";
import cn from "clsx";

const StyledTextInput = (props) => {
  const {
    meta: { error },
  } = useInput(props);

  return (
    <TextInput
      {...props}
      error={!!error}
      helperText={error}
      format={props.format}
      parse={props.parse}
      className={cn(styles.textInput, props.className)}
    />
  );
};

export default StyledTextInput;
