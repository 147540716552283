import useGetTradingActions from "./useGetTradingActions";
import useGetBacktestActions from "./useGetBacktestActions";
import useGetScreenerBacktestActions from "./useGetScreenerBacktestActions";
import { TRADING_RESOURCE } from "../../../shared/variables";

export default function useGetActions(record, resource) {
  const tradingActions = useGetTradingActions(record);
  const backtestActions = useGetBacktestActions(record);
  const screenerBacktestActions = useGetScreenerBacktestActions(record);

  if (resource === TRADING_RESOURCE.TRADINGS) return tradingActions;
  if (resource === "screener-backtests") return screenerBacktestActions;
  return backtestActions;
}
