import React, { useEffect } from "react";
import styles from "./AdditionalExit.module.css";
import { useTranslate } from "react-admin";
import { useForm, useFormState } from "react-final-form";
import CollapseBlock from "../CollapseBlock/CollapseBlock";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import SelectField from "../SelectField/SelectField";
import { MAX_RISK, TIME_INTERVALS } from "../../shared/variables";
import { formatMaxRisk, formatNumberValue, makeInterval, setGaId } from "../../shared/utils";
import NarrowRow from "../Rows/NarrowRow";
import NarrowLabelVal from "../Rows/NarrowLabelVal";
import Label from "../Rows/Label";
import MaxRiskInput from "./components/MaxRiskInput";
import { useStopTrailDefaultValue } from "../../pages/Algo/utils";

export function getNumberOptions(type) {
  if (type === "PERCENTAGE") {
    return { maximumFractionDigits: 2, style: "percent" };
  } else if (type === "ABSOLUTE") {
    return { maximumFractionDigits: 2, style: "currency", currency: "USD" };
  } else {
    return { maximumFractionDigits: 2 };
  }
}

export const AdditionalLine = ({ values }) => {
  const t = useTranslate();
  return (
    <NarrowRow>
      <NarrowLabelVal label={t("algos.enter_interval")} value={makeInterval(values.enter_interval)} />
      <NarrowLabelVal label={t("algos.exit_interval")} value={makeInterval(values.exit_interval)} />
      {values.max_risk_per_trade_enabled && !!values.max_risk_per_trade && (
        <NarrowLabelVal
          label={t("algos.maxRiskTrade")}
          value={`${formatNumberValue(
            values.max_risk_per_trade,
            getNumberOptions(values.max_risk_per_trade_value_type)
          )}${values.use_stop_trail ? ` | ${t("algos.useStopTrail").toUpperCase()}` : ""}`}
        />
      )}
      {values.max_risk_per_day_enabled && !!values.max_risk_per_day && (
        <NarrowLabelVal
          label={t("algos.maxRiskDay")}
          value={formatNumberValue(values.max_risk_per_day, getNumberOptions(values.max_risk_per_day_value_type))}
        />
      )}
      {values.max_entry_trades_enabled && (
        <NarrowLabelVal label={t("algos.maxTrades")} value={values.max_entry_trades} />
      )}
      {!values.max_risk_per_trade_enabled && !values.max_risk_per_day_enabled && !values.max_entry_trades_enabled && (
        <Label>{t("algos.additCriteria")}</Label>
      )}
    </NarrowRow>
  );
};

const mustHaveMax2Decimals = (value, type, t) => {
    const v = value && type === "PERCENTAGE" ? value * 100 : value;
    value && !/^[0-9]+(.[0-9]{1,2})?$/.test(v) && t("number.max2decimals");
}
const mustBeBetweenRange = (value, type, broker, use_stop_trail, t) => {
  const isTDWithTSL = broker === "AMERITRADE" && use_stop_trail;
  const regex = isTDWithTSL ? /^[0-9]+(.[0]{1,2})?$/ : /^[0-9]+(.[0-9]{1,2})?$/;
  const message = isTDWithTSL ? t("number.naturalRange") : t("number.decimalRange");
  return value && type === "PERCENTAGE" && (!regex.test((value * 100).toFixed(2)) || parseInt(value) >= 100) && message;
};
const mustBeNatural = (value, t) => value && !/^[0-9]*$/.test(value) && t("number.natural");

export const AdditionalExitForm = ({ ga, options, values, ...props }) => {
  const t = useTranslate();
  const form = useForm();

  useEffect(() => {
    form.setConfig(
      "validate",
      ({
        max_risk_per_trade_enabled,
        max_risk_per_day_enabled,
        max_entry_trades_enabled,
        max_risk_per_trade,
        max_risk_per_day,
        max_risk_per_trade_value_type,
        max_risk_per_day_value_type,
        max_entry_trades,
        broker,
        use_stop_trail,
      }) => {
        const fields = {};

        if (max_risk_per_trade_enabled) {
          const error =
            mustHaveMax2Decimals(max_risk_per_trade, max_risk_per_trade_value_type, t) ||
            mustBeBetweenRange(max_risk_per_trade, max_risk_per_trade_value_type, broker, use_stop_trail, t);
          error && (fields.max_risk_per_trade = error);
        }

        if (max_risk_per_day_enabled) {
          const error =
            mustHaveMax2Decimals(max_risk_per_day, max_risk_per_trade_value_type, t) ||
            mustBeBetweenRange(max_risk_per_day, max_risk_per_day_value_type, broker, use_stop_trail, t);
          error && (fields.max_risk_per_day = error);
        }

        if (max_entry_trades_enabled) {
          const error = mustBeNatural(max_entry_trades, t);
          error && (fields.max_entry_trades = error);
        }

        return fields;
      }
    );
  }, [values.broker, values.use_stop_trail]);

  return (
    <div className={styles.formContainer}>
      <div className={styles.formRow}>
        <SelectField
          id={setGaId(ga?.category, "enter-interval")}
          record={props.record}
          source="enter_interval"
          className={styles.formItem}
          options={TIME_INTERVALS}
          helperText={t("helpers.enter_interval")}
        />
        <SelectField
          id={setGaId(ga?.category, "exit-interval")}
          record={props.record}
          source="exit_interval"
          className={styles.formItem}
          options={TIME_INTERVALS}
          helperText={t("helpers.exit_interval")}
        />
      </div>

      <div className={styles.formRow}>
        <MaxRiskInput
          values={values}
          gaId={setGaId(ga?.category, "max-risk-per-trade")}
          type="max_risk_per_trade_value_type"
          value="max_risk_per_trade"
          enabled="max_risk_per_trade_enabled"
          label={t("algos.maxRiskTrade")}
          testid="algo-risk-trade"
          {...props}
        />
        <div className={styles.formItem}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.use_stop_trail || useStopTrailDefaultValue}
                disabled={!values.max_risk_per_trade_enabled}
                data-testid="use_stop_trailing_checkbox"
              />
            }
            label={t("algos.useStopTrail")}
            onChange={() => form.change("use_stop_trail", !values.use_stop_trail)}
          />
        </div>
      </div>

      <div className={styles.formRow}>
        <MaxRiskInput
          values={values}
          gaId={setGaId(ga?.category, "max-risk-per-day")}
          type="max_risk_per_day_value_type"
          value="max_risk_per_day"
          enabled="max_risk_per_day_enabled"
          label={t("algos.maxRiskDay")}
          testid="algo-risk-day"
        />
      </div>

      <div className={styles.formRow}>
        <MaxRiskInput
          values={values}
          gaId={setGaId(ga?.category, "max-trades-per-day")}
          value="max_entry_trades"
          enabled="max_entry_trades_enabled"
          label={t("algos.maxTrades")}
          testid="algo-max-trades"
        />
      </div>
    </div>
  );
};

export const AdditionalExitBlock = ({readOnly = false, ...props}) => {
  // TODO: rework changing values via DataProvider
  const { values } = useFormState();

  return readOnly ? <AdditionalLine values={values} /> : (
    <CollapseBlock
      id={setGaId(props.ga?.category, "interval-risk-toggle")}
      short={<AdditionalLine values={values} />}
      full={<AdditionalExitForm {...props} ga={props.ga} values={values} />}
      className={styles.container}
      openOnError={["max_risk_per_trade", "max_risk_per_day", "max_entry_trades"]}
    />
  );
};
