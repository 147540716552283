import React from "react";
import { Create } from "react-admin";
import AlgoForm from "../Algo/AlgoForm";
import { PortfolioType } from "../Algo/utils";
import { getAlgoInitialData } from "../Algo/CreateAlgo";

const CreateWatchlistAlgo = (props) => {
  const initialData = { name: "New Algo", portfolio_type: PortfolioType.WATCHLIST, enter_on_day: 0, exit_on_day: 1 };
  Object.assign(initialData, getAlgoInitialData());
  return (
    <Create {...props} record={initialData} resource="watchlist-algos">
      <AlgoForm {...props} />
    </Create>
  );
};

export default CreateWatchlistAlgo;
