import { convertEdtEstToLocal, convertLocalToEdtEst, formatMaxRisk } from "../../shared/utils";
import { MAX_RISK, ORDER_TYPES } from "../../shared/variables";
import { isDesktop } from "react-device-detect";

export const PortfolioType = {
  INTRADAY: "INTRADAY",
  WATCHLIST : "WATCHLIST"
}

export const transformAlgoValues = (values) => {
  //fields to exclude
  const  {last_run_date, created_date, archived, owner, status, id, alpha, beta, mdd, ticker,
    number_of_backtests, backtests_in_progress, max_return_pct, algo, market_hours_only, benchmarkTicker,
    return_value, return_pct, progress, wins, losses, trades, money_made, money_lost, sharpe_ratio, ...rest} = values;
  
  return ({
    ...rest,
    //not sending ticker for PortfolioType.WATCHLIST
    tickers: values.portfolio_type === PortfolioType.WATCHLIST ? [] : rest.tickers,
    //TODO: remove once MVP1-2693 is fixed
    order_validity: values.order_type === ORDER_TYPES.MARKET ? null : values.order_validity,
    regular_days_from: values.portfolio_type === PortfolioType.WATCHLIST ? null : convertLocalToEdtEst(values.regular_days_from),
    regular_days_to: values.portfolio_type === PortfolioType.WATCHLIST ? null : convertLocalToEdtEst(values.regular_days_to),
    early_close_days_from: values.portfolio_type === PortfolioType.WATCHLIST ? null : convertLocalToEdtEst(values.early_close_days_from),
    early_close_days_to: values.portfolio_type === PortfolioType.WATCHLIST ? null : convertLocalToEdtEst(values.early_close_days_to),
    benchmark_ticker: handleBenchmarkTicker(values),
    max_risk_per_trade: values.max_risk_per_trade,
    max_risk_per_day: values.max_risk_per_day,
  });
}

const handleBenchmarkTicker = (values) => {
  let benchmarkTicker = Array.isArray(values.benchmarkTicker) ? values.benchmarkTicker[0]?.id : values.benchmarkTicker;

  if (!benchmarkTicker) {
    benchmarkTicker = Array.isArray(values.benchmark_ticker) ? values.benchmark_ticker[0] : values.benchmark_ticker;
  }

  if (!benchmarkTicker) {
    benchmarkTicker = Array.isArray(values.ticker) ? values.ticker[0]?.id : values.ticker;
  }
  return benchmarkTicker;
};

export const forceExitDefaultValue = "END_OF_TRADING_HOURS";
export const useStopTrailDefaultValue = false;

export const transformTime = ({
  early_close_days_from,
  early_close_days_to,
  regular_days_from,
  regular_days_to,
  ...rest
}) => ({
  early_close_days_from: convertEdtEstToLocal(early_close_days_from),
  early_close_days_to: convertEdtEstToLocal(early_close_days_to),
  regular_days_from: convertEdtEstToLocal(regular_days_from),
  regular_days_to: convertEdtEstToLocal(regular_days_to),
  force_exit_at: forceExitDefaultValue,
  use_stop_trail: useStopTrailDefaultValue,
  ...rest,
});
