import React from "react";
import { NumberField } from "react-admin";
import clsx from "clsx";
import styles from "./ColoredNumber.module.css";

export const ColoredNumber = ({ record, source, label, style, className, locales, precision=2, zeroValue=0}) => {
  const value = record?.[source];
  const valueClass = isNaN(value) || value === 0 ? styles.grey : record && value > 0 ? styles.green : styles.red;

  return value === 0 && zeroValue ?
      <span className={clsx("Status-Col", styles.field, valueClass, className)}>{zeroValue}</span>
    :(
    <NumberField
      label={label}
      record={record}
      source={source}
      locales={locales}
      options={{
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
        style: typeof style === "object" ? style.percent : style,
        signDisplay: "always",
        currency: "USD",
      }}
      className={clsx("Status-Col", styles.field, valueClass, className)}
      headerClassName="Status-Col"
    />
  );
};
