import React from "react";
import { required, useTranslate } from "react-admin";
import StockInput from "../../../../components/StockInput/StockInput";
import classes from "./TradingParameters.module.css";
import { setGaId } from "../../../../shared/utils";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import InitialCapital from "../../../../components/InitialCapital";

export const TradingParameters = ({ ga, ...props }) => {
  const t = useTranslate();

  return (
    <div className={clsx(classes.gridLayout, { [classes.mobile]: isMobile })}>
      <StockInput
        {...props}
        ga={{ ...ga, action: "ticker-screener" }}
        name="ticker_screener"
        variant="outlined"
        label={t("tradings.ticker_screener")}
        sources={["screeners", "tickers"]}
        options={["screeners", "tickers"]}
        limitTags={1}
        fullWidth
        translateChoice={false}
        className={classes.stockInput}
        validate={[required()]}
        matchFrom="start"
        helperText={t("helpers.input_ticker_screener_trading")}
      />
      <div className={classes.row}>
        <div className={classes.initialCapital}>
          <InitialCapital {...props} ga={setGaId(ga?.category, "tradings-initial-capital")} />
        </div>
        <div className={classes.benchmark}>
          <StockInput
              {...props}
              ga={{ ...ga, action: "benchmark-ticker" }}
              name="benchmarkTicker"
              variant="outlined"
              label={t("backtest.benchmark_ticker")}
              sources={["benchmark_ticker"]}
              options={["tickers"]}
              limitTags={1}
              fullWidth
              translateChoice={false}
              validate={[required()]}
              matchFrom="start"
              helperText={t("helpers.input_benchmark")}
          />
        </div>
      </div>
    </div>
  );
};
