import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Reactour from "reactour";
import Button from "@material-ui/core/Button";
import { useNotify } from "react-admin";
import { isMobile } from "react-device-detect";

import { TOUR_PAPER_TRADING, TOUR_PAPER_TRADING_FIRST } from "../../store/reducers/tourReducer";
import TourPopupLayout from "./components/TourPopupLayout";
import { handleAfterClose, handleAfterOpen, handleGetCurrentStep } from "./components/Functions";

import Translate from "./components/Translate";
import classes from "../Carousel/Carousel.module.css";
import style from "./TourWelcome.module.scss";
import { customFetch } from "../../DataProvider";

const steps = [
  {
    selector: "",
    content: (props) => {
      const { goTo } = props;
      return (
        <TourPopupLayout
          propsLayout={props}
          btnNext={
            <div className={style.popupTour__btns}>
              <Button className={style.btnGrey} onClick={() => props.close()}>
                <Translate path="tour.skip_tour" />
              </Button>
              <Button data-testid="tour/start" className={classes.exploreButton} onClick={() => goTo(1)}>
                <Translate path="tour.start_tour" />
              </Button>
            </div>
          }
        >
          <h2>
            <Translate path="tourPaperTrading.step0.title" />
          </h2>
          <p data-testid="tour/description">
            <Translate path="tourPaperTrading.step0.text" />
          </p>
        </TourPopupLayout>
      );
    },
  },
  {
    selector: '[class*="TradingForm_tradingParams"]',
    content: (props) => (
      <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={2}>
        <h2>
          <Translate path="tourPaperTrading.step1.title" />
        </h2>
        <p data-testid="tour/description">
          <Translate path="tourPaperTrading.step1.text" />
        </p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="TradingParameters"]',
    content: (props) => (
      <TourPopupLayout
        propsLayout={props}
        btnNext={
          <Button data-testid="tour/finish" className={classes.exploreButton} onClick={() => props.close()}>
            <Translate path="tour.finish_tour" />
          </Button>
        }
      >
        <h2>
          <Translate path="tourPaperTrading.step2.title" />
        </h2>
        <p data-testid="tour/description">
          <Translate path="tourPaperTrading.step2.text" />
        </p>
      </TourPopupLayout>
    ),
  },
];

const stepsMobile = [
  {
    selector: "",
    content: (props) => {
      const { goTo } = props;
      return (
        <TourPopupLayout
          propsLayout={props}
          btnNext={
            <div className={style.popupTour__btns}>
              <Button className={style.btnGrey} onClick={() => props.close()}>
                <Translate path="tour.skip_tour" />
              </Button>
              <Button className={classes.exploreButton} onClick={() => goTo(1)}>
                <Translate path="tour.start_tour" />
              </Button>
            </div>
          }
        >
          <h2>
            <Translate path="tourPaperTradingMobile.step0.title" />
          </h2>
          <p>
            <Translate path="tourPaperTradingMobile.step0.text" />
          </p>
        </TourPopupLayout>
      );
    },
  },
  {
    selector: '[class*="algoParams"]',
    content: (props) => (
      <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={2}>
        <h2>
          <Translate path="tourPaperTradingMobile.step1.title" />
        </h2>
        <p>
          <Translate path="tourPaperTradingMobile.step1.text" />
        </p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="tradingParametersMobile"]',
    content: (props) => (
      <TourPopupLayout
        propsLayout={props}
        btnNext={
          <Button className={classes.exploreButton} onClick={() => props.close()}>
            <Translate path="tour.finish_tour" />
          </Button>
        }
      >
        <h2>
          <Translate path="tourPaperTradingMobile.step2.title" />
        </h2>
        <p>
          <Translate path="tourPaperTradingMobile.step2.text" />
        </p>
      </TourPopupLayout>
    ),
  },
];

export const TourPaperTrading = () => {
  const { tourPaperTrading, tourPaperTradingFirst } = useSelector((state) => state.tour);
  const { sidebarOpen } = useSelector((state) => state.admin.ui);
  const dispatch = useDispatch();
  const notify = useNotify();

  useEffect(() => {
    return () => {
      handleAfterClose(notify, dispatch);
      if (tourPaperTradingFirst) {
        customFetch("profile", "PUT", { paper_trading_tour_completed: true }).then((res) => {
          if (res?.paper_trading_tour_completed) dispatch({ type: TOUR_PAPER_TRADING_FIRST, payload: false });
        });
      }
    };
  }, [tourPaperTradingFirst]);

  const handleRequestClose = () => dispatch({ type: TOUR_PAPER_TRADING, payload: false });

  return (
    <Reactour
      steps={isMobile ? stepsMobile : steps}
      isOpen={tourPaperTrading}
      onRequestClose={handleRequestClose}
      rounded={5}
      className={style.reactour}
      startAt={0}
      getCurrentStep={(curr) => handleGetCurrentStep(curr, dispatch, sidebarOpen, 1)}
      onAfterOpen={handleAfterOpen}
      showCloseButton={false}
      showButtons={false}
      showNavigation={false}
    />
  );
};

export default TourPaperTrading;
