import React from "react";
import styles from "./AdvancedBlock.module.css";
import ToggleOrderType from "../../pages/Algo/components/ToggleOrderType/ToggleOrderType";
import { TimeInterval } from "../TimeInput/TimeInput";
import { useTranslate } from "react-admin";
import { useFormState } from "react-final-form";
import SelectField from "../../components/SelectField/SelectField";
import { ORDER_TYPES, TIME_INTERVALS } from "../../shared/variables";
import {
  toAMPMTime,
  setGaId,
  customRequired,
  getRegularMinTime,
  getEarlyMinTime,
  getRegularMaxTime,
  getEarlyMaxTime,
} from "../../shared/utils";
import { TimeZones } from "../../locales/timezones";
import NarrowRow from "../Rows/NarrowRow";
import NarrowLabelVal from "../Rows/NarrowLabelVal";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

export const AdvancedLine = ({ values }) => {
  const t = useTranslate();

  return (
    <NarrowRow className={isMobile ? styles.mobileView : null}>
      {values.order_type ? <NarrowLabelVal value={values.order_type + " " + t("order.order")} /> : null}
      {values.order_validity && values.order_type === ORDER_TYPES.LIMIT && (
        <NarrowLabelVal
          label={t("order.validity")}
          value={parseInt(values.order_validity) !== 0 ? parseInt(values.order_validity) / 60 + " min" : "SAME DAY"}
        />
      )}
      {values.regular_days_from && values.regular_days_to ? (
        <NarrowLabelVal
          label={t("algos.criteria.regular_days")}
          value={toAMPMTime(values.regular_days_from) + " - " + toAMPMTime(values.regular_days_to)}
        />
      ) : null}
      {values.early_close_days_from && values.early_close_days_to ? (
        <NarrowLabelVal
          label={t("algos.criteria.early_close_days")}
          value={toAMPMTime(values.early_close_days_from) + " - " + toAMPMTime(values.early_close_days_to)}
        />
      ) : null}
      {values.force_exit_at && (
        <NarrowLabelVal
          label={t("fieldsName.force_exit_at")}
          value={t("force_exit." + values.force_exit_at.toLowerCase())}
        />
      )}
    </NarrowRow>
  );
};

export const AdvancedFull = (props) => {
  const t = useTranslate();
  const { values } = useFormState();

  const timezone = useSelector((store) => store.profile.timezone);
  const { h12 } = TimeZones.find((item) => item.text === timezone) || {};

  const displayFormat = h12 ? "hh:mm A" : "HH:mm";

  const regularMinTime = getRegularMinTime();
  const regularMaxTime = getRegularMaxTime();

  const earlyMinTime = getEarlyMinTime();
  const earlyMaxTime = getEarlyMaxTime();

  return (
    <div className={styles.fullContainer}>
      <ToggleOrderType {...props} source="order_type" />
      {values.order_type === ORDER_TYPES.LIMIT ? (
        <SelectField
          id={setGaId(props.ga?.category, "valid-for")}
          label={t(`fieldsName.order_validity`)}
          source="order_validity"
          defaultValue="0"
          options={[
            ...TIME_INTERVALS,
            { label: "4 hour", value: (4 * 60 * 60).toString() },
            { label: "Same day", value: "0" },
          ]}
          validate={customRequired(t("common.required"))}
          className={styles.interval}
          variant="outlined"
          helperText={t("helpers.order_validity")}
        />
      ) : null}
      <div className={styles.gap8}>
        <TimeInterval
          id={setGaId(props.ga?.category, "regular-days")}
          source_from="regular_days_from"
          source_to="regular_days_to"
          label={t("fieldsName.regular_days")}
          minTime={regularMinTime}
          maxTime={regularMaxTime}
          tooltip={`${t("helpers.regular_days")}. On such day market hours in your timezone are ${regularMinTime.format(
            displayFormat
          )} - ${regularMaxTime.format(displayFormat)}`}
        />
      </div>
      <TimeInterval
        id={setGaId(props.ga?.category, "early-close-days")}
        source_from="early_close_days_from"
        source_to="early_close_days_to"
        minTime={earlyMinTime}
        maxTime={earlyMaxTime}
        tooltip={`${t("helpers.early_close_days")}. On such day market hours in your timezone are ${earlyMinTime.format(
          displayFormat
        )} - ${earlyMaxTime.format(displayFormat)}`}
        label={t("fieldsName.early_close_days")}
      />
      <SelectField
        source="force_exit_at"
        id={setGaId(props.ga?.category, "force_exit")}
        options={[
          { label: t("force_exit.end_of_trading_hours"), value: "END_OF_TRADING_HOURS" },
          { label: t("force_exit.end_of_market_hours"), value: "END_OF_MARKET_HOURS" },
          { label: t("force_exit.never"), value: "NEVER" },
        ]}
        validate={customRequired(t("common.required"))}
        className={styles.exit}
        variant="outlined"
        helperText={t("helpers.force_exit")}
      />
    </div>
  );
};

export const AdvancedBlock = (props) => (
  <div className={styles.container}>
    <AdvancedFull {...props} />
  </div>
);
