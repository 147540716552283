export const statuses = {
  pending: { id: "PENDING", name: "PENDING" },
  inProgress: { id: "IN_PROGRESS", name: "IN PROGRESS" },
  failed: { id: "FAILED", name: "FAILED" },
  completed: { id: "COMPLETED", name: "COMPLETED" },
  draft: { id: "DRAFT", name: "DRAFT" },
  live: { id: "LIVE", name: "LIVE" },
  active: { id: "ACTIVE", name: "ACTIVE" },
  archived: { id: "ARCHIVED", name: "ARCHIVED" },
  stopped: { id: "STOPPED", name: "STOPPED" },
  shared: { id: "SHARED", name: "SHARED" },
  exiting: { id: "EXITING", name: "EXITING" },
};
