import React, { useEffect } from "react";
import { useNotify, usePermissions, useRefresh, useTranslate } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../components/Card/Card";
import { BacktestHeader } from "../Backtest/BacktestHeader";
import { statuses } from "../../shared/statuses";
import TradesDetails from "../../components/TradesDetails/TradesDetails";
import ErrorDetails from "../../components/ErrorDetails/ErrorDetails";
import PerformanceDetails from "../../components/PerformanceDetails/PerformanceDetails";
import { isPaper } from "./utils";
import { GA_CATEGORIES, TABLES } from "../../shared/variables";
import MobileVersionAlert from "../../components/Alert/alerts/MobileVersionAlert";
import { STATUSES } from "../../store/reducers/statuses";
import { TRADING_CLEAR } from "../../store/reducers/tradingReducer";
import { BACKTEST_ALGOS } from "../../shared/permissions";

const ShowTradingView = (props) => {
  const t = useTranslate();
  const notify = useNotify();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const { status: tradingStatus, errorMessage: tradingErrorMessage } = useSelector((store) => store.trading);
  const isPaperTrading = isPaper(props);
  const { loaded, permissions } = usePermissions();

  const ga = {
    category: isPaperTrading ? GA_CATEGORIES.PAPER_TRADING_VIEW : GA_CATEGORIES.LIVE_TRADING_VIEW,
  };

  useEffect(() => {
    if (tradingStatus === STATUSES.UPDATE_SUCCESS) refresh();
    if (tradingStatus === STATUSES.UPDATE_FAIL) notify(t(tradingErrorMessage), "warning");
    return () => dispatch({ type: TRADING_CLEAR });
  }, [tradingStatus, tradingErrorMessage]);

  return (
    <div>
      {/* mobile note is only relevant for algo lab view */}
      {permissions?.includes(BACKTEST_ALGOS) && <MobileVersionAlert />}
      {props.record.error && props.record.status === statuses.failed.id ? (
        <ErrorDetails value={props.record.error} />
      ) : null}

      <Card removeTopOffset header={t("algos.blocks.algo_params")}>
        <BacktestHeader ga={ga} record={props.record}
                        resource={props.resource}
                        noCode={!permissions?.includes(BACKTEST_ALGOS)} />
      </Card>

      <PerformanceDetails {...props} />
      <TradesDetails
        {...props}
        ga={ga}
        filter={{ trading: props.record.id }}
        tableName={isPaperTrading ? TABLES.PAPER_TRADING_TRADES : TABLES.LIVE_TRADING_TRADES}
        tradesOrdersResource="trading_orders"
        tradesListResource="trading-days/trading"
        tickersResource="trading/tickers"
      />
    </div>
  );
};

export default ShowTradingView;
