import React from "react";
import { Layout } from "react-admin";
import { useSelector } from "react-redux";
import BEAppBar from "./AppBar";
import _ from "lodash";
import MySidebar from "../../components/SideBar/SideBar";
import styles from "./Layout.module.css";
import { isMobile } from "react-device-detect";

const BELayout = (props) => {
  const { discount, email, referral, payment } = useSelector((store) => store.header);

  const themeOverride = _.merge({}, props.theme, {
    overrides: {
      RaLayout: {
        appFrame: {
          marginTop: "110px !important",
        },
      },
    },
  });

  return (
    <Layout
      {...props}
      className={isMobile ? styles.layoutMobile : null}
      sidebar={MySidebar}
      appBar={BEAppBar}
      theme={payment || email || discount || referral ? themeOverride : props.theme}
    />
  );
};

export default BELayout;
