import React from "react";
import classes from "./TradingEvents.module.css";
import {List, TextField, Datagrid, useTranslate} from "react-admin";
import ListPagination from "../../../../ListPagination/ListPagination";
import {OrderStatusFilter} from "../../Trades";
import DatagridIdsWrapper from "../../../../DatagridIdsWrapper/DatagridIdsWrapper";
import {MomentField} from "../../../../MomentField";
import Config from "../../../../../config";
import useGetItemsPerPage from "../../../../ListPagination/hooks/useGetItemsPerPage";
import NoResultsFound from "../../../../NoResultsFound/NoResultsFound";

export const TradingEvents = (props) => {

    const { tradesListResource, tradesOrdersResource, tableName, filter, ...rest } = props;
    const perPage = useGetItemsPerPage(tableName);
    const t = useTranslate();

    return (
        <List
            bulkActionButtons={false}
            filter={filter}
            sort={{ field: "created_date", order: "DESC" }}
            exporter={false}
            filters={<OrderStatusFilter {...props} />}
            {...rest}
            className={classes.list}
            perPage={perPage}
            empty={<NoResultsFound />}
            pagination={<ListPagination {...props} />}
            resource="trading-events"
            hasShow={false}
            hasList={false}
            hasEdit={false}
            hasCreate={false}
        >
            <Datagrid
                className={classes.datagrid}
                size="small"
            >
                <DatagridIdsWrapper ga={props.ga} headerClassName="IdsWrapper-Col" cellClassName="IdsWrapper-Col" />
                <MomentField
                    format={Config.DATETIME_FORMAT}
                    source="created_date"
                    label={t("tradings.date")}
                    timezone={Config.STOCK_TZ}
                    cellClassName={classes.date_col}
                />
                <TextField source="level" cellClassName={classes.trades_col} headerClassName={classes.header} />
                <TextField source="message" cellClassName={classes.trades_col} headerClassName={classes.header} />
            </Datagrid>
        </List>
    )
}

export default TradingEvents;
