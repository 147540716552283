import brokersSaga from "./brokersSaga";
import plansSaga from "./plansSaga";
import screenersAllSaga from "./screenersAllSaga";
import screenersSaga from "./screenersSaga";
import subscriptionSaga from "./subscriptionSaga";
import tradingSaga from "./tradingSaga";
import usageSaga from "./usageSaga";
import sharedBacktestsSaga from "./sharedBacktestsSaga";
import sharedStrategiesSaga from "./sharedStrategiesSaga";

export default [
  brokersSaga,
  plansSaga,
  screenersAllSaga,
  screenersSaga,
  subscriptionSaga,
  tradingSaga,
  usageSaga,
  sharedBacktestsSaga,
  sharedStrategiesSaga
];
