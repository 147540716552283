import React from "react";
import { InitialLine } from "./components/Initial/Initial";
import { CriteriaBlock } from "../../components/CriteriaBlock/CriteriaBlock";
import {
  Show,
  SimpleShowLayout,
  useShowController,
  SimpleForm,
  useTranslate,
} from "react-admin";
import { BacktestList } from "../Backtest/Backtest";
import { AdditionalLine } from "../../components/AdditionalExit/AdditionalExit";
import { AdvancedLine } from "../../components/AdvancedBlock/AdvancedBlock";
import styles from "./Algo.module.scss";
import CardHeader from "../../components/Card/CardHeader";

const ShowAlgo = (props) => {
  const t = useTranslate();

  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);

  if (!record) return null;

  return (
    <>
      <CardHeader>{t("algos.blocks.algo_params")}</CardHeader>
      <Show {...props} actions={null}>
        <SimpleShowLayout className={styles.showContainer}>
          <InitialLine values={record} />
          <SimpleForm {...props} toolbar={null}>
            <CriteriaBlock {...props} readOnly />
          </SimpleForm>
          <AdditionalLine values={record} />
          <AdvancedLine values={record} />
        </SimpleShowLayout>
      </Show>
      <BacktestList {...props} algo={record} />
    </>
  );
};

export default ShowAlgo;
