import React from "react";
import NarrowRow from "../Rows/NarrowRow";
import classes from "./DescriptionLine.module.css";
import { isDesktop } from "react-device-detect";
import clsx from "clsx";

import {MarkdownField} from "../Markdown/MarkdownInput";

export const DescriptionLine = (props) => {
  const className = props.className;

  return props.record[props.source] && isDesktop ? (
    <NarrowRow className={clsx(classes.algosDesc, className)}>
        <MarkdownField record={props.record} source={props.source}/>
    </NarrowRow>
  ) : null;
};
