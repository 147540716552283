import { forwardRef } from "react";
import { useForm } from "react-final-form";

// helps us to access form methods from "outside" the form
export default forwardRef((_props, ref) => {
  const form = useForm();
  ref.current = form;

  return null;
});
