export const colors = {
  blue: "#2196f3",
  back: "#f4f6f8",
  back_light: "#f7f9fa",
  blue_dim: "#f2f9fe",
  red: "#e02d2d",
  red_light: "#ff3636",
  red_dim: "#fbf1f3",
  orange: "#f57c00",
  orange_dim: "#fff3e0",
  green: "#009a06",
  green_dim: "#f2f8f3",
  black: "#000000",
  gray_text: "#383838",
  gray_label: "#757575",
  gray_icon: "#979797",
  gray_dim: "#bcbcbc",
  gray_border: "#e0e0e0",
  white: "#ffffff",
};