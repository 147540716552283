import React from "react";
import { useTranslate } from "react-admin";
import styles from "./PerformanceDetails.module.css";
import Card from "../Card/Card";
import { BenchmarkChart } from "../BenchmarkChart";

const PerformanceDetails = (props) => {
  const t = useTranslate();

  return props?.record?.id ? (
    <Card
      header={t("backtest.blocks.benchmark")}
      className={styles.benchmarkChart}
    >
      <BenchmarkChart
        height={300}
        showLegend={true}
        priceLineVisible={true}
        scrollable={false}
        priceAxisPosition="right"
        timeScaleVisible={true}
        marginTop={props.record?.return}
        pressedMouseMove
        {...props}
      />
    </Card>
  ) : null;
};

export default PerformanceDetails;
