import React, { useEffect, useState } from "react";
import styles from "./TickersTab.module.css";
import Highcharts from "highcharts";
import treemap from "highcharts/modules/treemap";
import HighchartsReact from "highcharts-react-official";
import Search from "../../Search/Search";
import { Loading, useDataProvider, useTranslate } from "react-admin";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import SelectInputStandalone from "../../../components/SelectInputStandalone/SelectInputStandalone";

import TickersTabItem from "../TickersTabItem/TickersTabItem";

const ORDER_BY_CHOICES = [
  { id: "volume", name: "Volume" },
  { id: "trades", name: "Number of Trades" },
  { id: "money", name: "Money Traded" },
  { id: "reward", name: "Reward" },
  { id: "risk", name: "Risk" },
  { id: "win", name: "Win" },
  { id: "loss", name: "Loss" },
];

treemap(Highcharts);

const makeChartOptions = ({ data, orderBy, height }) => {
  const step = data.length > 1 ? (255 * 0.5) / (data.length - 1) : 0;

  return {
    tooltip: {
      pointFormatter: function () {
        return `${this.tickerName} : ${this.value.toLocaleString("en-US")}`;
      },
    },
    chart: {
      height,
    },
    series: [
      {
        type: "treemap",
        layoutAlgorithm: "squarified",
        data: data.map((item, index) => ({
          tickerName: item.ticker,
          name: `${item.ticker}(${item[orderBy]})`,
          value: item[orderBy],
          color: `#72AAD8${Math.round(255 - index * step).toString(16)}`,
        })),
        dataLabels: {
          enabled: true,
          formatter: function () {
            return `${this.point.tickerName}(${this.point.value.toLocaleString("en-US")})`;
          },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Roboto",
            color: "var(--white)",
          },
        },
      },
    ],
    title: {
      text: "",
    },
  };
};

const TickersTab = (props) => {
  const t = useTranslate();
  const dataProvider = useDataProvider();

  const [orderBy, setOrderBy] = useState(ORDER_BY_CHOICES[0].id);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [chartHeight, setChartHeight] = useState(0);
  const [chartOptions, setChartOptions] = useState(makeChartOptions({ data: [], orderBy, height: chartHeight }));
  const [screenerData, setScreenerData] = useState(null);

  useEffect(() => {
    if (!props.record.id) return;
    dataProvider
      .getList(props.tickersResource, {
        pagination: { page: 1, perPage: 999 },
        sort: { field: "ticker", order: "ASC" },
        filter: props.filter,
      })
      .then((res) => {
        setScreenerData(
          res.data.map(({ wins, losses, money_traded, num_of_trades, ...rest }) => ({
            win: wins,
            loss: losses,
            money: money_traded,
            trades: num_of_trades,
            ...rest,
          }))
        );
      })
      .catch((e) => console.error(e));
  }, [props.record.id]);

  useEffect(() => {
    if (!screenerData) return;
    const regex = new RegExp(search, "i");
    const data = screenerData.filter((item) => item.ticker.match(regex)).sort((a, b) => b[orderBy] - a[orderBy]);
    setFilteredData(data);
  }, [screenerData, orderBy, search]);

  useEffect(() => {
    setChartOptions(
      makeChartOptions({
        data: filteredData,
        orderBy,
        height: chartHeight,
      })
    );
  }, [filteredData, chartHeight]);

  const barsRefCallback = (element) => {
    // 25 is a magic number representing the chart margins
    const height = element ? Math.min(element.getBoundingClientRect().height + 25, 500) : 0;
    setChartHeight(height);
  };

  if (!filteredData || !screenerData) {
    return <Loading className={styles.loading} />;
  }

  const maxValues = {
    volume: Math.max(...screenerData.map((item) => item.volume)),
    trades: Math.max(...screenerData.map((item) => item.trades)),
    money: Math.max(...screenerData.map((item) => item.money)),
    reward: Math.max(...screenerData.map((item) => item.reward)),
    risk: Math.max(...screenerData.map((item) => item.risk)),
    win: Math.max(...screenerData.map((item) => item.win)),
    loss: Math.max(...screenerData.map((item) => item.loss)),
  };

  return (
    <div>
      <div className={clsx(styles.searchAndOrder, { [styles.mobile]: isMobile })}>
        <div>
          <Search className={styles.search} value={search} onClick={setSearch} ga={props.ga} />
        </div>
        <SelectInputStandalone
          ga={props.ga}
          value={orderBy}
          onChange={(event) => setOrderBy(event.target.value)}
          choices={ORDER_BY_CHOICES}
          label={t("backtest.orderBy")}
          className={styles.orderBy}
        />
      </div>
      <div className={clsx(styles.content, { [styles.mobile]: isMobile })}>
        <div className={styles.header}>
          <span className={styles.headerTicker}>{t("backtest.ticker")}</span>
          <span className={styles.headerWinLoss}>{t("backtest.win_loss")}</span>
          <span className={styles.headerRewardRisk}>{t("backtest.reward_risk")}</span>
        </div>
        {!isMobile && <span className={styles.chartHeader}>{t("backtest.heatmap")}</span>}

        <div className={styles.bars} ref={barsRefCallback}>
          {filteredData.map((item) => (
            <TickersTabItem item={item} maxValues={maxValues} orderBy={orderBy} />
          ))}
        </div>
        <div className={styles.chart}>
          {isMobile && (
            <span className={clsx(styles.chartHeader, styles.mobileChartHeader)}>{t("backtest.heatmap")}</span>
          )}
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default TickersTab;
