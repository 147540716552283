import React, { Fragment, useEffect } from "react";
import styles from "./PlanInfo.module.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { usePermissions, useTranslate } from "react-admin";

import { AnalyticsOutline } from "react-ionicons";
import { PulseOutline} from "react-ionicons";
import { LayersOutline } from "react-ionicons";
import { FilterOutline } from "react-ionicons";
import { OptionsOutline} from "react-ionicons";

import { colors } from "../../shared/cssVariables";
import {
  formatLimitValue,
  getLimitRatio,
  setGaId,
} from "../../shared/utils";
import { GA_CATEGORIES, PLANS, RATIO_LIMITS } from "../../shared/variables";
import { useSelector } from "react-redux";
import moment from "moment";
import { UTCNameById } from "../../locales/timezones";
import Config, { Storage } from "../../config";
import { getPlanState, getUsageState } from "../../store/selectors";
import { USER_SUBSCRIPTION_STATUSES } from "../../store/sagas/usageSaga";
import {BACKTEST_ALGOS, LIVE_TRADING, PAPER_TRADING, USE_SCREENERS} from "../../shared/permissions";

const PlanInfo = () => {
  const t = useTranslate();
  const { permissions } = usePermissions();
  const usage = useSelector(getUsageState);
  const {
    backtests,
    algos,
    screeners,
    money_tradings,
    paper_tradings,
    current_period_end,
    status,
    user,
  } = usage || {};
  const plan = useSelector(getPlanState);
  const {
    name,
    backtests_limit,
    algos_limit,
    screeners_limit,
    money_tradings_limit,
    paper_tradings_limit,
  } = plan || {};

  useEffect(() => {
    if (user && name) {
      document.cookie = `profile=${user.id}_${name?.toLowerCase()}`;
    }
  }, [user, name]);

  const timezone =
    UTCNameById(Storage.getItem("timezone")) || "America/New_York";

  const scheduledDate = moment(current_period_end * 1000)
    .tz(timezone)
    .format(Config.DATE_FORMAT);

  const getRestLimit = (limit, initialLimit) => {
    let remaining = formatLimitValue(initialLimit);
    if(remaining !== "∞") {
      remaining = Math.max(remaining - limit, 0);
    }
    return remaining;
  };

  const rests = {
    backtests: getRestLimit(backtests, backtests_limit),
    algos: getRestLimit(algos, algos_limit),
    screeners: getRestLimit(screeners, screeners_limit),
    paper_tradings: getRestLimit(paper_tradings, paper_tradings_limit),
    money_tradings: getRestLimit(money_tradings, money_tradings_limit),
  };

  const showLimitRange = (value, limit) => {
    if (limit === "∞") {
      return limit;
    } else {
      return `${value}/${limit}`;
    }
  };

  const getTooltip = () => (
    <div className={styles.tooltip}>
      {name !== PLANS.FREE && (
        <div>
          {t("subscriptions.scheduled_update", { date: scheduledDate })}
        </div>
      )}
      <div>{t("subscriptions.current_limits")}</div>
      <ul>
        {permissions?.includes(BACKTEST_ALGOS) ?
            <li>
              {showLimitRange(rests.backtests, formatLimitValue(backtests_limit))}{" "}
              {t("subscriptions.backtests")},
            </li> : null
        }
        {permissions?.includes(BACKTEST_ALGOS) ?
            <li>
              {showLimitRange(rests.algos, formatLimitValue(algos_limit))}{" "}
              {t("subscriptions.algos")},
            </li> : null
        }
        {permissions?.includes(USE_SCREENERS) ?
            <li>
              {showLimitRange(rests.screeners, formatLimitValue(screeners_limit))}{" "}
              {t("subscriptions.screeners")}.
            </li> : null
        }
        {permissions?.includes(PAPER_TRADING) ?
            <li>
              {showLimitRange(
                  rests.paper_tradings,
                  formatLimitValue(paper_tradings_limit)
              )}{" "}
              {t("subscriptions.paper_tradings")}
              {permissions?.includes(LIVE_TRADING) ? ',' : '.'}
            </li> : null
        }
        {permissions?.includes(LIVE_TRADING) ? <li>
          {showLimitRange(
            rests.money_tradings,
            formatLimitValue(money_tradings_limit)
          )}{" "}
          {t("subscriptions.money_tradings")}.
        </li> : null}
      </ul>
    </div>
  );

  const getLimitColor = (limit, initialLimit) => {
    switch (getLimitRatio(limit, initialLimit, status)) {
      case RATIO_LIMITS.A_LOT:
        return colors.gray_text;
      case RATIO_LIMITS.FEW:
        return colors.orange;
      case RATIO_LIMITS.NO:
      case RATIO_LIMITS.NOT_INCLUDES:
      default:
        return colors.gray_dim;
    }
  };

  const getLimitField = (name, initialLimit, Icon) => {
    const color = getLimitColor(usage[name], initialLimit);
    return (
      <Fragment>
        <Icon
          size={17}
          color={color}
          style={{ marginLeft: 12, marginRight: 3 }}
        />
        <span className={styles.value} style={{ color }}>
          {rests[name]}
        </span>
      </Fragment>
    );
  };

  const isPaymentFailed = status === USER_SUBSCRIPTION_STATUSES.PAYMENT_FAILED;
  return usage && plan ? (
    <Tooltip
      title={isPaymentFailed ? t("subscriptions.payment_failed") : getTooltip()}
    >
      <Link
        to="/profile/1"
        className={styles.container}
        id={setGaId(GA_CATEGORIES.HEADER, "from-limits-to-plans")}
      >
        {isPaymentFailed ? (
          <span className={styles.paymentFailedPlanName}>
            {t("subscriptions.payment_failed")}
          </span>
        ) : null}
        {!isPaymentFailed && name ? (
          <span className={styles.planName}>{name}</span>
        ) : null}
        {!isPaymentFailed ? (
          <div className={styles.values}>
            {permissions?.includes(BACKTEST_ALGOS) ? getLimitField("backtests", backtests_limit, AnalyticsOutline) : null}
            {permissions?.includes(BACKTEST_ALGOS) ? getLimitField("algos", algos_limit, OptionsOutline) : null}
            {permissions?.includes(USE_SCREENERS) ? getLimitField("screeners", screeners_limit, FilterOutline) : null}
            {permissions?.includes(PAPER_TRADING) ? getLimitField("paper_tradings", paper_tradings_limit, LayersOutline) : null}
            {permissions?.includes(LIVE_TRADING) ? getLimitField("money_tradings", money_tradings_limit, PulseOutline) : null}
          </div>
        ) : null}
      </Link>
    </Tooltip>
  ) : null;
};

export default PlanInfo;
