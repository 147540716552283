import React, { Fragment } from "react";
import styles from "./Breadcrumbs.module.css";
import { useSelector } from "react-redux";
import { useRedirect } from "react-admin";
import { TRADING_RESOURCE } from "../../shared/variables";
import { Storage } from "../../config";
import { isDesktop } from "react-device-detect";
import { PortfolioType } from "../../pages/Algo/utils";

const NAME_MAPPINGS = {
  "/screeners": {
    list: "screeners",
    new: "new screener",
    backtest: "backtest",
  },
  "/algos": { list: "algos", new: "new algo", backtest: "backtest" },
  "/watchlist-algos": { list: "watchlist-algos", new: "new algo", backtest: "backtest" },
  [`/${TRADING_RESOURCE.PAPER}`]: {
    list: "paper trading",
    new: "new paper trading",
  },
  [`/${TRADING_RESOURCE.LIVE}`]: {
    list: "live trading",
    new: "new live trading",
  },
  //FIXME: this should be localized
  "/profile": ["account", "subscription", "referrals", "brokers", "terms"],
  "/users": ["profile", "algos", "subscriptions"],
};

const makeBreadcrumbs = ({ pathname, resources }) => {
  // profile
  if (/^\/profile(?:\/\d)?$/.test(pathname)) {
    const match = pathname.match(/^(\/profile)(?:\/(\d))?$/);
    const tab = NAME_MAPPINGS[match[1]][match[2] || 0];
    return [{ name: isDesktop ? `profile (${tab})` : tab, path: null }];
  }
  // root pages
  if (/^(?:\/tradings)?\/[a-zA-Z-]+$/.test(pathname)) {
    return [
      {
        name: NAME_MAPPINGS[pathname]?.list || pathname.replace("/", ""),
        path: null,
      },
    ];
  }
  // new (create)
  if (/^(?:\/tradings)?\/[a-zA-Z-]+\/create$/.test(pathname)) {
    const match = pathname.match(/^(\/[a-zA-Z-]+(?:\/[a-z0-9-]+)?)\/create$/);
    return [
      {
        name: NAME_MAPPINGS[match[1]]?.list || match[1].replace("/", ""),
        path: match[1],
      },
      { name: NAME_MAPPINGS[match[1]]?.new || "create", path: null },
    ];
  }

  // algos
  if (/^\/algos\/[a-z0-9-]+$/.test(pathname)) {
    const match = pathname.match(/^\/algos\/([a-z0-9-]+)$/);
    const algo = resources.algos?.data[match[1]];
    const { name, portfolio_type } = algo || {};

    return portfolio_type === PortfolioType.INTRADAY ? [
        { name: "algos", path: "/algos" },
        { name, path: null },
      ] :
      [
        { name: "watchlist-alogs", path: "/watchlist-algos" },
        { name, path: null },
      ];
  }

  // users algos edit & show
  if (/^\/algos\/[a-z0-9-]+\/show$/.test(pathname)) {
    const match = pathname.match(/^(\/[a-zA-Z-]+)\/([a-z0-9-]+)\/show$/);
    const tab = NAME_MAPPINGS["/users"][1];

    const algo = resources[match[1].replace("/", "")]?.data[match[2]];
    const { name, owner } = algo || {};

    if (!name || !owner) return [];

    return [
      { name: "users", path: "/users" },
      { name: `${owner?.name} (${tab})`, path: `/users/${owner?.id}/1` },
      { name, path: null },
    ];
  }
  // tradings edit & show
  if (/^\/tradings\/[a-z0-9-]+(?:\/show)?(?:\/\d)?$/.test(pathname)) {
    const match = pathname.match(/^(\/[a-zA-Z-]+)\/([a-z0-9-]+)(?:\/show)?(?:\/\d)?$/);

    const trading = resources[match[1].replace("/", "")]?.data[match[2]];
    const { name, trading_type } = trading || {};
    if (!name) return [];

    match[1] = `/${TRADING_RESOURCE[trading_type]}`;

    return [
      {
        name: NAME_MAPPINGS[match[1]]?.list || match[1].replace("/", ""),
        path: match[1],
      },
      { name, path: null },
    ];
  }
  // users
  if (/^\/users\/[a-z0-9-]+(?:\/\d)?$/.test(pathname)) {
    const match = pathname.match(/^(\/users)\/([a-z0-9-]+)(?:\/(\d))?$/);
    const name = resources[match[1].replace("/", "")]?.data[match[2]]?.name;
    const tab = NAME_MAPPINGS[match[1]][match[3] || 0];
    return [
      {
        name: NAME_MAPPINGS[match[1]]?.list || match[1].replace("/", ""),
        path: match[1],
      },
      { name: name ? `${name} (${tab})` : tab, path: null },
    ];
  }
  // detailed view (edit)
  if (/^\/[a-zA-Z-]+\/[a-z0-9-]+$/.test(pathname)) {
    const match = pathname.match(/^(\/[a-zA-Z-]+)\/([a-z0-9-]+)$/);
    const name = resources[match[1].replace("/", "")]?.data[match[2]]?.name;
    if (!name) {
      return [];
    }

    return [
      {
        name: NAME_MAPPINGS[match[1]]?.list || match[1].replace("/", ""),
        path: match[1],
      },
      { name, path: null },
    ];
  }
  // screeners backtest
  if (/^\/screener-backtests\/[a-z0-9-]+\/show$/.test(pathname)) {
    const match = pathname.match(/^(\/screener-backtests)\/([a-z0-9-]+)\/show$/);
    const screenerId = resources["screener-backtests"]?.data[match[2]]?.screener?.id;
    const screenerName = resources.screeners?.data[screenerId]?.name;
    if (!screenerName) return [];
    return [
      { name: NAME_MAPPINGS["/screeners"].list, path: "/screeners" },
      { name: screenerName, path: "/screeners/" + screenerId },
      { name: NAME_MAPPINGS["/screeners"].backtest, path: null },
    ];
  }
  // algo backtest
  if (/^\/backtests\/[a-z0-9-]+\/show(?:\/\d)?$/.test(pathname)) {
    const match = pathname.match(/^\/backtests\/([a-z0-9-]+)\/show(?:\/\d)?$/);
    const backtest = resources.backtests?.data[match[1]];
    const { name, algo, shared, portfolio_type } = backtest || {};
    const owner = algo?.id ? resources["algos"]?.data[algo?.id]?.owner : null;

    if (!(algo?.name || name)) return [];
    const roles = Storage.getItem("roles");

    if (owner && owner?.id !== Storage.getItem("profile")) {
      return [
        { name: "users", path: "/users" },
        { name: `${owner?.name} (algos)`, path: `/users/${owner?.id}/1` },
        { name: algo?.name || name, path: `/algos/${algo?.id}/show` },
        { name: NAME_MAPPINGS["/algos"].backtest, path: null },
      ];
    }

    return portfolio_type === PortfolioType.INTRADAY ? [
      { name: NAME_MAPPINGS["/algos"].list, path: "/algos" },
      {
        name: algo?.name || name,
        path: shared ? (roles?.includes("super_admin") ? "/algos/" + algo?.id : null) : "/algos/" + algo?.id,
      },
      { name: NAME_MAPPINGS["/algos"].backtest, path: null },
    ] : [
      { name: NAME_MAPPINGS["/watchlist-algos"].list, path: "/watchlist-algos" },
      {
        name: algo?.name || name,
        path: shared ? (roles?.includes("super_admin") ? "/algos/" + algo?.id : null) : "/algos/" + algo?.id,
      },
      { name: NAME_MAPPINGS["/algos"].backtest, path: null },
    ];
  }
  return [];
};

const Breadcrumbs = () => {
  const redirect = useRedirect();

  const { admin: { resources } = {}, router: { location: { pathname } = {} } = {} } = useSelector((state) => state);

  const breadcrumbs = makeBreadcrumbs({ pathname, resources });

  return (
    <div className={styles.container}>
      {breadcrumbs.map((item, index) => (
        <Fragment key={index}>
          {index !== 0 ? <span className={styles.breadcrumbDelimiter}>/</span> : null}
          <button onClick={() => redirect(item.path)} disabled={!item.path} className={styles.breadcrumbItem}>
            {item.name}
          </button>
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
