import merge from "lodash/merge";
import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import typography from "./typography";
import muiOverrides from "./muiOverrides";
import raOverrides from "./raOverrides";

const baseOptions = {
  typography,
  sidebar: {
    width: 185,
    closedWidth: 72,
  },
};

export const createTheme = () => createMuiTheme(merge({}, baseOptions, muiOverrides, raOverrides));
