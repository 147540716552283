import React, { useState } from "react";
import styles from "./TimeInput.module.css";
import { useInput, useTranslate } from "react-admin";
import { useSelector } from "react-redux";
import cn from "clsx";
import moment from "moment";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import ClockIcon from "react-feather/dist/icons/clock";
import { isMobile } from "react-device-detect";
import Config from "../../config";
import { TimeZones } from "../../locales/timezones";

const innerFormat = "HH:mm:ss";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { 500: "#2196f3" }, // blue
  },
});

export const TimeInterval = (props) => {
  const t = useTranslate();
  const [active, setActive] = useState(false);
  const timezone = useSelector((store) => store.profile.timezone);

  const { abbr: timezoneShort = "EDT", h12 } =
  TimeZones.find((item) => item.text === timezone) || {};
  // const momentTz = Object.entries(moment.tz._names).find(_name => moment.tz(_name[1]).zoneAbbr() === timezoneShort)[1];

  const displayFormat = h12 ? "hh:mm A" : "HH:mm";
  const labelTz = `${props.label} (${timezoneShort} timezone)`;

  // here we expect props.minTime and props.maxTimemin to be a moment in local timezone
  const validateMinMax = (value) => {
    const moment_value = moment(value, displayFormat);

    if (moment_value.isValid() && moment_value.isBefore(props.minTime)) {
      return `Min ${props.minTime.format(displayFormat)}`;
    }

    if (moment_value.isValid() && moment_value.isAfter(props.maxTime)) {
      return `Max ${props.maxTime.format(displayFormat)}`;
    }
    return undefined;
  };

  const timesValid_from = (value, allValues) => {
    const _from = allValues[props.source_from];
    const _to = allValues[props.source_to];

    const moment_value = moment(value, displayFormat);
    const moment_from = moment(_from, displayFormat);
    const moment_to = moment(_to, displayFormat);
    if (!value) {
      return t("algos.validate.time_required");
    }

    if (_to && !_from) {
      return " ";
    }

    if (!moment_value.isValid()) {
      return t("algos.validate.time_invalid");
    }

    if (
      moment_from.isValid() &&
      moment_to.isValid() &&
      moment_from > moment_to
    ) {
      return t("backtest.validate.date_start_after_end");
    }
    return undefined;
  };

  const timesValid_to = (value, allValues) => {
    const _from = allValues[props.source_from];
    const _to = allValues[props.source_to];

    const moment_value = moment(value, displayFormat);
    const moment_from = moment(_from, displayFormat);
    const moment_to = moment(_to, displayFormat);
    if (!value) {
      return t("algos.validate.time_required");
    }

    if (_to && !_from) {
      return " ";
    }

    if (!moment_value.isValid()) {
      return t("algos.validate.time_invalid");
    }

    if (
      moment_from.isValid() &&
      moment_to.isValid() &&
      moment_from > moment_to
    ) {
      return " ";
    }
    return undefined;
  };

  return (
    <Tooltip
      title={!active ? props.tooltip : ""}
      enterDelay={Config.TOOLTIP_DELAY}
      enterNextDelay={Config.TOOLTIP_DELAY}
      classes={{ tooltip: styles.tooltip }}
    >
      <div>
        <TimeInput
          id={`${props.id}-from`}
          label={labelTz}
          source={props.source_from}
          displayFormat={displayFormat}
          onOpenClose={setActive}
          validate={[timesValid_from, validateMinMax]}
          className={cn(styles.timeField, styles.noRightRadius)}
          h12={h12}
        />
        <TimeInput
          id={`${props.id}-to`}
          source={props.source_to}
          displayFormat={displayFormat}
          onOpenClose={setActive}
          validate={[timesValid_to, validateMinMax]}
          className={cn(styles.timeField, styles.timeInputRight)}
          h12={h12}
        />
      </div>
    </Tooltip>
  );
};

export const TimeInput = (props) => {
  let {
    input: { value, name, onChange, onBlur },
    meta: { error },
  } = useInput(props);

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardTimePicker
          id={props.id}
          data-test-id={name}
          format={props.displayFormat}
          ampm={!!props.h12}
          variant={isMobile ? "dialog" : "inline"}
          label={props.label}
          openTo="hours"
          views={["hours", "minutes"]}
          value={moment(value, innerFormat)}
          // inputValue={moment(value, innerFormat).format(props.displayFormat)}

          onBlur={onBlur}
          onChange={(value) => {
            onChange(value ? value.format(innerFormat) : "");
          }}
          //pass the state up to the parent
          onOpen={() => props.onOpenClose(true)}
          onClose={() => props.onOpenClose(false)}
          error={!!error}
          helperText={error}
          inputVariant="outlined"
          className={cn(styles.timePicker, props.className)}
          keyboardIcon={<ClockIcon className={styles.clockIcon} />}
          mask={props.h12 ? "__:__ _M" : "__:__"}
          InputLabelProps={{ shrink: true }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
