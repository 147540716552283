import React from "react";
import { SimpleForm, usePermissions, useTranslate } from "react-admin";
import LinearProgress from "@material-ui/core/LinearProgress";
import ScreenerIcon from "react-feather/dist/icons/activity";
import { CriteriaBlock } from "../../components/CriteriaBlock/CriteriaBlock";
import classes from "./Backtest.module.css";
import NarrowRow from "../../components/Rows/NarrowRow";
import Label from "../../components/Rows/Label";
import NarrowLabelVal from "../../components/Rows/NarrowLabelVal";
import { formatNumberValue, setGaId, toDateFormat } from "../../shared/utils";
import WideRow from "../../components/Rows/WideRow";
import WideLabelVal from "../../components/Rows/WideLabelVal";
import WideValue from "../../components/Rows/WideValue";
import { InitialLine } from "../Algo/components/Initial/Initial";
import { AdditionalExitBlock } from "../../components/AdditionalExit/AdditionalExit";
import { AdvancedLine } from "../../components/AdvancedBlock/AdvancedBlock";
import { statuses } from "../../shared/statuses";
import StatusField from "../../components/StatusField/StatusField";
import ViewActions from "../../components/ViewActions/ViewActions";
import { BrokerIcon } from "../Trading/components/Brokers/BrokersBlock";
import { MomentField } from "../../components/MomentField";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import { DescriptionLine } from "../../components/DescriptionLine/DescriptionLine";
import TickerBenchmarkField from "../../components/TickerBenchmarkField/TickerBenchmarkField";
import WatchlistCriteriaBuilder from "../Watchlist/components/WatchlistCriteriaBuilder";
import { USE_SCREENERS } from "../../shared/permissions";

export const BacktestHeader = ({ ga, record, resource, noCode = false }) => {
  const t = useTranslate();
  const { loaded, permissions } = usePermissions();

  const onClickScreener = () => window.open(`/#/screeners/${record.screener.id}`, "_blank");

  return (
    <div>
      <div>
        {isMobile && !record.trading_type && record.shared && (
          <div className={classes.mobileActions}>
            <ViewActions ga={ga} record={record} resource={resource} />
          </div>
        )}
        <InitialLine values={record}>
          {!isMobile && <ViewActions ga={ga} record={record} resource={resource} />}
        </InitialLine>
        <DescriptionLine record={record} source="description" className={classes.noImage} />
      </div>

      {!noCode && (
        <SimpleForm record={record} resource={resource} toolbar={null}>
          {record.portfolio_type === "INTRADAY" ? (
            <>
              <CriteriaBlock record={record} resource={resource} readOnly />
              <AdditionalExitBlock ga={ga} record={record} resource={resource} readOnly={true} />
              {record.screener ? (
                <NarrowRow>
                  <Label
                    color="blue"
                    onClick={permissions?.includes(USE_SCREENERS) ? onClickScreener : false}
                    id={setGaId(ga?.category, "to-screener")}
                  >
                    <ScreenerIcon className={classes.screenerIcon} />
                    {record.screener?.name}
                  </Label>
                </NarrowRow>
              ) : null}
              <AdvancedLine values={record} />
            </>
          ) : (
            <WatchlistCriteriaBuilder readOnly={true} record={record} resource={resource} />
          )}
        </SimpleForm>
      )}

      <NarrowRow>
        {record.start_date && record.end_date ? (
          <NarrowLabelVal
            label={t("backtest.date_range")}
            value={toDateFormat(record.start_date) + " - " + toDateFormat(record.end_date)}
          />
        ) : null}
        <NarrowLabelVal
          label={t("backtest.capital")}
          value={formatNumberValue(record.initial_capital, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: "currency",
            currency: "USD",
          })}
        />
      </NarrowRow>

      {record.trading_type ? (
        <WideRow>
          <WideLabelVal label={t("tradings.engine")} value={<BrokerIcon record={record} source="broker" />} />

          <WideLabelVal
            label={t("tradings.trades_today")}
            value={
              <WideValue>
                {formatNumberValue(record.trades_today || 0, {
                  maximumFractionDigits: 2,
                })}
              </WideValue>
            }
          />

          <WideLabelVal
            label={t("tradings.last_trade")}
            value={<MomentField source="last_trade" fromNow={true} record={record} />}
          />
        </WideRow>
      ) : null}

      <WideRow>
        <div className={clsx(classes.wrapper, { [classes.mobile]: isMobile })}>
          <WideLabelVal label={t("backtest.status")} value={<StatusField source="status" record={record} />} />
          <WideLabelVal
            label={t("backtest.ticker")}
            value={
              <WideValue>
                <TickerBenchmarkField
                  ticket="ticker"
                  benchmark="benchmark_ticker"
                  record={record}
                  permissions={permissions}
                />
              </WideValue>
            }
          />
        </div>

        {(record.status !== statuses.failed.id && !record.error && !record.trading_type) || record.trading_type ? (
          <>
            <div
              className={clsx(classes.wrapper, {
                [classes.mobile]: isMobile && record.trading_type,
              })}
            >
              <WideLabelVal
                label={t("backtest.return_pct")}
                value={
                  <WideValue initialValueForColor={record.return_pct}>
                    {formatNumberValue(record.return_pct || 0, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "percent",
                    })}
                  </WideValue>
                }
              />
              {record.trading_type && (
                <WideLabelVal
                  label={t("tradings.gain_today_pct")}
                  value={
                    <WideValue initialValueForColor={record.gain_today_pct}>
                      {formatNumberValue(record.gain_today_pct || 0, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "percent",
                      })}
                    </WideValue>
                  }
                />
              )}
            </div>
            <div
              className={clsx(classes.wrapper, {
                [classes.mobile]: isMobile && record.trading_type,
              })}
            >
              <WideLabelVal
                label={t("backtest.return")}
                value={
                  <WideValue>
                    {formatNumberValue(record.return_value || 0, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })}
                  </WideValue>
                }
              />
              {record.trading_type && (
                <WideLabelVal
                  label={t("tradings.gain_today")}
                  value={
                    <WideValue>
                      {formatNumberValue(record.gain_today || 0, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "USD",
                      })}
                    </WideValue>
                  }
                />
              )}
            </div>

            {!record.trading_type ? (
              <div
                className={clsx(classes.wrapper, {
                  [classes.mobile]: isMobile,
                })}
              >
                <WideLabelVal
                  label={t("backtest.alpha")}
                  value={
                    <WideValue>
                      {formatNumberValue(record.alpha, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </WideValue>
                  }
                />

                <WideLabelVal
                  label={t("backtest.beta")}
                  value={
                    <WideValue>
                      {formatNumberValue(record.beta, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </WideValue>
                  }
                />

                <WideLabelVal
                  label={t("backtest.mdd")}
                  value={
                    <WideValue>
                      {formatNumberValue(record.mdd, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "percent",
                      })}
                    </WideValue>
                  }
                />
              </div>
            ) : null}

            <div className={clsx(classes.wrapper, { [classes.mobile]: isMobile })}>
              {/*<WideLabelVal*/}
              {/*  label={t("backtest.sharpe_ratio")}*/}
              {/*  value={*/}
              {/*    <WideValue>*/}
              {/*      {formatNumberValue(record.sharpe_ratio || 0, {*/}
              {/*        maximumFractionDigits: 2,*/}
              {/*        minimumFractionDigits: 2,*/}
              {/*      })}*/}
              {/*    </WideValue>*/}
              {/*  }*/}
              {/*/>*/}
              <WideLabelVal
                label={t("backtest.win_loss")}
                value={
                  <LinearProgressWithLabels
                    options={{
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }}
                    leftValue={record.wins || 0}
                    rightValue={record.losses || 0}
                  />
                }
              />
            </div>

            <WideLabelVal
              label={t("backtest.reward_risk")}
              value={
                <LinearProgressWithLabels
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }}
                  leftValue={record.money_made || 0}
                  rightValue={record.money_lost || 0}
                />
              }
            />
          </>
        ) : null}
      </WideRow>
    </div>
  );
};

export const LinearProgressWithLabels = ({ leftValue, rightValue, options }) => {
  const ratio = (leftValue / (leftValue + rightValue)) * 100;
  return (
    <div className={clsx(classes.linearProgress, { [classes.emptyLinearProgress]: !ratio })}>
      <div className={classes.linearProgressValues}>
        <span className={leftValue ? classes.positive : classes.empty}>{formatNumberValue(leftValue, options)}</span>
        &nbsp;
        <span className={classes.empty}>{"/"}</span>
        &nbsp;
        <span className={rightValue ? classes.negative : classes.empty}>{formatNumberValue(rightValue, options)}</span>
      </div>
      <div className={classes.linearProggressContainer}>
        <LinearProgress variant="determinate" value={ratio} />
      </div>
    </div>
  );
};
