export default {
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiToggleButton: {
      root: {
        selected: {
          backgroundColor: "var(--blue)",
        },
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 16,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.07)",
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "var(--header_shadow)",
      },
      colorSecondary: {
        backgroundColor: "var(--white)",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          color: "var(--gray_text)",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 24,
      },
    },
    MuiIconButton: {
      root: {
        padding: 4,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: "none",
      },
    },
    MuiAutocomplete: {
      popper: {
        boxShadow: "var(--popup_shadow)",
        backgroundColor: "var(--white)",
        borderRadius: 4,
        marginBottom: 10,
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: 5,
      },
    },
    MuiGrid: {
      "spacing-xs-1": {
        width: "100%",
      },
    },
    MuiMenuItem: {
      root: {
        letterSpacing: 0,
      },
    },
    MuiTypography: {
      body2: {
        letterSpacing: 0,
      },
    },
    MuiButton: {
      root: {
        letterSpacing: 0,
      },
    },
    MuiCheckbox: {
      root: {
        color: "var(--gray_icon)",
      },
    },
  },
};
