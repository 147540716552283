import React, { useEffect, useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import styles from "./BrokersTable.module.scss";
import { useTranslate } from "react-admin";
import useAuthAlpaca from "../Brokers/hooks/useAuthAlpaca";
import useAuthTDAmeritrade from "../Brokers/hooks/useAuthTDAmeritrade";
import { BROKERS, GA_CATEGORIES, TRADING_TYPE } from "../../../../shared/variables";
import { VALIDATE_BROKER } from "../../../../store/sagas/brokersSaga";
import { LINK_STATUSES } from "../../../../store/reducers/brokersReducer";
import LinkButton from "../Brokers/controls/LinkButton/LinkButton";
import CheckIcon from "react-feather/dist/icons/check";
import Button from "@material-ui/core/Button";
import { BrokerIcon } from "../../../Trading/components/Brokers/BrokersBlock";
import DoneIcon from "react-feather/dist/icons/check-circle";
import XCircleIcon from "react-feather/dist/icons/x-circle";
import BrokerTerms from "../Brokers/renders/BrokerTerms/BrokerTerms";
import clsx from "clsx";
import MoreActions from "../../../../components/MoreActions/MoreActions";
import { actions } from "../../../../shared/moreActions";
import { formatNumberValue } from "../../../../shared/utils";
import { isDesktop } from "react-device-detect";
import BrokerValidation from "../../../../components/Alert/alerts/BrokerValidation";
import useAuthIbkr from "../Brokers/hooks/useAuthIbkr";

export const VALIDATION_STATUS = {
  UNKNOWN: "UNKNOWN",
  VALID: "VALID",
  NOT_VALID: "NOT_VALID",
};

const Row = (props) => {
  const { row } = props;
  const { id, status, shortName, name, icon, disabled } = row;
  const [open, setOpen] = useState(false);
  const [agreementShow, setAgreementShow] = useState(false);
  const [valid, setValid] = useState(VALIDATION_STATUS.UNKNOWN);
  const t = useTranslate();

  const authAlpaca = useAuthAlpaca();
  const authAmeritrade = useAuthTDAmeritrade();
  const authIbkr = useAuthIbkr();

  const brokers = useSelector((store) => store.brokers.data);
  const broker = id ? brokers[id.toLowerCase()] || {} : {};
  const subAccounts = Array.isArray(broker.data) ? broker.data : [];

  const ga = { category: GA_CATEGORIES.PROFILE, action: "brokers-tab" };

  const showLinkButton = () => {
    if (disabled) {
      return (
        <Button disabled className={styles.comingSoonButton}>
          {t(status)}
        </Button>
      );
    }

    const linkStatus = broker.status;

    if (
      [LINK_STATUSES.NOT_LINKED, LINK_STATUSES.ACCOUNT_NOT_LINKED, LINK_STATUSES.REVOKED, LINK_STATUSES.ERROR].includes(
        linkStatus
      )
    ) {
      return (
        <LinkButton
          onClick={() => {
            setValid(VALIDATION_STATUS.UNKNOWN);
            setAgreementShow(true);
          }}
          disabled={broker?.status === LINK_STATUSES.ACCOUNT_NOT_LINKED}
        />
      );
    }

    if (linkStatus === LINK_STATUSES.LINKED) {
      return (
        <div className={styles.linkStatusOK}>
          <CheckIcon className={styles.linkIcon} />
          {t("profile.linked")}
        </div>
      );
    }
  };

  const getIcon = (condition) => {
    const size = 16;
    return condition ? (
      <DoneIcon size={size} className={styles.true} />
    ) : (
      <XCircleIcon size={size} className={styles.false} />
    );
  };

  const hasTrading = (type) =>
    (id === "ALPACA" && broker?.status === LINK_STATUSES.LINKED) ||
    subAccounts.some(({ trading_type }) => trading_type === type);

  const disableBroker = disabled || broker?.status === LINK_STATUSES.UNKNOWN;

  useEffect(() => {
    setAgreementShow(!!subAccounts?.length && broker?.status === LINK_STATUSES.ACCOUNT_NOT_LINKED);
    if ([LINK_STATUSES.REVOKED, LINK_STATUSES.ERROR].includes(broker?.status)) {
      setValid(VALIDATION_STATUS.NOT_VALID);
    } else if (
      (broker?.status === LINK_STATUSES.LINKED && valid !== VALIDATION_STATUS.VALID) ||
      broker?.status === LINK_STATUSES.NOT_LINKED
    ) {
      setValid(VALIDATION_STATUS.UNKNOWN);
    }
  }, [subAccounts, broker]);

  return (
    <React.Fragment>
      <TableRow className={styles.mainRow}>
        {/*<TableCell>*/}
        {/*  <IconButton*/}
        {/*    onClick={() => setOpen(!open)}*/}
        {/*    className={styles.chevron}*/}
        {/*    disabled={disableBroker || broker?.status !== LINK_STATUSES.LINKED}*/}
        {/*  >*/}
        {/*    {open ? <ChevronUp /> : <ChevronDown />}*/}
        {/*  </IconButton>*/}
        {/*</TableCell>*/}
        <TableCell colSpan={2}>
          <div className={clsx(styles.cell, styles.buttonWrapper)}>{showLinkButton()}</div>
        </TableCell>
        <TableCell>
          <div className={clsx(styles.cell, styles.broker, { [styles.disabled]: disableBroker })}>
            <BrokerIcon broker={{ name, icon }} />
            {name}
          </div>
        </TableCell>
        <TableCell>
          {isDesktop && broker?.status === LINK_STATUSES.LINKED ? (
            <div className={styles.cells}>
              <div className={clsx(styles.cell, styles.availability, styles[hasTrading(TRADING_TYPE.PAPER)])}>
                {getIcon(hasTrading(TRADING_TYPE.PAPER))} {t("brokers.paper_trading")}
              </div>
              <div className={clsx(styles.cell, styles.availability, styles[hasTrading(TRADING_TYPE.LIVE)])}>
                {getIcon(hasTrading(TRADING_TYPE.LIVE))} {t("brokers.live_trading")}
              </div>
              {/*<span className={clsx(styles.cell, styles.email)}>john.doe@mail.com</span>*/}
            </div>
          ) : null}
        </TableCell>

        <TableCell align="right">
          {broker?.status && broker?.status !== LINK_STATUSES.NOT_LINKED ? (
            <div className={styles.moreActions}>
              <MoreActions
                ga={ga}
                items={[actions.unlink, actions.validate]}
                record={{ id: id?.toLowerCase(), name }}
                setValid={setValid}
              />
            </div>
          ) : null}
        </TableCell>
      </TableRow>

      {disableBroker ? null : (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={5}>
            <div className={styles.brokerTerms}>
              <BrokerTerms
                brokerName={id?.toLowerCase()}
                brokerData={{ ...broker, shortName }}
                ga={ga}
                isOpen={agreementShow}
                onAgree={id === "ALPACA" ? authAlpaca : id === "IBKR" ? authIbkr : authAmeritrade}
                onCancel={() => setAgreementShow(!agreementShow)}
              />
            </div>
            <div className={styles.message}>
              <BrokerValidation ga={ga} status={valid} onCancel={() => setValid(VALIDATION_STATUS.UNKNOWN)} />
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit className={styles.collapseRow}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("brokers.name")}</TableCell>
                    <TableCell>{t("brokers.buying_power")}</TableCell>
                    <TableCell>{t("brokers.paper")}</TableCell>
                    <TableCell>{t("brokers.live")}</TableCell>
                    <TableCell>{t("brokers.shorting")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subAccounts.map(({ id, type, buying_power, trading_type, shorting_enabled }) => (
                    <TableRow key={id}>
                      <TableCell>{`${shortName} ${type}`}</TableCell>
                      <TableCell>
                        {formatNumberValue(buying_power, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 0,
                          style: "currency",
                          currency: "USD",
                        })}
                      </TableCell>
                      <TableCell>{getIcon(trading_type === TRADING_TYPE.PAPER)}</TableCell>
                      <TableCell>{getIcon(trading_type === TRADING_TYPE.LIVE)}</TableCell>
                      <TableCell>{getIcon(shorting_enabled)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

const BrokersTable = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: VALIDATE_BROKER, payload: { id: "alpaca" } });
    dispatch({ type: VALIDATE_BROKER, payload: { id: "ameritrade" } });
    dispatch({ type: VALIDATE_BROKER, payload: { id: "ibkr" } });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableBody data-testid="brokers">
          {BROKERS.map((broker) => (
            <Row key={broker.name} row={broker} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BrokersTable;
