import { useCallback, useEffect } from "react";
import { setGaId } from "../../shared/utils";
import PropTypes from "prop-types";

const DatagridIdsWrapper = ({ ga }) => {
  const getColumnId = useCallback(
    (el) => {
      const name = el.textContent;
      return name && setGaId(ga?.category, `title-${name}`);
    },
    [ga],
  );

  const wrapColumnHeaders = useCallback(() => {
    document
      .querySelectorAll(".MuiTableCell-head > span:not(.select-all)")
      .forEach((el) => el.setAttribute("id", getColumnId(el)));
  }, [ga, getColumnId]);

  const wrapColumnCheckboxes = useCallback(() => {
    document
      .querySelectorAll("input[class^='PrivateSwitchBase-input']")
      .forEach((el) =>
        el.setAttribute("id", setGaId(ga?.category, ga?.action ? `${ga?.action}-checkbox` : "checkbox")),
      );
  }, [ga]);

  const wrapUnselectCheckboxesButton = useCallback(() => {
    document
      .querySelectorAll("button[title='Unselect']")
      .forEach((el) =>
        el.setAttribute(
          "id",
          setGaId(ga?.category, ga?.action ? `${ga?.action}-checkbox-deselect` : "checkbox-deselect"),
        ),
      );
  }, [ga]);

  const wrapExpandRowButton = useCallback(() => {
    document
      .querySelectorAll("div[aria-label='Expand']")
      .forEach((el) => el.setAttribute("id", setGaId(ga?.category, "trade-open")));
  }, [ga]);

  useEffect(() => {
    wrapColumnHeaders();
    wrapColumnCheckboxes();
    wrapUnselectCheckboxesButton();
    wrapExpandRowButton();
  }, [wrapColumnHeaders, wrapColumnCheckboxes, wrapUnselectCheckboxesButton, wrapExpandRowButton]);

  return null;
};

export default DatagridIdsWrapper;

DatagridIdsWrapper.propTypes = {
  ga: PropTypes.object.isRequired,
  headerClassName: PropTypes.string.isRequired,
  cellClassName: PropTypes.string.isRequired,
};
