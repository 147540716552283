import React from "react";
import {
  useLogout,
  usePermissions,
  useRedirect,
  useTranslate,
} from "react-admin";
import UserIcon from "react-feather/dist/icons/user";
import LogoutIcon from "react-feather/dist/icons/log-out";
import PopupMenu from "../PopupMenu/PopupMenu";
import { setGaId } from "../../shared/utils";
import { GA_CATEGORIES } from "../../shared/variables";

const UserMenu = () => {
  const t = useTranslate();
  const redirect = useRedirect();
  const logout = useLogout();
  const { permissions } = usePermissions();
  const hasPermissions = permissions && JSON.parse(permissions)?.length;

  const menuItems = [
    {
      condition: hasPermissions,
      onClick: () => redirect("/profile"),
      icon: <UserIcon size={18} />,
      gaId: setGaId(GA_CATEGORIES.HEADER, "profile"),
      id: "profile",
      label: t("Profile"),
    },
    {
      condition: true,
      onClick: () => logout(),
      icon: <LogoutIcon size={18} />,
      gaId: setGaId(GA_CATEGORIES.HEADER, "logout"),
      id: "logout",
      label: t("Logout"),
    },
  ];

  return <PopupMenu menuItems={menuItems} id="profile" />;
};

export default UserMenu;
