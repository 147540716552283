import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DISCOUNT_BANNER, EMAIL_BANNER, PAYMENT_BANNER, REFERRAL_BANNER } from "../../../store/reducers/headerReducer";
import { usePermissions, useTranslate } from "react-admin";
import HeaderSlideIn from "./HeaderSlideIn";
import { customFetch } from "../../../DataProvider";
import { USER_SUBSCRIPTION_STATUSES } from "../../../store/sagas/usageSaga";
//icons
import MailIcon from "react-feather/dist/icons/mail";
import ShareIcon from "react-feather/dist/icons/share-2";
import GiftIcon from "react-feather/dist/icons/gift";
import AlertIcon from "react-feather/dist/icons/alert-circle";
import { sendEmailVerification, showReferral } from "../../../shared/utils";
import { auth } from "../../Auth/firebaseConfig";

const TopHeader = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { discount, email, referral, payment } = useSelector((store) => store.header);

  //email verification header
  const user = auth.currentUser;
  const { emailVerified } = user || {};
  const { permissions } = usePermissions();
  const hasPermissions = permissions && JSON.parse(permissions)?.length;
  const showEmail = user && emailVerified !== true && !!hasPermissions;
  const [isDisabled, setIsDisabled] = useState(false);

  const emailResend = () => {
    sendEmailVerification();
    setIsDisabled(true);
  };

  useEffect(() => {
    setTimeout(() => {
      if (isDisabled) {
        setIsDisabled(false);
      }
    }, 20000);
  }, [isDisabled]);

  //discount
  const { paymentOverdueFailed, discount_message, status } = useSelector((store) => store.usage.data);
  const showDiscount = !!discount_message;

  //payment
  const showPayment = paymentOverdueFailed;

  useEffect(() => {
    dispatch({ type: PAYMENT_BANNER, payload: showPayment });
    dispatch({ type: DISCOUNT_BANNER, payload: showDiscount });
    dispatch({ type: EMAIL_BANNER, payload: showEmail });
    dispatch({ type: REFERRAL_BANNER, payload: showReferral });
  }, [showEmail, showDiscount, showPayment, showReferral]);

  const openBillingPortal = async () => {
    try {
      window.location.href = await customFetch(
        `billing/portal-session?returnUrl=${encodeURIComponent(window.location.href)}`,
        "POST",
        null,
        "text",
      );
    } catch (e) {
      console.error(e);
    }
  };

  //return in reverse order so that closing one leads to the next message
  return (
    <>
      {/*standard blue header message for EMAIL VERIFICATION*/}
      <HeaderSlideIn
        show={email}
        closable={false}
        color="blue"
        id={EMAIL_BANNER}
        icon={<MailIcon size={18} color={"var(--blue)"} />}
        message={t("authorization.check_email")}
        linkMessage={t("authorization.resend_email")}
        action={emailResend}
        disabled={isDisabled}
      />

      {/*standard blue header message for REFERRAL*/}
      <HeaderSlideIn
        show={referral}
        closable={true}
        color="blue"
        id={REFERRAL_BANNER}
        icon={<ShareIcon size={18} color={"var(--blue)"} />}
        message={t("referral.refer_friends")}
        linkMessage={t("referral.learn_more")}
        action="/profile/2"
      />

      {/*standard black header message for DISCOUNTS*/}
      <HeaderSlideIn
        show={discount}
        closable={true}
        color="black"
        id={DISCOUNT_BANNER}
        icon={<GiftIcon size={18} color={"var(--white)"} />}
        message={discount_message}
        linkMessage={t("subscriptions.see_the_deal")}
        action="/profile/1"
      />

      {/*standard red header message for PAYMENTS*/}
      <HeaderSlideIn
        show={payment && status === USER_SUBSCRIPTION_STATUSES.PAYMENT_OVERDUE}
        closable={false}
        color="red"
        icon={<AlertIcon size={18} color={"var(--red)"} />}
        id={PAYMENT_BANNER}
        message={t("subscriptions.payment_failed_message_soft")}
        linkMessage={t("subscriptions.fix_payment")}
        action={openBillingPortal}
      />

      {/*standard red header message for PAYMENTS*/}
      <HeaderSlideIn
        show={payment && status === USER_SUBSCRIPTION_STATUSES.PAYMENT_FAILED}
        closable={false}
        color="red"
        icon={<AlertIcon size={18} color={"var(--red)"} />}
        id={PAYMENT_BANNER}
        message={t("subscriptions.payment_failed_message_hard")}
        linkMessage={t("subscriptions.choose_plan")}
        action={openBillingPortal}
      />
    </>
  );
};

export default TopHeader;
