import { put, takeEvery, retry } from "redux-saga/effects";
import { customFetch } from "../../DataProvider";
import {
  PLANS_LOADING,
  PLANS_RECEIVED,
  PLANS_FAIL,
} from "../reducers/plansReducer";

export const GET_PLANS = "GET_PLANS";
const SECOND = 1000;

export default function* plansSaga() {
  yield takeEvery(GET_PLANS, function* () {
    yield put({ type: PLANS_LOADING });

    try {
      const plans = yield retry(
        2,
        3 * SECOND,
        customFetch,
        "billing/subscription-plans",
        "GET"
      );

      const parsedPlans = plans.map((plan) => ({
        ...plan,
      }));
      yield put({ type: PLANS_RECEIVED, payload: { plans: parsedPlans } });
    } catch (e) {
      yield put({ type: PLANS_FAIL });
    }
  });
}
