import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import classes from "./FullScreenEditor.module.scss";
import SearchIcon from "react-feather/dist/icons/search";
import ChevronDownIcon from "react-feather/dist/icons/chevron-down";
import ClearIcon from "react-feather/dist/icons/x";
import MaximizeIcon2 from "react-feather/dist/icons/maximize-2";
import MinimizeIcon2 from "react-feather/dist/icons/minimize-2";
import ResizePanel from "react-resize-panel";

import { Accordion, AccordionDetails, AccordionSummary, OutlinedInput, Tooltip } from "@material-ui/core";
import Config from "../../config";
import {MarkdownDoc} from "../Markdown/MarkdownDoc";
import FlexSearch from "flexsearch";
import PropTypes from "prop-types";
import { colors } from "../../shared/cssVariables";
import { setGaId } from "../../shared/utils";
import clsx from "clsx";
import { InitialLineWithValues } from "../../pages/Algo/components/Initial/Initial";
import useDisableByLimit from "../../shared/hooks/useDisableByLimit";

const flexSearchIndex = new FlexSearch("fast", {
  doc: {
    id: "name",
    field: ["aliases", "doc"],
  },
});

export const FullScreenEditor = ({ ga, criteriaInputs, additionalBlock, handleSave, entity }) => {
  const t = useTranslate();
  const { values } = useFormState();
  const [openEditor, setOpenEditor] = useState(false);
  const [list, setList] = useState({});
  const [emptyDefaultList, setEmptyDefaultList] = useState({});
  const [search, setSearch] = useState();
  const [expandedGroups, setExpandedGroups] = useState([]);
  const { discount, email, referral, payment, banner } = useSelector((store) => store.header);
  const disableByLimit = useDisableByLimit(entity);
  const fullScreen = true;

  const isGroupExpanded = (name) => expandedGroups.includes(name);

  const handleExpandedChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpandedGroups([...expandedGroups, panel]);
      return;
    }

    const newExpandedGroups = expandedGroups.filter((item) => item !== panel);
    setExpandedGroups(newExpandedGroups);
  };

  useEffect(() => {
    const indicators = window.INDICATORS.map((indicator) => ({ ...indicator }));
    if (indicators?.length) {
      indicators.forEach((indicator) => {
        if (indicator.aliases) {
          indicator.aliases.push(indicator.name);
          indicator.aliases = indicator.aliases.join("\n");
        }
      });

      flexSearchIndex.add(indicators);

      const groupedList = prepareGroupedList(indicators);
      prepareEmptyDefaultList(groupedList);
      setList(groupedList);
    }
  }, [window.INDICATORS]);

  const prepareEmptyDefaultList = (data) => {
    const emptyGroupedList = {};
    Object.keys(data).forEach((item) => (emptyGroupedList[item] = []));
    setEmptyDefaultList(emptyGroupedList);
  };

  const prepareGroupedList = (data) => {
    return data.reduce((result, item) => {
      result[item.group] = result[item.group] || [];
      result[item.group].push(item);
      return result;
    }, Object.create(null));
  };

  const handleClickOpen = () => {
    window.scrollTo({ top: 0 });
    setOpenEditor(true);
  };

  const handleClose = () => {
    window.scrollTo({ top: 0 });
    setOpenEditor(false);
  };

  const handleCloseSave = () => {
    handleSave({ values, redirectOnSave: true });
    window.scrollTo({ top: 0 });
    setOpenEditor(false);
  };

  const handleSearch = (e) => setSearch(e.target.value);

  useEffect(() => {
    if (typeof search === "string") {
      const result = search ? flexSearchIndex.search({ query: search }) : window.INDICATORS;

      const newGroupedList = prepareGroupedList(result);
      const newExpandedGroups = Object.keys(newGroupedList);

      const newList = { ...emptyDefaultList, ...newGroupedList };
      setList(newList);
      setExpandedGroups(newExpandedGroups);
    }
  }, [search]);

  useEffect(() => {
    if (openEditor) {
      document.body.style.paddingRight = "0px";
      if (document.getElementsByClassName("MuiAppBar-colorSecondary")[0]) {
        document.getElementsByClassName("MuiAppBar-colorSecondary")[0].style.paddingRight = "0px";
      }
    }
  }, [openEditor]);

  return (
    <div className={classes.fullScreenWrap}>
      <Tooltip
        title={t("fullScreenEditor.enter")}
        enterDelay={Config.TOOLTIP_DELAY}
        enterNextDelay={Config.TOOLTIP_DELAY}
      >
        <Button
          data-testid="fullscreen-button-enter"
          onClick={handleClickOpen}
          className={classes.enterButton}
          id={setGaId(ga?.category, "open")}
        >
          <span className={classes.btnEnterFullScreenText}>{t("fullScreenEditor.enter_fullscreen")}</span>
          <MaximizeIcon2 size={18} />
        </Button>
      </Tooltip>
      <Dialog
        disablePortal
        fullScreen
        onClose={handleClose}
        open={openEditor}
        className={classes.root}
        style={(discount || email || referral || payment || banner) && { height: "calc(100vh - 110px)" }}
        BackdropProps={{ open: false }}
      >
        <AppBar className={classes.appBar}>
          <InitialLineWithValues className={classes.initialLine} />
          <Button
            className={classes.exitButton}
            data-testid="fullscreen-button-exit"
            onClick={handleClose}
            id={setGaId(ga?.category, "exit-fullscreen")}
          >
            {t("fullScreenEditor.exit")}
          </Button>
          <Button
            className={clsx(classes.exitButton, classes.exitSaveButton)}
            data-testid="fullscreen-button-save-and-exit"
            onClick={handleCloseSave}
            id={setGaId(ga?.category, "save-and-exit-fullscreen")}
            disabled={entity && disableByLimit}
          >
            {t("fullScreenEditor.save_exit")}
            <MinimizeIcon2 size={18} style={{ marginLeft: 10 }} />
          </Button>
        </AppBar>
        <div className={classes.dialogContent}>
          <div className={classes.editorContainer}>
            <div className={classes.criteriaInputs}>{React.cloneElement(criteriaInputs, { ga, fullScreen })}</div>
            <div className={classes.parameters}>{React.cloneElement(additionalBlock, { ga, fullScreen })}</div>
          </div>
          <ResizePanel direction="w">
            <div className={classes.collapsableListWrapper}>
              <div className={classes.searchWrapper}>
                <OutlinedInput
                  id={setGaId(ga?.category, "search")}
                  data-testid="fullscreen-search"
                  className={classes.searchInput}
                  placeholder={t("fullScreenEditor.search")}
                  endAdornment={
                    search ? (
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setSearch("")}
                        aria-label="clear"
                        className={classes.clearSearch}
                      >
                        <ClearIcon size={18} color={colors.gray_label} />
                      </IconButton>
                    ) : (
                      <SearchIcon size={18} color={colors.gray_label} />
                    )
                  }
                  value={search}
                  onInput={handleSearch}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") ev.preventDefault();
                  }}
                />
              </div>
              <div className={classes.list}>
                {Object.keys(list).map((item) => (
                  <Accordion
                    key={item}
                    square
                    expanded={isGroupExpanded(item)}
                    onChange={handleExpandedChange(item)}
                    TransitionProps={{ unmountOnExit: true }}
                    data-testid="fullscreen-accordion"
                  >
                    <AccordionSummary
                      className={classes.groupSummary}
                      expandIcon={<ChevronDownIcon size={18} color={colors.gray_icon} />}
                      IconButtonProps={{ edge: "start" }}
                      aria-controls="panel1a-content"
                      id={setGaId(ga?.category, "toggle-section")}
                    >
                      <span className={classes.heading}>
                        {item} ({list[item].length})
                      </span>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      {list[item].map(({ name, doc }) => (
                        <Accordion className={classes.category} key={name} TransitionProps={{ unmountOnExit: true }}>
                          <AccordionSummary
                            className={classes.categorySummary}
                            expandIcon={<ChevronDownIcon size={18} color={colors.gray_icon} />}
                            IconButtonProps={{ edge: "start" }}
                            aria-controls="panel1a-content"
                            id={setGaId(ga?.category, "toggle-indicator")}
                            data-testid={"accordion-indicator"}
                          >
                            {name}
                          </AccordionSummary>
                          <AccordionDetails className={classes.accordionDetails}>
                            <MarkdownDoc ga={ga} className={classes.docItem} children={doc} />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          </ResizePanel>
        </div>
      </Dialog>
    </div>
  );
};

export default FullScreenEditor;

FullScreenEditor.propTypes = {
  criteriaInputs: PropTypes.element.isRequired,
  additionalBlock: PropTypes.element.isRequired,
};
