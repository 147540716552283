import React from "react";
import { useTranslate } from "react-admin";
import { BacktestHeader } from "./BacktestHeader";
import Card from "../../components/Card/Card";
import TradesDetails from "../../components/TradesDetails/TradesDetails";
import ErrorDetails from "../../components/ErrorDetails/ErrorDetails";
import PerformanceDetails from "../../components/PerformanceDetails/PerformanceDetails";
import { GA_CATEGORIES, TABLES } from "../../shared/variables";
import MobileVersionAlert from "../../components/Alert/alerts/MobileVersionAlert";

export const BacktestResults = (props) => {
  const t = useTranslate();
  const ga = { category: GA_CATEGORIES.BACKTEST };

  return (
    <>
      <MobileVersionAlert />
      {props.record.error ? <ErrorDetails value={props.record.error} /> : null}

      <Card removeTopOffset header={t("backtest.blocks.params")}>
        <BacktestHeader ga={ga} record={props.record} resource={props.resource} />
      </Card>

      {!props.record.error ? (
        <>
          <PerformanceDetails {...props} />
          <TradesDetails
            {...props}
            ga={ga}
            filter={{ backtest: props.record.id }}
            tableName={TABLES.BACKTEST_TRADES}
            tradesOrdersResource="orders"
            tradesListResource="trading-days/backtest"
            tickersResource="backtest/tickers"
          />
        </>
      ) : null}
    </>
  );
};
