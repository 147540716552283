window.ace.define('ace/mode/breakingequity', function(require, exports, module) {

    var oop = require("ace/lib/oop");
    var TextMode = require("ace/mode/text").Mode;
    var BreakingEquityHighlightRules = require("ace/mode/breakingequity_highlight_rules").BreakingEquityHighlightRules;

    var Mode = function() {
        this.HighlightRules = BreakingEquityHighlightRules;
    };
    oop.inherits(Mode, TextMode);
    exports.Mode = Mode;
});

window.ace.define('ace/mode/breakingequity_highlight_rules', function(require, exports, module) {

    var oop = require("ace/lib/oop");
    var TextHighlightRules = require("ace/mode/text_highlight_rules").TextHighlightRules;

    var BreakingEquityHighlightRules = function() {

        // this.$rules = new TextHighlightRules().getRules();
        this.$rules =  {
            start: [
                {
                    token : "constant",
                    regex : "\\$[^\\.\\s(,]*"
                },
                {
                    token : "comment",
                    regex : "\\#.*"
                },
                {
                    token : "support.function",
                    regex : "\\.[^\\s\\[\\])(,]*"
                },
                {
                    token : "keyword.operator",
                    regex : "and|or|\\/|="
                },
                {
                    token : "storage.type",
                    regex : "[0123456789\\-\\.]+"
                },
                {
                    token : "support.other",
                    regex : "\\(|\\)|,"
                },
                {
                    token : "support.variable",
                    regex : "[\\(,(, )]*?[a-z_]+?="
                },

            ]
        };

    }

    oop.inherits(BreakingEquityHighlightRules, TextHighlightRules);

    exports.BreakingEquityHighlightRules = BreakingEquityHighlightRules;
});

// export default class CustomBEMode extends window.ace.acequire('ace/mode/text').Mode {
//     constructor() {
//         super();
//         this.HighlightRules = CustomHighlightRules;
//     }
// }
