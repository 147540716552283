import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import Reactour from "reactour";
import Button from "@material-ui/core/Button";
import { useNotify } from "react-admin";

import { TOUR_ALGO, TOUR_ALGO_FIRST } from "../../store/reducers/tourReducer";
import TourPopupLayout from "./components/TourPopupLayout";
import { handleAfterClose, handleAfterOpen, handleGetCurrentStep } from "./components/Functions";

import Translate from "./components/Translate";
import { customFetch } from "../../DataProvider";
import style from "./TourWelcome.module.scss";
import classes from "../Carousel/Carousel.module.css";

const steps = [
  {
    selector: "",
    content: (props) => {
      const { goTo } = props;
      return (
        <TourPopupLayout
          propsLayout={props}
          btnNext={
            <div className={style.popupTour__btns}>
              <Button className={style.btnGrey} onClick={() => props.close()}>
                <Translate path="tour.skip_tour" />
              </Button>
              <Button className={classes.exploreButton} onClick={() => goTo(1)} data-testid="tour/start">
                <Translate path="tour.start_tour" />
              </Button>
            </div>
          }
        >
          <h2>
            <Translate path="tourAlgo.step0.title" />
          </h2>
          <p data-testid="tour/description">
            <Translate path="tourAlgo.step0.text" />
          </p>
        </TourPopupLayout>
      );
    },
  },
  {
    selector: '[class*="AlgoForm_algoParams"]',
    content: (props) => (
      <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={2}>
        <h2>
          <Translate path="tourAlgo.step1.title" />
        </h2>
        <p data-testid="tour/description">
          <Translate path="tourAlgo.step1.text" />
        </p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="Initial_InitialForm"]',
    content: (props) => (
      <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={3}>
        <h2>
          <Translate path="tourAlgo.step2.title" />
        </h2>
        <p data-testid="tour/description">
          <Translate path="tourAlgo.step2.text" />
        </p>
      </TourPopupLayout>
    ),
  },
  {
    selector: ".Criteria",
    content: (props) => (
      <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={4}>
        <h2>
          <Translate path="tourAlgo.step3.title" />
        </h2>
        <p data-testid="tour/description">
          <Translate path="tourAlgo.step3.text" />
        </p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="AdditionalExit_container"]',
    content: (props) => (
      <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={5}>
        <h2>
          <Translate path="tourAlgo.step4.title" />
        </h2>
        <p data-testid="tour/description">
          <Translate path="tourAlgo.step4.text" />
        </p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="AdvancedBlock_container"]',
    content: (props) => (
      <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={6}>
        <h2>
          <Translate path="tourAlgo.step5.title" />
        </h2>
        <p data-testid="tour/description">
          <Translate path="tourAlgo.step5.text" />
        </p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="BasktestParameters_gridLayout"]',
    content: (props) => (
      <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={7}>
        <h2>
          <Translate path="tourAlgo.step6.title" />
        </h2>
        <p data-testid="tour/description">
          <Translate path="tourAlgo.step6.text" />
        </p>
      </TourPopupLayout>
    ),
  },
  {
    selector: ".list-page",
    content: (props) => (
      <TourPopupLayout
        propsLayout={props}
        btnNext={
          <Button className={classes.exploreButton} onClick={() => props.close()} data-testid="tour/finish">
            <Translate path="tour.finish_tour" />
          </Button>
        }
      >
        <h2>
          <Translate path="tourAlgo.step7.title" />
        </h2>
        <p data-testid="tour/description">
          <Translate path="tourAlgo.step7.text" />
        </p>
      </TourPopupLayout>
    ),
  },
];

const stepsMobile = [
  {
    selector: "",
    content: (props) => {
      const { goTo } = props;
      return (
        <TourPopupLayout
          propsLayout={props}
          btnNext={
            <div className={style.popupTour__btns}>
              <Button className={style.btnGrey} onClick={() => props.close()}>
                <Translate path="tour.skip_tour" />
              </Button>
              <Button className={classes.exploreButton} onClick={() => goTo(1)}>
                <Translate path="tour.start_tour" />
              </Button>
            </div>
          }
        >
          <h2>
            <Translate path="tourAlgoMobile.step0.title" />
          </h2>
          <p>
            <Translate path="tourAlgoMobile.step0.text" />
          </p>
        </TourPopupLayout>
      );
    },
  },
  {
    selector: '[class*="Card_card__i2o_G"]',
    content: (props) => (
      <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={2}>
        <h2>
          <Translate path="tourAlgoMobile.step1.title" />
        </h2>
        <p>
          <Translate path="tourAlgoMobile.step1.text" />
        </p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="BasktestParameters_mobile"]',
    content: (props) => (
      <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={3}>
        <h2>
          <Translate path="tourAlgoMobile.step2.title" />
        </h2>
        <p>
          <Translate path="tourAlgoMobile.step2.text" />
        </p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="list-page"]',
    content: (props) => (
      <TourPopupLayout
        propsLayout={props}
        btnNext={
          <Button className={clsx(classes.exploreButton, style.finish)} onClick={() => props.close()}>
            <Translate path="tour.finish_tour" />
          </Button>
        }
      >
        <h2>
          <Translate path="tourAlgoMobile.step3.title" />
        </h2>
        <p>
          <Translate path="tourAlgoMobile.step3.text" />
        </p>
      </TourPopupLayout>
    ),
  },
];

export const TourAlgo = () => {
  const { tourAlgo, tourAlgoFirst } = useSelector((state) => state.tour);
  const { sidebarOpen } = useSelector((state) => state.admin.ui);
  const dispatch = useDispatch();
  const notify = useNotify();

  useEffect(() => {
    return () => {
      handleAfterClose(notify, dispatch);
      if (tourAlgoFirst) {
        customFetch("profile", "PUT", { algo_tour_completed: true }).then((res) => {
          if (res?.algo_tour_completed) dispatch({ type: TOUR_ALGO_FIRST, payload: false });
        });
      }
    };
  }, [tourAlgoFirst]);

  const handleRequestClose = () => dispatch({ type: TOUR_ALGO, payload: false });

  return (
    <Reactour
      steps={isMobile ? stepsMobile : steps}
      isOpen={tourAlgo}
      onRequestClose={handleRequestClose}
      rounded={5}
      className={style.reactour}
      startAt={0}
      onAfterOpen={handleAfterOpen}
      getCurrentStep={(curr) => handleGetCurrentStep(curr, dispatch, sidebarOpen, 1)}
      showCloseButton={false}
      showButtons={false}
      showNavigation={false}
    />
  );
};

export default TourAlgo;
