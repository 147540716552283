import React from "react";
import styles from "./StockField.module.css";
import BriefcaseIcon from "react-feather/dist/icons/briefcase";
import NarrowRow from "../Rows/NarrowRow";
import NarrowValue from "../Rows/NarrowValue";
import Label from "../Rows/Label";

const StockField = ({ label, values, className }) => {
  return (
    <NarrowRow className={className}>
      {label ? <Label className={styles.label}>{label}</Label> : null}
      {(values.tickers || []).map((item, index) => (
        <NarrowValue key={`ticker${index}`}>{item}</NarrowValue>
      ))}
      {(values.indexes || []).map((item, index) => (
        <NarrowValue className={styles.index} key={`index${index}`}>
          <BriefcaseIcon className={styles.briefcase} />
          {item}
        </NarrowValue>
      ))}
    </NarrowRow>
  );
};

export default StockField;
