import { useNotify, useRedirect, useTranslate } from "react-admin";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { DISABLED_FIELDS, ENGINES, ENTITY, TRADING_RESOURCE } from "../../../shared/variables";
import { statuses } from "../../../shared/statuses";
import { actions } from "../../../shared/moreActions";
import useDisableByLimit from "../../../shared/hooks/useDisableByLimit";
import { RERUN_BACKTEST } from "../../../store/reducers/rerunBacktestReducer";
import { useDispatch } from "react-redux";
import { PortfolioType } from "../../../pages/Algo/utils";

export default function useGetBacktestActions(record) {
  const redirect = useRedirect();
  const t = useTranslate();
  const notify = useNotify();
  const disableByLimit = useDisableByLimit();
  const dispatch = useDispatch();

  if (record?.trading_type) return {};

  const backtestClone = () => {
    const source = _objectWithoutProperties(record, ["serialized_result"]);
    source.id = record?.algo?.id;
    dispatch({ type: RERUN_BACKTEST, payload: { backtest: source } });
    redirect(`/algos/${source.id}`);
    notify(t("backtest.load_to_current_algo"), "info");
  };

  const backtestAction = () => {
    redirect(
      `/${TRADING_RESOURCE.PAPER}/create?${new URLSearchParams({
        source: JSON.stringify(_objectWithoutProperties({ broker: ENGINES.BE.id, ...record }, DISABLED_FIELDS)),
      }).toString()}`
    );
  };

  const getBacktestActions = () => {
    const { status, portfolio_type } = record;

    if (status === statuses.failed.id || portfolio_type === PortfolioType.WATCHLIST) {
      return {
        mainActionName: t("actions.rerun_backtest"),
        mainActionFunc: backtestClone,
        mainActionDisabled: disableByLimit.backtests,
        mainActionEntity: ENTITY.BACKTEST,
        moreActions: [actions.copy_to_new_algo],
      };
    }
    return {
      mainActionName: t("actions.launch_paper"),
      mainActionFunc: backtestAction,
      mainActionTestId: "launch-paper",
      mainActionDisabled: disableByLimit.paper_tradings,
      mainActionEntity: ENTITY.PAPER_TRADING,
      moreActions: [actions.launch_live, actions.copy_to_current_algo, actions.copy_to_new_algo],
    };
  };
  return getBacktestActions();
}
