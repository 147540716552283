import React, { useState, useEffect, useRef } from "react";
import {
  Datagrid,
  List,
  TopToolbar,
  Filter,
  SearchInput,
  useTranslate,
  useDataProvider,
  BulkDeleteButton,
  TextField,
} from "react-admin";
import { useListContext } from "react-admin";
import { MomentField } from "../../components/MomentField";
import ArchiveIcon from "react-feather/dist/icons/archive";
import { ToggleButtonsField } from "../../components/ToggleButtonsField/ToggleButtonsField";
import { actions } from "../../shared/moreActions";
import { statuses } from "../../shared/statuses";
import { GET_USAGE } from "../../store/sagas/usageSaga";
import { useDispatch } from "react-redux";
import Card from "../../components/Card/Card";
import { setGaId } from "../../shared/utils";
import { GA_CATEGORIES, TABLES } from "../../shared/variables";
import MoreActions from "../../components/MoreActions/MoreActions";
import DatagridIdsWrapper from "../../components/DatagridIdsWrapper/DatagridIdsWrapper";
import StatusField from "../../components/StatusField/StatusField";
import { isDesktop } from "react-device-detect";
import BacktestMobileList from "./components/BacktestMobileList/BacktestMobileList";
import styles from "../Backtest/Backtest.module.css";
import ListPagination from "../../components/ListPagination/ListPagination";
import useGetItemsPerPage from "../../components/ListPagination/hooks/useGetItemsPerPage";

const BulkActionButtons = ({ ga, ...rest }) => {
  const t = useTranslate();
  return (
    <BulkDeleteButton
      {...rest}
      icon={<ArchiveIcon />}
      id={setGaId(ga?.category, `${ga?.action}-bulk-archive`)}
      label={t("actions.archive")}
      undoable={true}
    />
  );
};

const liveStatuses = [
  statuses.pending.id,
  statuses.completed.id,
  statuses.inProgress.id,
  statuses.failed.id,
  statuses.draft.id,
];

const archivedStatuses = [statuses.archived.id];

export const BacktestFilter = (props) => {
  return (
    <Filter variant="standard" className="Backtests-Filter" {...props}>
      <SearchInput
        source="q"
        variant="standard"
        alwaysOn
        id={setGaId(props.ga?.category, `${props.ga?.action}-search`)}
      />
    </Filter>
  );
};

export const BacktestToolbar = ({ ga, setSwitch, record, backtestsLoading, setBacktestsLoading }) => {
  const { loading, filterValues, setFilters, page, perPage, currentSort } = useListContext();
  const dataProvider = useDataProvider();
  const intervalRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    JSON.stringify(filterValues.status) === JSON.stringify(archivedStatuses)
      ? setSwitch(statuses.archived.id)
      : setSwitch(statuses.live.id);
  }, [loading]);

  const selectedSwitch = () => {
    return JSON.stringify(filterValues.status) === JSON.stringify(archivedStatuses)
      ? statuses.archived.id
      : statuses.live.id;
  };

  const handleRequestSwitch = (event) => {
    const checked = event.target.id;

    switch (checked) {
      case statuses.live.id: {
        setFilters({ status: liveStatuses });
        break;
      }
      case statuses.archived.id: {
        setFilters({ status: archivedStatuses });
        break;
      }
      default:
        return;
    }
  };

  function updateBacktestList() {
    if (!record.id) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
      setBacktestsLoading(false);
      return;
    }
    dataProvider
      .getOne("screeners", { id: record.id })
      .then((res) => {
        if (!res.data?.backtests_in_progress) {
          window.clearInterval(intervalRef.current);
          intervalRef.current = null;
          setBacktestsLoading(false);
          dispatch({ type: GET_USAGE });
        }
        dataProvider
          .getList("screener-backtests", {
            pagination: {
              page,
              perPage,
            },
            sort: { field: currentSort.field, order: currentSort.order },
            filter: { ...filterValues, screener: record.id },
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    if (backtestsLoading && !intervalRef.current) {
      updateBacktestList();
      intervalRef.current = window.setInterval(() => {
        updateBacktestList();
      }, 5000);
    }
  }, [backtestsLoading]);

  //unmount
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  return (
    <TopToolbar className={styles.backtestToolbar}>
      <ToggleButtonsField
        ga={ga}
        onChange={handleRequestSwitch}
        defaultValue={selectedSwitch()}
        inputNames={[statuses.live.id, statuses.archived.id]}
        inputLabels={[statuses.live.id, statuses.archived.id]}
      />
    </TopToolbar>
  );
};

export const ActiveDaysTextField = ({ record }) => {
  if (record?.active_days === 0) {
    return <span>0</span>;
  }
  if (!record || record.status !== statuses.completed.id || !(record.active_days && record.total_days)) return null;
  return (
    <span>{`${Number(record.active_days).toLocaleString("en-EN")}/${Number(record.total_days).toLocaleString(
      "en-EN"
    )}`}</span>
  );
};

export const BacktestList = (props) => {
  const { record, backtestsLoading, setBacktestsLoading } = props;
  const backtestClick = (id, basePath) => "/" + basePath + "/" + id + "/show";
  const t = useTranslate();
  const [switcher, setSwitch] = useState(statuses.live.id);

  const tableName = TABLES.SCREENER_BACKTESTS;
  const perPage = useGetItemsPerPage(tableName);

  const ga = { category: GA_CATEGORIES.SCREENER_EDIT, action: "backtest" };

  const enableArchive = () => switcher === statuses.live.id;

  return (
    <Card header={t("screeners.blocks.backtest_list")}>
      <List
        filters={<BacktestFilter {...props} ga={ga} />}
        filterDefaultValues={{ status: liveStatuses }}
        actions={
          <BacktestToolbar
            ga={ga}
            switcher={switcher}
            setSwitch={setSwitch}
            record={record}
            backtestsLoading={backtestsLoading}
            setBacktestsLoading={setBacktestsLoading}
          />
        }
        filter={{ screener: record.id ?? null }}
        sort={{ field: "created_date", order: "DESC" }}
        basePath="screener-backtests"
        resource="screener-backtests"
        exporter={false}
        bulkActionButtons={enableArchive() && <BulkActionButtons {...props} ga={ga} />}
        perPage={perPage}
        pagination={<ListPagination {...props} tableName={tableName} />}
        hasShow={false}
        hasList={false}
        hasEdit={false}
        hasCreate={false}
        className={isDesktop ? styles.listDesktop : styles.listMobile}
      >
        {isDesktop ? (
          <Datagrid rowClick={backtestClick} size="medium">
            <DatagridIdsWrapper ga={ga} headerClassName="IdsWrapper-Col" cellClassName="IdsWrapper-Col" />

            {enableArchive() ? null : <TextField headerClassName="Empty-Col" cellClassName="Empty-Col" />}

            <MomentField label={t("backtest.created")} source="created_date" fromNow={true} cellClassName="Other-Col" />

            <MomentField
              label={t("backtest.from_date")}
              source="start_date"
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />
            <MomentField
              label={t("backtest.to_date")}
              source="end_date"
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />

            <StatusField
              label={t("backtest.status")}
              source="status"
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />

            <TextField
              source="total_tickers"
              label={t("backtest.total_tickers")}
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />

            <ActiveDaysTextField
              label={t("backtest.active_days")}
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />

            <MoreActions
              cellClassName="Actions-Col"
              {...props}
              ga={ga}
              items={[actions.copy_to_current_screener, actions.copy_to_new_screener, actions.archive]}
            />
          </Datagrid>
        ) : (
          <BacktestMobileList />
        )}
      </List>
    </Card>
  );
};
