import { getErrorMessage } from "./helpers";
import { Storage } from "../../../../../../../../../config";
import { LOCALSTORAGE } from "../../../../../../../../../shared/variables";
export class HistoryProvider {
  constructor(datafeedUrl, requester, chartId) {
    this._datafeedUrl = datafeedUrl;
    this._requester = requester;
    this._chartId = chartId;
  }
  getBars(symbolInfo, resolution, periodParams) {
    const lsOrders = Storage.getItem(LOCALSTORAGE.ORDER_CHARTS);
    const orders = lsOrders ? JSON.parse(lsOrders) : {};
    const { date } = orders[this._chartId] ? orders[this._chartId].find((order) => order.active) || {} : {};

    //ISO date for UDF endpoint
    const toDate = new Date(date);
    const toDateISO = toDate.toISOString().substring(0, 10);

    toDate.setDate(toDate.getDate() + 1);
    const toTime = toDate.getTime() / 1000;

    //requesting -100d just in case here
    const fromDate = new Date(date);
    fromDate.setDate(fromDate.getDate() - 100);
    const fromTime = fromDate.getTime() / 1000;
    const requestParams = {
      symbol: symbolInfo.ticker || "",
      resolution: resolution,
      from: fromTime,
      to: toTime,
    };
    if (periodParams.countBack !== undefined) {
      requestParams.countback = periodParams.countBack;
    }
    if (symbolInfo.currency_code !== undefined) {
      requestParams.currencyCode = symbolInfo.currency_code;
    }
    if (symbolInfo.unit_id !== undefined) {
      requestParams.unitId = symbolInfo.unit_id;
    }
    return new Promise((resolve, reject) => {
      this._requester
        .sendRequest(this._datafeedUrl, `history/${toDateISO}`, requestParams)
        .then((response) => {
          if (response.s !== "ok" && response.s !== "no_data") {
            reject(response.errmsg);
            return;
          }
          const bars = [];
          const meta = {
            noData: false,
          };
          if (response.s === "no_data") {
            meta.noData = true;
            meta.nextTime = response.nextTime;
          } else {
            const volumePresent = response.v !== undefined;
            const ohlPresent = response.o !== undefined;
            for (let i = 0; i < response.t.length; ++i) {
              const barValue = {
                time: response.t[i] * 1000,
                close: parseFloat(response.c[i]),
                open: parseFloat(response.c[i]),
                high: parseFloat(response.c[i]),
                low: parseFloat(response.c[i]),
              };
              if (ohlPresent) {
                barValue.open = parseFloat(response.o[i]);
                barValue.high = parseFloat(response.h[i]);
                barValue.low = parseFloat(response.l[i]);
              }
              if (volumePresent) {
                barValue.volume = parseFloat(response.v[i]);
              }
              bars.push(barValue);
            }
          }

          let remainingBars = [];
          const diffBarsTime = bars[bars?.length - 1].time - bars[bars?.length - 2].time;
          for (let i = 0; i <= 15000; i++) {
            let firstTime = remainingBars?.length ? remainingBars[0].time : bars[0].time;
            remainingBars = [
              {
                time: firstTime - diffBarsTime,
                close: bars[0].close,
                open: bars[0].close,
                high: bars[0].close,
                low: bars[0].close,
              },
              ...remainingBars,
            ];
          }

          resolve({
            bars: [...remainingBars, ...bars],
            meta: meta,
          });
        })
        .catch((reason) => {
          const reasonString = getErrorMessage(reason);
          // tslint:disable-next-line:no-console
          console.warn(`HistoryProvider: getBars() failed, error=${reasonString}`);
          reject(reasonString);
        });
    });
  }
}
