import React from "react";
import { useTranslate } from "react-admin";
import Alert from "../Alert";
import { ALERT } from "../variables";
import { VALIDATION_STATUS } from "../../../pages/Profile/components/BrokersTable/BrokersTable";

const BrokerValidation = ({ ga, status, onCancel }) => {
  const t = useTranslate();

  const data =
    status === VALIDATION_STATUS.VALID
      ? {
          type: ALERT.SUCCESS,
          data: [{ description: [t("profile.connectionValid")] }],
        }
      : {
          type: ALERT.DANGER,
          data: [{ header: "profile.connectionErrorTitle", description: [t("profile.connectionErrorText")] }],
        };

  return status !== VALIDATION_STATUS.UNKNOWN ? <Alert ga={ga} data={data} setOpenAlert={onCancel} /> : null;
};

export default BrokerValidation;
