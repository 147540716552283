import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import classes from "./Rows.module.css";

const Label = ({ color, children, className, ...rest }) => {
  return (
    <span className={clsx(classes.label, classes[color], className)} {...rest}>
      {children}
    </span>
  );
};

export default Label;

Label.propTypes = {
  color: PropTypes.oneOf(["dark", "blue"]),
};
