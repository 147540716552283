import React from "react";
import styles from "./ErrorMessage.module.css";
import XCircleSign from "react-feather/dist/icons/x-circle";
import cn from "clsx";
import { useTranslate } from "react-admin";
import { LINK_STATUSES } from "../../../../../../store/reducers/brokersReducer";
import Button from "@material-ui/core/Button";
import { setGaId } from "../../../../../../shared/utils";

const ErrorMessage = ({ isOpen, status, ...rest }) => {
  const hasErrorStatus = status === LINK_STATUSES.ERROR;
  const hasRevokedStatus = status === LINK_STATUSES.REVOKED;

  return (
    <div
      className={cn(
        styles.errorMessage,
        isOpen ? styles.messageShow : styles.messageHide
      )}
    >
      {hasRevokedStatus && <RevokedStatus />}
      {hasErrorStatus && <ErrorStatus {...rest} />}
    </div>
  );
};

export default ErrorMessage;

const ErrorStatus = ({ ga, onRelink }) => {
  const t = useTranslate();
  const relinkButtonId = setGaId(ga?.category, `${ga?.action}-relink-account`);

  return (
    <>
      <div className={styles.relinkDescription}>
        {t("profile.relinkDescription")}
      </div>
      <div className={styles.buttons}>
        <Button
          onClick={onRelink}
          className={styles.relinkButton}
          id={relinkButtonId}
        >
          {t("profile.relink")}
        </Button>
      </div>
    </>
  );
};

const RevokedStatus = () => {
  const t = useTranslate();
  return (
    <>
      <div className={styles.titleContainer}>
        <XCircleSign color="red" size={18} className={styles.icon} />
        <span className={styles.title}>
          {t("profile.connectionErrorTitle")}
        </span>
      </div>
      <div className={styles.text}>{t("profile.connectionErrorText")}</div>
    </>
  );
};
