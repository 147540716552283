import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./CollapseBlock.module.css";
import Fade from "@material-ui/core/Fade";
import Collapse from "@material-ui/core/Collapse";
import cn from "clsx";
import ChevronUp from "react-feather/dist/icons/chevron-up";
import ChevronDown from "react-feather/dist/icons/chevron-down";
import { useFormState } from "react-final-form";
import IconButton from "@material-ui/core/IconButton";
import {Tooltip} from "@material-ui/core";
import {useTranslate} from "react-admin";
import Config from "../../config";

const CollapseBlock = ({
  id,
  short,
  full,
  className,
  open = false,
  openOnError,
  onExpand,
  onCollapse,
  collapsible = true,
  setIsOpenBroker,
}) => {
  const { tourAlgo } = useSelector((state) => state.tour);
  const { errors, submitFailed } = useFormState();

  const isBlockError = openOnError && openOnError.some((item) => errors[item]);

  const [isOpen, setIsOpen] = useState(open);
  const t = useTranslate();

  useEffect(() => {
    if (tourAlgo) {
      setIsOpen(true);
    } else {
      setIsOpen(open);
    }
  }, [tourAlgo, open]);

  useEffect(() => {
    if (open && onExpand) {
      onExpand(open);
    }
    if (submitFailed && isBlockError) {
      setIsOpen(true);
    }
  }, [isBlockError, submitFailed]);

  return (
    <div className={cn(styles.container, className)}>
      <Fade in={!isOpen}>
        <Tooltip title={t("common.doubleclick_to_open")} enterDelay={Config.TOOLTIP_DELAY} placement="bottom-start">
            <div className={styles.short} onClick={e => {e.stopPropagation()}} onDoubleClick={e => {
                setIsOpen(true);
                if (onExpand) onExpand();
                e.stopPropagation();
            }}>{short}</div>
        </Tooltip>
      </Fade>
      <Collapse in={isOpen} className={styles.collapse}>
        <div>{full}</div>
      </Collapse>
      <IconButton
        id={id}
        data-testid={isOpen ? "chevron-up" : "chevron-down"}
        className={styles.openButton}
        onClick={() => {
          if (isOpen && isBlockError) return;
          setIsOpen(!isOpen);
          if (setIsOpenBroker) setIsOpenBroker(!isOpen);
          if (onExpand) onExpand();
          if (onCollapse) onCollapse();
        }}
      >
        {isOpen ? collapsible ? <ChevronUp /> : null : <ChevronDown />}
      </IconButton>
    </div>
  );
};

export default CollapseBlock;
