import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Loading, setSidebarVisibility } from "react-admin";
import { TipsToStart } from "./TipsToStart/TipsToStart";
import { customFetch } from "../../DataProvider";
import classes from "./Dashboard.module.css";
import { useSelector } from "react-redux";
import { getPlanState, getUsageState } from "../../store/selectors";
import { formatLimitValue, getLimitRatio } from "../../shared/utils";
import { ENTITY, RATIO_LIMITS } from "../../shared/variables";
import LimitAlert from "../../components/Alert/alerts/LimitAlert";
import { TOUR_WELCOME_FIRST } from "../../store/reducers/tourReducer";
import useDisableByLimit from "../../shared/hooks/useDisableByLimit";
import SharedAlgoCards from "./SharedAlgoCards";

export const DashboardList = () => {
  const [limitAlert, setLimitAlert] = useState({});
  const [creatingInProgress, setCreatingInProgress] = useState();
  const exploreAlgosRef = useRef(null);
  const dispatch = useDispatch();
  const disableByLimit = useDisableByLimit();

  const { algos, status } = useSelector(getUsageState) || {};
  const { algos_limit } = useSelector(getPlanState) || {};
  const sharedBacktests = useSelector((state) => state.sharedBacktests.data);

  const disableCreateButton = creatingInProgress || disableByLimit.algos;

  useEffect(() => {
    customFetch("profile").then((res) => {
      if (res && !res.guided_tour_completed) {
        dispatch({ type: TOUR_WELCOME_FIRST, payload: true });
        dispatch(setSidebarVisibility(true));
      }
    });
  }, []);

  const showAlert = () => {
    const limitRatio = getLimitRatio(algos, algos_limit, status);
    if (limitRatio !== RATIO_LIMITS.A_LOT) {
      const leftLimits = formatLimitValue(algos_limit) - algos;
      setLimitAlert({
        ratio: limitRatio,
        entity: ENTITY.ALGOS,
        limits: leftLimits,
      });
    }
  };

  useEffect(() => showAlert(), [algos, algos_limit]);

  if (!sharedBacktests?.length) return <Loading />;

  return sharedBacktests?.length ? (
    <div className={classes.dashboardPage}>
      <LimitAlert {...limitAlert} />
      <TipsToStart exploreAlgosRef={exploreAlgosRef} disableCreate={disableCreateButton} />
      <SharedAlgoCards
        exploreAlgosRef={exploreAlgosRef}
        disableCreate={disableCreateButton}
        setCreatingInProgress={setCreatingInProgress}
        data={sharedBacktests}
      />
    </div>
  ) : null;
};
