import { put, takeEvery, retry } from "redux-saga/effects";
import { customFetch } from "../../DataProvider";
import {
  SHARED_BACKTESTS_LOADING,
  SHARED_BACKTESTS_RECEIVED,
  SHARED_BACKTESTS_FAIL,
} from "../reducers/sharedBacktestsReducer";
import {Storage} from "../../config";
import {BACKTEST_ALGOS} from "../../shared/permissions";

export const GET_SHARED_BACKTESTS = "GET_SHARED_BACKTESTS";
const SECOND = 1000;

export default function* sharedBacktestsSaga() {
  yield takeEvery(GET_SHARED_BACKTESTS, function* () {
    yield put({ type: SHARED_BACKTESTS_LOADING });

    const permissions = JSON.parse(Storage.getItem("permissions"));

    try {
      let sharedBacktests = [];

      if(permissions.includes(BACKTEST_ALGOS)) {
        sharedBacktests = yield retry(
            2,
            3 * SECOND,
            customFetch,
            `backtests/shared?sort=${encodeURIComponent('["created_date","DESC"]')}`,
            "GET"
        );
      }

      yield put({ type: SHARED_BACKTESTS_RECEIVED, payload: { sharedBacktests } });
    } catch (e) {
      yield put({ type: SHARED_BACKTESTS_FAIL });
    }
  });
}
