import React  from "react";
import {AlgoList} from "../Algo/ShowAlgos";

const ShowWatchlistAlgos = (props) => {

  const basePath = props.basePath;
  return (
    <AlgoList portfolioType="WATCHLIST" basePath={basePath} />
  );
};

export default ShowWatchlistAlgos;
