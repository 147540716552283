import React from "react";
import styles from "./Toolbar.module.css";
import { SaveButton, Toolbar as RaToolbar, useTranslate, useRedirect } from "react-admin";
import { useFormState } from "react-final-form";
import SplitButton from "../../../../components/SplitButton/SplitButton";
import { setGaId } from "../../../../shared/utils";
import Config from "../../../../config";
import { Tooltip, Button } from "@material-ui/core";
import { ENTITY } from "../../../../shared/variables";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isDesktop, isMobile } from "react-device-detect";
import clsx from "clsx";
import useDisableByLimit from "../../../../shared/hooks/useDisableByLimit";

const Toolbar = ({ ga, setBacktests, handleSave, loadRecent, isLoadingRecent, record, ...props }) => {
  const t = useTranslate();
  const redirect = useRedirect();
  const { values } = useFormState();
  const disableByLimit = useDisableByLimit();

  const handleCancel = () => redirect("/screeners");

  const isNewRecord = !record?.id;
  const disableSaveButton = isNewRecord && disableByLimit.screeners;
  const disableSaveAndLaunchButton = disableSaveButton || disableByLimit.backtests;

  return (
    <RaToolbar {...props} className={clsx(styles.toolbar, { [styles.mobile]: isMobile })}>
      <SplitButton
        buttons={[
          <SaveButton
            id={setGaId(ga?.category, "save-launch")}
            data-testid="screener-button-save-launch"
            label={t("backtest.saveLaunchBacktests")}
            className={styles.save}
            entity={ENTITY.BACKTEST}
            disabled={disableSaveAndLaunchButton}
          />,
          <Button
            id={setGaId(ga?.category, "save")}
            data-testid="screener-button-save"
            variant="text"
            onClick={() => handleSave({ values, redirectOnSave: true })}
            className={styles.saveButton}
            entity={ENTITY.SCREENER}
            disabled={disableSaveButton}
          >
            {t("backtest.save")}
          </Button>,
        ]}
      />
      {isDesktop && (
        <>
          <Button variant="text" onClick={handleCancel} className={styles.cancel} id={setGaId(ga?.category, "cancel")}>
            {t("backtest.cancel")}
          </Button>

          <Tooltip
            title={t("helpers.load_recent_values")}
            enterDelay={Config.TOOLTIP_DELAY}
            enterNextDelay={Config.TOOLTIP_DELAY}
          >
            <div style={{ marginLeft: "auto" }}>
              {isLoadingRecent ? (
                <CircularProgress className={styles.spinner} />
              ) : (
                <Button
                  variant="text"
                  onClick={loadRecent}
                  className={styles.recentButton}
                  id={setGaId(ga?.category, "load-recent-values")}
                  data-testid="load-recent-values"
                >
                  {t("backtest.loadRecent")}
                </Button>
              )}
            </div>
          </Tooltip>
        </>
      )}
    </RaToolbar>
  );
};

export default Toolbar;
