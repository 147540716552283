import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { Notification, useTranslate } from "react-admin";
import styles from "./Authorization.module.scss";
import LoginForm from "./LoginForm";
import logo from "../../assets/logo.svg";

export const LoginPage = ({ changeComponent, FirebaseScreen, ...rest }) => {
  const t = useTranslate();

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
      </div>
      <Box textAlign="center" className={styles.box}>
        <div className={styles.content}>
          <Typography variant="h6" className={styles.title}>
            {t("authorization.login")}
          </Typography>

          <FirebaseScreen />

          <div className={styles.or}>
            <Typography variant="overline">{t("odd.or")}</Typography>
          </div>

          <div className={styles.loginForm}>
            <LoginForm variant="outlined" {...rest} />
          </div>

          <div className={styles.linksGroup}>
            <Link onClick={changeComponent}>{t("authorization.cant_login")}</Link>
            <span className={styles.dot} />
            <Link href="/#/signup">{t("authorization.sign_up")}</Link>
          </div>

          <Notification />
        </div>
      </Box>
    </div>
  );
};
