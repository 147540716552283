import React from "react";
import {formatNumberValue, makeInterval, toAMPMTime} from "../../shared/utils";

import {NumberField, useTranslate} from "react-admin";
import {getNumberOptions} from "../AdditionalExit/AdditionalExit";

export const IntervalField = ({ record, source}) => {
  const value = record?.[source];
  return <>{makeInterval(value)}</>
};

export const RiskField = ({ record, source, type_source, trail_source, enabled_source}) => {
  const value = record?.[source];
  const type = record?.[type_source];
  const trail = record?.[trail_source];
  const enabled = record?.[enabled_source];
  const t = useTranslate();

  return <>{enabled ? formatNumberValue(value, getNumberOptions(type)) : ""} {enabled && trail ? t("algos.tsl").toUpperCase() : ""}</>
};

export const AutoExitField = ({ record, source}) => {
  const value = record?.[source];
  const t = useTranslate();

  return <>{t("force_exit." + value.toLowerCase() + "_short")}</>
};

export const RewardRiskRatio = (props) => {
  const r = { ...props.record };

  r.rewardRatio =
      r && r.money_made
          ? (r.money_made * 100) / (r.money_made + (r.money_lost || 0))
          : 0;
  r.riskRatio = r.rewardRatio ? 100 - r.rewardRatio : 0;

  return (
      <>
        <NumberField
            record={r}
            source="rewardRatio"
            options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
        />
        {"/"}
        <NumberField
            record={r}
            source="riskRatio"
            options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
        />
      </>
  );
};

export const WinLossRatio = (props) => {
    const r = { ...props.record };
    r.winRatio = r && r.wins ? (r.wins * 100) / (r.wins + (r.losses || 0)) : 0;
    r.lossRatio = r.winRatio >= 0 ? 100 - r.winRatio : 0;

    return (
        <>
            <NumberField
                record={r}
                source="winRatio"
                options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
            />
            {"/"}
            <NumberField
                record={r}
                source="lossRatio"
                options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
            />
        </>
    );
};

export const TimeField = (props) => {
    const value = props.record?.[props.source];

    return (
        <>
            {toAMPMTime(value)}
        </>
    );

};