import { put, takeEvery, retry } from "redux-saga/effects";
import { customFetch } from "../../DataProvider";
import {
  SHARED_STRATEGIES_LOADING,
  SHARED_STRATEGIES_RECEIVED,
  SHARED_STRATEGIES_FAIL,
} from "../reducers/sharedStrategiesReducer";

export const GET_SHARED_STRATEGIES = "GET_SHARED_STRATEGIES";
const SECOND = 1000;

export default function* sharedStrategiesSaga() {
  yield takeEvery(GET_SHARED_STRATEGIES, function* () {
    yield put({ type: SHARED_STRATEGIES_LOADING });

    try {
      const sharedStrategies = yield retry(
        2,
        3 * SECOND,
        customFetch,
        "strategies/shared",
        "GET"
      );

      yield put({ type: SHARED_STRATEGIES_RECEIVED, payload: { sharedStrategies } });
    } catch (e) {
      yield put({ type: SHARED_STRATEGIES_FAIL });
    }
  });
}
