import React from "react";
import Button from "@material-ui/core/Button";
import styles from "./LinkButton.module.css";
import LinkIcon from "react-feather/dist/icons/link";
import { useTranslate } from "react-admin";

export default (props) => {
  const t = useTranslate();

  return (
    <Button className={styles.linkButton} {...props}>
      <LinkIcon className={styles.linkIcon} />
      {t("profile.linkAccount")}
    </Button>
  );
};
