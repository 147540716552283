import React from "react";
import { useTranslate } from "react-admin";
import { Tooltip } from "@material-ui/core";

const DisableCreatingTooltip = ({ disabled, entity, children }) => {
  const t = useTranslate();
  return (
    <Tooltip
      title={
        disabled && entity
          ? t("subscriptions.disable_creating", { entity })
          : ""
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default DisableCreatingTooltip;
