import React from "react";
import styles from "./Card.module.css";
import cn from "clsx";
import CardHeader from "./CardHeader";

const Card = ({ children, removeTopOffset, header, className, ...rest }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, rest);
    }
    return child;
  });

  return (
    <div style={{ marginTop: removeTopOffset ? 0 : 24 }}>
      {header ? <CardHeader>{header}</CardHeader> : null}
      <div className={cn(styles.card, className)}>{childrenWithProps}</div>
    </div>
  );
};
export default Card;
