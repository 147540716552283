import React, {useState} from "react";
import {Slider, TextField} from "@material-ui/core";
import styles from "./WatchlistCriteriaInput.module.scss";
import {useInput} from "react-admin";

const WatchlistCriteriaInput = ({min = 0, max = 3, readOnly = false, ...props}) => {

    const {source, ...rest} = props;
    const enter = useInput({source: source[0], ...rest});
    const exit = useInput({source: source[1], ...rest});

    const enter_on_day = Number(props.record[source[0]]) || 0;
    const exit_on_day = Number(props.record[source[1]]) || 1;

    const [slideValue, setSliderValue] = useState([enter_on_day, exit_on_day]);

    const [enterDays, setEnterDays] = useState(enter_on_day);
    const [exitDays, setExitDays] = useState(exit_on_day);

    const [minOverride, setMinOverride] = useState(Math.max(enter_on_day - 2, 0));
    const [maxOverride, setMaxOverride] = useState(exit_on_day + 2);

    function sliderMarks(min, max) {
        const marks = [];
        let index = min + 0.75;
        while(index <= max) {
            marks.push({value: index, label: Math.round(index) + "d"});
            index = index + Math.max((max - min) / 5, 1);
        }
        return marks
    }

    function onInputChange(e, isEnter) {
        const value = /[a-zA-Z]/g.test(e.target.value) ? enterDays : e.target.value;
        const numValue = Math.round(parseFloat(value) * 2)/2;

        isEnter ? setEnterDays(value) : setExitDays(value);
        const sliderValues = isEnter ? [numValue, slideValue[1]] : [slideValue[0], numValue];
        isEnter ? enter.input.onChange(value) : exit.input.onChange(value);

        if(!isNaN(parseFloat(value)) && !value.endsWith(".")){
            onSliderChange(sliderValues)
        }
    }

    function onSliderChange(value) {
        setMinOverride(Math.max(value[0] - 2, 0));
        setMaxOverride(value[1] + 2);
        setEnterDays(value[0]);
        setExitDays(value[1]);
        setSliderValue(value);

        enter.input.onChange(value[0]);
        exit.input.onChange(value[1]);
    }

    return (
        <>
            <div className={props.className}>
                <div>
                    <span className={styles.wording}>
                        <span><b>ENTER</b></span>
                        {/*<TextInput source="enter_on_day" label="" variant="outlined"/>*/}
                        <TextField value={enter.input.value}
                                   name={enter.input.name}
                                   disabled={readOnly}
                                   className={styles.minMaxNumber}
                                   onChange={e => onInputChange(e, true)}
                        /> <span>day(s) after stock added.</span>
                    </span>

                     <span className={styles.wording}>
                         {/*<TextInput source="exit_on_day" label="" variant="outlined"/>*/}
                        <span><b>EXIT</b></span>
                        <TextField value={exit.input.value}
                                   name={exit.input.name}
                                   disabled={readOnly}
                                   className={styles.minMaxNumber}
                                   onChange={e => onInputChange(e, false)}
                        /> <span>day(s) after stock added.</span>
                    </span>
                </div>
                <div className={styles.sliderWrapper}>
                    <Slider
                        value={slideValue}
                        valueLabelDisplay="auto"
                        min={minOverride}
                        max={maxOverride}
                        disabled={readOnly}
                        step={0.5}
                        classes={{
                            valueLabel: styles.valueLabel,
                            thumb: styles.thumb,
                            track: styles.track,
                            mark: styles.mark,
                            valueLabelLabel: styles.valueLabelLabel}}

                        marks={sliderMarks(minOverride, maxOverride)}
                        onChange={(e, value) => onSliderChange(value)}
                        valueLabelFormat={label => {
                            return (Number.isInteger(label) ? "9:30am" : "4:00pm");
                        }}>

                    </Slider>
                </div>
            </div>

        </>
    );
};

export default WatchlistCriteriaInput;

