import React from "react";
import { useTranslate } from "react-admin";
import styles from "./BrokersBlock.module.css";
import CollapseBlock from "../../../../components/CollapseBlock/CollapseBlock";
import NarrowRow from "../../../../components/Rows/NarrowRow";
import Label from "../../../../components/Rows/Label";
import Brokers from "./Brokers";
import { useFormState } from "react-final-form";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import { BROKERS, ENGINES } from "../../../../shared/variables";

const getBrokerInfo = ({ record, source, value }) => {
  const broker = value || record?.[source];
  if(!broker) {
    return {name: 'n/a', icon: null};
  }
  return (broker === ENGINES.BE.id ? ENGINES.BE : BROKERS.find((item) => item?.id === broker)) || {};
};

export const BrokerIcon = ({ record, source, broker, className, value }) => {
  const t = useTranslate();
  const { name, icon } = broker || getBrokerInfo({ record, source, value });
  return name && icon ? (
    <Tooltip title={t(name) || ""}>
      <img src={icon} alt={t(name)} className={clsx(styles.icon, className)} />
    </Tooltip>
  ) :  null;
};

const BrokersLine = ({ record }) => {
  const t = useTranslate();
  const { name } = getBrokerInfo({ record, source: "broker" });
  return (
    <NarrowRow>
      <Label className={styles.label}>
        <BrokerIcon record={record} source="broker" />
        {t(name)}
      </Label>
    </NarrowRow>
  );
};

const BrokersBlock = ({ ga, ...props }) => {
  const { values } = useFormState();
  const { isOpenBroker, setIsOpenBroker } = props;

  return (
    <div className={styles.brokers}>
        <CollapseBlock
          short={<BrokersLine record={values} />}
          full={<Brokers {...props} ga={ga} />}
          open={isOpenBroker}
          setIsOpenBroker={setIsOpenBroker}
        />
    </div>
  );
};

export default BrokersBlock;
