import React, { useEffect, useRef, useState } from "react";
import styles from "./TradingForm.module.css";
import {
  setSidebarVisibility,
  SimpleForm,
  useDataProvider,
  useNotify,
  usePermissions,
  useRedirect,
  useTranslate,
} from "react-admin";
import { InitialBlock } from "../Algo/components/Initial/Initial";
import { CriteriaBlock, CriteriaCollapseBlock } from "../../components/CriteriaBlock/CriteriaBlock";
import { AdditionalExitBlock } from "../../components/AdditionalExit/AdditionalExit";
import { AdvancedBlock } from "../../components/AdvancedBlock/AdvancedBlock";
import FullScreenEditor from "../../components/FullScreenEditor/FullScreenEditor";
import {
  ENTITY,
  GA_CATEGORIES,
  LOCALSTORAGE,
  MAX_RISK,
  ORDER_TYPES,
  RATIO_LIMITS,
  TABLES,
  TRADING_TYPE,
} from "../../shared/variables";
import { useDispatch, useSelector } from "react-redux";
import { TradingParameters } from "./components/TradingParameters/TradingParameters";
import { statuses } from "../../shared/statuses";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { getTradingsResource, isPaper } from "./utils";
import TradingToolbar from "./components/Toolbar/Toolbar";
import { TRADING_SAVE } from "../../store/sagas/tradingSaga";
import { TRADING_CLEAR } from "../../store/reducers/tradingReducer";
import { STATUSES } from "../../store/reducers/statuses";
import { convertLocalToEdtEst, formatLimitValue, getLimitRatio } from "../../shared/utils";
import { getPlanState, getUsageState } from "../../store/selectors";
import WarnWhenUnsavedChanges from "../../components/WarnWhenUnsavedChanges/WarnWhenUnsavedChanges";
import LimitAlert from "../../components/Alert/alerts/LimitAlert";
import TradesDetails from "../../components/TradesDetails/TradesDetails";
import PerformanceDetails from "../../components/PerformanceDetails/PerformanceDetails";
import FormHelper from "../../components/FormHelper";
import fromEntries from "object.fromentries";
import BrokersBlock from "./components/Brokers/BrokersBlock";
import Card from "../../components/Card/Card";
import MobileVersionAlert from "../../components/Alert/alerts/MobileVersionAlert";
import { customFetch } from "../../DataProvider";
import { TOUR_LIVE_TRADING_FIRST, TOUR_PAPER_TRADING_FIRST } from "../../store/reducers/tourReducer";
import { forceExitDefaultValue } from "../Algo/utils";
import { BACKTEST_ALGOS } from "../../shared/permissions";
import classes from "../Backtest/Backtest.module.css";
import { DescriptionLine } from "../../components/DescriptionLine/DescriptionLine";

export const TRADING_VALUES = ["screeners", "tickers", "initial_capital", "benchmark_ticker"];

const transformValues = ({ values, status: newStatus }) => ({
  initial_capital: values.initial_capital,
  trading_type: values.trading_type,
  name: values.name,
  algo_type: values.algo_type,
  broker: values.broker,
  description: values.description,
  enter_criteria: values.enter_criteria,
  exit_criteria: values.exit_criteria,
  order_type: values.order_type,
  order_validity: values.order_type === ORDER_TYPES.MARKET ? null : values.order_validity,
  interval: values.interval,
  enter_interval: values.enter_interval,
  exit_interval: values.exit_interval,
  stop_loss: values.stop_loss,
  stop_loss_pct: values.stop_loss_pct,
  market_hours_only: values.market_hours_only,
  max_risk_per_trade_enabled: values.max_risk_per_trade_enabled,
  max_risk_per_day_enabled: values.max_risk_per_day_enabled,
  max_entry_trades: values.max_entry_trades,
  max_entry_trades_enabled: values.max_entry_trades_enabled,
  regular_days_from: convertLocalToEdtEst(values.regular_days_from),
  regular_days_to: convertLocalToEdtEst(values.regular_days_to),
  early_close_days_from: convertLocalToEdtEst(values.early_close_days_from),
  early_close_days_to: convertLocalToEdtEst(values.early_close_days_to),
  // BE-144
  max_risk_per_trade: values.max_risk_per_trade,
  max_risk_per_day: values.max_risk_per_day,

  max_risk_per_trade_value_type: values.max_risk_per_trade_value_type,
  max_risk_per_day_value_type: values.max_risk_per_day_value_type,
  status: newStatus || values.status || statuses.draft.id,
  benchmark_ticker: values.benchmark_ticker?.length ? values.benchmark_ticker[0] : "",
  ticker: values.tickers?.length ? values.tickers[0] : "",
  screener: values.screeners?.length ? { id: values.screeners[0] } : null,
  force_exit_at: values.force_exit_at || forceExitDefaultValue,
  use_stop_trail: values.use_stop_trail,
  tags: values.tags,
});

const TradingForm = (props) => {
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const { loaded, permissions } = usePermissions();

  const { status: tradingStatus, message: tradingMessage, errorMessage: tradingErrorMessage } = useSelector(
    (store) => store.trading,
  );
  const owner = localStorage.getItem(LOCALSTORAGE.PROFILE);

  const [parameters, setParameters] = useState({});
  const { record, resource, ...rest } = props;

  const usage = useSelector(getUsageState) || {};

  //todo: this has to be done for all brokers
  const brokerStatus = useSelector((store) => store.brokers?.data?.alpaca?.status);
  const brokerNotLinked = brokerStatus !== "LINKED" && brokerStatus !== "UNKNOWN";

  const { paper_tradings, money_tradings, status } = usage;
  const { paper_tradings_limit, money_tradings_limit } = useSelector(getPlanState) || {};
  const [limitAlert, setLimitAlert] = useState({});
  const [checkingUnsavedChanges, checkUnsavedChanges] = useState(true);
  const [isLoadingRecent, setLoadingRecent] = useState(false);
  const [isOpenBroker, setIsOpenBroker] = useState(!record?.broker); //BE-93
  const isPaperTrading = isPaper(props);
  const tradingsResource = getTradingsResource(isPaperTrading);

  const formRef = useRef(null);

  const ga = { category: isPaperTrading ? GA_CATEGORIES.PAPER_TRADING_EDIT : GA_CATEGORIES.LIVE_TRADING_EDIT };

  useEffect(() => {
    if (window.location.href.includes("create?source")) {
      notify(t("tradings.clone"), "info");
    }
    customFetch("profile").then((res) => {
      if (res && !res[isPaperTrading ? "paper_trading_tour_completed" : "live_trading_tour_completed"]) {
        dispatch({ type: isPaperTrading ? TOUR_PAPER_TRADING_FIRST : TOUR_LIVE_TRADING_FIRST, payload: true });
        dispatch(setSidebarVisibility(true));
      }
    });
  }, []);

  useEffect(() => {
    brokerNotLinked && setIsOpenBroker(brokerNotLinked);
  }, [brokerStatus]);

  useEffect(() => {
    const limitRatio = isPaperTrading
      ? getLimitRatio(paper_tradings, paper_tradings_limit, status)
      : getLimitRatio(money_tradings, money_tradings_limit, status);
    if (limitRatio !== RATIO_LIMITS.A_LOT) {
      const leftLimits = isPaperTrading
        ? formatLimitValue(paper_tradings_limit) - paper_tradings
        : formatLimitValue(money_tradings_limit) - money_tradings;
      setLimitAlert({
        ratio: limitRatio,
        entity: isPaperTrading ? ENTITY.PAPER_TRADINGS : ENTITY.LIVE_TRADINGS,
        limits: leftLimits,
      });
    }
  }, [paper_tradings, paper_tradings_limit, money_tradings, money_tradings_limit, isPaperTrading, record, status]);

  useEffect(() => {
    if (!record) return;
    const params = {};
    if (!record?.trading_type) {
      params.trading_type = isPaperTrading ? TRADING_TYPE.PAPER : TRADING_TYPE.LIVE;
    }
    params.benchmark_ticker = record.benchmark_ticker ? [record.benchmark_ticker] : [];
    params.screeners = record.screeners || (record.screener && [record.screener.id]) || [];
    params.tickers = record.tickers || (record.ticker && [record.ticker]) || [];
    setParameters(params);
  }, [record, isPaperTrading]);

  // notifications, redirects
  useEffect(() => {
    if (tradingStatus === STATUSES.UPDATE_SUCCESS) {
      notify(t(tradingMessage), "info");
      redirect(`/${tradingsResource}`);
    }
    if (tradingStatus === STATUSES.UPDATE_FAIL) {
      notify(t(tradingErrorMessage), "warning");
    }
    return () => dispatch({ type: TRADING_CLEAR });
  }, [tradingStatus, tradingMessage, tradingErrorMessage]);

  const handleSaveLaunch = (values) => {
    if (brokerStatus !== "LINKED") setIsOpenBroker(true);
    handleSave({ values, status: statuses.active.id });
    const transformedValues = transformValues({ values, status });
    const tradingValues = fromEntries(TRADING_VALUES.map((key) => [key, transformedValues[key]]));
    localStorage.setItem(LOCALSTORAGE.TRADING_RECENT, JSON.stringify(tradingValues));
  };

  const handleSave = async ({ values, status = statuses.draft.id }) => {
    checkUnsavedChanges(false);
    const data = transformValues({ values, status });
    dispatch({ type: TRADING_SAVE, payload: { id: props.record.id, data } });
  };

  const loadRecent = async () => {
    setLoadingRecent(true);
    dataProvider
      .getList("tradings/paper", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "updated_date", order: "DESC" },
        filter: { status: ["ACTIVE", "STOPPED"], owner },
      })
      .then(({ data }) => {
        const transformedData = {
          ...data[0],
          screeners: data[0].screener ? [data[0].screener.id] : [],
          tickers: data[0].ticker ? [data[0].ticker] : [],
        };
        formRef.current.batch(() => {
          TRADING_VALUES.forEach((key) => {
            formRef.current.change(key, transformedData[key]);
          });
        });
        setLoadingRecent(false);
      })
      .catch((e) => {
        console.error(e);
        setLoadingRecent(false);
      });
  };

  return (
    <>
      {record.broker}
      {/* mobile note is only relevant for algo lab view */}
      {permissions?.includes(BACKTEST_ALGOS) && <MobileVersionAlert />}
      <LimitAlert {...limitAlert} />
      <SimpleForm
        submitOnEnter={false}
        record={{
          ..._objectWithoutProperties(record, ["ticker", "screener", "benchmark_ticker"]),
          ...parameters,
        }}
        {...rest}
        save={handleSaveLaunch}
        toolbar={
          <TradingToolbar
            {...props}
            ga={ga}
            handleSave={handleSave}
            loadRecent={loadRecent}
            isLoadingRecent={isLoadingRecent}
          />
        }
      >
        <FormHelper ref={formRef} />
        {checkingUnsavedChanges && <WarnWhenUnsavedChanges />}

        <Card header={t("algos.blocks.algo_params")} className="tradingParametersMobile">
          <TradingHeader handleSave={handleSave}
                         isPaperTrading={isPaperTrading}
                         noCode={!permissions?.includes(BACKTEST_ALGOS)}
                         {...props}/>
        </Card>

        <Card header={t("tradings.trading_parameters")} className="tradingParametersMobile">
          <BrokersBlock ga={ga} {...props} isOpenBroker={isOpenBroker} setIsOpenBroker={setIsOpenBroker} />
          <TradingParameters ga={ga} {...props} />
        </Card>
      </SimpleForm>

      {/*not rendering performance and trades if nothing has been traded*/}
      {(record.trading_days?.length || record.status !== "DRAFT") && (
        <>
          <PerformanceDetails {...props} />
          <TradesDetails
            {...props}
            ga={ga}
            filter={{ trading: props.record.id }}
            tableName={isPaperTrading ? TABLES.PAPER_TRADING_TRADES : TABLES.LIVE_TRADING_TRADES}
            tradesOrdersResource="trading_orders"
            tradesListResource="trading-days/trading"
            tickersResource="trading/tickers"
          />
        </>
      )}
    </>
  );
};

export const TradingHeader = (props) => {
  const isPaperTrading = props.isPaperTrading;
  const handleSave = props.handleSave;
  // const { loaded, permissions } = usePermissions();

  const { record, resource, noCode, ...rest } = props;

  const [showCode, setShowCode] = useState(false);
  const toggleCode = function (show) {
    setShowCode(show || !showCode);
  };

  const ga = { category: isPaperTrading ? GA_CATEGORIES.PAPER_TRADING_EDIT : GA_CATEGORIES.LIVE_TRADING_EDIT };

  return (
      <div className={styles.tradingParams}>
        <InitialBlock {...props} ga={ga} />

        {!noCode ? <>
          <div style={{display: showCode ? "block" : "none"}}>
            <FullScreenEditor
                ga={{
                  category: isPaperTrading
                      ? GA_CATEGORIES.PAPER_TRADING_FULLSCREEN
                      : GA_CATEGORIES.LIVE_TRADING_FULLSCREEN,
                }}
                criteriaInputs={<CriteriaBlock {...props} />}
                additionalBlock={<AdditionalExitBlock {...props} />}
                handleSave={handleSave}
                entity={props?.record?.id ? null : isPaperTrading ? ENTITY.PAPER_TRADING : ENTITY.LIVE_TRADING}
            />
          </div>

          <CriteriaCollapseBlock ga={ga} onExpand={toggleCode} onCollapse={toggleCode} {...props} />
        </>:
            <DescriptionLine record={record} source="description" className={classes.noImage} />
        }
        <AdditionalExitBlock ga={ga} {...props} />
        <AdvancedBlock ga={ga} {...props} />
      </div>
  )
}

export default TradingForm;
