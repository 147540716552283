import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";

import { Box, Button, Link, Typography } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import { useTranslate } from "react-admin";

import styles from "./Authorization.module.scss";
import cn from "clsx";
import logo from "../../assets/logo.svg";
import { auth } from "./firebaseConfig";

const actionCodeSettings = {
  url: window.location.href,
  handleCodeInApp: false,
};

export const ForgotPassword = ({ changeComponent }) => {
  const t = useTranslate();

  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const sendResetLink = () => {
    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(function () {
        setErrorMessage("");
        setSuccessMessage(t("authorization.forgot_password.success"));
      })
      .catch(function (error) {
        setSuccessMessage("");
        setErrorMessage(t(error.message));
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
      </div>
      <Box className={styles.box}>
        <div className={styles.content}>
          <Typography variant="h6" className={styles.title}>
            {t("authorization.forgot_password.reset_pass")}
          </Typography>
          <Typography variant="body2">{t("authorization.forgot_password.message")}</Typography>

          <ValidatorForm onSubmit={sendResetLink} className={styles.form}>
            <TextValidator
              size="small"
              variant="standard"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={[t("email.validate.required"), t("email.validate.format")]}
              className={cn(styles.input, styles.reset)}
              label="Email"
            />

            {successMessage ? (
              <Typography variant="caption" className={styles.error}>
                {successMessage}
              </Typography>
            ) : null}

            {errorMessage ? (
              <Typography variant="caption" color="error" className={styles.error}>
                {errorMessage}
              </Typography>
            ) : null}

            <Button type="submit" variant="contained" color="primary" className={styles.submit}>
              {t("authorization.forgot_password.btn_label")}
            </Button>
          </ValidatorForm>

          <div className={styles.linksGroup}>
            <Link onClick={changeComponent}>{t("authorization.login")}</Link>
            <span className={styles.dot} />
            <Link href="/#/signup">{t("authorization.sign_up")}</Link>
          </div>
        </div>
      </Box>
    </div>
  );
};
