import { useNotify, useRedirect, useTranslate } from "react-admin";
import { ENTITY } from "../../../shared/variables";
import { actions } from "../../../shared/moreActions";
import useDisableByLimit from "../../../shared/hooks/useDisableByLimit";
import { RERUN_BACKTEST } from "../../../store/reducers/rerunBacktestReducer";
import { useDispatch } from "react-redux";

export default function useGetScreenerBacktestActions(record) {
  const redirect = useRedirect();
  const t = useTranslate();
  const notify = useNotify();
  const disableByLimit = useDisableByLimit();
  const dispatch = useDispatch();

  const backtestClone = () => {
    dispatch({ type: RERUN_BACKTEST, payload: { backtest: record } });
    redirect(`/screeners/${record?.screener?.id}`);
    notify(t("backtest.load_to_current_screener"), "info");
  };

  return {
    mainActionName: t("actions.rerun_backtest"),
    mainActionFunc: backtestClone,
    mainActionDisabled: disableByLimit.backtests,
    mainActionEntity: ENTITY.BACKTEST,
    moreActions: [actions.copy_to_new_screener],
  };
}
