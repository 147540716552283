import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { setGaId } from "../../../shared/utils";
import classes from "../CreateFromASample.module.css";

const DesktopButtonGroup = (props) => {
  const {
    ga,
    noResults,
    anchorRef,
    title,
    isOpen,
    onClickCreate,
    onClickToggle,
    disabled,
    basePath,
    label,
  } = props;
  return (
    <ButtonGroup
      variant="contained"
      color="primary"
      ref={anchorRef}
      aria-label="split button"
      className={classes.buttonGroup}
      disabled={disabled}
    >
      {!noResults ? (
        <Button
          onClick={onClickCreate}
          className={classes.button}
          data-testid={basePath}
          id={setGaId(
            ga?.category,
            ga?.action ? `create-${ga.action}` : "create"
          )}
        >
          {label}
        </Button>
      ) : null}
      <Button
        color="primary"
        size="small"
        aria-controls={isOpen ? "split-button-menu" : undefined}
        aria-expanded={isOpen ? "true" : undefined}
        aria-label="create"
        aria-haspopup="menu"
        data-testid="create-from-a-sample"
        onClick={onClickToggle}
        className={noResults ? classes.noResults : ""}
      >
        {props.children}
        {noResults ? <span>{title}</span> : null}
        <ArrowDropDownIcon />
      </Button>
    </ButtonGroup>
  );
};

export default DesktopButtonGroup;
