import { useSelector } from "react-redux";
import { getPlanState, getUsageState } from "../../store/selectors";
import { ENTITY } from "../variables";
import { disableCreating } from "../utils";

export default function useDisableByLimit(entity) {
  const { screeners, algos, backtests, paper_tradings, money_tradings, status } = useSelector(getUsageState) || {};
  const { screeners_limit, algos_limit, backtests_limit, paper_tradings_limit, money_tradings_limit } =
    useSelector(getPlanState) || {};

  const disableByLimits = {
    algos: disableCreating(algos, algos_limit, status),
    backtests: disableCreating(backtests, backtests_limit, status),
    screeners: disableCreating(screeners, screeners_limit, status),
    paper_tradings: disableCreating(paper_tradings, paper_tradings_limit, status),
    live_tradings: disableCreating(money_tradings, money_tradings_limit, status),
  };

  const disableByEntityLimit = () => {
    switch (entity) {
      case ENTITY.ALGO:
      case ENTITY.ALGOS:
        return disableByLimits.algos;
      case ENTITY.BACKTEST:
      case ENTITY.BACKTESTS:
        return disableByLimits.backtests;
      case ENTITY.SCREENER:
      case ENTITY.SCREENERS:
        return disableByLimits.screeners;
      case ENTITY.PAPER_TRADING:
      case ENTITY.PAPER_TRADINGS:
        return disableByLimits.paper_tradings;
      case ENTITY.LIVE_TRADING:
      case ENTITY.LIVE_TRADINGS:
        return disableByLimits.live_tradings;
      default:
        return false;
    }
  };

  return entity ? disableByEntityLimit() : disableByLimits;
}
