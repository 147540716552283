import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loading, useNotify, useRedirect, useTranslate } from "react-admin";
import { Grid } from "@material-ui/core";
import classes from "./StrategiesDashboard.module.css";
import { MarkdownField } from "../../components/Markdown/MarkdownInput";
import getMDImages from "../../components/Markdown/GetMDImages";
import { formatLimitValue, getLimitRatio, setGaId } from "../../shared/utils";
import { ENTITY, GA_CATEGORIES, RATIO_LIMITS } from "../../shared/variables";
import Button from "@material-ui/core/Button";
import { ColoredNumber } from "../../components/ColoredNumber/ColoredNumber";
import { customFetch } from "../../DataProvider";
import { getPlanState, getUsageState } from "../../store/selectors";
import { isPaper } from "../Trading/utils";
import LimitAlert from "../../components/Alert/alerts/LimitAlert";

export const StrategyGains = (props) => {
  const strategy = props.strategy;
  // strategy.annual_gain_pct = Number(strategy.gain30_days_pct) * 12;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} md={2} lg={2} xl={2} className={classes.pHeader}>
          Today
        </Grid>
        <Grid item xs={3} sm={3} md={2} lg={2} xl={2} className={classes.pHeader}>
          Last 30 Days
        </Grid>
        <Grid item xs={3} sm={3} md={2} lg={2} xl={2} className={classes.pHeader}>
          Annualized
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
          <ColoredNumber record={strategy} source="gain_today_pct" style={{ percent: "percent" }} />
        </Grid>
        <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
          <ColoredNumber record={strategy} source="gain30_days_pct" style={{ percent: "percent" }} />
        </Grid>
        <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
          <ColoredNumber record={strategy} source="annual_gain_pct" zeroValue="n/a" style={{ percent: "percent" }} />
        </Grid>
      </Grid>
    </>
  );
};
export const StrategyTags = (props) => {
  const tags = props.tags;

  return tags ? tags.split(",").map((tag, i) => <span className={classes.tag}>{tag}</span>) : null;
};

export const ImageSlider = (props) => {
  const images = getMDImages(props.description);

  return (
    <div>
      {images.map((image, id) => (
        <img src={image._destination} alt="" className={classes.image} />
      ))}
    </div>
  );
};

export const StrategyCard = (props) => {
  const t = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();

  const strategy = props.strategy;
  const showViewAction = props.showViewAction;
  const showTradeAction = props.showTradeAction;
  const showImage = props.showImage;
  const actionsClassName = props.actionsClassName || classes.actionsRight;
  const onLaunchFail = props.onLaunchFail;

  const handleTradeLive = (strategy) => {
    customFetch(`strategies/shared/${strategy.id}/clone`, "POST", null, "text")
      .then((strategyJSON) => {
        const strategy = JSON.parse(strategyJSON);
        redirect(`/tradings/${strategy.id}`);
      })
      .catch(() => {
        notify(t("tradings.clone_failed"));
        onLaunchFail && onLaunchFail();
      });
  };

  return (
    <div className={classes.card}>
      {showImage ? (
        <div className={classes.imageBox}>
          <ImageSlider description={strategy.description} />
        </div>
      ) : null}

      <div className={classes.title}>{strategy.name}</div>

      <div className={classes.performance}>
        <StrategyGains strategy={strategy} />
      </div>

      <div className={props?.descriptionClassName || classes.description}>
        <MarkdownField record={strategy} source="description" stripImages={true} />
      </div>

      <div className={classes.tags}>
        <StrategyTags tags={strategy.tags} />
      </div>

      <div className={actionsClassName}>
        {showViewAction ? (
          <Button
            variant="text"
            className={classes.viewButton}
            onClick={() => redirect(`/strategies/${strategy.id}/show`)}
            id={setGaId(GA_CATEGORIES.DASHBOARD, "marketplace-view-more")}
            data-testid="marketplace-view-more"
          >
            {t("strategies.view_more")}
          </Button>
        ) : null}

        {showTradeAction ? (
          <Button
            className={classes.tradeButton}
            variant="contained"
            onClick={() => handleTradeLive(strategy)}
            id={setGaId(GA_CATEGORIES.DASHBOARD, "marketplace-trade-live")}
            data-testid="marketplace-trade-live"
          >
            {t("strategies.trade_live")}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export const StrategiesDashboard = (props) => {
  const sharedStrategies = useSelector((state) => state.sharedStrategies.data);

  const [limitAlert, setLimitAlert] = useState({});
  const { money_tradings, paper_tradings, status } = useSelector(getUsageState) || {};
  const { money_tradings_limit, paper_tradings_limit } = useSelector(getPlanState) || {};
  const isPaperTrading = isPaper(props);

  const [showLimitAlert, setShowLimitAlert] = useState(false);

  function displayLimitAlert() {
    setShowLimitAlert(true);
  }

  useEffect(() => {
    const limitRatio = isPaperTrading
      ? getLimitRatio(paper_tradings, paper_tradings_limit, status)
      : getLimitRatio(money_tradings, money_tradings_limit, status);
    if (limitRatio !== RATIO_LIMITS.A_LOT) {
      const leftLimits = isPaperTrading
        ? formatLimitValue(paper_tradings_limit) - paper_tradings
        : formatLimitValue(money_tradings_limit) - money_tradings;
      setLimitAlert({
        ratio: limitRatio,
        entity: isPaperTrading ? ENTITY.PAPER_TRADINGS : ENTITY.LIVE_TRADINGS,
        limits: leftLimits,
      });
    }
  }, [isPaperTrading, paper_tradings, paper_tradings_limit, money_tradings, money_tradings_limit]);

  if (!sharedStrategies?.length) return <Loading />;

  return (
    <div>
      {showLimitAlert && <LimitAlert {...limitAlert} />}
      <Grid container spacing={4}>
        {sharedStrategies?.map((strategy, index) => (
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <StrategyCard
              strategy={strategy}
              showViewAction={true}
              showTradeAction={true}
              showImage={true}
              onLaunchFail={displayLimitAlert}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
