import React, { useEffect, useState } from "react";
import { useDataProvider, useTranslate, useNotify, SimpleForm } from "react-admin";
import Card from "../../../../components/Card/Card";
import { DescriptionLine } from "../../../../components/DescriptionLine/DescriptionLine";
import StockField from "../../../../components/StockField/StockField";
import { IntervalsLine } from "../../../Screener/components/IntervalsBlock/IntervalsBlock";
import { CriteriaBlock } from "../../../../components/CriteriaBlock/CriteriaBlock";
import { isDesktop, isMobile } from "react-device-detect";
import ViewActions from "../../../../components/ViewActions/ViewActions";
import { GA_CATEGORIES } from "../../../../shared/variables";
import { InitialLine } from "../../../Algo/components/Initial/Initial";

const ScreenerParameters = (props) => {
  const dataProvider = useDataProvider();
  const t = useTranslate();
  const notify = useNotify();
  const ga = { category: GA_CATEGORIES.SCREENER_BACKTEST };

  const { record, resource } = props;

  const [screener, setScreener] = useState({});

  useEffect(() => {
    if (record.screener) {
      dataProvider
        .getOne("screeners", { id: record.screener.id })
        .then((res) => setScreener(res.data))
        .catch((e) => notify(e.message, "error"));
    } else {
      setScreener(record);
    }
  }, []);

  return (
    <Card removeTopOffset header={t("screeners.screenerParameters")}>
      <InitialLine values={screener}>
        {isDesktop && !record.shared && <ViewActions ga={ga} record={{ ...screener, ...record }} resource={resource} />}
      </InitialLine>
      {isDesktop && <DescriptionLine record={screener} source="description" />}
      {isMobile && (
        <StockField
          label={t("screeners.tickers_indexes")}
          values={{ indexes: record.indexes, tickers: record.tickers }}
        />
      )}

      <SimpleForm {...props} toolbar={null}>
        <CriteriaBlock {...props} readOnly />
      </SimpleForm>

      <IntervalsLine
        values={{ ...screener, ...record, cutoff_method: `${record.cutoff_method}-${record.cutoff_sorting_order}` }}
      />
      {isDesktop && <StockField values={{ indexes: record.indexes, tickers: record.tickers }} />}
    </Card>
  );
};

export default ScreenerParameters;
