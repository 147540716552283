import { STATUSES } from "./statuses";

export const USAGE_LOADING = "USAGE_LOADING";
export const USAGE_RECEIVED = "USAGE_RECEIVED";
export const USAGE_FAIL = "USAGE_FAIL";

export default (
  previousState = { data: {}, status: STATUSES.INIT },
  { type, payload }
) => {
  switch (type) {
    case USAGE_LOADING: {
      return {
        ...previousState,
        status: STATUSES.LOADING,
      };
    }
    case USAGE_RECEIVED: {
      return { data: payload.usage, status: STATUSES.LOAD_SUCCESS };
    }
    case USAGE_FAIL: {
      return { ...previousState, status: STATUSES.LOAD_FAIL };
    }
    default:
      return previousState;
  }
};
