import { STATUSES } from "./statuses";

export const SCREENERS_LOADING = "SCREENERS_LOADING";
export const SCREENERS_RECEIVED = "SCREENERS_RECEIVED";

export default (
  previousState = { data: [], status: STATUSES.INIT },
  { type, payload }
) => {
  switch (type) {
    case SCREENERS_LOADING: {
      return {
        data: [],
        status: STATUSES.LOADING,
      };
    }
    case SCREENERS_RECEIVED: {
      return { data: payload.screeners, status: STATUSES.LOAD_SUCCESS };
    }
    default:
      return previousState;
  }
};
