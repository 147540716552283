import React from "react";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import classes from "./PlanItem.module.css";
import { useTranslate } from "react-admin";
import { PLAN_STATUSES } from "../../Subscriptions";
import { setGaId } from "../../../../../../shared/utils";
import {MomentField} from "../../../../../../components/MomentField";

const ActivateButton = ({plan, ga, onClick, disabled, className}) => {
  const t = useTranslate();

  // MVP1-3208: trial
  let buttonLabel = "";
  if (plan.status === PLAN_STATUSES.FAILED) {
    buttonLabel = t("button_labels.select_again")
  } else if (plan.trial_period_days && plan.monthly_price && !plan.trialEnd && !plan.hasStripeAccount) {
    //trial only works for first time purchase; TODO: seems like too much logic for front-end
    buttonLabel = t("button_labels.start_trial", {days: plan.trial_period_days})
  } else {
    buttonLabel = t("button_labels.select")
  }

  let button = <div>Error</div>;
  if(plan.status === PLAN_STATUSES.ACTIVE) {
    if (!plan.price.replace(/[^0-9.-]+/g,"") || isNaN(plan.price.replace(/[^0-9.-]+/g,""))) { //price is none - just show "active"
      button = <div className={clsx(classes.status, classes.active)}>{t("subscriptions.active")}</div>
    } else {
      button = ( //else show when the plan renews / trial ends
          <div className={clsx(classes.status, classes.active, className)}>
            {plan.trialEnd && plan.trialEnd >= plan.currentPeriodEnd ? t("subscriptions.trial_ends") : t("subscriptions.payment_on")}
            &nbsp;<MomentField record={plan} source="currentPeriodEnd" className={classes.periodEnds}/>
          </div>
      )
    }

  } else {
    button = (
        <Button
            id={setGaId(ga?.category, `${ga?.action}-select-plan-${plan.name}`)}
            variant="contained"
            className={clsx(classes.status, classes.select, className)}
            onClick={() => onClick(plan.id)}
            disabled={disabled}
        >
          {buttonLabel}
        </Button>
    )
  }

  return button;
}

const PlanItem = ({ ga, plan, onClick, disabled, discountDuration, hasDiscountCountDown }) => {
  const t = useTranslate();

  return (
    <div
      className={clsx(classes.plan, {
        [classes.planActive]: plan.status === PLAN_STATUSES.ACTIVE,
        [classes.planInactive]: plan.status === PLAN_STATUSES.INACTIVE,
        [classes.planFailed]: plan.status === PLAN_STATUSES.FAILED,
      })}
    >
      <div>
        <div className={classes.iconContainer}>
          {plan.most_popular && <span className={classes.mostPopular}>{t("subscriptions.higlight_type.popular")}</span>}
        </div>
        <span className={classes.headerLabel}>
          {plan.icon} {plan.name}
        </span>
        <p className={classes.description}>{plan.description}</p>
      </div>

      <div className={classes.planDetails}>
        <span className={classes.price}>{plan.price}</span>
        <span className={plan.price !== plan.oldPrice ? classes.oldPrice : classes.nonePrice}>{plan.oldPrice}</span>
        <p className={classes.description}>
          {plan.priceDescription}
          <br />
          {discountDuration && plan.price !== plan.oldPrice && hasDiscountCountDown ? (
            t(`subscriptions.discount_duration_${discountDuration}`, { price: plan.oldPrice })
          ) : (
            <div>&nbsp;</div>
          )}
        </p>

        {plan.list}
      </div>
      <ActivateButton plan={plan} ga={ga} onClick={onClick} disabled={disabled} />
    </div>
  );
};

export default PlanItem;
