import { call, put, takeEvery } from "redux-saga/effects";
import { authFetchAPI } from "../../DataProvider";
import {
  BROKERS_LINK_FAIL,
  BROKERS_LINK_SUCCESS,
  BROKERS_LINKING,
  BROKERS_UNLINK_SUCCESS,
  BROKERS_UNLINKING,
  BROKERS_VALIDATE_LOAD_FAIL,
  BROKERS_VALIDATE_LOAD_SUCCESS,
  BROKERS_VALIDATE_LOADING,
  LINK_STATUSES,
} from "../reducers/brokersReducer";
import { VALIDATION_STATUS } from "../../pages/Profile/components/BrokersTable/BrokersTable";

export const VALIDATE_BROKER = "VALIDATE_BROKER";
export const LINK_BROKER = "LINK_BROKER";
export const LINK_BROKER_ACCOUNT = "LINK_BROKER_ACCOUNT";
export const UNLINK_BROKER_ACCOUNT = "UNLINK_BROKER_ACCOUNT";

export default function* brokersSaga() {
  yield takeEvery(VALIDATE_BROKER, function* ({ payload: { id: brokerName, setValid } }) {
    yield put({ type: BROKERS_VALIDATE_LOADING });
    try {
      const validationRes = yield call(authFetchAPI, `brokers/validate?broker=${brokerName?.toUpperCase()}`);
      const validationData = yield validationRes.json();

      let status = LINK_STATUSES.ERROR;
      let data = [];
      if (validationData.result === "OK") {
        status = LINK_STATUSES.LINKED;
        if (brokerName !== "alpaca") {
          const brockesRes = yield call(authFetchAPI, `brokers/list-accounts?broker=${brokerName?.toUpperCase()}`);
          data = yield brockesRes.json();
        }
      }
      if (validationData.result === "ERROR" && !validationData.message.match(/Status Code: 401/)) {
        status = LINK_STATUSES.ERROR;
      }
      if (validationData.result === "ERROR" && validationData.message.match(/Status Code: 401/)) {
        status = LINK_STATUSES.REVOKED;
      }
      if (validationData.result === "NOT_LINKED") {
        status = LINK_STATUSES.NOT_LINKED;
      }
      if (validationData.result === "ACCOUNT_NOT_LINKED") {
        status = LINK_STATUSES.ACCOUNT_NOT_LINKED;
        const brockesRes = yield call(authFetchAPI, `brokers/list-accounts?broker=${brokerName?.toUpperCase()}`);
        data = yield brockesRes.json();
      }

      if (!Array.isArray(data)) status = LINK_STATUSES.ERROR;
      setValid && setValid(status === LINK_STATUSES.LINKED ? VALIDATION_STATUS.VALID : VALIDATION_STATUS.NOT_VALID);
      yield put({
        type: BROKERS_VALIDATE_LOAD_SUCCESS,
        payload: { brokerStatuses: { [brokerName]: { status, data } } },
      });
    } catch (e) {
      yield put({ type: BROKERS_VALIDATE_LOAD_FAIL });
      console.error(e);
    }
  });

  yield takeEvery(LINK_BROKER, function* ({ payload: { brokerName, body } }) {
    yield put({ type: BROKERS_LINKING });
    try {
      const linkRes = yield call(authFetchAPI, `brokers/link`, { method: "POST", body });
      let data = [];

      if (linkRes.ok) {
        const status = brokerName === "alpaca" ? LINK_STATUSES.LINKED : LINK_STATUSES.ACCOUNT_NOT_LINKED;
        const brockesRes = yield call(authFetchAPI, `brokers/list-accounts?broker=${brokerName?.toUpperCase()}`);
        data = yield brockesRes.json();
        yield put({
          type: BROKERS_VALIDATE_LOAD_SUCCESS,
          payload: { brokerStatuses: { [brokerName]: { status, data } } },
        });
      } else {
        yield put({ type: BROKERS_LINK_FAIL, payload: { brokerName } });
      }
    } catch (e) {
      console.error(e);
      yield put({ type: BROKERS_LINK_FAIL, payload: { brokerName } });
    }
  });

  yield takeEvery(LINK_BROKER_ACCOUNT, function* ({ payload: { brokerName, id } }) {
    yield put({ type: BROKERS_LINKING });
    try {
      const body = JSON.stringify({ account_id: id, broker: brokerName.toUpperCase() });
      const linkRes = yield call(authFetchAPI, `brokers/link-account`, { method: "POST", body });

      if (linkRes.ok) {
        yield put({ type: BROKERS_LINK_SUCCESS, payload: { brokerName } });
      } else {
        yield put({ type: BROKERS_LINK_FAIL, payload: { brokerName } });
      }
    } catch (e) {
      console.error(e);
      yield put({ type: BROKERS_LINK_FAIL, payload: { brokerName } });
    }
  });

  yield takeEvery(UNLINK_BROKER_ACCOUNT, function* ({ payload: { id, name, accountId, notify, t } }) {
    yield put({ type: BROKERS_UNLINKING });
    try {
      const params = new URLSearchParams({
        broker: id?.toUpperCase(),
        ...(accountId && { accountId }),
      }).toString();
      const linkRes = yield call(authFetchAPI, `brokers/unlink-account?${params}`, { method: "DELETE" });

      if (linkRes.ok) {
        yield put({ type: BROKERS_UNLINK_SUCCESS, payload: { brokerName: id } });
        notify(t("brokers.acc_is_unlinked", { name }));
      }
    } catch (e) {
      console.error(e);
    }
  });
}
