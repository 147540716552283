import { MenuItem } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import styles from "./PopupMenuItem.module.css";
import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tooltip: {
    marginTop: -36,
  },
});

const Item = forwardRef(
  ({ gaId, id, label, onClick, disabled, icon, popupState }, ref) => (
    <MenuItem
      id={gaId}
      key={id || label}
      className={styles.item}
      onClick={() => {
        onClick();
        popupState.close();
      }}
      disabled={disabled}
      data-testid={id}
      ref={ref}
    >
      {icon}
      <span className={styles.label}>{label}</span>
    </MenuItem>
  )
);

const PopupMenuItem = forwardRef(({ condition, tooltip, ...props }, ref) => {
  const classes = useStyles();

  return condition ? (
    tooltip ? (
      <Tooltip
        title={tooltip}
        ref={ref}
        placement="bottom-start"
        classes={{ tooltip: classes.tooltip }}
      >
        <span>
          <Item {...props} />
        </span>
      </Tooltip>
    ) : (
      <Item {...props} ref={ref} />
    )
  ) : null;
});

export default PopupMenuItem;
