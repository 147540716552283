import union from "lodash/union";
import remove from "lodash/remove";

export const TRADING_LONG_EXITING_ADD = "TRADING_LONG_EXITING_ADD";
export const TRADING_LONG_EXITING_REMOVE = "TRADING_LONG_EXITING_REMOVE";

const initialState = { data: [] };

export default (previousState = initialState, { type, payload }) => {
  switch (type) {
    case TRADING_LONG_EXITING_ADD: {
      return {
        data: union(previousState.data, [payload]),
      };
    }

    case TRADING_LONG_EXITING_REMOVE: {
      return {
        data: remove(previousState.data, (id) => id === payload),
      };
    }

    default:
      return previousState;
  }
};
