import { STATUSES } from "./statuses";

export const SHARED_BACKTESTS_LOADING = "SHARED_BACKTESTS_LOADING";
export const SHARED_BACKTESTS_RECEIVED = "SHARED_BACKTESTS_RECEIVED";
export const SHARED_BACKTESTS_FAIL = "SHARED_BACKTESTS_FAIL";

export default (previousState = { data: [], status: STATUSES.INIT }, { type, payload }) => {
  switch (type) {
    case SHARED_BACKTESTS_LOADING: {
      return { data: [], status: STATUSES.LOADING };
    }
    case SHARED_BACKTESTS_RECEIVED: {
      return { data: payload.sharedBacktests, status: STATUSES.LOAD_SUCCESS };
    }
    case SHARED_BACKTESTS_FAIL: {
      return { data: [], status: STATUSES.LOAD_FAIL };
    }
    default:
      return previousState;
  }
};
