import fetchReducer from "./fetchReducer";
import tickerReducer from "./tickerReducer";
import indexesReducer from "./indexesReducer";
import backtestInfoReducer from "./backtestInfoReducer";
import profileReducer from "./profileReducer";
import screenersReducer from "./screenersReducer";
import screenersAllReducer from "./screenersAllReducer";
import appReducer from "./appReducer";
import usageReducer from "./usageReducer";
import plansReducer from "./plansReducer";
import brokersReducer from "./brokersReducer";
import tradingReducer from "./tradingReducer";
import subscriptionReducer from "./subscriptionReducer";
import tourReducer from "./tourReducer";
import tradingExitingReducer from "./tradingExitingReducer";
import tradingLongExitingReducer from "./tradingLongExitingReducer";
import headerReducer from "./headerReducer";
import sharedBacktestsReducer from "./sharedBacktestsReducer";
import sharedStrategiesReducer from "./sharedStrategiesReducer";
import rerunBacktestReducer from "./rerunBacktestReducer";

export default {
  tickers: tickerReducer,
  indexes: indexesReducer,
  backtestInfo: backtestInfoReducer,
  screeners: screenersReducer,
  screenersAll: screenersAllReducer,
  fetchedData: fetchReducer,
  app: appReducer,
  usage: usageReducer,
  plans: plansReducer,
  profile: profileReducer,
  brokers: brokersReducer,
  trading: tradingReducer,
  tradingExiting: tradingExitingReducer,
  tradingLongExiting: tradingLongExitingReducer,
  subscription: subscriptionReducer,
  tour: tourReducer,
  header: headerReducer,
  sharedBacktests: sharedBacktestsReducer,
  sharedStrategies: sharedStrategiesReducer,
  rerunBacktest: rerunBacktestReducer,
};
