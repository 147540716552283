import * as React from "react";
import {
  TextField,
  EmailField,
  ArrayField,
  email,
  Create,
  List,
  Datagrid,
  SimpleForm,
  TextInput,
  TabbedShowLayout,
  BooleanInput,
  DateInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SingleFieldList,
  useTranslate,
  useListContext,
  TopToolbar,
  CreateButton,
  Tab, SimpleList,
} from "react-admin";
import {UserFilterSidebar} from "./components/filter/UserFilter";
import {isDesktop} from "react-device-detect";

//validation
import { required } from "react-admin";
import { MomentField } from "../../components/MomentField";
import Profile from "./components/Profile/Profile";
import { AlgoList } from "../Algo/ShowAlgos";
import {CountryField} from "../../components/PhoneField/PhoneField";
import classes from "./User.module.css"
import ListPagination from "../../components/ListPagination/ListPagination";
import { TABLES } from "../../shared/variables";
import useGetItemsPerPage from "../../components/ListPagination/hooks/useGetItemsPerPage";

const ListActions = () => {
  const { basePath } = useListContext();
  const t = useTranslate();
  return (
    <TopToolbar className={classes.create}>
      <CreateButton
        label={t("users.create")}
        color="primary"
        size="large"
        variant="contained"
        basePath={basePath}
      />
    </TopToolbar>
  );
};



export const UserList = (props) => {
  const t = useTranslate();

  const tableName = TABLES.USERS;
  const perPage = useGetItemsPerPage(tableName);

  return (
    <List
      {...props}
      aside={isDesktop ? <UserFilterSidebar/> : null}
      className={classes.list}

      sort={{ field: "terms_accepted_datetime", order: "DESC" }}
      filterDefaultValues={{ enabled: true }}
      actions={<ListActions />}
      exporter={false}
      perPage={perPage}
      pagination={<ListPagination {...props} tableName={tableName} />}
    >
      {isDesktop ? (<Datagrid rowClick="edit" size="medium" className={classes.datagrid}>
        <TextField source="name" cellClassName="Other-Col Name-Col"/>

        <EmailField source="email" cellClassName="Other-Col Name-Col"/>

        <CountryField source="country" showLabel={true} ifEmpty="n/a" cellClassName="Other-Col"/>

        <MomentField
            source="terms_accepted_datetime"
            label={t("users.joined")}
            fromNow={true}
            cellClassName="Other-Col"
            headerClassName="Other-Header"
        />

        <ArrayField source="roles" cellClassName="Other-Col">
          <SingleFieldList linkType={false}>
            <TextField source="name" className="User-Role"/>
          </SingleFieldList>
        </ArrayField>

      </Datagrid>) :

      <SimpleList

          primaryText={record => <div className={classes.mobileName}>
            <TextField record={record} source="name"/> &lt;<EmailField record={record} source="email" />&gt;
          </div>}

          secondaryText={record => <div className={classes.secondaryText}>

            <ArrayField record={record} source="roles">
              <SingleFieldList linkType={false} >
                <TextField source="name" className={classes.role}/>
              </SingleFieldList>
            </ArrayField>

            <div>
              <div>{t("users.joined")}&nbsp;</div>
              <MomentField record={record} source="terms_accepted_datetime" fromNow={true}/>
            </div>
          </div>}

          tertiaryText={record => <CountryField record={record} source="country" showLabel={true} ifEmpty="n/a" />}
          linkType="edit"
          className={classes.simpleList}
          classes={{tertiary: classes.tertiary}}

      />
      }
    </List>
  );
};

export const UserEdit = (props) => (
  <TabbedShowLayout className="User-Tabs" {...props}>
    <Tab label="Profile" contentClassName="User-Tab">
      <Profile {...props} />
    </Tab>
    <Tab label="Algos" contentClassName="User-Tab">
      <AlgoList {...props} ownerId={props.id} />
    </Tab>
    <Tab label="Subscriptions" contentClassName="User-Tab">
      <p>Coming soon...</p>
    </Tab>
  </TabbedShowLayout>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      variant="outlined"
      className="User-Create"
    >
      <TextInput source="name" validate={required()} />
      <DateInput source="date_of_birth" />
      <TextInput source="email" validate={[required(), email()]} />
      <TextInput source="phone" />

      <ReferenceArrayInput source="role_ids" reference="roles">
        <SelectArrayInput optionText="name" label="Roles" />
      </ReferenceArrayInput>
      <BooleanInput source="enabled" defaultValue={true} />
    </SimpleForm>
  </Create>
);
