import React, {useState} from "react";
import {Slider} from "@material-ui/core";
import styles from "./WatchlistCriteriaInput.module.scss";
import {useInput} from "react-admin";

const RangeInput = ({min = 0, max = 3, readOnly = false, defaultValue, record, ...props}) => {

    const {source, ...rest} = props;
    const range = useInput({source: source, defaultValue: defaultValue, ...rest});

    let record_min = min;
    let record_max = max;

    if(record && record[source] && record[source].constructor === Array){
        record_min =  Number(record[source][0]);
        record_max =  Number(record[source][1]);
    }

    const [slideValue, setSliderValue] = useState([record_min, record_max]);


    const [minValue, setMinValue] = useState(record_min);
    const [maxValue, setMaxValue] = useState(record_max);

    const step = props.step || (max - min) / 200;

    const [minOverride, setMinOverride] = useState(Math.max(minValue - step * 10, min));
    const [maxOverride, setMaxOverride] = useState(Math.min(maxValue + step * 10, max));

    function onChange(value) {

        setMinOverride(Math.max(value[0] - step * 10, min));
        setMaxOverride(Math.min(value[1] + step * 10, max));

        setMinValue(value[0]);
        setMaxValue(value[1]);

        setSliderValue(value);

        range.input.onChange(value);
    }

    return (
        <div className={styles.sliderWrapper}>
            <Slider
                value={slideValue}
                name={range.input.name}
                onChange={(e, value) => onChange(value)}
                valueLabelDisplay={readOnly ? "on" : "auto"}
                min={minOverride}
                max={maxOverride}
                disabled={readOnly}
                step={step}
                classes={{
                    valueLabel: styles.valueLabel,
                    thumb: styles.thumb,
                    track: styles.track,
                    mark: styles.mark,
                    valueLabelLabel: styles.valueLabelLabel}}

                valueLabelFormat={props.valueLabelFormat}
                marks={props.marks}
                >

            </Slider>
        </div>

    );
};

export default RangeInput;

