import React from "react";
import styles from "./Search.module.css";
import { OutlinedInput } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "react-feather/dist/icons/x";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslate } from "react-admin";
import cn from "clsx";
import { colors } from "../../shared/cssVariables";
import { setGaId } from "../../shared/utils";

const Search = ({ value, onClick, className, ga }) => {
  const t = useTranslate();

  return (
    <OutlinedInput
      id={setGaId(ga?.category, "search")}
      className={cn(styles.searchInput, className)}
      placeholder={t("fullScreenEditor.search")}
      endAdornment={
        value ? (
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClick("")}
            aria-label="clear"
            className={styles.clearSearch}
          >
            <ClearIcon size={18} color={colors.gray_label} />
          </IconButton>
        ) : (
          <SearchIcon size={18} color="disabled" />
        )
      }
      value={value}
      onInput={(e) => onClick(e.target.value)}
    />
  );
};

export default Search;
