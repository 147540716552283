import React, { useState } from "react";
import { useTranslate } from "react-admin";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { GoogleAuthProvider } from "firebase/auth";

import { LoginPage } from "./LoginPage";
import { ForgotPassword } from "./ForgotPassword";

// TODO: Fix properly. This import is a workaround. Without it, autocomplete in StockInput component does not work.
import "../../store/store";

import { auth } from "./firebaseConfig";
import { initializeContextAfterLogin } from "./AuthProvider";

export const FirebaseScreen = () => {
  const t = useTranslate();

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {

        if (authResult) {
          initializeContextAfterLogin(authResult.user);
        }

        return false;
      },
    },
    signInFlow: "popup",
    signInSuccessUrl: null,
    signInOptions: [
      {
        provider: GoogleAuthProvider.PROVIDER_ID,
        fullLabel: t("authorization.with_google"),
      },
    ],
  };

  return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
};

export const Authorization = () => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const toggleForgotPassword = () => setForgotPassword(!forgotPassword);

  return forgotPassword ? (
    <ForgotPassword changeComponent={toggleForgotPassword} />
  ) : (
    <LoginPage changeComponent={toggleForgotPassword} FirebaseScreen={FirebaseScreen} />
  );
};
