import React, { useEffect, useState } from "react";
import { required, useTranslate, useNotify } from "react-admin";
import StockInput from "../../../../components/StockInput/StockInput";
import classes from "./BasktestParameters.module.css";
import { useForm, useFormState } from "react-final-form";
import { Storage } from "../../../../config";
import { setGaId } from "../../../../shared/utils";
import Card from "../../../../components/Card/Card";
import { DateRange } from "../../../../components/DateInput/DateInput";
import { useSelector } from "react-redux";
import { STATUSES } from "../../../../store/reducers/statuses";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import InitialCapital from "../../../../components/InitialCapital";

export const BacktestParameters = ({ ga, id, options, ...props }) => {
  const form = useForm();
  const {
    values: { screeners, benchmarkTicker },
  } = useFormState();
  const t = useTranslate();
  const notify = useNotify();
  const storeScreeners = useSelector((store) => store.screeners);
  const [loadingScreenersStatus, setLoadingScreenersStatus] = useState();

  const shouldBenchmarkBeRequired = () => {
    return screeners?.length > 0 && (benchmarkTicker ? benchmarkTicker?.length === 0 : true);
  };

  useEffect(() => {
    form.setConfig("validate", () => (shouldBenchmarkBeRequired() ? { benchmarkTicker: "Required" } : {}));
  }, [screeners, benchmarkTicker]);

  useEffect(() => {
    const roles = Storage.getItem("roles");
    const isAdmin = roles?.includes("super_admin");

    if (storeScreeners?.status === STATUSES.LOADING && !isAdmin) {
      setLoadingScreenersStatus(STATUSES.LOADING);
    } else if (loadingScreenersStatus === STATUSES.LOADING && storeScreeners?.status === STATUSES.LOAD_SUCCESS) {
      const availableScreeners = screeners?.length
        ? storeScreeners?.data?.filter(({ id }) => screeners.includes(id))
        : screeners;
      if (screeners?.length && !availableScreeners?.length) notify(t("autocomplete.unavailable_screener"), "warning");
    }
  }, [storeScreeners]);

  const noHeader = props.noHeader;

  return (
    <Card removeTopOffset={noHeader} header={noHeader ? null : t("algos.blocks.backtest_params")}>
      <div className={clsx(classes.gridLayout, { [classes.mobile]: isMobile })}>
        {props.record.portfolio_type === "INTRADAY" ?
          <StockInput
            {...props}
            ga={{ ...ga, action: "tickers-screeners" }}
            name="ticker"
            variant="outlined"
            label={t("backtest.tickers_screeners")}
            sources={["screeners", "tickers"]}
            options={["screeners", "tickers"]}
            fullWidth
            translateChoice={false}
            className={classes.stockInput}
            validate={[required()]}
            matchFrom="start"
            helperText={t("helpers.input_tickers_screeners")}
          /> : null}

        <div className={classes.row}>
          <div className={classes.dates}>
            <DateRange
              id={setGaId(ga?.category, "backtest-date")}
              label_from={t("backtest.from")}
              label_to={t("backtest.to")}
              tooltip_from={t("helpers.input_from")}
              tooltip_to={t("helpers.input_to")}
              source_from="start_date"
              source_to="end_date"
            />
          </div>

          <div className={classes.initialCapital}>
            <InitialCapital {...props} ga={setGaId(ga?.category, "backtest-initial-capital")} />
          </div>

          <div className={classes.benchmark}>
            <StockInput
              {...props}
              ga={{ ...ga, action: "benchmark-ticker" }}
              name="benchmarkTicker"
              variant="outlined"
              label={t("backtest.benchmark_ticker")}
              sources={["benchmark_ticker"]}
              options={["tickers"]}
              limitTags={1}
              fullWidth
              translateChoice={false}
              validate={shouldBenchmarkBeRequired()}
              matchFrom="start"
              helperText={t("helpers.input_benchmark")}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
