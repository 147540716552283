import polyglotI18nProvider from 'ra-i18n-polyglot';
import enMessages from 'ra-language-english';
import {en, ra} from './en';

const defaultLocale = 'en';
const polyglotOptions = {
    allowMissing: true
};

const trueMerge = (a, b) => {
    Object.keys(b).forEach(key => {
        try {
            if (b[key].constructor === Object) {
                a[key] = trueMerge(a[key], b[key]);
            } else {
                a[key] = b[key];
            }
        } catch(e) {
            a[key] = b[key];
        }
    });
    return a;
};

const messages = {
    en: {
        ...en,
        ra: trueMerge(enMessages.ra, ra),
    },
};

const messagesByLocale = (locale) => messages[locale];

//TODO: remove this after react-admin upgrade and move labels to NullableBooleanInput instances
enMessages.ra.boolean.null = 'All';
enMessages.ra.boolean.false = 'Empty';
enMessages.ra.boolean.true = 'Non-empty';

export const i18nProvider = new polyglotI18nProvider(
    messagesByLocale,
    defaultLocale,
    polyglotOptions
);