import React, { useState } from "react";
import { useTranslate } from "react-admin";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import Card from "../Card/Card";
import WideLabelVal from "../Rows/WideLabelVal";
import WideRow from "../Rows/WideRow";
import styles from "./QuickStats.module.scss";
import useQuickStats from "./hooks/useQuickStats";
import QuickStatsValue from "./components/QuickStatsValue";
import QuickStatsMenu from "./components/QuickStatsMenu";
import { LOCALSTORAGE, TRADING_RESOURCE } from "../../shared/variables";
import { Storage } from "../../config";

const { QUICK_STATS_PAPER, QUICK_STATS_LIVE } = LOCALSTORAGE;

const QuickStats = ({ ga, resource }) => {
  const storageKey = resource === TRADING_RESOURCE.PAPER ? QUICK_STATS_PAPER : QUICK_STATS_LIVE;
  const storageValue = JSON.parse(Storage.getItem(storageKey));
  const t = useTranslate();
  const [includeArchived, setIncludeArchived] = useState(storageValue);
  const { gain_total, gain_today, gain7_days, gain30_days } = useQuickStats(resource, includeArchived);

  return (
    <Card
      header={t("tradings.quick_stats.header")}
      removeTopOffset
      className={clsx(styles.card, { [styles.mobile]: isMobile })}
    >
      <WideRow className={clsx(styles.wideRow, { [styles.mobile]: isMobile })}>
        <WideLabelVal
          label={t("tradings.quick_stats.gain_total")}
          value={<QuickStatsValue value={gain_total} className={clsx({ [styles.totalGainValue]: isMobile })} />}
        />
      </WideRow>
      <WideRow className={clsx(styles.wideRow, { [styles.mobile]: isMobile })}>
        <WideLabelVal label={t("tradings.quick_stats.gain_today")} value={<QuickStatsValue value={gain_today} />} />
        <WideLabelVal label={t("tradings.quick_stats.gain7_days")} value={<QuickStatsValue value={gain7_days} />} />
        <WideLabelVal label={t("tradings.quick_stats.gain30_days")} value={<QuickStatsValue value={gain30_days} />} />
      </WideRow>
      <QuickStatsMenu
        ga={ga}
        storageKey={storageKey}
        includeArchived={includeArchived}
        setIncludeArchived={setIncludeArchived}
      />
    </Card>
  );
};

export default QuickStats;
