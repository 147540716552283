import React, { Component } from "react";
import T from "prop-types";

import { Datagrid, TextField } from "react-admin";

import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import get from "lodash/get";
import LocalStorage from "./LocalStorage";
import SelectionMenu from "./SelectionMenu";
import DatagridIdsWrapper from "../DatagridIdsWrapper/DatagridIdsWrapper";
import store from "../../store/store";

const arrayToSelection = (values) =>
  values.reduce((selection, columnName) => {
    selection[columnName] = true;
    return selection;
  }, {});

// CustomizableDatagrid allows to show/hide columns dynamically
// the preferences are stored in local storage
class CustomizableDatagrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: this.getInitialSelection(),
      sidebarOpen: true,
    };

    store.subscribe(() => {
      this.setState({
        sidebarOpen: store.getState().admin.ui.sidebarOpen,
      });
    });
  }

  getColumnNames() {
    const { children } = this.props;
    return filter(React.Children.map(children, (field) => get(field, ["props", "source"])));
  }

  getColumnLabels() {
    const { children } = this.props;
    return filter(
      React.Children.map(
        children,
        (field) =>
          field && {
            source: get(field, ["props", "source"]),
            label: get(field, ["props", "label"]),
          }
      ),
      (item) => item && item.source
    );
  }

  getInitialSelection() {
    const { defaultColumns, resource, storage } = this.props;

    const previousSelection = storage.get(resource);

    // if we have a previously stored value, let's return it
    if (!isEmpty(previousSelection)) {
      return previousSelection;
    }

    // if defaultColumns are set let's return them
    if (!isEmpty(defaultColumns)) {
      return arrayToSelection(defaultColumns);
    }

    // otherwise we fallback on the default behaviour : display all columns
    return arrayToSelection(this.getColumnNames());
  }

  // updates the storage with the internal state value
  updateStorage = () => {
    this.props.storage.set(this.props.resource, this.state.selection);
  };

  toggleColumn = (columnName) => {
    const previousSelection = this.state.selection;
    const selection = {
      ...previousSelection,
      [columnName]: !previousSelection[columnName],
    };
    this.setState({ selection }, this.updateStorage);
  };

  renderChild = (child) => {
    const source = get(child, ["props", "source"]);
    const { selection } = this.state;

    // Show children without source, or children explicitly visible
    if (!source || selection[source]) {
      return React.cloneElement(child, {});
    }

    return null;
  };

  render() {
    const { ga, children, defaultColumns, settingsButtonClassName, enableArchive, ...rest } = this.props;
    const { selection, sidebarOpen } = this.state;

    return (
      <div style={{ overflowX: "auto", width: `calc(100vw - ${sidebarOpen ? 250 : 137}px)` }}>
        {rest?.ids?.length ? (
          <SelectionMenu
            ga={ga}
            selection={selection}
            tooltip={"data_grid.configure_columns"}
            menuItemsHeader={"data_grid.show_columns"}
            menuItems={this.getColumnLabels()}
            onItemClick={this.toggleColumn}
          />
        ) : null}
        <Datagrid style={{ tableLayout: "auto" }} {...rest}>
          {enableArchive ? null : <TextField headerClassName="Empty-Col" cellClassName="Empty-Col" />}
          {React.Children.map(children, this.renderChild)}
          <DatagridIdsWrapper ga={ga} headerClassName="IdsWrapper-Col" cellClassName="IdsWrapper-Col" />
        </Datagrid>
      </div>
    );
  }
}

CustomizableDatagrid.propTypes = {
  ga: T.object,
  defaultColumns: T.arrayOf(T.string),
  settingsButtonClassName: T.string,
  storage: T.shape({
    get: T.func.isRequired,
    set: T.func.isRequired,
  }),
};

CustomizableDatagrid.defaultProps = {
  defaultColumns: [],
  storage: LocalStorage,
};

export default CustomizableDatagrid;
