import React from "react";
import { useRedirect } from "react-admin";
import { ALERT } from "../variables";
import { DISABLED_FIELDS, ENTITY } from "../../../shared/variables";
import Alert from "../Alert";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import useDisableByLimit from "../../../shared/hooks/useDisableByLimit";

const SharedScreenerAlert = ({ basePath, record }) => {
  const redirect = useRedirect();
  const disableByLimit = useDisableByLimit();

  const clone = () => {
    redirect(
      basePath +
        "/create?" +
        new URLSearchParams({ source: JSON.stringify(_objectWithoutProperties(record, DISABLED_FIELDS)) }).toString()
    );
  };

  const alert = {
    type: ALERT.BRAND,
    data: [{ description: ["helpers.shared_screener"] }],
    action: {
      label: "helpers.clone_screener",
      onClick: clone,
      disabled: disableByLimit.screeners,
      entity: ENTITY.SCREENER,
    },
  };

  return <Alert data={alert} />;
};

export default SharedScreenerAlert;
