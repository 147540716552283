import React from "react";
import { Show } from "react-admin";
import ShowTradingView from "./ShowTradingView";

const ShowTrading = (props) => {
  return (
    <Show actions={null} {...props}>
      <ShowTradingView {...props} />
    </Show>
  );
};

export default ShowTrading;
