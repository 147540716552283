import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { LOCALSTORAGE } from "../../shared/variables";

const { TOKEN } = LOCALSTORAGE;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in
    user.getIdToken().then((token) => {
      localStorage.setItem(TOKEN, token);
    });
  }
});

// TODO: Try deleting these lines in newer versions of react-admin-firebase.
// Those should not be necessary, but for some reason current version (10.3.1)
// of firebase fails in react-admin-firebase with "Error: Service firestore is not available" otherwise.
// Possibly due to modular design
getFirestore(app);
getStorage(app);
