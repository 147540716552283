import React from "react";
import ScreenerParameters from "./components/ScreenerParameters/ScreenerParameters";
import DayResults from "./components/DayResults/DayResults";
import ErrorDetails from "../../components/ErrorDetails/ErrorDetails";

const ScreenerBacktest = (props) => {
  return (
    <>
      {props.record.error ?
        <ErrorDetails value={props.record.error} /> : null
      }
      <ScreenerParameters {...props} />
      <DayResults {...props} />
    </>
  );
};

export default ScreenerBacktest;
