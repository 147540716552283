import React, { cloneElement } from "react";
import { useSelector } from "react-redux";
import {getResources, MenuItemLink, Sidebar, usePermissions, useTranslate} from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import clsx from "clsx";

import NeedHelp from "../NeedHelp/NeedHelp";
import { getLinkClassName } from "./utils";
import { setGaId } from "../../shared/utils";
import { GA_CATEGORIES } from "../../shared/variables";
import style from "./SideBar.module.css";

// prevents titleAccess passing to the svg DOM element
const IconWrapper = ({ titleAccess, children, ...props }) => cloneElement(children, props);


const ReadOnlyMenuItem = ({ resource = {}, className}) => {

    const { name, icon, options } = resource;
    const {
        admin: { resources, ui: { sidebarOpen } = {} } = {},
        router: { location: { pathname } = {} } = {},
    } = useSelector((state) => state);

    return sidebarOpen ?  (
        <div className={style.menuItemReadOnly}>
            <div className={style.menuItem}>
            {/*    <IconWrapper>*/}
            {/*        <resource.icon />*/}
            {/*    </IconWrapper>*/}
            </div>
            <span>{options?.label}:</span>
        </div>
    ) : null;
}

const MenuItem = ({ resource = {}, className}) => {
  const t = useTranslate();
  const {
    admin: { resources, ui: { sidebarOpen } = {} } = {},
    router: { location: { pathname } = {} } = {},
  } = useSelector((state) => state);
  const { name, icon, options } = resource;
  const activeClassName = getLinkClassName({ resources, pathname, name });
  const leftIcon = icon ? (
      <IconWrapper>
        <resource.icon />
      </IconWrapper>
  ) : (
      <DefaultIcon />
  );

  return (
      <MenuItemLink
          activeClassName={activeClassName}
          className={clsx(
              (name?.split("/")[1] ? name?.split("/")[1] : name?.split("/")[0]) + "__menu",
              className,
              activeClassName
          )}
          key={name}
          id={setGaId(GA_CATEGORIES.SIDEBAR, name)}
          to={`/${name}`}
          primaryText={t(options?.label || name)}
          leftIcon={leftIcon}
          sidebarIsOpen={sidebarOpen}
      />
  );
}

const Menu = () => {
  //here we already filter out the items without "label"
  const menuItems = useSelector(getResources).filter(resource => resource.options && resource.options.label);
  const [...otherMenuItems] = menuItems;
  const {
    admin: { resources, ui: { sidebarOpen } = {} } = {},
    router: { location: { pathname } = {} } = {},
  } = useSelector((state) => state);

  return (
    <div className={style.menu}>
      {/*<MenuItem resource={dashboard} className={sidebarOpen && resource.options.topLevel ? style.menuItemReadOnly : null}/>*/}

      <div className="algolab_menu">
          {otherMenuItems.map((resource, index) => {
            const parent = resource.options.parent || false;
            if(parent) {
                return <ReadOnlyMenuItem key={`${index}-${resource}`} resource={resource} className={style.menuItem}/>
            }
            return <MenuItem key={`${index}-${resource}`} resource={resource} className={sidebarOpen && resource.options.topLevel ? style.menuItemReadOnly : style.menuItem} />
          })}
      </div>
    </div>
  );
};

const MySidebar = (props) => {
  const { sidebarOpen } = useSelector((state) => state.admin.ui);
  const { permissions } = usePermissions();
  const hasPermissions = permissions && JSON.parse(permissions)?.length;

  if (!hasPermissions) {
    return null;
  } else {
    return (
      <Sidebar {...props} className={!sidebarOpen ? "Sidebar-Close" : ""}>
        <>
          <Menu />
          <NeedHelp />
        </>
      </Sidebar>
    );
  }
};

export default MySidebar;
