import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-min-noconflict/ext-language_tools";

import "./BreakingEquityMode";
import {
  getIndicatorName,
  singleIndicator,
  parentIndicator,
  getParamsStr,
  matchingIndicators,
  needAutoCloseBrackets,
  getCurrentPosValue,
} from "./utils";
import { isArray } from "lodash";

function indicatorAutocompleteValue(indicator) {
  let value = indicator.name;
  let mindatas = "";

  //if mindatas > 1 add new line automatically
  if (indicator.mindatas && indicator.mindatas > 1) {
    mindatas = mindatas + "\n";
    for (let i = 1; i <= indicator.mindatas; i++) {
      mindatas = mindatas + `    #series ${i} goes here` + (i < indicator.mindatas ? "," : "") + "\n";
    }
    value = `${value}(${mindatas})`;
  }

  if (indicator.series?.length) {
    value = `${value}.${indicator.series.length === 1 ? indicator.series[0] : ""}`;
  }

  return value;
}

function autoCompleteSeries(indicator, callback) {
  callback(
    null,
    indicator.series.map(function (series) {
      return {
        caption: series,
        value: series,
        meta: "series",
      };
    })
  );
}

function autoCompleteParams(paramsStr, indicator, callback) {
  if (indicator) {
    let params = paramsStr ? indicator.params.filter((param) => !paramsStr.includes(param.name)) : indicator.params;
    if (!params.length && indicator.params) {
      params = indicator.params;
    }
    callback(
      null,
      params.map(function (param) {
        return {
          caption: param.name,
          value: param.name + "=" + param.default_value,
          meta: "default: " + param.default_value,
        };
      })
    );
  }
}

//some string functions
function endsWithAny(suffixes, string) {
  return suffixes.some(function (suffix) {
    return string.endsWith(suffix);
  });
}

function _indicesOf(searchStr, str, caseSensitive) {
  let searchStrLen = searchStr.length;
  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0,
    index,
    indices = [];
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
}

function closesIndexOf(searchStr, str, position) {
  const indexes = _indicesOf(searchStr, str);
  let result = -1;

  if (indexes) {
    result = indexes[0];
    indexes.forEach((index) => {
      if (Math.abs(index - position) < Math.abs(result - position)) {
        result = index;
      }
    });
  }

  return result;
}

function getAutoComplete(indicators, editor, callback) {
  return callback(
    null,
    indicators
      .filter((indicator) => !editor.$autocompleteBlacklist.includes(indicator.name))
      .map(function (indicator) {
        return {
          caption: indicator.name,
          value: indicatorAutocompleteValue(indicator),
          meta: indicator.meta,
          completer: {
            insertMatch: function (editor, data) {
              editor.completer.insertMatch({ value: data.value });
              if (data.value.endsWith(".")) {
                //showing list of series if there is more than 1
                editor.execCommand("startAutocomplete");
              }
            },
          },
        };
      })
  );
}

export const BreakingEquityCompleter = {
  getCompletions: function (editor, session, pos, prefix, callback) {
    let currentLine = editor.getValue().split("\n")[pos.row];
    let currentPosValue = getCurrentPosValue(editor.getValue(), pos);

    const noClue =
      editor.getValue()[pos.column - 1] === " " &&
      (editor.getValue().substr(pos.column, 3) === "and" || editor.getValue().substr(pos.column, 2) === "or");

    //MVP1-2204: skipp suggestions all together if it's within the comment
    if ((currentLine.indexOf("#") >= 0 && currentLine.indexOf("#") < pos.column) || noClue) {
      return;
    }

    if (currentPosValue) {
      const currentPosValueSplit = currentPosValue.split(/ and(?!\.)| or(?!\.)|<=|>=|>|<| = |-|\+|\/|\*/);
      const lastParentValue = currentPosValueSplit.slice(-1)[0].trim();
      const $indicatorName = getIndicatorName(lastParentValue, currentPosValueSplit);

      if (currentPosValue.endsWith("$") || $indicatorName) {
        if (currentPosValue.endsWith("$")) {
          let indicators = matchingIndicators("$");
          if (currentPosValue.endsWith("$")) {
            getAutoComplete(indicators, editor, callback);
          }
        }

        let indicators = matchingIndicators($indicatorName);
        const indicator = singleIndicator($indicatorName, indicators);

        if (indicator) {
          // auto-closing "(" with ")" and suggesting params
          const extendedLineValue = editor
            .getValue()
            .split("\n")
            [pos.row].substring(0, pos.column + 1);

          if (needAutoCloseBrackets(currentPosValue, extendedLineValue, editor.getValue())) {
            editor.session.insert(editor.getCursorPosition(), ")");
            editor.moveCursorTo(pos.row, pos.column);
            //update line value from the session
            currentLine = editor.session.getValue().split("\n")[pos.row];
          }

          if (currentPosValue.endsWith("[") && !extendedLineValue.endsWith("]")) {
            editor.session.insert(editor.getCursorPosition(), "]");
            editor.moveCursorTo(pos.row, pos.column);
            return;
          }
          //auto-completing methods/series of the indicator
          if (currentPosValue.endsWith(".")) {
            autoCompleteSeries(indicator, callback);
            return;
          }

          //current position between ( and ) -> show params
          if (
            closesIndexOf(")", currentLine, pos.column) > pos.column - 1 &&
            closesIndexOf("(", currentLine, pos.column) <= pos.column - 1 //<= is on purpose - that's how ace calculates the position
          ) {
            const params = getParamsStr(currentLine);
            const parent = parentIndicator(lastParentValue);
            if (parent) {
              let neddedParent = parent?.aliases?.filter((el) => currentLine.includes(el));
              if (neddedParent && isArray(neddedParent)) neddedParent = neddedParent[0];
              if (neddedParent && currentLine[currentLine.indexOf(neddedParent) + neddedParent?.length] !== "(") {
                return autoCompleteParams(params, "", callback);
              }
            }
            autoCompleteParams(params, parent, callback);
            return;
          }
        }

        if (currentPosValue.endsWith($indicatorName)) {
          //when auto-completing indicators we do not need to show it's name in params
          getAutoComplete(indicators, editor, callback);
        }
      }
    }

    //adding and/or | TODO: would be best to do via regexp
    const trimLine = currentPosValue.trim();
    if (
      !endsWithAny([" and", " or"], trimLine) &&
      trimLine.match(/.*?([a-zA-Z0-9]|\])$/) &&
      trimLine !== "or" &&
      trimLine !== "and" &&
      trimLine !== ""
    ) {
      callback(
        null,
        ["and", "or"].map(function (snippet) {
          return {
            caption: snippet,
            value: snippet,
            meta: "snippet",
            completer: {
              insertMatch: function (editor, data) {
                const pos = editor.session.curOp.selectionBefore.end;
                const line = editor.getValue().split("\n")[pos.row];
                const posValue = line.substring(0, pos.column);

                editor.completer.insertMatch({
                  value: posValue.endsWith(" ") ? data.value : " " + data.value,
                });
              },
            },
          };
        })
      );
    }
  },
};

export function onLoad(editor) {
  editor.renderer.setScrollMargin(0, 16, 0, 16);
  // const customMode = new CustomBEMode();
  editor.commands.on("afterExec", function (e, t) {
    //MVP1-1134: Cannot delete bracket
    const pos = e.editor.session.curOp.selectionAfter.end;
    const currentLine = e.editor.getValue().split("\n")[pos.row];
    const currentPosValue = currentLine.substring(0, pos.column);

    if (e.command.name === "backspace" && (currentPosValue.endsWith("(") || currentPosValue.endsWith("["))) {
      return;
    }

    if (
      e.command.name === "del" ||
      e.command.name === "backspace" ||
      (e.command.name === "insertstring" &&
        (e.args === "$" || e.args === "." || e.args === "," || e.args === "(" || e.args === " " || e.args === "["))
    ) {
      e.editor.execCommand("startAutocomplete");
    }
  });
}
