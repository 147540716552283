import React, { useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import classes from "./CreateFromASample.module.css";
import { useRedirect, useTranslate } from "react-admin";
import DisableCreatingTooltip from "../DisableCreatingTooltip/DisableCreatingTooltip";
import { isDesktop, isMobile } from "react-device-detect";
import MobileButton from "./MobileButton/MobileButton";
import DesktopButtonGroup from "./DesktopButtonGroup/DesktopButtonGroup";
import MobileDialog from "./MobileDialog/MobileDialog";
import DesktopPopper from "./DesktopPopper/DesktopPopper";

const CreateFromASample = (props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const redirect = useRedirect();
  const t = useTranslate();
  const {
    label,
    samples,
    basePath,
    handleCreateFromASample,
    noResults,
    entity,
    disabled,
    ga,
  } = props;

  const handleCreate = () => redirect(basePath + "/create");

  const handleToggle = () => {
    window.scrollTo({ top: 0 });
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const onClickMenuItem = (sample) => () => {
    handleCreateFromASample(sample);
    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <DisableCreatingTooltip disabled={disabled} entity={entity}>
          {isMobile && (
            <MobileButton
              className={classes.button}
              onClickFn={handleToggle}
              title={t("algos.algo")}
              disabled={disabled}
            />
          )}
          {isDesktop && (
            <DesktopButtonGroup
              title={noResults ? t("create_from_a_sample.label") : null}
              anchorRef={anchorRef}
              noResults={noResults}
              ga={ga}
              basePath={basePath}
              label={label}
              onClickCreate={handleCreate}
              onClickToggle={handleToggle}
              isOpen={open}
              disabled={disabled}
            >
              {props.children}
            </DesktopButtonGroup>
          )}
        </DisableCreatingTooltip>

        {isMobile && (
          <MobileDialog
            isOpen={open}
            ga={ga}
            samples={samples}
            onClickMenuItem={onClickMenuItem}
          />
        )}

        {isDesktop && (
          <DesktopPopper
            ga={ga}
            isOpen={open}
            anchorRef={anchorRef}
            onClickClose={handleClose}
            samples={samples}
            noResults={noResults}
            onClickMenuItem={onClickMenuItem}
            basePath={basePath}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CreateFromASample;
