import React, { useEffect, useState, useRef } from "react";
import styles from "./EditScreener.module.css";
import {
  Edit,
  SimpleForm,
  useTranslate,
  useNotify,
  useRedirect,
  Create,
  useDataProvider,
  required,
  TextInput,
} from "react-admin";
import { IntervalsBlock } from "./components/IntervalsBlock/IntervalsBlock";
import Toolbar from "./components/Toolbar/Toolbar";
import StockInput from "../../components/StockInput/StockInput";
import FullScreenEditor from "../../components/FullScreenEditor/FullScreenEditor";
import { GET_SCREENERS } from "../../store/sagas/screenersSaga";
import { GET_SCREENERS_ALL } from "../../store/sagas/screenersAllSaga";
import { useDispatch, useSelector } from "react-redux";
import { BacktestParameters } from "./components/BacktestParameters/BacktestParameters";
import { BacktestList } from "../ScreenerBacktest/Backtest";
import ScreenerParameters from "../ScreenerBacktest/components/ScreenerParameters/ScreenerParameters";
import Alert from "../../components/Alert/Alert";
import { authFetchAPI } from "../../DataProvider";
import { CUTOFF_VALUES, ENTITY, GA_CATEGORIES, LOCALSTORAGE, RATIO_LIMITS } from "../../shared/variables";
import {
  formatLimitValue,
  getLimitRatio,
  customRequired,
  setGaId,
  customMaxLength,
  getStartDate,
  getEndDate,
} from "../../shared/utils";
import HelpTooltip from "../../components/HelpTooltip/HelpTooltip";
import { getPlanState, getUsageState } from "../../store/selectors";
import { Storage } from "../../config";
import WarnWhenUnsavedChanges from "../../components/WarnWhenUnsavedChanges/WarnWhenUnsavedChanges";
import LimitAlert from "../../components/Alert/alerts/LimitAlert";
import { GET_USAGE } from "../../store/sagas/usageSaga";
import isEqual from "lodash/isEqual";
import CardHeader from "../../components/Card/CardHeader";
import { CriteriaBlock } from "../../components/CriteriaBlock/CriteriaBlock";
import fromEntries from "object.fromentries";
import FormHelper from "../../components/FormHelper";
import { ALERT } from "../../components/Alert/variables";
import MobileVersionAlert from "../../components/Alert/alerts/MobileVersionAlert";
import { isDesktop, isMobile } from "react-device-detect";
import ScreenerMobile from "./components/ScreenerMobile/ScreenerMobile";
import SharedScreenerAlert from "../../components/Alert/alerts/SharedScreenerAlert";
import { MarkdownInput } from "../../components/Markdown/MarkdownInput";
import { RESET_RERUN_BACKTEST } from "../../store/reducers/rerunBacktestReducer";

export const TRACKABLE_VALUES = ["start_date", "end_date"];

const alert = {
  type: ALERT.BRAND,
  data: [
    {
      header: "helpers.help_analasyng_screeners",
      description: ["helpers.screener_doc_1", "helpers.screener_doc_2"],
    },
  ],
  action: {
    label: "helpers.screener_user_guide",
    id: "user-guide",
    onClick: () =>
      window.open(
        "https://breakingequity.atlassian.net/wiki/spaces/BED/pages/623509533/Using+Screeners+Howto",
        "_blank"
      ),
  },
};

const ScreenerForm = (props) => {
  const t = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const usage = useSelector(getUsageState) || {};
  const { screeners, backtests: backtests_usage, status } = usage;
  const { screeners_limit, backtests_limit } = useSelector(getPlanState) || {};
  const rerunBacktest = useSelector((state) => state.rerunBacktest);

  const [openAlert, setOpenAlert] = useState(null);
  const [backtestsLoading, setBacktestsLoading] = useState(false);
  const [backtests, setBacktests] = useState({});
  const [limitAlerts, setLimitAlerts] = useState();
  const [checkingUnsavedChanges, checkUnsavedChanges] = useState(true);
  const [initialValues, setInitialValues] = useState(props.record);
  const [isLoadingRecent, setLoadingRecent] = useState(false);

  const formRef = useRef(null);
  const { record, ...rest } = props;
  const ga = { category: GA_CATEGORIES.SCREENER_EDIT };

  const getBacktestsLocal = () =>
    dataProvider
      .getList("screener-backtests", {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: { field: "created_date", order: "DESC" },
        filter: {
          status: ["PENDING", "COMPLETED", "IN_PROGRESS", "FAILED", "DRAFT"],
          screener: record.id || record.id_for_getting_backtests,
        },
      })
      .then((res) => {
        return res.data?.length ? res.data[0] : {};
      })
      .catch((error) => console.error(error));

  const showAlert = () => {
    let backtestsLimitAlert;

    const limitRatio = getLimitRatio(backtests_usage, backtests_limit, status);
    if (limitRatio !== RATIO_LIMITS.A_LOT) {
      const leftLimits = formatLimitValue(backtests_limit) - backtests_usage;
      backtestsLimitAlert = {
        ratio: limitRatio,
        entity: ENTITY.BACKTESTS,
        limits: leftLimits,
      };
    }

    if (record?.id) {
      setLimitAlerts([backtestsLimitAlert]);
    } else {
      const alerts = [];
      const limitScreeners = getLimitRatio(screeners, screeners_limit, status);

      if (limitScreeners !== RATIO_LIMITS.A_LOT) {
        const leftLimits = formatLimitValue(screeners_limit) - screeners;
        alerts.push({
          ratio: limitScreeners,
          entity: ENTITY.SCREENERS,
          limits: leftLimits,
        });
      }

      if (backtestsLimitAlert) alerts.push(backtestsLimitAlert);
      alerts.length && setLimitAlerts(alerts);
    }
  };

  useEffect(() => {
    if (window.location.href.includes("create?source")) {
      notify(t("screeners.clone"), "info");
    }
    return () => dispatch({ type: RESET_RERUN_BACKTEST });
  }, []);

  // restore missing backtest values
  useEffect(() => {
    if (!record || !(record.id || record.id_for_getting_backtests)) return;
    if (record.backtests_in_progress > 0) {
      setBacktestsLoading(true);
    }
    if (!TRACKABLE_VALUES.every((value) => record[value])) {
      const backtestsNew = localStorage.getItem(LOCALSTORAGE.SCREENER_BACKTESTS_NEW);
      if (backtestsNew) {
        setBacktestsLoading(true);
        try {
          const parsedBacktestsNew = JSON.parse(backtestsNew);
          setBacktests(fromEntries(TRACKABLE_VALUES.map((value) => [value, parsedBacktestsNew[value] || []])));
          localStorage.removeItem(LOCALSTORAGE.SCREENER_BACKTESTS_NEW);
        } catch (e) {
          console.error(e);
        }
      } else {
        getBacktestsLocal().then((backtestsLocal) => {
          setBacktests(fromEntries(TRACKABLE_VALUES.map((value) => [value, backtestsLocal?.[value] || []])));
        });
      }
    }
  }, [record]);

  useEffect(() => {
    showAlert();
  }, [backtests_usage, backtests_limit, screeners, screeners_limit]);

  const handleSave = async ({ values, redirectOnSave = false, status = "DRAFT" }) => {
    let screenerId = null;
    const cutoff_sorting_order = values.cutoff_method.split("-")[1];
    const cutoff_method = values.cutoff_method.split("-")[0];
    values = { ...values, cutoff_sorting_order, cutoff_method };
    try {
      if (!record?.id) {
        // create
        const { data: { id } = {} } = await dataProvider.create("screeners", {
          data: { ...values, status },
        });
        dispatch({ type: GET_USAGE });
        checkUnsavedChanges(false);
        redirectOnSave && redirect("edit", "/screeners", id, values);
        screenerId = id;
        notify(t("screeners.on_save"), "info");
      } else {
        //update
        const changedFields = [];
        for (const key in values) {
          if (
            !["screenerInput", "updated_date", "created_date", "status"].includes(key) &&
            !isEqual(values[key], initialValues[key])
          ) {
            changedFields.push(key);
          }
        }
        // we should not update if the form is pristine
        if (changedFields.length || status === "LIVE") {
          const valuesToSend =
            changedFields.filter((field) => field !== "name" && field !== "description")?.length || status === "LIVE"
              ? { ...values, status }
              : values;
          await dataProvider.update("screeners", {
            id: record.id,
            data: valuesToSend,
          });
          notify(t("screeners.on_save"), "info");
          setInitialValues(values);
        }
        screenerId = record.id;
      }
      return screenerId;
    } catch (error) {
      notify(error.message ? `Error: ${error.message}` : "ra.notification.http_error", "warning");
    }
  };

  const handleValidateSaveAndLaunch = async (values) => {
    const screenerId = await handleSave({
      values,
      redirectOnSave: false,
      status: "LIVE",
    });

    const backtestsValues = fromEntries(TRACKABLE_VALUES.map((key) => [key, record[key]]));
    localStorage.setItem("screenersBacktestsRecent", JSON.stringify(backtestsValues));

    try {
      const res = await authFetchAPI("screener-backtests/launch", {
        method: "POST",
        body: JSON.stringify({
          screener: { id: screenerId },
        }),
      });
      if (!res.ok) throw new Error();
      notify(t("backtest.successfully_launched"), "info");
      // workaround to start backtests polling if we are on the edit page
      if (record.id) setBacktestsLoading(true);
      dispatch({ type: GET_USAGE });
      !values?.id && redirect("edit", "/screeners", screenerId, values);
    } catch (e) {
      notify(t("common.cant_save"), "warning");
      console.error(e);
    }
  };

  const loadRecent = async () => {
    setLoadingRecent(true);
    dataProvider
      .getList("screeners", {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: { field: "updated_date", order: "DESC" },
        filter: {
          status: ["DRAFT", "LIVE"],
        },
      })
      .then(({ data }) => {
        formRef.current.batch(() => {
          TRACKABLE_VALUES.forEach((key) => {
            formRef.current.change(key, data?.[0]?.[key]);
          });
        });
        setLoadingRecent(false);
      })
      .catch((e) => {
        console.error(e);
        setLoadingRecent(false);
      });
  };

  const isReadOnly = props?.record?.owner && props.record.owner.id !== Storage.getItem("profile");
  const cutoffMethod = (source = {}) =>
    source.cutoff_method && source.cutoff_sorting_order
      ? `${source.cutoff_method}-${source.cutoff_sorting_order}`
      : CUTOFF_VALUES.DAILY_VOLUME_DESC;

  return (
    <>
      <MobileVersionAlert />
      {props?.record?.shared && <SharedScreenerAlert {...props} />}
      {limitAlerts?.map((item, index) => (
        <LimitAlert key={index} {...item} />
      ))}
      <SimpleForm
        submitOnEnter={false}
        initialValues={{
          filter_criteria_interval: 1,
          sort_criteria_interval: 60,
          sorting_order: "DESC",
          filter_criteria: "",
          sort_criteria: "",
          start_date: getStartDate(),
          end_date: getEndDate(),
        }}
        record={
          rerunBacktest?.screener?.id && rerunBacktest?.screener?.id === record?.id
            ? { ...rerunBacktest, cutoff_method: cutoffMethod(rerunBacktest) }
            : { ...record, ...backtests, cutoff_method: cutoffMethod(record), cutoff_limit: record.cutoff_limit || 1 }
        }
        {...rest}
        save={handleValidateSaveAndLaunch}
        toolbar={
          !isReadOnly &&
          !props?.record?.shared && (
            <Toolbar
              setBacktests={setBacktests}
              handleSave={handleSave}
              ga={ga}
              loadRecent={loadRecent}
              isLoadingRecent={isLoadingRecent}
            />
          )
        }
      >
        <FormHelper ref={formRef} />
        {(isReadOnly || props?.record?.shared) && <ScreenerParameters {...props} />}
        {!isReadOnly && !props?.record?.shared && isMobile && <ScreenerMobile ga={ga} {...props} />}
        {!isReadOnly && !props?.record?.shared && isDesktop && (
          <>
            {openAlert && <Alert ga={ga} data={alert} setOpenAlert={setOpenAlert} />}
            {checkingUnsavedChanges && <WarnWhenUnsavedChanges />}
            <CardHeader>
              {t("screeners.blocks.screener_params")}
              <HelpTooltip ga={ga} title={t("helpers.algo_parameters")} setOpenAlert={() => setOpenAlert(true)} />
            </CardHeader>
            <div className={styles.inputs_container}>
              <TextInput
                id={setGaId(ga?.category, "name")}
                variant="outlined"
                source="name"
                fullWidth
                className={styles.nameInput}
                validate={[customRequired(t("name.validate.required")), customMaxLength(1000, t)]}
              />

              <MarkdownInput
                id={setGaId(ga?.category, "description")}
                variant="outlined"
                multiline
                label={t("fieldsName.description")}
                source="description"
                fullWidth
                validate={customMaxLength(1000, t)}
              />

              <StockInput
                {...props}
                ga={ga}
                variant="outlined"
                name="screenerInput"
                label={t("screeners.tickers_indexes")}
                sources={["indexes", "tickers"]}
                options={["indexes", "tickers"]}
                pageName="screener"
                fullWidth
                translateChoice={false}
                className={styles.stockInput}
                validate={[required()]}
                matchFrom="start"
              />
            </div>

            <FullScreenEditor
              ga={{ category: GA_CATEGORIES.SCREENER_FULLSCREEN }}
              criteriaInputs={<CriteriaBlock {...props} />}
              additionalBlock={<IntervalsBlock variant={"outlined"} />}
              handleSave={handleSave}
              entity={record?.id ? null : ENTITY.SCREENER}
            />

            <CriteriaBlock {...props} ga={ga} />
            <IntervalsBlock variant={"outlined"} ga={ga} />
            <BacktestParameters ga={ga} {...props} />
          </>
        )}
      </SimpleForm>
      {!props?.record?.shared && (
        <BacktestList
          {...props}
          screener={props.record}
          backtestsLoading={backtestsLoading}
          setBacktestsLoading={setBacktestsLoading}
        />
      )}
    </>
  );
};

export const ScreenerCreate = (props) => {
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_SCREENERS });
    dispatch({ type: GET_SCREENERS_ALL });
  }, []);

  const onSuccess = () => {
    notify(t("screeners.on_save"));
    redirect("/screeners", props.basePath);
  };

  return (
    <Create {...props} record={{ ...props.record, name: "New Screener" }} className={styles.edit} onSuccess={onSuccess}>
      <ScreenerForm />
    </Create>
  );
};

export const ScreenerEdit = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_SCREENERS });
    dispatch({ type: GET_SCREENERS_ALL });
  }, []);

  return (
    <Edit {...props} className={styles.edit} undoable={false}>
      <ScreenerForm />
    </Edit>
  );
};
