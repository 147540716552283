import React from "react";
import GridShowLayout from "../../../Layout/GridShowLayout";
import { Grid } from "@material-ui/core";
import {
  TextField,
  required,
  TextInput,
  email,
  DateInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  BooleanInput,
  SimpleForm,
  Edit,
  useTranslate,
  Toolbar,
  SaveButton,
  ListButton,
} from "react-admin";
import MaskedInput from "../../../../components/MaskedInput";
import { TimeZones } from "../../../../locales/timezones";
import { MarkdownInput } from "../../../../components/Markdown/MarkdownInput";
import styles from "./Profile.module.css";
import { customTwitterChecker, customUrlChecker } from "../../../../shared/utils";

const UserEditToolbar = (props) => {
  const t = useTranslate();
  return (
    <Toolbar className="User-Toolbar" {...props}>
      <SaveButton />
      <ListButton label={t("action.cancel")} className="Cancel-Button" icon={false} />
    </Toolbar>
  );
};

const Profile = (props) => {
  const t = useTranslate();
  return (
    <Edit className="User-Edit" {...props}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<UserEditToolbar />}>
        <GridShowLayout className="Width-100p">
          <Grid container xs={12} spacing={2}>
            <Grid item xs={4}>
              <TextField source="id" />

              <TextInput source="email" validate={[required(), email()]} variant="outlined" className="Width-100p" />
              <TextInput source="name" validate={required()} variant="outlined" className="Width-100p" />
              <div>
                <MarkdownInput
                  variant="outlined"
                  multiline
                  label={t("authorization.bio")}
                  source="bio"
                  fullWidth
                  className={styles.bio}
                />
              </div>
              <TextInput source="website" validate={customUrlChecker(t)} variant="outlined" className="Width-100p" />
              <TextInput
                source="twitter"
                validate={customTwitterChecker(t)}
                variant="outlined"
                className="Width-100p"
              />
              <MaskedInput
                mask="(999)-999-9999"
                source="phone"
                validate={[required()]}
                label={t("authorization.phone")}
                variant="outlined"
                className="Width-100p"
              />
              <DateInput source="date_of_birth" variant="outlined" className="Width-100p" />

              <ReferenceArrayInput source="role_ids" reference="roles" variant="outlined" className="Width-100p">
                <SelectArrayInput optionText="name" label={t("authorization.roles")} source="name" />
              </ReferenceArrayInput>
              <SelectInput
                source="zone_id"
                label={t("authorization.timezone")}
                optionText="text"
                optionValue="abbr"
                choices={TimeZones}
                variant="outlined"
                className="Width-100p"
              />
              <BooleanInput source="enabled" />
            </Grid>
          </Grid>
        </GridShowLayout>
      </SimpleForm>
    </Edit>
  );
};

export default Profile;
