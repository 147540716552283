import React from "react";
import { useTranslate } from "react-admin";
import { statuses } from "../../shared/statuses";
import { colors } from "../../shared/cssVariables";
import styles from "./Status.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import PlayCircleIcon from "react-feather/dist/icons/play-circle";
import StopCircleIcon from "react-feather/dist/icons/stop-circle";
import WarningIcon from "react-feather/dist/icons/alert-circle";
import EditIcon from "react-feather/dist/icons/edit";
import ArchiveIcon from "react-feather/dist/icons/archive";
import DoneIcon from "react-feather/dist/icons/check-circle";
import ShareIcon from "react-feather/dist/icons/share-2";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";

const LongerThanUsualCircularProgress = ({ condition, size, tooltipTitle }) => (
  <div className={styles.statusIcon}>
    <Tooltip title={tooltipTitle || ""}>
      <CircularProgress
        style={{ width: size, height: size }}
        className={clsx({ [styles.longerThanUsual]: condition })}
      />
    </Tooltip>
  </div>
);

const StatusField = ({ record, source }) => {
  const t = useTranslate();
  const size = 18;

  if (record?.[source] === statuses.inProgress.id || record?.[source] === statuses.pending.id) {
    const { reconciliation } = record;
    return (
      <LongerThanUsualCircularProgress
        condition={reconciliation}
        size={size}
        tooltipTitle={
          reconciliation
            ? t("backtest.reconciliation")
            : record?.[source] === statuses.inProgress.id
            ? statuses.inProgress.name
            : statuses.pending.name
        }
      />
    );
  }

  if (record?.[source] === statuses.exiting.id) {
    const { exit_estimation } = record;
    return (
      <LongerThanUsualCircularProgress
        condition={exit_estimation}
        size={size}
        tooltipTitle={
          exit_estimation
            ? t("tradings.exit_estimation.message", { estimate: t(`tradings.exit_estimation.${exit_estimation}`) })
            : statuses.exiting.name
        }
      />
    );
  }

  const statusesData = {
    [statuses.failed.id]: {
      tooltipTitle: record?.[source] + " : " + record?.error_summary?.split("\\n").join("\n"),
      icon: WarningIcon,
      color: colors.red,
    },
    [statuses.completed.id]: {
      tooltipTitle: record?.[source],
      icon: DoneIcon,
      color: colors.green,
    },
    [statuses.draft.id]: {
      tooltipTitle: record?.[source],
      icon: EditIcon,
      color: colors.gray_icon,
    },
    [statuses.live.id]: {
      tooltipTitle: record?.[source],
      icon: EditIcon,
      color: colors.gray_icon,
    },
    [statuses.active.id]: {
      tooltipTitle: record?.[source],
      icon: PlayCircleIcon,
      color: colors.green,
    },
    [statuses.archived.id]: {
      tooltipTitle: record?.[source],
      icon: ArchiveIcon,
      color: colors.gray_icon,
    },
    [statuses.stopped.id]: {
      tooltipTitle: record?.[source],
      icon: StopCircleIcon,
      color: colors.gray_icon,
    },
  };

  if (!statusesData[record?.[source]]) {
    return record?.[source] || null;
  }

  const statusData = statusesData[record[source]];

  return (
    <div className={styles.statusIcon}>
      <Tooltip title={statusData.tooltipTitle}>
        <statusData.icon color={statusData.color} size={size} />
      </Tooltip>
      {record?.shared && (
        <Tooltip title={t("backtest.shared")}>
          <ShareIcon color={statusData.color} size={size} />
        </Tooltip>
      )}
    </div>
  );
};

export default StatusField;
