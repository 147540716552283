import React from "react";
import styles from "./Initial.module.css";
import AlgoType from "../../../../components/AlgoType";
import { TextInput, useTranslate } from "react-admin";
import ToggleType from "../ToggleType/ToggleType";
import { useFormState } from "react-final-form";
import CollapseBlock from "../../../../components/CollapseBlock/CollapseBlock";
import { customMaxLength, customRequired, setGaId } from "../../../../shared/utils";
import Label from "../../../../components/Rows/Label";
import NarrowRow from "../../../../components/Rows/NarrowRow";
import clsx from "clsx";
import { MarkdownInput } from "../../../../components/Markdown/MarkdownInput";
import { Storage } from "../../../../config";

export const InitialForm = ({ ga, record }) => {
  const t = useTranslate();

  const roles = Storage.getItem("roles");
  const isAdmin = roles?.includes("super_admin");

  return (
    <div className={styles.InitialForm}>
      <TextInput
        id={setGaId(ga?.category, "name")}
        label={t("fieldsName.name")}
        data-testid={"algo-input-name"}
        record={record}
        source="name"
        variant="outlined"
        validate={[customRequired(t("name.validate.required")), customMaxLength(1000, t)]}
        fullWidth
        className={styles.nameInput}
      />

      {isAdmin ?
        <TextInput
          id={setGaId(ga?.category, "tags")}
          label={t("fieldsName.tags")}
          data-testid={"algo-input-name"}
          record={record}
          source="tags"
          variant="outlined"
          validate={[customMaxLength(200, t)]}
          fullWidth
          className={styles.nameInput}
        />
        : null}

      <MarkdownInput
        id={setGaId(ga?.category, "description")}
        record={record}
        data-testid={"algo-input-description"}
        label={t("fieldsName.description")}
        defaultValue=""
        source="description"
        multiline
        validate={customMaxLength(1000, t)}
        className={styles.description}
      />
      <div className={styles.marginTop}>
        <ToggleType ga={ga} record={record} source="algo_type" />
      </div>
    </div>
  );
};

export const InitialLineWithValues = ({ className }) => {
  const { values } = useFormState();
  return <InitialLine values={values} className={className} />;
};

export const InitialLine = ({ values: value, ...props }) => (
  <NarrowRow className={clsx(styles.initialLineWrapper, props.className)}>
    <AlgoType algoType={value.algo_type} />
    <Label color="dark" className={styles.nameShort}>
      {value.algo?.name || value.name}
    </Label>
    <div className={styles.actionButton}>{props.children}</div>
  </NarrowRow>
);

export const InitialBlock = (props) => {
  const { values } = useFormState();
  return (
    <CollapseBlock
      id={setGaId(props.ga?.category, "name-toggle")}
      short={<InitialLine {...props} values={values} />}
      full={<InitialForm record={props.record} ga={props.ga} />}
      className={styles.container}
      openOnError={["name"]}
    />
  );
};
