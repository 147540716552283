import React from "react";
import styles from "./TickersTabItem.module.css";
import { formatNumberValue } from "../../../shared/utils";

const TickersTabItem = ({ item, maxValues, orderBy }) => {
  const format = (value) =>
    formatNumberValue(value, {
      style: "currency",
      currency: "USD",
    });

  return (
    <div className={styles.tickerItem}>
      <span className={styles.tickerName}>{item.ticker}</span>
      <span className={styles.winLoss}>
        {Number(item.win).toLocaleString('en-EN')}
        <span className={styles.valueGray}>{` / ${Number(item.loss).toLocaleString('en-EN')}`}</span>
      </span>
      <span className={styles.rewardRisk}>
        {format(item.reward)}
        <span className={styles.valueGray}>{` / ${format(item.risk)}`}</span>
      </span>
      <div className={styles.barBackground} />
      <div
        className={styles.bar}
        style={{ width: `${(item[orderBy] / maxValues[orderBy]) * 100}%` }}
      />
    </div>
  );
};

export default TickersTabItem;