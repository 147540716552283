import React from "react";
import { useTranslate } from "react-admin";
import SelectionMenu from "../../DataDrid/SelectionMenu";
import { setGaId } from "../../../shared/utils";
import { Storage } from "../../../config";

const QuickStatsMenu = ({ ga, storageKey, includeArchived, setIncludeArchived }) => {
  const t = useTranslate();
  const onItemClick = () => {
    setIncludeArchived(!includeArchived);
    Storage.setItem(storageKey, JSON.stringify(!includeArchived));
  };

  return (
    <SelectionMenu
      ga={setGaId(ga?.category, "quick-stats-selection-menu")}
      selection={{ includeArchived }}
      menuItems={[{ source: "includeArchived", label: t("tradings.quick_stats.include_archived") }]}
      onItemClick={onItemClick}
    />
  );
};

export default QuickStatsMenu;
