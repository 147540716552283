import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Reactour from "reactour";
import Button from "@material-ui/core/Button";
import { useNotify } from "react-admin";

import { TOUR_WELCOME, TOUR_WELCOME_FIRST } from "../../store/reducers/tourReducer";
import TourPopupLayout from "./components/TourPopupLayout";
import { customFetch } from "../../DataProvider";
import { handleAfterClose, handleAfterOpen, handleGetCurrentStep } from "./components/Functions";

import Translate from "./components/Translate";
import classes from "../Carousel/Carousel.module.css";
import style from "./TourWelcome.module.scss";

const steps = [
  {
    selector: "",
    content: (props) => {
      const { goTo } = props;
      return (
        <TourPopupLayout
          propsLayout={props}
          btnNext={
            <div className={style.popupTour__btns}>
              <Button className={style.btnGrey} onClick={() => props.close()} data-testid="tour/skip">
                <Translate path="tour.skip_tour" />
              </Button>
              <Button className={classes.exploreButton} onClick={() => goTo(1)} data-testid="tour/start">
                <Translate path="tour.start_tour" />
              </Button>
            </div>
          }
        >
          <h2>
            <Translate path="tourWelcome.step0.title" />
          </h2>
          <p data-testid="tour/description">
            <Translate path="tourWelcome.step0.text" />
          </p>
        </TourPopupLayout>
      );
    },
  },
  {
    selector: ".dashboard__menu",
    content: (props) => {
      window.scrollTo(0, 0);
      return (
        <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={2}>
          <h2>
            <Translate path="tourWelcome.step1.title" />
          </h2>
          <p data-testid="tour/description">
            <Translate path="tourWelcome.step1.text" />
          </p>
        </TourPopupLayout>
      );
    },
  },
  {
    selector: ".algolab_menu",
    content: (props) => (
      <TourPopupLayout propsLayout={props} btnNext="default" btnNextStep={3}>
        <h2>
          <Translate path="tourWelcome.step2.title" />
        </h2>
        <p data-testid="tour/description">
          <Translate path="tourWelcome.step2.text" />
        </p>
      </TourPopupLayout>
    ),
  },
  {
    selector: ".tryAlgo",
    content: (props) => {
      document.querySelector(".noclick").style.display = "none";
      return (
        <TourPopupLayout
          propsLayout={props}
          btnNext={
            <Button className={classes.exploreButton} onClick={() => props.close()} data-testid="tour/finish">
              <Translate path="tour.finish_tour" />
            </Button>
          }
        >
          <h2>
            <Translate path="tourWelcome.step3.title" />
          </h2>
          <p data-testid="tour/description">
            <Translate path="tourWelcome.step3.text" />
          </p>
        </TourPopupLayout>
      );
    },
  },
];

export const TourWelcome = () => {
  const { tourWelcome, tourWelcomeFirst } = useSelector((state) => state.tour);
  const { sidebarOpen } = useSelector((state) => state.admin.ui);
  const dispatch = useDispatch();
  const notify = useNotify();

  useEffect(() => {
    return () => {
      handleAfterClose(notify, dispatch);
      if (tourWelcomeFirst) {
        customFetch("profile", "PUT", { guided_tour_completed: true }).then((res) => {
          if (res?.guided_tour_completed) dispatch({ type: TOUR_WELCOME_FIRST, payload: false });
        });
      }
    };
  }, []);

  const handleRequestClose = () => dispatch({ type: TOUR_WELCOME, payload: false });

  return (
    <Reactour
      steps={steps}
      isOpen={tourWelcome}
      onRequestClose={handleRequestClose}
      rounded={5}
      className={style.reactour}
      startAt={0}
      getCurrentStep={(curr) => handleGetCurrentStep(curr, dispatch, sidebarOpen, 5)}
      onAfterOpen={handleAfterOpen}
      showCloseButton={false}
      showButtons={false}
      showNavigation={false}
    />
  );
};

export default TourWelcome;
