import React from "react";
import styles from "./ToggleOrderType.module.css";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { useInput, useTranslate } from "react-admin";
import clsx from "clsx";
import { Tooltip } from "@material-ui/core";
import Config from "../../../../config";
import { setGaId } from "../../../../shared/utils";
import { ORDER_TYPES } from "../../../../shared/variables";

const ToggleOrderType = (props) => {
  const {
    input: { onChange, value },
  } = useInput(props);
  const t = useTranslate();

  return (
    <ButtonGroup className={styles.buttonGroup}>
      <Tooltip title={t("helpers.limit_order")} enterDelay={Config.TOOLTIP_DELAY} enterNextDelay={Config.TOOLTIP_DELAY}>
        <Button
          id={setGaId(props.ga?.category, "limit-orders")}
          data-testid={"algo-type-limit"}
          onClick={() => onChange(ORDER_TYPES.LIMIT)}
          className={clsx(styles.button, value === ORDER_TYPES.LIMIT && styles.activeButton)}
        >
          {t("algos.limit_order")}
        </Button>
      </Tooltip>
      <Tooltip
        title={t("helpers.market_price")}
        enterDelay={Config.TOOLTIP_DELAY}
        enterNextDelay={Config.TOOLTIP_DELAY}
      >
        <Button
          id={setGaId(props.ga?.category, "market-orders")}
          data-testid={"algo-type-market"}
          onClick={() => onChange(ORDER_TYPES.MARKET)}
          className={clsx(styles.button, value === ORDER_TYPES.MARKET && styles.activeButton)}
        >
          {t("algos.market_price")}
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};

export default ToggleOrderType;
