import * as React from "react";
import {
  Card as MuiCard,
  CardContent,
  withStyles,
} from "@material-ui/core";
import { FilterList, FilterListItem } from "ra-ui-materialui";
import RolesIcon from "react-feather/dist/icons/user-check";
import EnabledIcon from "react-feather/dist/icons/check-square";
import classes from "./UserFilter.module.css";
import {
  Filter,
  Loading,
  SearchInput,
  useGetList,
  useTranslate,
} from "react-admin";

const UserFilter = (props) => {
  return (
    <Filter variant="standard" {...props}>
      <SearchInput source="q" variant="standard" alwaysOn />
    </Filter>
  );
};

const EnabledFilter = () => {
  const t = useTranslate();

  return (
    <FilterList label={t("users.enabled")} icon={<EnabledIcon />}>
      <FilterListItem label="Yes" value={{ enabled: true }} />
      <FilterListItem label="No" value={{ enabled: false }} />
    </FilterList>
  );
};

const RolesFilter = () => {
  const t = useTranslate();
  const { data, ids, loading, error } = useGetList(
    "roles",
    { page: 1, perPage: 100 },
    { field: "name", order: "DESC" }
  );
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <FilterList label={t("resources.users.roles")} icon={<RolesIcon />}>
      {ids.map((id) => (
        <FilterListItem label={data[id].name} value={{ roles: id }} />
      ))}
    </FilterList>
  );
};

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

export const UserFilterSidebar = () => (
  <div className={classes.leftPane}>
    <Card>
      <CardContent>
        <UserFilter />
        <EnabledFilter />
        <RolesFilter />
      </CardContent>
    </Card>
  </div>
);
