import React, { useEffect, useState } from "react";
import styles from "./Brokers.module.css";
import InfoIcon from "react-feather/dist/icons/info";
import { useTranslate } from "react-admin";
import { useForm, useFormState } from "react-final-form";
import SelectField from "../../../../components/SelectField/SelectField";
import { colors } from "../../../../shared/cssVariables";
import { isPaper } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { VALIDATE_BROKER } from "../../../../store/sagas/brokersSaga";
import { LINK_STATUSES } from "../../../../store/reducers/brokersReducer";
import useAuthAlpaca from "../../../Profile/components/Brokers/hooks/useAuthAlpaca";
import BrokerTerms from "../../../Profile/components/Brokers/renders/BrokerTerms/BrokerTerms";
import ErrorMessage from "../../../Profile/components/Brokers/renders/ErrorMessage/ErrorMessage";
import LinkButton from "../../../Profile/components/Brokers/controls/LinkButton/LinkButton";
import { customRequired, setGaId } from "../../../../shared/utils";
import { ToggleButtonsField } from "../../../../components/ToggleButtonsField/ToggleButtonsField";
import cn from "clsx";
import { BROKERS, ENGINES } from "../../../../shared/variables";
import { BrokerIcon } from "./BrokersBlock";
import useAuthTDAmeritrade from "../../../Profile/components/Brokers/hooks/useAuthTDAmeritrade";

const Brokers = (props) => {
  const { ga, record } = props;
  const t = useTranslate();
  const dispatch = useDispatch();
  const form = useForm();
  const {
    values: { broker = ENGINES.BE.id },
  } = useFormState();

  const [agreementShow, setAgreementShow] = useState({ alpaca: false, ameritrade: false });

  const authAlpaca = useAuthAlpaca(setAgreementShow);
  const authAmeritrade = useAuthTDAmeritrade(setAgreementShow);

  const linkStatuses = useSelector((store) => store.brokers.data);
  const isPaperTrading = isPaper(props);

  useEffect(() => {
    dispatch({ type: VALIDATE_BROKER, payload: { id: "alpaca" } });
    dispatch({ type: VALIDATE_BROKER, payload: { id: "ameritrade" } });
  }, []);

  useEffect(() => {
    if (
      linkStatuses?.ameritrade?.data?.length &&
      linkStatuses?.ameritrade?.status === LINK_STATUSES.ACCOUNT_NOT_LINKED
    ) {
      setAgreementShow({ ...agreementShow, ameritrade: true });
    }
  }, [linkStatuses]);

  const getInfoMessage = (condition, message) =>
    condition ? (
      <div className={styles.infoMessage}>
        <InfoIcon size={19} color={colors.gray_text} />
        <span className={styles.infoMessageText}>{t(message)}</span>
      </div>
    ) : null;

  const handleEngineSwitch = (event) => {
    const id = event.target.id;
    form.change("broker", id === ENGINES.BE.id ? id : ENGINES.CB.id);
  };

  return (
    <div className={styles.container}>
      <div className={cn({ [styles.switchEngineRow]: broker !== ENGINES.BE.id })}>
        {isPaperTrading && (
          <ToggleButtonsField
            ga={ga}
            onChange={handleEngineSwitch}
            defaultValue={broker}
            inputNames={[ENGINES.BE.id, ENGINES.CB.id]}
            inputLabels={[t(ENGINES.BE.name), t(ENGINES.CB.name)]}
            className={styles.brokersToggle}
          />
        )}
        {!isPaperTrading || (isPaperTrading && broker !== ENGINES.BE.id) ? (
          <>
            <div className={styles.selectFieldRow}>
              <SelectField
                id={setGaId(ga?.category, "broker")}
                record={record}
                source="broker"
                className={styles.brokerInput}
                validate={customRequired(t("common.required"))}
                options={BROKERS.map(({ id, name, icon, disabled }) => ({
                  label: (
                    <div className={styles.brokerOption}>
                      <BrokerIcon broker={{ name, icon }} />
                      <span className={styles.name}>
                        {id === "AMERITRADE" && isPaperTrading ? name + t("tradings.no_paper") : name}
                      </span>
                    </div>
                  ),
                  value: id,
                  disabled: isPaperTrading && id === "AMERITRADE" ? true : disabled,
                }))}
              />

              {getInfoMessage(
                !broker,
                isPaperTrading ? "tradings.select_paper_broker" : "tradings.select_money_broker"
              )}
              {broker &&
                (linkStatuses[broker.toLowerCase()]?.status === LINK_STATUSES.NOT_LINKED ||
                  linkStatuses[broker.toLowerCase()]?.status === LINK_STATUSES.ACCOUNT_NOT_LINKED ||
                  linkStatuses[broker.toLowerCase()]?.status === LINK_STATUSES.REVOKED) && (
                  <LinkButton
                    onClick={() => {
                      setAgreementShow({ ...agreementShow, [broker.toLowerCase()]: true });
                    }}
                    disabled={agreementShow[broker.toLowerCase()]}
                  />
                )}
            </div>
            <div className={styles.notifications}>
              <BrokerTerms
                brokerName={broker.toLowerCase()}
                brokerData={{
                  ...linkStatuses?.[broker.toLowerCase()],
                  shortName: broker === "AMERITRADE" ? "TDA" : "",
                }}
                isOpen={agreementShow[broker.toLowerCase()]}
                onAgree={broker === "ALPACA" ? authAlpaca : authAmeritrade}
                onCancel={() => {
                  setAgreementShow({ ...agreementShow, [broker.toLowerCase()]: false });
                  form.change("broker", broker);
                }}
              />
              <ErrorMessage
                isOpen={
                  broker && linkStatuses[broker.toLowerCase()]?.status === LINK_STATUSES.REVOKED && !agreementShow
                }
                status={linkStatuses[broker.toLowerCase()]?.status}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Brokers;
