import { DISABLE_CREATING_STATUSES, LOCALSTORAGE, RATIO_LIMITS } from "./variables";
import { UNLIMITED_USAGE } from "./permissions";
import { USER_SUBSCRIPTION_STATUSES } from "../store/sagas/usageSaga";
import moment from "moment-timezone";
import isNil from "lodash/isNil";
import { TimeZones } from "../locales/timezones";
import Config from "../config";
import store from "../store/store";
import { auth } from "../pages/Auth/firebaseConfig";

export const kFormatter = (num) => {
  const sign = Math.sign(num);
  const abs = Math.abs(num);
  if (abs >= 1000 && abs < 1000000) {
    return (sign * abs / 1000).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    }) + "K";
  } else if (abs >= 1000000) {
    return (sign * abs / 1000000).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    }) + "M";
  }
  return (sign * abs).toLocaleString("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 0 });
};

export const hasSearchParam = (search) => {
  const filterParam = new URLSearchParams(search).get("filter");
  const { q } = JSON.parse(filterParam) || {};
  return q;
};

export const formatLimitValue = (value) => {
  switch (value) {
    case undefined:
      return "∞";
    case -1:
      return "∞";
    default:
      return parseInt(value);
  }
};

export const getLimitRatio = (used, initialLimit, status) => {
  const permissions = JSON.parse(localStorage.getItem(LOCALSTORAGE.PERMISSIONS));
  if (permissions?.includes(UNLIMITED_USAGE) || isNil(used) || isNil(initialLimit)) return RATIO_LIMITS.A_LOT;
  if (status === USER_SUBSCRIPTION_STATUSES.PAYMENT_FAILED) return RATIO_LIMITS.NO;

  const formattedInitialLimit = formatLimitValue(initialLimit);
  const limit = formattedInitialLimit - used;

  if (!isNaN(formattedInitialLimit)) {
    if (!formattedInitialLimit) return RATIO_LIMITS.NOT_INCLUDES;
    if (used >= formattedInitialLimit) return RATIO_LIMITS.NO;
    if (limit === 0 && formattedInitialLimit !== 0) return RATIO_LIMITS.NO;
    if (limit && limit < 0.2 * formattedInitialLimit) return RATIO_LIMITS.FEW;
    if (limit > 0.2 * formattedInitialLimit) return RATIO_LIMITS.A_LOT;
  } else return RATIO_LIMITS.A_LOT;
};

export const disableCreating = (used, initialLimit, status) => {
  const limitRatio = getLimitRatio(used, initialLimit, status);
  return DISABLE_CREATING_STATUSES.includes(limitRatio);
};

export const toAMPMTime = (timeValue) => moment(timeValue, "HH:mm:ss").format("h:mm A");

export const makeInterval = (interval) => {
  if (!interval) return null;
  if (interval >= 3600) {
    return interval / 3600 + "h";
  } else {
    return interval / 60 + "m";
  }
};

export const customRequired =
  (message = "Required") =>
    (value) => {
      if (!value) return message;
      return undefined;
    };

export const customFromTo = (from, to) => (value) => {
  if (value < from) return `Min ${from}`;
  if (value > to) return `Max ${to}`;
};

export const customMustBePositive =
  (message = "Must be a positive value") =>
    (value) => {
      if (value < 0) return message;
      return undefined;
    };

export const customMaxLength =
  (maxLength = 1000, t) =>
    (value) => {
      if (!value) return undefined;
      if (value.length > maxLength) {
        return t ? `${t("common.maxLength")} ${value.length}/${maxLength}` : `${value.length}/${maxLength}`;
      }
      return undefined;
    };

export const customTwitterChecker = (t) => (value) => {
  if (!value) return undefined;
  if (value?.length && !value.match(/https?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/))
    return t("common.wrong_twitter");
};

export const customUrlChecker = (t) => (value) => {
  if (!value) return undefined;
  if (
    value?.length &&
    !value.match(/https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g)
  )
    return t("common.wrong_url");
};

const TIME_FORMAT_24 = "HH:mm:ss";
const offsetEDtEst = moment().tz("America/Detroit").utcOffset();

export const convertLocalToEdtEst = (timeString, outFormat = TIME_FORMAT_24) => {
  const ianaTimezone = store.getState().profile.ianaTimezone || "America/New_York";
  const offsetLocal = moment().tz(ianaTimezone).utcOffset();
  return moment(timeString, TIME_FORMAT_24)
    .add({ minutes: offsetEDtEst - offsetLocal })
    .format(outFormat);
};

// we can't use ianaTimezone here due to this function is called from the DataProvider
// without any access to the Redux Store
export const convertEdtEstToLocal = (timeString, outFormat = TIME_FORMAT_24) => {
  const utcName =
    TimeZones.find((item) => item.text === localStorage.getItem(LOCALSTORAGE.TIMEZONE))?.utc[0] || "America/New_York";
  const offsetLocal = moment().tz(utcName).utcOffset();
  const result = moment(timeString, TIME_FORMAT_24).add({
    minutes: offsetLocal - offsetEDtEst,
  });
  if (outFormat) {
    return result.format(outFormat);
  }
  return result;
};
export const toDateFormat = (value) => moment(value).format(Config.DATE_FORMAT);

export const formatNumberValue = (value, options) =>
  !isNil(value) ? new Intl.NumberFormat("en-US", options).format(value) : null;

export const setGaId = (category, action) => {
  if (typeof category === "string" && typeof action === "string") {
    const formattedAction = action
      ?.replace(/[_|\s/,]+/g, "-")
      ?.replace("%", "percent")
      ?.toLowerCase();
    return `ga-event_${category}_${formattedAction}`;
  }
  return "";
};

export const getCountDownTimeValues = (expirationDate) => {
  if (!expirationDate) return {};

  const countDownDate = new Date(expirationDate).getTime();
  const now = new Date().getTime();

  // Find the distance between now and the count down date
  const distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return { days, hours, minutes, seconds };
};

export const sendEmailVerification = () => {
  const verificationUrl = window.location.href.split("#")[0];
  const actionCodeSettings = {
    handleCodeInApp: true,
    url: `${verificationUrl}#/dashboard`,
  };
  if (!auth.currentUser.emailVerified) {
    sendEmailVerification(auth.currentUser, actionCodeSettings);
  }
};

export const getEndDate = () =>
  convertEdtEstToLocal(moment(store.getState().backtestInfo?.data_end_date), moment.HTML5_FMT.DATE);

export const getStartDate = () =>
  convertEdtEstToLocal(moment(store.getState().backtestInfo?.data_end_date).subtract(7, "days"), moment.HTML5_FMT.DATE);

export const showReferral =
  process.env.REACT_APP_GROWSURF_ID && process.env.REACT_APP_REFERRAL_PROGRAM?.toUpperCase() === "TRUE";

export const getRegularMinTime = () => convertEdtEstToLocal("09:30", null);
export const getRegularMaxTime = () => convertEdtEstToLocal("16:00", null);

export const getEarlyMinTime = () => convertEdtEstToLocal("09:30", null);
export const getEarlyMaxTime = () => convertEdtEstToLocal("13:00", null);

export const getDomain = () => {
  let host = window.location.host;
  return host.includes(".") ? host.split(".")[1] : host;
}

export const getBrokerRedirectUri = () => {
  // BE-337
  const host = window.location.host;
  //TODO: add evn variable
  if (host.includes("dev")) {
    return "https://develop.breakingequity.com/";
  } else if(host.includes("localhost")) {
    return window.location.origin + "/";
  }
  return "https://app.breakingequity.com/";
}
