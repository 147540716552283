import React from "react";
import { Link, useNotify, useTranslate } from "react-admin";
import AceEditor from "react-ace";
import styles from "./ErrorDetails.module.css";
import { Accordion, AccordionDetails, Typography } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ChevronDownIcon from "react-feather/dist/icons/chevron-down";
import { Button } from "ra-ui-materialui";

const ErrorDetails = (props) => {
  const t = useTranslate();
  const [expanded, setExpanded] = React.useState(false);
  const notify = useNotify();

  const toggleExpanded = () => (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const copy = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.nativeEvent.preventDefault();

    navigator.clipboard.writeText(props.value);
    notify(t("unexpected_error.clipboard"), "info");
  };

  return props.value ? (
    <div className={styles.container}>
      <Accordion
        className={styles.expandPanel}
        expanded={expanded}
        onChange={toggleExpanded()}
      >
        <AccordionSummary
          className={styles.summary}
          classes={{
            expandIcon: styles.expandIcon,
          }}
          expandIcon={<ChevronDownIcon color="var(--gray_icon)" />}
          aria-controls="panel1bh-content"
          id="error-header"
        >
          <Typography className={styles.heading}>
            {t("unexpected_error.header")}
          </Typography>
          <div>
            {t("unexpected_error.summary1")}

            <Link
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={styles.contactSupportLink}
              href="mailto:support@breakingequity.com"
              target="_blank"
            >
              {t("unexpected_error.contact_support")}
            </Link>
            {t("unexpected_error.summary2")}
          </div>

          <div className={styles.supportSummary}>
            <Button
              onClick={copy}
              className={styles.contactSupportButton}
              label={t("unexpected_error.click_here")}
            />

            {t("unexpected_error.to_copy")}
          </div>
        </AccordionSummary>
        <AccordionDetails className={styles.details}>
          <AceEditor
            mode="java"
            value={props.value}
            height="400px"
            width="100%"
            enableBasicAutocompletion={false}
            enableLiveAutocompletion={false}
            readOnly={true}
            style={{
              borderRadius: "4px",
              border: `1px solid ${!props.error ? "#bbb" : "red"}`,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  ) : null;
};

export default ErrorDetails;
