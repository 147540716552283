import React, { useRef, useState } from "react";
import { useInput, useTranslate } from "react-admin";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FormHelperText, Tooltip } from "@material-ui/core";
import Config from "../../config";

const SelectField = (props) => {
  const {
    input: { value, ...rest },
    meta: { error, touched },
    isRequired,
  } = useInput(props);
  const { id, helperText, noLabel } = props;
  const t = useTranslate();
  const [tooltipOpen, setTooltipOpen] = useState();

  const tooltipTimer = useRef(null);

  const handleTooltip = (value) => () => {
    if (value) {
      tooltipTimer.current = setTimeout(() => setTooltipOpen(value), Config.TOOLTIP_DELAY);
    } else {
      clearTimeout(tooltipTimer.current);
      setTooltipOpen(value);
    }
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      className={props.className}
      required={isRequired}
      error={!!error && touched}
    >
      {!noLabel && <InputLabel htmlFor={`${props.source}-select`}>{t(`fieldsName.${props.source}`)}</InputLabel>}
      <Tooltip title={helperText} open={!!helperText && tooltipOpen === `${props.source}-select`}>
        <Select
          data-testid={`algo-select-${props.source}`}
          defaultValue={props.defaultValue}
          value={value}
          required={isRequired}
          label={!noLabel && t(`fieldsName.${props.source}`)}
          disabled={props.disabled}
          className={props.selectClassName}
          MenuProps={{
            id: `menu-${props.source}`,
            "data-testid": `algo-menu-${props.source}`,
          }}
          {...rest}
          onMouseEnter={handleTooltip(`${props.source}-select`)}
          onMouseLeave={handleTooltip()}
        >
          {(props.options || []).map(({ label, value, disabled }, index) => (
            <MenuItem id={id} value={value} key={index} onMouseEnter={handleTooltip()} disabled={disabled}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
      {!!error && touched ? <FormHelperText>{t("criteria.required")}</FormHelperText> : null}
    </FormControl>
  );
};

export default SelectField;
