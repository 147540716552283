import React from "react";
import styles from "./ToggleButtons.module.css";
import { useInput } from "react-admin";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import cn from "clsx";
import { setGaId } from "../../shared/utils";

const ToggleButtons = (props) => {
  const {
    input: { value, onChange },
  } = useInput(props);

  return (
    <ButtonGroup className={cn(styles.buttonGroup, props.className)}>
      {props.choices.map((choice) => (
        <Button
          id={setGaId(props.ga?.category, choice.name)}
          key={choice.id}
          onClick={() => onChange(choice.id)}
          className={cn(value === choice.id ? styles.active : styles.inactive)}
          data-testid={choice.id}
        >
          {choice.name}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ToggleButtons;
