import React from "react";
import classes from "./HelpTooltip.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import HelpCircleIcon from "react-feather/dist/icons/help-circle";
import { useTranslate } from "react-admin";
import { setGaId } from "../../shared/utils";

const HelpTooltip = ({ ga, title, setOpenAlert }) => {
  const t = useTranslate();
  return (
    <Tooltip title={title}>
      <Button
        variant="outlined"
        className={classes.help}
        onClick={setOpenAlert}
        id={setGaId(ga?.category, "help")}
      >
        {t("helpers.help")}
        <HelpCircleIcon size={14} className={classes.icon} />
      </Button>
    </Tooltip>
  );
};

export default HelpTooltip;
