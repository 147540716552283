import React from "react";
import { Button, ButtonGroup, CircularProgress } from "@material-ui/core";
import { convertLocalToEdtEst, setGaId } from "../../../shared/utils";
import styles from "../ViewActions.module.scss";
import { statuses } from "../../../shared/statuses";
import MoreActions from "../../MoreActions/MoreActions";
import { TRADING_RESOURCE } from "../../../shared/variables";
import { isPaper } from "../../../pages/Trading/utils";
import useGetActions from "../hooks/useGetActions";
import DisableCreatingTooltip from "../../DisableCreatingTooltip/DisableCreatingTooltip";

const transformValues = ({
  screener,
  ticker,
  early_close_days_from,
  early_close_days_to,
  regular_days_from,
  regular_days_to,
  ...rest
}) => ({
  screeners: screener?.id ? [screener?.id] : [],
  tickers: ticker ? [ticker] : [],
  early_close_days_from: convertLocalToEdtEst(early_close_days_from),
  early_close_days_to: convertLocalToEdtEst(early_close_days_to),
  regular_days_from: convertLocalToEdtEst(regular_days_from),
  regular_days_to: convertLocalToEdtEst(regular_days_to),
  ...rest,
});

const ViewActionsButton = (props) => {
  const { ga, record, className, resource } = props;

  const isPaperTrading = record?.trading_type && isPaper(props);
  const actionsList = useGetActions(record, resource);

  let actionsResource = "algos";
  if (record?.trading_type) actionsResource = TRADING_RESOURCE.TRADINGS;
  if (resource === "screener-backtests") actionsResource = "screeners";

  const hasExitingStatus = (exiting_target_status) =>
    record?.status === statuses.exiting.id && record?.exiting_target_status === exiting_target_status;

  return (
    <ButtonGroup variant="contained" color="primary" className={className}>
      <DisableCreatingTooltip disabled={actionsList?.mainActionDisabled} entity={actionsList?.mainActionEntity}>
        <Button
          id={setGaId(ga?.category, actionsList?.mainActionName)}
          onClick={actionsList?.mainActionFunc}
          className={styles.mainButton}
          disabled={actionsList?.mainActionDisabled}
          data-testid={actionsList?.mainActionTestId}
        >
          {hasExitingStatus(statuses.stopped.id) && <CircularProgress size={16} color="inherit" />}
          {actionsList?.mainActionName}
        </Button>
      </DisableCreatingTooltip>
      <MoreActions
        ga={ga}
        button
        items={actionsList?.moreActions}
        record={transformValues(record)}
        resource={
          actionsResource === TRADING_RESOURCE.TRADINGS
            ? isPaperTrading
              ? TRADING_RESOURCE.PAPER
              : TRADING_RESOURCE.LIVE
            : actionsResource
        }
        basePath={!record?.trading_type ? "/algos" : null}
        actionsResource={actionsResource}
      />
    </ButtonGroup>
  );
};

export default ViewActionsButton;
