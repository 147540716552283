import { useRef } from "react";
import { useDispatch } from "react-redux";
import { LINK_BROKER } from "../../../../../store/sagas/brokersSaga";
import {getBrokerRedirectUri} from "../../../../../shared/utils";

export default (setAgreementShow) => {
  const loginWindowRef = useRef(null);
  const intervalRef = useRef(null);
  const dispatch = useDispatch();

  // BE-337
  let redirectUri = getBrokerRedirectUri();

  const queryData = {
    response_type: "code",
    redirect_uri: redirectUri,
    client_id: process.env.REACT_APP_TDAMERITRADE_CLIENT_ID + "@AMER.OAUTHAP",
  };

  return function () {
    if (loginWindowRef.current == null || loginWindowRef.current.closed) {
      loginWindowRef.current = window.open(
        `https://auth.tdameritrade.com/auth?${new URLSearchParams(queryData).toString()}`,
        "_blank",
        "width=450,height=650"
      );
    } else {
      loginWindowRef.current.focus();
    }

    // now we are trying to get the code
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      let queryParams = null;

      try {
        loginWindowRef.current.postMessage("broker", getBrokerRedirectUri());
        queryParams = loginWindowRef.current?.location?.search;
      } catch (e) {
        // waiting for the redirect to get query params
      }
      if (queryParams) {
        const code = new URLSearchParams(queryParams).get("code");
        if (!code) {
          loginWindowRef.current.close();
          clearInterval(intervalRef.current);
          return;
        }
        const body = JSON.stringify({
          code,
          redirect_uri: redirectUri,
          broker: "AMERITRADE",
        });
        dispatch({
          type: LINK_BROKER,
          payload: { brokerName: "ameritrade", body },
        });
        loginWindowRef.current.close();
        clearInterval(intervalRef.current);
      }
    }, 100);
  };
};
