import React, { createElement } from "react";
import { connect } from "react-redux";
import {showNotification, useNotify, useTranslate} from "react-admin";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkSlug from "remark-slug";
import SyntaxHighlighter from "react-syntax-highlighter";
import a11yLight from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-light";
import CopyIcon from "react-feather/dist/icons/copy";
import IconButton from "@material-ui/core/IconButton";
import classes from "./Markdown.module.css";
import { colors } from "../../shared/cssVariables";
import { setGaId } from "../../shared/utils";

export const MarkdownDoc = (props) => {
  const t = useTranslate();
  const notify = useNotify()

  const copy = (value) => () => {
    navigator.clipboard.writeText(value);
    notify(t("fullScreenEditor.clipboard"));
  };

  const codeField = (value) => (
    <div className={classes.copyClipboardWrapper}>
      <SyntaxHighlighter style={a11yLight} language={"bash"} children={value} />
      <IconButton
        id={setGaId(props.ga?.category, "copy-indicator")}
        edge="start"
        color="inherit"
        onClick={copy(value)}
        aria-label="copy"
        className={classes.copyIcon}
      >
        <CopyIcon size={18} color={colors.gray_icon} />
      </IconButton>
    </div>
  );

  const renderers = {
    heading: ({ level, node, children }) => createElement("h" + level, { id: node.data.id }, children),
    inlineCode: ({ children }) => codeField(children),
    code: ({ children }) => codeField(children),
  };

  const value = props.value?.replaceAll("\\\n", "\n");

  return (
      <div className={classes.md}>
        <ReactMarkdown remarkPlugins={[remarkGfm, remarkSlug]} components={renderers} linkTarget="_blank" children={value} {...props} />
      </div>
  );
};

export default connect(null, { showNotification })(MarkdownDoc);
