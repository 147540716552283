import React from "react";
import clsx from "clsx";
import classes from "./Rows.module.css";
import Label from "./Label";

const WideLabelVal = ({ label, value, className }) => {
  return (
    <div className={clsx(classes.wideLabelVal, className)}>
      <Label className={classes.wide}>{label}</Label>
      {value}
    </div>
  );
};

export default WideLabelVal;
