import React, { useMemo } from "react";
import {
  Datagrid,
  Filter,
  List,
  NumberField,
  SearchInput,
  TextField,
  useTranslate,
  ListContextProvider,
  useListController, useListContext, TopToolbar,
} from "react-admin";
import FailedIcon from "react-feather/dist/icons/alert-circle";
import StopLossIcon from "react-feather/dist/icons/slash";
import CanceledIcon from "react-feather/dist/icons/minus-circle";
import CompletedIcon from "react-feather/dist/icons/check-circle";
import SlippageIcon from "react-feather/dist/icons/chevrons-down";
import CircleIcon from "react-feather/dist/icons/circle";
import ArrowUp from "react-feather/dist/icons/arrow-up-circle";
import WarningIcon from "react-feather/dist/icons/alert-circle";
import { Tooltip } from "@material-ui/core";
import classes from "./Trades.module.css";
import { setGaId } from "../../../shared/utils";
import DatagridIdsWrapper from "../../DatagridIdsWrapper/DatagridIdsWrapper";
import ExpandPanel from "./components/ExpandPanel/ExpandPanel";
import Config from "../../../config";
import { MomentField } from "../../MomentField";
import useGetItemsPerPage from "../../ListPagination/hooks/useGetItemsPerPage";
import ListPagination from "../../ListPagination/ListPagination";
import { isMobile } from "react-device-detect";
import {GA_CATEGORIES, ORDER_EXIT_TYPES} from "../../../shared/variables";
import {
  exportIndicators,
  getLinesValue,
  indicatorsForMapping,
  removeChartIndicatorsDuplicates,
} from "./components/TradesChart/tradingview/mapCriteriasToIndicators";
import { ColoredNumber } from "../../ColoredNumber/ColoredNumber";
import uniq from "lodash/uniq";
import {ToggleButtonsField} from "../../ToggleButtonsField/ToggleButtonsField";
import {filterStatuses} from "../../../pages/ScreenerBacktest/components/DayResults/DayResults";
import NoResultsFound from "../../NoResultsFound/NoResultsFound";

export const OrderStatusFilter = (props) => {
  return (
    <Filter variant="standard" className={classes.filter} {...props}>
      <SearchInput source="q" variant="standard" alwaysOn id={setGaId(props.ga?.category, "search")} />
    </Filter>
  );
};

export const OrderStatuses = {
  CANCELED: { name: "CANCELED", icon: CanceledIcon },
  EXPIRED: { name: "EXPIRED", icon: FailedIcon },
  ACCEPTED: { name: "ACCEPTED", icon: ArrowUp },
  COMPLETED: { name: "COMPLETED", icon: CompletedIcon },
  CREATED: { name: "CREATED", icon: CompletedIcon },
  SUBMITTED: { name: "SUBMITTED", icon: CompletedIcon },
};

export const OrderStatus = (props) => {
  const t = useTranslate();
  const tooltip = props.status + (props.slippage ? t(" WITH SLIPPAGE") : "");
  const IconToRender = props.slippage ? SlippageIcon : OrderStatuses[props.status]?.icon || CircleIcon;

  return IconToRender ? (
    <Tooltip title={props.status ? tooltip : ""}>
      <IconToRender className={`icon-DIMMED icon-${props.status}`} />
    </Tooltip>
  ) : null;
};

export const OrderType = (props) => {
  if (props.type === ORDER_EXIT_TYPES.STOP.value) {
    return (
      <Tooltip title={ORDER_EXIT_TYPES.STOP.label}>
        <StopLossIcon />
      </Tooltip>
    );
  }
  if (props.type === ORDER_EXIT_TYPES.STOP_TRAIL.value) {
    return (
      <Tooltip title={ORDER_EXIT_TYPES.STOP_TRAIL.label}>
        <StopLossIcon />
      </Tooltip>
    );
  }
  return null;
};

export const DayStatus = (props) => {
  const t = useTranslate();
  if (props.record.day_status === "COMPLETED_ATYPICALLY") {
    return (
      <Tooltip title={t("backtest.completed_atypically")}>
        <WarningIcon className={classes.unsuccessful} />
      </Tooltip>
    );
  } else {
    return null;
  }
};

const TradingDaysToolbar = () => {
  const ga = { category: GA_CATEGORIES.BACKTEST };
  const { filterValues, setFilters } = useListContext();

  const statuses = Object.values(filterStatuses);
  const { ALL, EMPTY, NOT_EMPTY } = filterStatuses;

  const selectedSwitch = () => {
    if (filterValues.not_empty === true) return NOT_EMPTY;
    if (filterValues.not_empty === false) return EMPTY;
    return ALL;
  };

  const handleRequestSwitch = (event) => {
    const checked = event.target.id;
    // clean up filter object
    const prioFilter = (({ trades, trades_Lte, trades_Gte, ...o }) => o)(filterValues)
    switch (checked) {
      case EMPTY: {
        setFilters({...prioFilter, trades_Lte: 0 });
        break;
      }
      case NOT_EMPTY: {
        setFilters({...prioFilter, trades_Gte: 1 });
        break;
      }
      default:
        setFilters({});
    }
  };

  return (
      <TopToolbar>
        <ToggleButtonsField
            ga={ga}
            onChange={handleRequestSwitch}
            defaultValue={selectedSwitch()}
            inputNames={statuses}
            inputLabels={statuses}
        />
      </TopToolbar>
  );
};

export const TradesList = (props) => {
  const t = useTranslate();
  const { tradesListResource, tradesOrdersResource, tableName, filter, ...rest } = props;
  const perPage = useGetItemsPerPage(tableName);

  const enterIndicators = useMemo(() => exportIndicators(props.record.enter_criteria), [props.record.enter_criteria]);
  const exitIndicators = useMemo(() => exportIndicators(props.record.exit_criteria), [props.record.exit_criteria]);
  const enterNumbers = useMemo(() => getLinesValue(props.record.enter_criteria), [props.record.enter_criteria]);
  const exitNumbers = useMemo(() => getLinesValue(props.record.exit_criteria), [props.record.exit_criteria]);

  const chartSettings = {
    parentId: props.record.algo?.id || props.record.id,
    algo_type: props.record.algo_type,
    interval:
      props.record.enter_interval === props.record.exit_interval ? parseInt(props.record.enter_interval) / 60 : 1,
    neededIndicatorsForMapping: window.INDICATORS.filter((el) => indicatorsForMapping.includes(el.name)),
    numbers: uniq([...enterNumbers, ...exitNumbers]),
  };
  const criteriaSettings = {
    enterInterval: props.record.enter_interval,
    exitInterval: props.record.exit_interval,
    enterIndicators: removeChartIndicatorsDuplicates(enterIndicators),
    exitIndicators: removeChartIndicatorsDuplicates(exitIndicators),
  };

  const getDatagrid = () => {
    return (
      <Datagrid
        className={classes.datagrid}
        size="small"
        expand={<ExpandPanel {...props} chart={chartSettings} criteria={criteriaSettings} serializedResult={props.record?.serialized_result} />}
        rowClick="expand"
      >
        <DatagridIdsWrapper ga={props.ga} headerClassName="IdsWrapper-Col" cellClassName="IdsWrapper-Col" />
        <MomentField
          format={Config.DATE_FORMAT}
          source="date"
          timezone={Config.STOCK_TZ}
          cellClassName={classes.date_col}
        />
        <TextField source="trades" cellClassName={classes.trades_col} headerClassName={classes.header} />

        <NumberField
          label={isMobile ? t("backtest.eod") : t("backtest.eod_value")}
          source="trading_value_close"
          locales="en-EN"
          cellClassName={classes.gain_col}
          headerClassName={classes.header}
          options={{
            maximumFractionDigits: isMobile ? 0 : 2,
            minimumFractionDigits: isMobile ? 0 : 2,
            style: "currency",
            currency: "USD",
          }}
        />

        {!isMobile &&
          <ColoredNumber
            locales="en-EN"
            style={{ percent: "currency" }}
            source="gain"
            precision={isMobile ? 0 : 2}
            cellClassName={classes.gain_col}
            headerClassName={classes.header}
          />
        }

        <ColoredNumber
            locales="en-EN"
            style={{ percent: "percent" }}
            source="gain_pct"
            label={t("backtest.return_pct")}
            precision={2}
            cellClassName={classes.gain_col}
            headerClassName={classes.header}
        />

        {!isMobile && <>
          <DayStatus
            label={isMobile ? " " : t("backtest.status")}
            source="day_status"
            cellClassName={isMobile ? classes.mobile_trades_col : classes.trades_col}
            headerClassName={classes.header}
            {...props}
          />
          <TextField label="" source="fake" />
        </>}
      </Datagrid>
    );
  };

  if (props?.record?.serialized_result) {
    return <SerializedResultList props={props}>{getDatagrid()}</SerializedResultList>;
  }

  return (
    <List
      bulkActionButtons={false}
      actions={<TradingDaysToolbar/>}
      filters={<OrderStatusFilter {...props} />}
      filter={filter}
      empty={<NoResultsFound />}
      sort={{ field: "date", order: "DESC" }}
      exporter={false}
      className={classes.list}
      {...rest}
      perPage={perPage}
      pagination={<ListPagination {...props} />}
      resource={tradesListResource}
      hasShow={false}
      hasList={false}
      hasEdit={false}
      hasCreate={false}
    >
      {getDatagrid()}
    </List>
  );
};

function SerializedResultList({ children, props }) {
  const getSerializedData = (serializedResult) => {
    const days = Object.keys(serializedResult)?.length ? serializedResult?.days : null;
    let data = {};
    if (days) {
      days.reverse().forEach((day) => (data[day.date] = {...day, id: day.date}));
    }
    return data;
  };

  const serializedResult = JSON.parse(props?.record?.serialized_result || "{}");
  const serializedData = getSerializedData(serializedResult);

  const listController = {
    ...useListController({ ...props, record: serializedResult?.days, resource: props?.tradesListResource }),
    data: serializedData,
    ids: Object.keys(serializedData),
    total: Object.keys(serializedData)?.length,
  };

  return <ListContextProvider value={listController}>{children}</ListContextProvider>;
}
