import React, { useEffect, useState } from "react";
import {useNotify, usePermissions, useTranslate} from "react-admin";
import { loadStripe } from "@stripe/stripe-js";
import Config from "../../../../config";
import { Storage } from "../../../../config";
import classes from "./Subscriptions.module.css";
import clsx from "clsx";

import { PulseOutline } from "react-ionicons";
import { FilterOutline } from "react-ionicons";
import { LayersOutline } from "react-ionicons";
import { OptionsOutline } from "react-ionicons";
import { BarChartOutline } from "react-ionicons";
import { HelpCircleOutline } from "react-ionicons";
import { CloseCircleOutline } from "react-ionicons";

import { Cart } from "react-ionicons";
import { Flask } from "react-ionicons";
import { Briefcase } from "react-ionicons";
import { Cube } from "react-ionicons";
import { Leaf } from "react-ionicons";
import { Ribbon } from "react-ionicons";
import { Rocket } from "react-ionicons";




import { colors } from "../../../../shared/cssVariables";
import Alert from "../../../../components/Alert/Alert";
import { formatLimitValue } from "../../../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import { GET_USAGE_POLLING, USER_SUBSCRIPTION_STATUSES } from "../../../../store/sagas/usageSaga";
import { UPDATE_SUBSCRIPTION } from "../../../../store/sagas/subscriptionSaga";
import { STATUSES } from "../../../../store/reducers/statuses";
import ExpandSubscriptionBlock from "./components/ExpandSubscriptionBlock/ExpandSubscriptionBlock";
import PlanItem from "./components/PlanItem/PlanItem";
import { GA_CATEGORIES, LOCALSTORAGE, PLANS } from "../../../../shared/variables";
import { ALERT } from "../../../../components/Alert/variables";
import moment from "moment";
import useCountDown from "../../../../hooks/useCountDown";
import {BACKTEST_ALGOS} from "../../../../shared/permissions";

const stripePromise = loadStripe(Config.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const PLAN_STATUSES = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  FAILED: "FAILED",
};

const getNotification = (t, { algos_limit, backtests_limit, screeners_limit }) => [
  {
    id: "warning",
    type: ALERT.WARNING,
    icon: true,
    data: [
      {
        header: "subscriptions.free_tier",
        description: [t("subscriptions.free_tier_doc", { algos_limit, backtests_limit })],
      },
    ],
  },
  {
    id: "success",
    type: ALERT.SUCCESS,
    icon: true,
    data: [
      {
        header: "subscriptions.successfully_updated",
        description: [
          t("subscriptions.successfully_updated_doc", {
            algos_limit,
            backtests_limit,
            screeners_limit,
          }),
        ],
      },
    ],
  },
  {
    id: "danger",
    type: ALERT.DANGER,
    icon: true,
    data: [
      {
        header: "subscriptions.payment_declined",
        description: [
          <span>
            {t("subscriptions.payment_declined_doc")}{" "}
            <a href={t("subscriptions.support_email")} className={classes.link}>
              {t("subscriptions.contact_support")}
            </a>
          </span>,
        ],
      },
    ],
  },
  {
    id: "failed",
    icon: true,
    data: [
      {
        header: "subscriptions.payment_failed_subscription",
        description: [<span>{t("subscriptions.payment_failed_message_subscription")}</span>],
      },
    ],
  },
];

const getList = ({ plan, t, permissions }) => {
  const supportLevel = plan?.support_level ? plan?.support_level.toLowerCase() + " Support" : "";

  const iconParams = {
    size: 19,
    color: colors.green,
    style: { marginRight: 8, width: 19, height: 19 },
  };

  const items = {
    backtests_limit: {
      icon: <BarChartOutline {...iconParams} />,
      nameSingle: "backtest",
      namePlural: "backtests",
    },
    algos_limit: {
      icon: <OptionsOutline {...iconParams} />,
      nameSingle: "algo",
      namePlural: "algos",
    },
    screeners_limit: {
      icon: <FilterOutline {...iconParams} />,
      nameSingle: "screener",
      namePlural: "screeners",
    },
    paper_tradings_limit: {
      icon: <LayersOutline {...iconParams} />,
      nameSingle: "paper_trading",
      namePlural: "paper_tradings",
    },
    money_tradings_limit: {
      icon: <PulseOutline {...iconParams}/>,
      nameSingle: "money_trading",
      namePlural: "money_tradings",
      suffix: null,
    },
  };

  const getItem = ({ field, index, t }) => {
    const formattedField = formatLimitValue(plan[field]);
    return (
      <li
        className={clsx(classes.listItem, {
          [classes.listItemDisabled]: !formattedField,
        })}
        key={index}
      >
        <span>
          <div className={classes.value}>
            {formattedField ? items[field]?.icon : <CloseCircleOutline {...iconParams} color={colors.gray_dim} />}
            {formattedField}{" "}
            {t(`subscriptions.${formattedField === 1 ? items[field]?.nameSingle : items[field]?.namePlural}`)}
          </div>
          {formattedField !== 0 && items[field].suffix ? (
            <div className={classes.suffix} dangerouslySetInnerHTML={{ __html: items[field].suffix }} />
          ) : (
            formattedField === 0 && items[field].suffix && <div>&nbsp;</div>
          )}
        </span>
      </li>
    );
  };

  const planItems = [
    // "backtests_limit",
    // "algos_limit",
    // "screeners_limit",
    "paper_tradings_limit",
    "money_tradings_limit",
  ];
  if(plan.name !== "Free" || permissions?.includes(BACKTEST_ALGOS)) {
    planItems.unshift("algos_limit");
  }

  return (
    <ul className={classes.list}>
      {planItems.map((field, index) => getItem({ field, index, t }))}
      <li className={classes.listItem}>
        <div className={classes.value}>
          <HelpCircleOutline {...iconParams} /> {supportLevel}
        </div>
      </li>
    </ul>
  );
};

const getDescription = ({ name, t }) => {
  switch (name) {
    case PLANS.FREE:
      return t("subscriptions.free_description");
    case PLANS.STARTER:
      return t("subscriptions.starter_description");
    case PLANS.BASIC:
      return t("subscriptions.basic_description");
    case PLANS.PROFESSIONAL:
      return t("subscriptions.professional_description");
    case PLANS.MARKETPLACE:
      return t("subscriptions.marketplace_description");
    case PLANS.ALGOLAB:
      return t("subscriptions.algolab_description");
    case PLANS.ALGOLABPRO:
      return t("subscriptions.algolabpro_description");
    default:
      return "";
  }
};

export const Subscriptions = () => {
  const notify = useNotify();
  const t = useTranslate();
  const plans = useSelector((store) => store.plans.data);
  const dispatch = useDispatch();

  const [billingPlans, setBillingPlans] = useState([]);
  const [openAlert, setOpenAlert] = useState();
  const { loaded, permissions } = usePermissions();

  const {
    data: {
      subscription_plan,
      downgrade_plan,
      status: usageStatus,
      has_stripe_account: hasStripeAccount,
      updated_date,
      discount_duration,
      discount_expiration,
      trial_start: trialStart,
      trial_end: trialEnd,
      current_period_end:  currentPeriodEnd
    },
    status: usageRequestStatus,
  } = useSelector((state) => state.usage);
  const subscription = useSelector((state) => state.subscription);
  const { SUBSCRIPTION_UPDATED_DATE } = LOCALSTORAGE;

  const planId = downgrade_plan?.id || subscription_plan?.id;
  const activePlan = billingPlans.find((plan) => plan.id === planId);
  const notification = activePlan ? getNotification(t, activePlan) : [];

  const ga = { category: GA_CATEGORIES.PROFILE, action: "subscriptions-tab" };
  const hasDiscountCountDown = !!useCountDown(discount_expiration);

  const selectPlan = async (id) => {
    dispatch({ type: UPDATE_SUBSCRIPTION, payload: { id } });
  };

  const cancelSubscription = () => {
    const freePlan = billingPlans.find((plan) => plan.name === PLANS.FREE);
    const { id } = freePlan || {};
    dispatch({ type: UPDATE_SUBSCRIPTION, payload: { id } });
  };

  const getIcon = (name) => {
    const color = colors.blue;
    const { FREE, STARTER, BASIC, PROFESSIONAL, MARKETPLACE, ALGOLAB, ALGOLABPRO } = PLANS;
    switch (name) {
      case FREE:
        return <Leaf color={color} />;
      case STARTER:
        return <Cube color={color} />;
      case BASIC:
        return <Ribbon color={color} />;
      case PROFESSIONAL:
        return <Briefcase color={color} />;
      case MARKETPLACE:
        return <Cart color={color} />;
      case ALGOLAB:
        return <Flask color={color} />;
      case ALGOLABPRO:
        return <Rocket color={color} />;
      default:
        return <Leaf color={color} />;
    }
  };

  useEffect(() => {
    const billingPlans = plans.map((item) => {
      let status = PLAN_STATUSES.INACTIVE;
      if (usageStatus === USER_SUBSCRIPTION_STATUSES.PAYMENT_FAILED && item.id === planId) {
        status = PLAN_STATUSES.FAILED;
      } else if (item.id === planId) {
        status = PLAN_STATUSES.ACTIVE;
      }

      item.list = getList({ plan: item, t, permissions });
      const regularPrice = item.monthly_price ? `$${item.monthly_price}` : t("subscriptions.free");
      const discountPrice = item.discount_price ? `$${item.discount_price}` : null;

      return {
        ...item,
        icon: getIcon(item.name),
        description: getDescription({ name: item.name, t }),
        oldPrice: regularPrice,
        price: !hasDiscountCountDown ? regularPrice : discountPrice || regularPrice,
        priceDescription: item.monthly_price ? t("subscriptions.paid_billing") : t("subscriptions.free_billing"),
        status,
        // MVP1-3208: propagating trial/stripe information
        trialEnd: trialEnd,
        trialStart: trialStart,
        currentPeriodEnd: currentPeriodEnd,
        hasStripeAccount: hasStripeAccount,
        list: getList({ plan: item, t, permissions }),
      };
    });
    setBillingPlans(billingPlans);
  }, [planId, plans, usageStatus, hasDiscountCountDown]);

  useEffect(() => {
    if (subscription.status === STATUSES.UPDATE_SUCCESS && subscription.sessionId) {
      Storage.setItem(SUBSCRIPTION_UPDATED_DATE, updated_date);
      stripePromise.then((stripe) => stripe.redirectToCheckout({ sessionId: subscription.sessionId }));
      return;
    }
    if (subscription.status === STATUSES.UPDATE_SUCCESS && usageRequestStatus === STATUSES.LOAD_SUCCESS) {
      notify(t("subscriptions.successfully_updated"));
      if (activePlan?.name === PLANS.FREE) {
        setOpenAlert("warning");
      } else {
        setOpenAlert("success");
      }
    }
    if (subscription.status === STATUSES.UPDATE_FAIL) {
      if (hasStripeAccount) {
        notify(t("subscriptions.plan_update_failed"), "error");
      } else {
        notify(t("subscriptions.payment_declined"));
      }
      setOpenAlert("danger");
    }
    if (usageRequestStatus === STATUSES.LOAD_FAIL) {
      setOpenAlert("failed");
    }
    Storage.removeItem(SUBSCRIPTION_UPDATED_DATE);
  }, [subscription, usageRequestStatus, activePlan, hasStripeAccount]);

  useEffect(() => {
    if (window.location.hash.includes("subscribed=true")) {
      if (moment(Storage.getItem(SUBSCRIPTION_UPDATED_DATE)).isSameOrBefore(updated_date)) {
        dispatch({ type: GET_USAGE_POLLING });
      } else {
        Storage.removeItem(SUBSCRIPTION_UPDATED_DATE);
        notify(t("subscriptions.successfully_updated"));
        setOpenAlert("success");
      }
    }
  }, []);

  return (
    <div>
      <div className={classes.subscriptions}>
        {openAlert && activePlan ? (
          <Alert
            data={notification.find((item) => item.id === openAlert)}
            setOpenAlert={activePlan?.name === PLANS.FREE ? null : () => setOpenAlert(null)}
          />
        ) : null}
        {usageStatus === USER_SUBSCRIPTION_STATUSES.PAYMENT_FAILED ? (
          <Alert data={notification.find((item) => item.id === "failed")} setOpenAlert={null} />
        ) : null}
        <div className={classes.subscriptionsContainer}>
          <div className={classes.trial_box}>
            <span className={classes.text}>{t("helpers.join_discord_trial1")}</span>
            <a
                href="https://discord.gg/V3YQsYPnzW"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
            >
              {t("helpers.join_discord_community")}
            </a>
            <span>{t("helpers.join_discord_trial2")}</span>
          </div>
          <ul className={classes.grid}>
            {billingPlans.filter(plan => !plan.archived).map((plan, index) => (
              <li className={classes.plan} key={index}>
                <PlanItem
                  ga={ga}
                  plan={plan}
                  onClick={selectPlan}
                  discountDuration={discount_duration}
                  hasDiscountCountDown={hasDiscountCountDown}
                  disabled={subscription.status === STATUSES.UPDATING || usageRequestStatus === STATUSES.LOADING}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ExpandSubscriptionBlock id="subscription_mechanics" />
      <ExpandSubscriptionBlock
        id="cancel_subscription"
        ga={ga}
        cancelSubscription={cancelSubscription}
        plan={activePlan}
      />
    </div>
  );
};
