import * as React from "react";
import Moment from "react-moment";
import { useTranslate } from "react-admin";
import Config, { Storage } from "../config";
import { Tooltip } from "@material-ui/core";
import moment from "moment-timezone";
import { UTCNameById } from "../locales/timezones";

export const MomentField = (props) => {
  moment.updateLocale("en", {
    relativeTime: {
      s: "a moment",
    },
  });

  const t = useTranslate();
  const timezone =
    props.timezone ||
    UTCNameById(Storage.getItem("timezone")) ||
    "America/New_York";
  let date = props.record?.[props.source];

  if (Number.isInteger(date)) {
    date = new Date(date * 1000)
  }
  const formattedDate = date
    ? moment(date).tz(timezone).format(Config.DATETIME_FORMAT)
    : date;

  if (props && props.record && props.source && props.record[props.source]) {
    if (props.fromNow) {
      return (
        <Tooltip title={formattedDate || date}>
          <div className={props.className}>
            <Moment fromNow className={props.className}>
              {date}
            </Moment>
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Moment
          tz={props.format === Config.DATETIME_FORMAT ? timezone : null}
          format={props.format ? props.format : Config.DATE_FORMAT}
          className={props.className}
        >
          {date}
        </Moment>
      );
    }
  } else {
    return <span>{t("n/a")}</span>;
  }
};
