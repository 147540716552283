import { put, takeEvery, retry } from "redux-saga/effects";
import { customFetch } from "../../DataProvider";

import {
  SCREENERS_ALL_LOADING,
  SCREENERS_ALL_LOAD_SUCCESS,
  SCREENERS_ALL_LOAD_FAIL,
} from "../reducers/screenersAllReducer";
import {Storage} from "../../config";
import {USE_SCREENERS} from "../../shared/permissions";

export const GET_SCREENERS_ALL = "GET_SCREENERS_ALL";
const SECOND = 1000;

export default function* screenersSaga() {
  yield takeEvery(GET_SCREENERS_ALL, function* () {
    const permissions = JSON.parse(Storage.getItem("permissions"));
    yield put({ type: SCREENERS_ALL_LOADING });
    try {
      let personalScreeners = []
      if(permissions.includes(USE_SCREENERS)) {
        personalScreeners = yield retry(
            2,
            3 * SECOND,
            customFetch,
            `screeners`
        );
      }
      const sharedScreeners = yield retry(
        2,
        3 * SECOND,
        customFetch,
        `screeners/shared`
      );
      yield put({
        type: SCREENERS_ALL_LOAD_SUCCESS,
        payload: { screenersAll: [...personalScreeners, ...sharedScreeners] },
      });
    } catch (e) {
      yield put({
        type: SCREENERS_ALL_LOAD_FAIL,
      });
    }
  });
}
