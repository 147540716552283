import { STATUSES } from "./statuses";

export const TRADING_CLEAR = "TRADING_CLEAR";

export const TRADING_UPDATING = "TRADING_UPDATING";
export const TRADING_UPDATE_SUCCESS = "TRADING_UPDATE_SUCCESS";
export const TRADING_UPDATE_FAIL = "TRADING_UPDATE_FAIL";

export const TRADING_REFRESH = "TRADING_REFRESH";

const initialState = {
  status: STATUSES.INIT,
  errorMessage: "",
  message: "",
};

export default (previousState = initialState, { type, payload }) => {
  switch (type) {
    case TRADING_CLEAR: {
      return initialState;
    }

    case TRADING_UPDATING: {
      return {
        ...previousState,
        status: STATUSES.UPDATING,
        errorMessage: "",
        message: "",
      };
    }
    case TRADING_UPDATE_SUCCESS: {
      return {
        ...previousState,
        status: STATUSES.UPDATE_SUCCESS,
        message: payload.message,
      };
    }
    case TRADING_UPDATE_FAIL: {
      return {
        status: STATUSES.UPDATE_FAIL,
        errorMessage: payload.errorMessage,
      };
    }
    case TRADING_REFRESH: {
      return {
        ...previousState,
        status: STATUSES.REFRESH,
        errorMessage: "",
        message: "",
      };
    }

    default:
      return previousState;
  }
};
