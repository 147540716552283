import React from "react";
import TryAlgoButton from "./components/TryAlgoButton";
import ViewActionsButton from "./components/ViewActionsButton";

const ViewActions = (props) => {
  const { record } = props;

  if (record?.shared) return <TryAlgoButton {...props} />;

  return <ViewActionsButton {...props}/>;
};

export default ViewActions;
