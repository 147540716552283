import React from "react";
import { Show } from "react-admin";
import StrategyView from "./StrategyView";
import StrategyEdit from "./StrategyEdit";

export const ShowStrategy = (props) => {
  return (
    <Show actions={null} {...props}>
      <StrategyView {...props} />
    </Show>
  );
};

export const EditStrategy = (props) => {
    return (
        <Show actions={null} {...props}>
            <StrategyEdit {...props} />
        </Show>
    );
};
