import { STATUSES } from "./statuses";

export const SCREENERS_ALL_LOADING = "SCREENERS_ALL_LOADING";
export const SCREENERS_ALL_LOAD_SUCCESS = "SCREENERS_ALL_LOAD_SUCCESS";
export const SCREENERS_ALL_LOAD_FAIL = "SCREENERS_ALL_LOAD_FAIL";

export default (
  previousState = { data: [], status: STATUSES.INIT },
  { type, payload }
) => {
  switch (type) {
    case SCREENERS_ALL_LOADING: {
      return {
        ...previousState,
        status: STATUSES.LOADING,
      };
    }
    case SCREENERS_ALL_LOAD_SUCCESS: {
      return { data: payload.screenersAll, status: STATUSES.LOAD_SUCCESS };
    }
    case SCREENERS_ALL_LOAD_FAIL: {
      return { data: [], status: STATUSES.LOAD_FAIL };
    }
    default:
      return previousState;
  }
};
