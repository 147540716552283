export const BRACKETS = {
  NOT: "not",
  OPEN: "open",
  CLOSE: "close",
  EQUAL: "equal",
};

export const getBracket = (el) => {
  let open = (el.match(/\(/g) || []).length;
  let close = (el.match(/\)/g) || []).length;
  switch (true) {
    case open === 0 && close === 0:
      return BRACKETS.NOT;
    case open > close:
      return BRACKETS.OPEN;
    case open < close:
      return BRACKETS.CLOSE;
    case open === close:
      return BRACKETS.EQUAL;
  }
};

export function getParamsStr(lineStr) {
  const match = lineStr.match(/\((.*)\)/);
  return match ? match[1] : "";
}

export function parentIndicator(lineStr) {
  const indicators = lineStr.split("$");
  let lastIndicator = indicators[indicators.length - 1];
  lastIndicator = substringBefore(lastIndicator, [".", "(", ")"]);
  return singleIndicator(`$${lastIndicator}`);
}

export function substringBefore(str = "", substrings) {
  let result = str;
  substrings.forEach((substring) => {
    const values = result.split(substring);
    result = values[0];
  });
  return result;
}

export function singleIndicator($indicatorName = "", indicators) {
  const indicatorList = indicators || matchingIndicators($indicatorName);
  if (indicatorList) {
    return indicatorList.filter((indicator) => {
      const aliases = indicator.aliases || [];
      return aliases.includes($indicatorName.substring(1)) || indicator.name === $indicatorName.trim();
    })[0];
  }
}

export function matchingIndicators($indicatorName = "") {
  return window.INDICATORS.filter((indicator) => {
    const aliases = indicator.aliases || [];
    const name = indicator.name.toLowerCase();
    return aliases.includes($indicatorName.substring(1)) || name.startsWith($indicatorName.trim().toLowerCase());
  });
}

export function getIndicatorName(lastParentValue = "", currentPosValue = "") {
  let value = lastParentValue;

  if (getBracket(value) === BRACKETS.EQUAL) {
    let params;
    if (value.match(/\(/g).length > 1) {
      let allValues = value.split("(");
      let neededValue = allValues[allValues.length - 1];
      params = getParamsStr(`(${neededValue}`);
    } else {
      params = getParamsStr(value);
    }

    const valueWithoutParams = value.replace(params, "");
    const indicator = parentIndicator(valueWithoutParams);
    return indicator?.name;
  } else if (getBracket(value) === BRACKETS.CLOSE) {
    for (let i = currentPosValue.length - 2; i >= 0; i--) {
      if (getBracket(currentPosValue[i]) === BRACKETS.OPEN) {
        value = currentPosValue[i];
        break;
      }
    }
  } else if (getBracket(value) === BRACKETS.NOT) {
    return value.replace(".", "");
  } else if (getBracket(value) === BRACKETS.OPEN) {
    let resIndicator = value.split("$").filter((el) => el.includes("(") || !el.replace(" ", "").endsWith(")."));
    let lastIndicator = resIndicator[resIndicator.length - 1];
    if (lastIndicator.endsWith(".")) lastIndicator = lastIndicator.replace(".", "");
    const params = getParamsStr(lastIndicator);
    if (params) lastIndicator = lastIndicator.replace(params, "");
    const indicator = parentIndicator(params ? `$${lastIndicator}` : `$${lastIndicator})`);
    return indicator?.name;
  }

  return value.split("(")[0];
}

export function needAutoCloseBrackets(currentPosValue, extendedLineValue, allCriteria) {
  return (
    currentPosValue.endsWith("(") && !extendedLineValue.endsWith(")") && getBracket(allCriteria) !== BRACKETS.EQUAL
  );
}

export function getCurrentPosValue(editorValue, pos) {
  const currentLinesArray = editorValue.split("\n");
  //remove everything after the current cursor position
  currentLinesArray.length = pos.row + 1;
  currentLinesArray[currentLinesArray.length - 1] = currentLinesArray.slice(-1)[0].substring(0, pos.column);
  //remove comments
  const filteredcurrentLinesArray = currentLinesArray.map((el) => (el.charAt(0) === "#" ? " " : el));
  //remove all spaces and make the value an one-line
  return filteredcurrentLinesArray.join(" ").replace(/\s+/g, " ");
}
