import React, { useEffect, useState } from "react";
import {
  Filter,
  Datagrid,
  TopToolbar,
  useTranslate,
  useListContext,
  SearchInput,
  List,
  TextField,
  useRedirect,
  usePermissions,
  SimpleList,
  BulkDeleteButton,
} from "react-admin";
import { MomentField } from "../../components/MomentField";
import Tooltip from "@material-ui/core/Tooltip";
import AlgoType from "../../components/AlgoType";
import { ColoredNumber } from "../../components/ColoredNumber/ColoredNumber";
import { actions } from "../../shared/moreActions";
import MoreActions from "../../components/MoreActions/MoreActions";
import styles from "./Algo.module.scss";
import mobileStyles from "../../components/MobileList.module.css";
import { customFetch } from "../../DataProvider";
import CreateFromASample from "../../components/CreateFromASample/CreateFromASample";
import cn from "clsx";
import { formatLimitValue, getLimitRatio, setGaId } from "../../shared/utils";
import { ENTITY, GA_CATEGORIES, RATIO_LIMITS, TABLES } from "../../shared/variables";
import { useSelector, useDispatch } from "react-redux";
import { getPlanState, getUsageState } from "../../store/selectors";
import { VIEW_USERS_ALGOS } from "../../shared/permissions";
import { ToggleButtonsField } from "../../components/ToggleButtonsField/ToggleButtonsField";
import { Storage } from "../../config";
import LimitAlert from "../../components/Alert/alerts/LimitAlert";
import { GET_USAGE } from "../../store/sagas/usageSaga";
import DatagridIdsWrapper from "../../components/DatagridIdsWrapper/DatagridIdsWrapper";
import { isDesktop, isMobile } from "react-device-detect";
import { Link } from "ra-ui-materialui";
import useGetItemsPerPage from "../../components/ListPagination/hooks/useGetItemsPerPage";
import ListPagination from "../../components/ListPagination/ListPagination";
import useDisableByLimit from "../../shared/hooks/useDisableByLimit";
import ArchiveIcon from "react-feather/dist/icons/archive";
import { statuses } from "../../shared/statuses";
import {PortfolioType} from "./utils";

const filterStatuses = Object.freeze({ MINE: "MY ALGOS", ARCHIVED: "ARCHIVED", ALL: "ALL USERS" });

const BulkActionButtons = ({ ga, hasList, hasEdit, hasShow, hasCreate, ...rest }) => {
  const t = useTranslate();
  return (
    <BulkDeleteButton
      {...rest}
      id={setGaId(ga?.category, "algo-checkbox-archive")}
      icon={<ArchiveIcon />}
      label={t("actions.archive")}
    />
  );
};

const ListActions = ({ ga, samples, handleCreateFromASample, disableCreate, filterStatuses, setSwitch, owner, basePath }) => {
  const t = useTranslate();
  const { loaded, permissions } = usePermissions();
  const disableByLimit = useDisableByLimit();

  const { loading, filterValues, setFilters } = useListContext();
  const { MINE, ARCHIVED, ALL } = filterStatuses;

  const filterLabels = [t("algos.filter.mine"), t("algos.filter.archived"), t("algos.filter.all")];
  const filterNames = permissions?.includes(VIEW_USERS_ALGOS)
    ? Object.values(filterStatuses)
    : Object.values(filterStatuses).filter((status) => status !== ALL);

  const userAlgos = owner !== Storage.getItem("profile");

  const selectedSwitch = () => {
    if (filterValues.owner === owner && filterValues.statusNot === statuses.archived.id) return MINE;
    if (filterValues.owner === owner && filterValues.status === statuses.archived.id) return ARCHIVED;
    return ALL;
  };

  useEffect(() => {
    setSwitch(selectedSwitch());
  }, [loading]);

  const handleRequestSwitch = (event) => {
    const checked = event.target.id;
    switch (checked) {
      case MINE: {
        setFilters({ owner, statusNot: statuses.archived.id });
        break;
      }
      case ARCHIVED: {
        setFilters({ owner, status: statuses.archived.id });
        break;
      }
      default:
        setFilters({ owner: null });
    }
  };

  return loaded ? (
    <TopToolbar className={styles.toggleFilter}>
      {!userAlgos ? (
        //shorter label with + icon for mobile
        <>
          <ToggleButtonsField
            ga={ga}
            onChange={handleRequestSwitch}
            defaultValue={selectedSwitch()}
            inputNames={filterNames}
            inputLabels={filterLabels}
          />
          <CreateFromASample
            ga={ga}
            label={isDesktop ? t("algos.create") : t("algos.algo")}
            samples={samples}
            basePath={basePath}
            handleCreateFromASample={handleCreateFromASample}
            entity={ENTITY.ALGO}
            disabled={disableCreate || disableByLimit.algos}
          />
        </>
      ) : null}
    </TopToolbar>
  ) : null;
};

const AlgoFilter = ({ ga, record }) => {
  return (
    <Filter variant="standard" record={record}>
      <SearchInput source="q" variant="standard" alwaysOn id={setGaId(ga?.category, "search")} />
    </Filter>
  );
};

const NameField = ({ record, source }) => {
  return (
    <Tooltip title={record?.[source]}>
      <span>{record?.[source]}</span>
    </Tooltip>
  );
};

export const AlgoList = (props) => {
  const basePath = props.basePath;
  const t = useTranslate();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const [limitAlert, setLimitAlert] = useState({});
  const { loaded, permissions } = usePermissions();
  const [disableCreate, setDisableCreate] = useState();
  const sharedBacktests = useSelector((state) => state.sharedBacktests.data);

  const owner = props.ownerId || Storage.getItem("profile");
  const [switcher, setSwitch] = useState(filterStatuses.MINE);

  const tableName = TABLES.ALGOS;
  const perPage = useGetItemsPerPage(tableName);

  const ga = { category: GA_CATEGORIES.ALGO_LAB };

  const usage = useSelector(getUsageState) || {};
  const { algos, status } = usage || {};
  const { algos_limit } = useSelector(getPlanState) || {};

  const showAlert = () => {
    const limitRatio = getLimitRatio(algos, algos_limit, status);
    if (limitRatio !== RATIO_LIMITS.A_LOT) {
      const leftLimits = formatLimitValue(algos_limit) - algos;
      setLimitAlert({
        ratio: limitRatio,
        entity: ENTITY.ALGOS,
        limits: leftLimits,
      });
    }
  };

  useEffect(() => showAlert(), [algos, algos_limit]);

  const handleCreateFromASample = ({ id }) => {
    setDisableCreate(true);
    customFetch(`backtests/shared/${id}/clone`, "POST", null, "text")
      .then((backtest) => {
        dispatch({ type: GET_USAGE });
        const algoId = JSON.parse(backtest).algo.id;
        redirect(`algos/${algoId}`);
      })
      .catch(() => setDisableCreate(false));
  };

  const enableArchive = () => switcher === filterStatuses.MINE;
  const algosMobileClick = (id) => `/algos/${id}`;

  return loaded ? (
    <div className={cn({ [styles.mobileWrapper]: isMobile })}>
      <LimitAlert {...limitAlert} />
      <List
        {...props}
        sort={{ field: "last_run_date", order: "DESC" }}
        exporter={false}
        filterDefaultValues={{ owner, statusNot: statuses.archived.id }}
        actions={
          <ListActions
            ga={ga}
            basePath={basePath}
            samples={sharedBacktests}
            disableCreate={disableCreate}
            handleCreateFromASample={handleCreateFromASample}
            filterStatuses={filterStatuses}
            setSwitch={setSwitch}
            owner={owner}
          />
        }
        filters={<AlgoFilter ga={ga} {...props} />}
        className={styles.list}
        perPage={perPage}
        pagination={
          <ListPagination
            {...props}
            tableName={tableName}
            entity={ENTITY.ALGO}
            samples={props?.ownerId ? null : sharedBacktests}
            handleCreate={handleCreateFromASample}
            disableCreate={disableCreate}
          />
        }
        bulkActionButtons={enableArchive() && <BulkActionButtons ga={ga} {...props} />}

        filter={{portfolioType: props.portfolioType || PortfolioType.INTRADAY }}
        resource="algos"
        basePath="algos"
      >
        {isDesktop ? (
          <Datagrid style={{ tableLayout: "fixed" }} rowClick={props.ownerId ? "show" : "edit"} size="medium">
            <DatagridIdsWrapper ga={ga} headerClassName="IdsWrapper-Col" cellClassName="IdsWrapper-Col" />
            <TextField source="fake" label="" headerClassName="Empty-Col" />

            <AlgoType
              label={t("algos.algo_type")}
              source="algo_type"
              headerClassName={cn("Status-Col", styles.noVPadding)}
              cellClassName={cn("Status-Col", styles.noVPadding)}
            />
            <NameField source="name" cellClassName="Other-Col Name-Col" headerClassName="Other-Col Name-Col" />

            <ColoredNumber
              label={t("backtest.max_return_pct")}
              style={{ percent: "percent" }}
              source="max_return_pct"
              cellClassName="Other-Col MuiTableCell-alignRight"
              headerClassName="Other-Col"
            />
            <TextField
              source="number_of_backtests"
              label={t("backtest.backtests")}
              cellClassName="Other-Col MuiTableCell-alignRight"
              headerClassName="Other-Header"
            />

            <MomentField
              source="created_date"
              label={t("algos.created")}
              fromNow={true}
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />
            <MomentField
              source="last_run_date"
              label={t("algos.last_run")}
              fromNow={true}
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />

            {permissions?.includes(VIEW_USERS_ALGOS) ? (
              <TextField
                source="owner.name"
                label="Author"
                cellClassName="Other-Col"
                headerClassName="Other-Col"
                sortable={false}
              />
            ) : null}

            <MoreActions
              cellClassName="Actions-Col"
              {...props}
              ga={ga}
              items={[actions.launch_paper, actions.launch_live, actions.archive, actions.clone]}
            />
          </Datagrid>
        ) : (
          <SimpleList
            linkType={false}
            className={mobileStyles.list}
            primaryText={(record) => (
              <div className={mobileStyles.container}>
                <Link to={() => algosMobileClick(record?.id)}>
                  <div className={mobileStyles.name}>
                    <NameField record={record} source="name" />
                  </div>
                  <div className={mobileStyles.date}>
                    {t("algos.last_run")}&nbsp;
                    <MomentField record={record} source="last_run_date" fromNow={true} />
                  </div>
                  <div className={mobileStyles.items}>
                    <ColoredNumber
                      record={record}
                      style={{ percent: "percent" }}
                      source="max_return_pct"
                      className={mobileStyles.gain}
                    />
                    <AlgoType record={record} source="algo_type" />
                  </div>
                </Link>
                <div className={mobileStyles.actions}>
                  <MoreActions
                    {...props}
                    record={record}
                    ga={ga}
                    items={[actions.launch_paper, actions.launch_live, actions.archive, actions.clone]}
                  />
                </div>
              </div>
            )}
          />
        )}
      </List>
    </div>
  ) : null;
};
