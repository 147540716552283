import { put, takeEvery, call, delay, cancelled, race, take } from "redux-saga/effects";
import { authFetchAPI } from "../../DataProvider";
import {
  TRADING_UPDATING,
  TRADING_UPDATE_SUCCESS,
  TRADING_UPDATE_FAIL,
  TRADING_REFRESH,
  TRADING_CLEAR,
} from "../reducers/tradingReducer";
import { GET_USAGE } from "./usageSaga";
import { statuses } from "../../shared/statuses";
import { ENGINES } from "../../shared/variables";
import { TRADING_EXITING_ADD, TRADING_EXITING_CLEAR, TRADING_EXITING_REMOVE } from "../reducers/tradingExitingReducer";
import { TRADING_LONG_EXITING_REMOVE } from "../reducers/tradingLongExitingReducer";

export const TRADING_SAVE = "TRADING_SAVE";
export const TRADINGS_POLLING = "TRADINGS_POLLING";
export const CANCEL_TRADINGS_POLLING = "CANCEL_TRADINGS_POLLING";
export const BREAKING_EQUITY = ENGINES.BE.id;

export default function* tradingSaga() {
  yield takeEvery(TRADING_SAVE, function* ({ payload: { id, data, notify, t } }) {
    yield put({ type: TRADING_UPDATING });

    if (!data.broker) {
      data.broker = BREAKING_EQUITY;
    }
    try {
      // no brokerName when saving without validation
      if (data.broker !== BREAKING_EQUITY) {
        const validationRes = yield call(authFetchAPI, `brokers/validate?broker=${data.broker?.toUpperCase()}`);
        const validationData = yield validationRes.json();

        if (validationData.result !== "OK") {
          yield put({
            type: TRADING_UPDATE_FAIL,
            payload: { errorMessage: "tradings.not_linked" },
          });
          return;
        }
      }

      let res;
      const isDraft = data?.status === statuses.draft.id;
      if (id) {
        const url = isDraft ? `tradings/${id}` : `tradings/${id}/launch`;

        res = yield call(authFetchAPI, url, {
          body: JSON.stringify(data),
          method: "PUT",
        });
      } else {
        const url = isDraft ? `tradings` : `tradings/launch`;
        res = yield call(authFetchAPI, url, {
          body: JSON.stringify(data),
          method: "POST",
        });
      }

      if (!res.ok) {
        const { code, message } = yield res.json();
        const brokerValidation = code ? `tradings.broker_validation.${code}` : null;
        yield put({
          type: TRADING_UPDATE_FAIL,
          payload: {
            errorMessage: brokerValidation || message || "ra.notification.http_error",
          },
        });
        return;
      }
      if (res.ok && notify && t) {
        notify(t("tradings.start", "info"));
      }

      yield put({ type: GET_USAGE });
      yield put({
        type: TRADING_UPDATE_SUCCESS,
        payload: { message: "tradings.saved" },
      });
    } catch (e) {
      yield put({
        type: TRADING_UPDATE_FAIL,
        payload: {
          errorMessage: e.message ? `Error: ${encodeURIComponent.message}` : "ra.notification.http_error",
        },
      });
      console.error(e);
    }
  });

  yield takeEvery(TRADINGS_POLLING, function* ({ payload: id }) {
    yield put({ type: TRADING_EXITING_ADD, payload: id });

    function* polling() {
      try {
        while (true) {
          let res = yield call(authFetchAPI, `tradings/${id}`, { method: "GET" });
          const resData = yield res.json();
          if (resData?.status !== statuses.exiting.id) {
            yield put({ type: TRADING_EXITING_REMOVE, payload: id });
            yield put({ type: TRADING_LONG_EXITING_REMOVE, payload: id });
            yield put({ type: TRADING_REFRESH });
            break;
          }
          yield delay(4000);
        }
      } catch (e) {
        yield put({ type: TRADING_EXITING_REMOVE, payload: id });
        yield put({ type: TRADING_LONG_EXITING_REMOVE, payload: id });
        yield put({ type: TRADING_CLEAR });
        console.error(e);
      } finally {
        if (yield cancelled()) {
          yield put({ type: TRADING_EXITING_CLEAR });
          yield put({ type: TRADING_CLEAR });
        }
      }
    }

    yield race({
      task: call(polling),
      cancel: take(CANCEL_TRADINGS_POLLING),
    });
  });
}
