import * as React from "react";
import {
  TextField,
  List,
  Datagrid,
  useTranslate,
  SimpleList,
  NumberField,
} from "react-admin";
import {isDesktop} from "react-device-detect";

//validation
import { MomentField } from "../../components/MomentField";
import classes from "./Leaderboard.module.css"
// import ListPagination from "../../components/ListPagination/ListPagination";
import {ColoredNumber} from "../../components/ColoredNumber/ColoredNumber";
import { MedalOutline } from "react-ionicons";
import {BrokerIcon} from "../Trading/components/Brokers/BrokersBlock";
import {WinLossRatio} from "../../components/AlgolFields/AlgoFields";


export const RankField = (props) => {
  const rank = props.record.id;

  switch (rank) {
    case 1:
      return <><span className={classes.medal}>#{rank}</span> <MedalOutline className={classes.gold}/></>;
    case 2:
      return <><span className={classes.medal}>#{rank}</span> <MedalOutline className={classes.silver}/></>;
    case 3:
      return <><span className={classes.medal}>#{rank}</span> <MedalOutline className={classes.bronze}/></>;
    default:
      return <span className={classes.medal}>#{rank}</span>;
  }
}

export const BrokersField = (props) => {
  const brokers = props.record.brokers.split(",");

  return (
      <>
        {brokers.map((broker, index) => (
            <span className={classes.brokerIcon}>
              <BrokerIcon value={broker} />
            </span>
        ))}
      </>
  )
}

export const LeaderboardList = (props) => {
  const t = useTranslate();

  return (
    <List
      {...props}
      className={classes.list}
      exporter={false}
      perPage={10}
      sort={{ field: "user_rank", order: "ASC" }}
      pagination={null}
      // actions={false}
      bulkActionButtons={false}
    >
      {isDesktop ?
      (<Datagrid rowClick={false} size="medium" className={classes.datagrid}>

        <TextField source="none"
                   sortable={false}
                   label=""
                   headerClassName="Fake-Col"/>

        <RankField source="id"
                   sortable={false}
                   label={t("leaderboard.rank")}
                   headerClassName="Status-Col"/>

        <TextField source="user_name"
                   sortable={false}
                   label={t("leaderboard.user_name")}
                   headerClassName="Short-Name-Col"/>

        <ColoredNumber source="gain"
                       sortable={false}
                       style="currency"
                       cellClassName="MuiTableCell-alignRight"
                       headerClassName="Status-Col"/>

        <NumberField source="tradings"
                     label={t("subscriptions.strategies")}
                     sortable={false}
                     headerClassName="Status-Col"/>

        <NumberField source="trades_made"
                     sortable={false}
                     label={t("leaderboard.trades_made")}
                     headerClassName="Status-Col"/>

        <WinLossRatio
            label={t("backtest.win_loss")}
            source="winsLossRatio"
            sortable={false}
        />

        <MomentField
            sortable={false}
            source="start_date"
            label={t("leaderboard.start_date")}
            fromNow={true}
        />

        <BrokersField source="brokers" sortable={false} />

      </Datagrid>) :

      <SimpleList

          primaryText={record =>

          <div className={classes.primaryText}>
            <span className={classes.userName}>@<TextField record={record} source="user_name" /></span>
            <div>
              <div>&nbsp;|&nbsp;</div>
              <NumberField record={record}
                           source="trades_made"/>
              <div>&nbsp;{t("leaderboard.trades")}&nbsp;</div>
            </div>
            <div className={classes.mobileRank}>
            <RankField record={record}
                       source="id"
                       label={t("leaderboard.rank")}/>
            </div>
          </div>}

          secondaryText={record =>

          <div className={classes.secondaryText}>
            <div>
              <div>{t("leaderboard.start_date")}&nbsp;</div>
              <MomentField record={record} source="start_date" fromNow={true}/>
            </div>
          </div>}

          tertiaryText={record =>
              <span className={classes.tertiaryText}>
                <ColoredNumber source="gain"
                               signDisplay="exceptZero"
                               record={record}
                               style="currency"/>
              </span>
          }
          linkType={false}
          className={classes.simpleList}

      />
      }
    </List>
  );
};


