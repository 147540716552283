import { useRef } from "react";
import { useDispatch } from "react-redux";
import { LINK_BROKER } from "../../../../../store/sagas/brokersSaga";
import {authFetchAPI} from "../../../../../DataProvider";
import {getBrokerRedirectUri} from "../../../../../shared/utils";

export default (setAgreementShow) => {
  const loginWindowRef = useRef(null);
  const intervalRef = useRef(null);
  const dispatch = useDispatch();

  // BE-337
  let redirectUri = getBrokerRedirectUri();

  return function () {
    if (loginWindowRef.current == null || loginWindowRef.current.closed) {
      authFetchAPI("brokers/request-token?broker=IBKR", {method: "GET"}).then(async (res) => {
        let token = await res.text();
        loginWindowRef.current = window.open(
            `https://www.interactivebrokers.com/authorize?oauth_token=${token}`,
            "_blank",
            "width=450,height=651"
        );
      });

    } else {
      loginWindowRef.current.focus();
    }

    // now we are trying to get the code
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      let queryParams = null;

      try {
        loginWindowRef.current.postMessage("broker", getBrokerRedirectUri());
        queryParams = loginWindowRef.current?.location?.search;
      } catch (e) {
        // waiting for the redirect to get query params
      }
      if (queryParams) {
        const oauth_token = new URLSearchParams(queryParams).get("oauth_token");
        const oauth_verifier = new URLSearchParams(queryParams).get("oauth_verifier");
        if (!(oauth_token || oauth_verifier)) {
          loginWindowRef.current.close();
          clearInterval(intervalRef.current);
          return;
        }
        const code = oauth_token + "|" + oauth_verifier;
        const body = JSON.stringify({
          code,
          redirect_uri: redirectUri,
          broker: "IBKR",
        });
        dispatch({
          type: LINK_BROKER,
          payload: { brokerName: "ibkr", body },
        });
        if (setAgreementShow) {
          setAgreementShow((state) => {
            return { ...state, ibkr: false };
          });
        }
        loginWindowRef.current.close();
        clearInterval(intervalRef.current);
      }
    }, 100);
  };
};
