import { Loading, useDataProvider, useNotify, useTranslate } from "react-admin";
import React, { useEffect, useState } from "react";
import classes from "./ExpandPanel.module.css";
import Config, { Storage } from "../../../../../config";
import NoResultsFound from "../../../../NoResultsFound/NoResultsFound";
import { TradesChart } from "../TradesChart/TradesChart";
import { ToggleButtonsFieldGroup } from "../../../../ToggleButtonsField/ToggleButtonsField";
import { LOCALSTORAGE, ORDER_EXIT_TYPES } from "../../../../../shared/variables";
import { ALGO_TYPES } from "../../../../AlgoType";
import { isEmpty } from "lodash";
import { isDesktop } from "react-device-detect";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import OrdersList, { getOrderDetails } from "./OrdersList";
import { chartDates } from "../TradesChart/tradingview/TVWidget";
import moment from "moment";

const TABS = { ORDERS: "ORDERS", CHART: "CHART" };

const getChartsInfo = (orders, activeTicker, chart, chartId) => {
  const tickers = [...new Set(orders.map((order) => order.ticker))];
  const ordersGroupedByTicker = tickers.map((ticker) => {
    const ordersWithEnterExitValues = orders.filter(
      (order) => order.ticker === ticker && order.size !== 0 && order.price !== 0
    );

    let ordersInfo = [];
    ordersWithEnterExitValues.forEach((order) => {
      const orderDetails = getOrderDetails({ order, side: "fill" });
      const orderInfo = {
        type: ORDER_EXIT_TYPES[order?.type]?.label || "Exit",
        size: orderDetails?.size,
        price: orderDetails?.price,
        datetime: order?.datetime,
        created_datetime: order?.created_datetime,
      };

      if (order.size > 0) {
        if (chart?.algo_type === ALGO_TYPES.LONG) orderInfo.type = "Enter";
        ordersInfo = [orderInfo, ...ordersInfo];
      } else if (order.size < 0) {
        if (chart?.algo_type === ALGO_TYPES.SHORT) orderInfo.type = "Enter";
        ordersInfo = [orderInfo, ...ordersInfo];
      }
    });

    return { ticker, ordersInfo, active: ticker === activeTicker, date: orders[0]?.date };
  });

  Storage.setItem(LOCALSTORAGE.ORDER_CHARTS, JSON.stringify({ [chartId]: ordersGroupedByTicker }));
};

const ExpandPanel = (props) => {
  const dataProvider = useDataProvider();
  const [orders, setOrders] = useState([]);
  const [dayOrders, setDayOrders] = useState([]);

  const [chart, setChart] = useState({});
  const [total, setTotal] = useState(null);
  const [criteriaValue, setCriteriaValue] = useState([]);
  const [enterCriteria, setEnterCriteria] = useState(null);
  const notify = useNotify();
  const t = useTranslate();

  const orderId = `${props.record.id}${TABS.ORDERS}`;
  const chartId = `${props.record.id}${TABS.CHART}`;

  useEffect(() => {
    props.criteria.enterInterval === props.criteria.exitInterval ? setEnterCriteria(null) : setEnterCriteria(true);
  }, [props.criteria]);

  useEffect(() => {
    if (enterCriteria === null) {
      setCriteriaValue([...props.criteria.enterIndicators, ...props.criteria.exitIndicators]);
    } else if (enterCriteria === true) {
      setCriteriaValue(props.criteria.enterIndicators);
    } else if (enterCriteria === false) {
      setCriteriaValue(props.criteria.exitIndicators);
    }
  }, [props.criteria, enterCriteria]);

  useEffect(() => {
    if (props?.serializedResult) {
      const { days } = JSON.parse(props?.serializedResult || "{}") || {};
      let orders = [];
      days
        .reverse()
        .filter((day) => moment(day.date).isSameOrBefore(moment(props.record.date)))
        .forEach((day) => {
          day.orders.forEach((order) => {
            order.created_datetime = order.datetime;
            order.created_size = order.size;
            order.date = moment(order.datetime).format(Config.INT_DATE_FORMAT);
            orders.push(order);
          });
        });
      setTotal(orders?.length);
      setOrders(orders); //chart orders
      setDayOrders(
        orders.filter((order) => {
          //orders list for the trades day
          return order.date === props.record.date;
        })
      );
    } else {
      dataProvider
        .getList(props.tradesOrdersResource, {
          pagination: {
            page: 1,
            perPage: 960 * 3, //max 960 trading minutes
          },
          sort: { field: "created_datetime", order: "DESC" },
          filter: {
            date: chartDates(props.record.date), //MVP1-2954: getting prior dates orders
            ...props.filter,
          },
        })
        .then((res) => {
          setTotal(res.data.length);
          setOrders(res.data); //chart orders
          setDayOrders(
            res.data.filter((order) => {
              //orders list for the trades day
              return order.date === props.record.date;
            })
          );
        })
        .catch((error) => {
          console.error(error);
          notify(t("backtest.operation_error"));
        });
    }
  }, [props.record.date]);

  const handleTabsSwitch = ({ parentId, order }) => {
    switch (parentId) {
      case orderId: {
        setChart({ parentId });
        const stored = JSON.parse(Storage.getItem(LOCALSTORAGE.ORDER_CHARTS));
        delete stored[parentId.replace("ORDERS", "")];
        if (isEmpty(stored)) {
          Storage.removeItem(LOCALSTORAGE.ORDER_CHARTS);
        } else {
          Storage.setItem(LOCALSTORAGE.ORDER_CHARTS, JSON.stringify(stored));
        }
        break;
      }
      case chartId: {
        setChart({ parentId, order });
        getChartsInfo(orders, order.ticker, props.chart, parentId.replace("CHART", ""));
        break;
      }

      default:
        return;
    }
  };

  if (total === null) return <Loading />;

  if (total > 0) {
    return (
      <>
        {isDesktop ? (
          <div className={classes.toggleButtons}>
            <ToggleButtonsFieldGroup
              id={props.record.id}
              ga={props.ga}
              onChange={({ target }) =>
                handleTabsSwitch({
                  parentId: target.id,
                  order: orders ? orders[orders.length - 1] : null,
                })
              }
              defaultValue={TABS.ORDERS}
              value={chart?.order?.ticker && chart?.parentId === chartId ? chartId : orderId}
              inputNames={[TABS.ORDERS, TABS.CHART]}
              inputLabels={[TABS.ORDERS, TABS.CHART]}
            />
            {chart?.order?.ticker &&
              chart?.parentId === chartId &&
              props.criteria.enterInterval !== props.criteria.exitInterval && (
                <div className={classes.toggleInterval}>
                  <span>
                    {t("intervals.only_one", {
                      interval: enterCriteria ? t("fieldsName.enter_interval") : t("fieldsName.exit_interval"),
                    })}
                  </span>
                  <div onClick={() => setEnterCriteria(!enterCriteria)} className={classes.toggleIntervalOn}>
                    {enterCriteria ? t("fieldsName.exit_interval") : t("fieldsName.enter_interval")}
                  </div>
                </div>
              )}
          </div>
        ) : null}
        <div className={classes.orders}>
          {chart?.order?.ticker && chart?.parentId === chartId ? (
            <TradesChart
              containerId={props.record.id}
              chart={{
                ...props.chart,
                interval: enterCriteria
                  ? parseInt(props.criteria.enterInterval) / 60
                  : parseInt(props.criteria.exitInterval) / 60,
                indicators: enterCriteria === null ? uniqWith(criteriaValue, isEqual) : criteriaValue,
              }}
            />
          ) : (
            <OrdersList chartId={chartId} orders={dayOrders} onTickerClick={handleTabsSwitch} />
          )}
        </div>
      </>
    );
  } else {
    return <NoResultsFound />;
  }
};

export default ExpandPanel;
