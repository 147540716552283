import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ChevronUp from "react-feather/dist/icons/chevron-up";
import ChevronDown from "react-feather/dist/icons/chevron-down";
import Collapse from "@material-ui/core/Collapse";
import { useTranslate } from "react-admin";
import { formatLimitValue } from "../../../../../../shared/utils";
import Alert from "../../../../../../components/Alert/Alert";
import classes from "./ExpandSubscriptionBlock.module.css";
import { ALERT } from "../../../../../../components/Alert/variables";
import { PLANS } from "../../../../../../shared/variables";

export const ExpandSubscriptionBlock = ({ id, ga, plan, cancelSubscription }) => {
  const [isOpen, setIsOpen] = useState(false);
  const t = useTranslate();
  const { backtests_limit, algos_limit, screeners_limit, name } = plan || {};

  const alert = [
    {
      id: "cancel_subscription",
      accordion: { label: "subscriptions.upgrade_cancellation" },
      type: ALERT.BRAND,
      data: [
        {
          header: "subscriptions.upgrade_info",
          description: ["subscriptions.upgrade_info_doc"],
        },
        {
          header: "subscriptions.can_cancel",
          description: [
            t("subscriptions.can_cancel_doc", {
              backtests_limit: formatLimitValue(backtests_limit),
              algos_limit: formatLimitValue(algos_limit),
              screeners_limit: formatLimitValue(screeners_limit),
            }),
          ],
        },
      ],
      action:
        name !== PLANS.FREE
          ? {
              label: "subscriptions.cancel_subscription",
              id: `${ga?.action}-cancel-subscription`,
              onClick: cancelSubscription,
            }
          : null,
    },
    {
      id: "subscription_mechanics",
      accordion: { label: "subscriptions.sub_mechanics" },
      type: ALERT.BRAND,
      data: [
        {
          header: "subscriptions.algo_screeners_charges",
          description: [
            "subscriptions.algo_screeners_charges_doc_1",
            "subscriptions.algo_screeners_charges_doc_2",
            "subscriptions.algo_screeners_charges_doc_3",
          ],
        },
        {
          header: "subscriptions.backtest_charges",
          description: [
            "subscriptions.backtest_charges_doc_1",
            "subscriptions.backtest_charges_doc_2",
            "subscriptions.backtest_charges_doc_3",
            "subscriptions.backtest_charges_doc_4",
          ],
        },
        {
          header: "subscriptions.sub_help",
          description: ["subscriptions.sub_help_doc1"],
        },
      ],
    },
  ];

  const getAlert = () => alert.find((item) => item.id === id);

  return (
    <div className={classes.accordionContainer}>
      <div className={classes.accordionHeader}>
        <span>{t(getAlert()?.accordion?.label)}</span>
        <IconButton
          className={classes.openButton}
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </IconButton>
      </div>
      <Collapse in={isOpen}>
        <div className={classes.accordionBody}>
          <Alert ga={ga} data={getAlert()} />
        </div>
      </Collapse>
    </div>
  );
};

export default ExpandSubscriptionBlock;
