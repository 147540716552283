import React from "react";
import { useTranslate } from "react-admin";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";
import Typography from "@material-ui/core/Typography";
import classes from "./Carousel.module.css";
import slides from "./slides";

export const Carousel = ({ exploreAlgosRef, disableCreate }) => {
  const t = useTranslate();

  const settings = {
    loop: false,
    lazyload: true,
    mouseDrag: true,
    fixedWidth: 350,
    controls: false,
    navPosition: "bottom",
  };

  return (
    <div className={classes.swiperWrapper}>
      {slides ? (
        <TinySlider settings={settings}>
          {slides.map(({ image, heading, text, footer }, id) => (
            <div key={id}>
              <div className={classes.slide}>
                <img src={image} alt="" className={classes.image} />
                <div className={classes.info}>
                  <Typography variant="h3" className={classes.heading}>{`${id + 1}. ${t(heading)}`}</Typography>
                  <p className={classes.text}>{t(text)}</p>
                  {footer({ exploreAlgosRef, disableCreate })}
                </div>
              </div>
            </div>
          ))}
        </TinySlider>
      ) : null}
    </div>
  );
};
