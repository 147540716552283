import React, {useState} from "react";
import styles from "../AdditionalExit.module.css";
import {BooleanInput, NumberInput, SelectInput, TextInput, useInput, useTranslate} from "react-admin";
import { Tooltip } from "@material-ui/core";
import Config from "../../../config";
import clsx from "clsx";
import StyledTextInput from "../../StyledTextInput/StyledTextInput";
import PercentIcon from "react-feather/dist/icons/percent";
import DollarIcon from "react-feather/dist/icons/dollar-sign";
import {HelpCircle} from "react-ionicons";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import cn from "clsx";

const MaxRiskInput = (item, ...props) => {
  const t = useTranslate();
  const [showToolTip, setShowToolTip] = useState(false);
  function openTooltip() {
    setShowToolTip(true);
  }

  function closeTooltip() {
    setShowToolTip(false);
  }

  const {
    meta: { error },
  } = useInput(props);

  return (
    <>
      <BooleanInput
        label={item.label}
        source={item.enabled}
        data-testid={`${item.testid}-checkbox`}
        className={clsx(styles.switch, styles.formItem)}
      />

      <div className={clsx(styles.riskInputWrapper, styles.formItem)}>
        <Tooltip
          open={showToolTip}
          title={t(`helpers.${item.value}`)}
          enterDelay={Config.TOOLTIP_DELAY}
          enterNextDelay={Config.TOOLTIP_DELAY}
        >
          <div className={clsx({ inputGrey: !item.values[item.enabled] })}>
            <StyledTextInput
              id={item.gaId}
              data-testid={`${item.testid}-input`}
              source={item.value}

              format={v => {
                  if (v && item.values[item.type] === "PERCENTAGE" && !isNaN(v) && !v.toString().endsWith(".")) {
                      return parseFloat((v * 100).toFixed(2));
                  }
                  return v;
              }}
              parse={v => {
                  if (v && item.values[item.type] === "PERCENTAGE" && !isNaN(v) && !v.toString().endsWith(".")) {
                      return parseFloat((parseFloat(v) / 100).toFixed(4));
                  }
                  return v;
              }}
              className={clsx(styles.riskInput, { [styles.comboRight]: !!item.type })}
              variant="outlined"
              label={false}
              disabled={!item.values[item.enabled]}
            />
          </div>
        </Tooltip>

        {item.type ? (
          <SelectInput
            source={item.type}
            choices={[
              { id: "PERCENTAGE", name: <PercentIcon className={styles.svg} /> },
              { id: "ABSOLUTE", name: <DollarIcon className={styles.svg} /> },
            ]}
            variant="outlined"
            label=""
            className={clsx(styles.typeSelect, styles.comboLeft, { inputGrey: !item.values[item.enabled] })}
            translateChoice={false}
            disabled={!item.values[item.enabled]}
          />
        ) : null}
      </div>
      <ClickAwayListener onClickAway={closeTooltip}>
        <div onClick={openTooltip} onBlur={closeTooltip} className={styles.helpIcon}>
            <HelpCircle />
        </div>
      </ClickAwayListener>

    </>
  );
};

export default MaxRiskInput;
