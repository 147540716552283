import React from "react";
import { useRedirect, useTranslate } from "react-admin";
import Alert from "../Alert";
import { ALERT, SUBSCRIPTIONS_URL } from "../variables";

const PlanIncludesNoLimit = ({ entity }) => {
  const t = useTranslate();
  const redirect = useRedirect();
  const toSubscriptions = () => redirect(SUBSCRIPTIONS_URL);

  const data = {
    type: ALERT.WARNING,
    data: [
      {
        header: t("subscriptions.plan_includes_no_limit", { entity }),
        description: [t("subscriptions.limit_desc")],
      },
    ],
    action: {
      label: "subscriptions.upgrade_plan",
      onClick: toSubscriptions
    },
  };

  return <Alert data={data} />;
};

export default PlanIncludesNoLimit;
