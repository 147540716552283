import React from "react";
import styles from "./CriteriaBlock.module.css";
import CriteriaInput from "../CriteriaInput/CriteriaInput";
import { useTranslate, required, minLength } from "react-admin";
import CollapseBlock from "../CollapseBlock/CollapseBlock";
import { useFormState } from "react-final-form";
import NarrowRow from "../Rows/NarrowRow";
import Label from "../Rows/Label";
import { setGaId } from "../../shared/utils";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

export const firstLineComment = (criteria) => {
  let value = criteria || "";
  value = value.split("\n")[0].trim();

  return value.startsWith("#") ? value.substring(1) : "";
};

export const CriteriaCollapseBlock = (props) => {
  return (
    <CollapseBlock
      id={setGaId(props.ga?.category, "criteria-toggle")}
      onExpand={props.onExpand}
      onCollapse={props.onCollapse}
      collapsible={true}
      short={<DescriptionLine record={props.record} />}
      full={<CriteriaBlock {...props} ga={props.ga} />}
      openOnError={["enter_criteria", "exit_criteria"]}
      open={true}
      className="Criteria"
    />
  );
};

export const DescriptionLine = () => {
  const t = useTranslate();
  const { values } = useFormState();
  const enter = firstLineComment(values.enter_criteria);
  const exit = firstLineComment(values.exit_criteria);

  let description = enter && exit ? enter + " | " + exit : "";

  return (
    <NarrowRow>
      <Label> {description || t("algos.helpers.expand_criteria")}</Label>
    </NarrowRow>
  );
};

const inputParams = {
  screeners: {
    left: {
      id: "filter-criteria",
      source: "filter_criteria",
      label: "screeners.filter_criteria",
      helperText: "screeners.filter_criteria_helper",
      autocompleteBlacklist: ["$Day"],
    },
    right: {
      id: "score-criteria",
      source: "sort_criteria",
      label: "screeners.sort_criteria",
      helperText: "screeners.sort_criteria_helper",
    },
  },
  others: {
    left: {
      id: "enter-criteria",
      "data-testid": "algo-criteria-enter",
      source: "enter_criteria",
      label: "algos.helpers.enter_criteria.lbl",
      helperText: "helpers.enter_criteria",
    },
    right: {
      id: "exit-criteria",
      "data-testid": "algo-criteria-exit",
      source: "exit_criteria",
      label: "algos.helpers.exit_criteria.lbl",
      helperText: "helpers.exit_criteria",
    },
  },
};

export const CriteriaBlock = ({ ga, readOnly, fullScreen, resource, className }) => {
  const t = useTranslate();
  const { left, right } = resource?.includes("screener") ? inputParams.screeners : inputParams.others;

  return (
    <div className={clsx(styles.container, { [styles.mobile]: isMobile, className })}>
      <CriteriaInput
        {...left}
        id={setGaId(ga?.category, left?.id)}
        label={t(left?.label)}
        helperText={t(left?.helperText)}
        validate={!readOnly && [required(), minLength(1)]}
        readOnly={readOnly}
        fullScreen={fullScreen}
        left
      />
      <CriteriaInput
        {...right}
        id={setGaId(ga?.category, right?.id)}
        label={t(right?.label)}
        helperText={t(right?.helperText)}
        validate={!readOnly && [required(), minLength(1)]}
        readOnly={readOnly}
        fullScreen={fullScreen}
        right
      />
    </div>
  );
};
