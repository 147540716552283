import React, { useEffect, useRef, useState } from "react";
import {
  BulkDeleteButton,
  Filter,
  List,
  NumberField,
  SearchInput,
  Show,
  TopToolbar,
  useDataProvider,
  useListContext,
  useNotify,
  usePermissions,
  useRedirect,
  useRefresh,
  useTranslate,
} from "react-admin";
import { MomentField } from "../../components/MomentField";
import ArchiveIcon from "react-feather/dist/icons/archive";
import ClipboardIcon from "react-feather/dist/icons/clipboard";

import LayersIcon from "react-feather/dist/icons/layers";
import ZapIcon from "react-feather/dist/icons/zap";
import CancelIcon from "react-feather/dist/icons/x-circle";
import ShareIcon from "react-feather/dist/icons/share-2";
import CloneIcon from "react-feather/dist/icons/copy";
import UploadIcon from "react-feather/dist/icons/upload";

import { CREATE_SHARED_ALGOS, LIVE_TRADING, PAPER_TRADING } from "../../shared/permissions";
import { ToggleButtonsField } from "../../components/ToggleButtonsField/ToggleButtonsField";
import { actions } from "../../shared/moreActions";
import PopupMenu from "../../components/PopupMenu/PopupMenu";
import { statuses } from "../../shared/statuses";
import { ColoredNumber } from "../../components/ColoredNumber/ColoredNumber";
import styles from "./Backtest.module.css";
import { BacktestResults } from "./BacktestResults";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { DISABLED_FIELDS, ENGINES, ENTITY, GA_CATEGORIES, TABLES, TRADING_RESOURCE } from "../../shared/variables";
import { convertEdtEstToLocal, setGaId } from "../../shared/utils";
import { useDispatch } from "react-redux";
import { Storage } from "../../config";
import CustomizableDatagrid from "../../components/DataDrid/CustomizableDatagrid";
import { GET_USAGE } from "../../store/sagas/usageSaga";
import { useHistory } from "react-router-dom";
import Card from "../../components/Card/Card";
import StatusField from "../../components/StatusField/StatusField";
import { transformTime, useStopTrailDefaultValue } from "../Algo/utils";
import MobileDetect from "mobile-detect"
import BacktestMobileList from "../../components/BacktestMobileList/BacktestMobileList";
import useGetItemsPerPage from "../../components/ListPagination/hooks/useGetItemsPerPage";
import ListPagination from "../../components/ListPagination/ListPagination";
import TickerBenchmarkField from "../../components/TickerBenchmarkField/TickerBenchmarkField";
import useDisableByLimit from "../../shared/hooks/useDisableByLimit";
import { GET_SHARED_BACKTESTS } from "../../store/sagas/sharedBacktestsSaga";
import { RERUN_BACKTEST } from "../../store/reducers/rerunBacktestReducer";
import AlgoType from "../../components/AlgoType";
import { customFetch } from "../../DataProvider";
import {
  AutoExitField,
  IntervalField,
  RewardRiskRatio,
  RiskField,
  TimeField,
  WinLossRatio,
} from "../../components/AlgolFields/AlgoFields";

const BulkActionButtons = ({ ga, ...rest }) => {
  const t = useTranslate();
  return (
    <BulkDeleteButton
      {...rest}
      icon={<ArchiveIcon />}
      id={setGaId(ga?.category, `${ga?.action}-bulk-archive`)}
      label={t("actions.archive")}
    />
  );
};

const liveStatuses = [
  statuses.pending.id,
  statuses.completed.id,
  statuses.inProgress.id,
  statuses.failed.id,
  statuses.draft.id,
];

const archivedStatuses = [statuses.archived.id];

export const BacktestFilter = (props) => {
  return (
    <Filter variant="standard" className="Backtests-Filter" {...props}>
      <SearchInput
        source="q"
        variant="standard"
        alwaysOn
        id={setGaId(props.ga?.category, `${props.ga?.action}-search`)}
      />
    </Filter>
  );
};

export const BacktestToolbar = ({ ga, setSwitch, record, backtestsLoading, setBacktestsLoading }) => {
  const { loading, filterValues, setFilters, page, perPage, currentSort } = useListContext();
  const dataProvider = useDataProvider();
  const intervalRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    JSON.stringify(filterValues.status) === JSON.stringify(archivedStatuses)
      ? setSwitch(statuses.archived.id)
      : setSwitch(statuses.live.id);
  }, [loading]);

  const selectedSwitch = () => {
    return JSON.stringify(filterValues.status) === JSON.stringify(archivedStatuses)
      ? statuses.archived.id
      : statuses.live.id;
  };

  const handleRequestSwitch = (event) => {
    const checked = event.target.id;

    switch (checked) {
      case statuses.live.id: {
        setFilters({ status: liveStatuses });
        break;
      }
      case statuses.archived.id: {
        setFilters({ status: archivedStatuses });
        break;
      }
      default:
        return;
    }
  };

  function updateBacktestList() {
    if (!record.id) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
      setBacktestsLoading(false);
      return;
    }
    dataProvider
      .getOne("algos", { id: record.id })
      .then((res) => {
        if (!res.data?.backtests_in_progress) {
          window.clearInterval(intervalRef.current);
          intervalRef.current = null;
          setBacktestsLoading(false);
          dispatch({ type: GET_USAGE });
        }
        dataProvider
          .getList("backtests", {
            pagination: {
              page,
              perPage,
            },
            sort: { field: currentSort.field, order: currentSort.order },
            filter: { ...filterValues, algo: record.id },
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    if (backtestsLoading && !intervalRef.current) {
      updateBacktestList();
      intervalRef.current = window.setInterval(() => {
        updateBacktestList();
      }, 5000);
    }
  }, [backtestsLoading]);

  //unmount
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  return (
    <TopToolbar className={styles.backtestToolbar}>
      <ToggleButtonsField
        ga={ga}
        onChange={handleRequestSwitch}
        defaultValue={selectedSwitch()}
        inputNames={[statuses.live.id, statuses.archived.id]}
        inputLabels={[statuses.live.id, statuses.archived.id]}
      />
    </TopToolbar>
  );
};

const BacktestActions = ({ ga, record, items = [], resource, readOnly }) => {
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { loaded, permissions } = usePermissions();
  const redirect = useRedirect();
  const { location } = useHistory();
  const disableByLimit = useDisableByLimit();
  const dispatch = useDispatch();

  const {
    page,
    perPage,
    currentSort: { field, order },
    filterValues,
  } = useListContext();

  const transformValues = ({
    screener,
    ticker,
    benchmark_ticker,
    early_close_days_from,
    early_close_days_to,
    regular_days_from,
    regular_days_to,
    ...rest
  }) => ({
    ticker:
      ticker || screener
        ? [
            {
              type: screener ? "screener" : "ticker",
              id: ticker || screener?.id,
              name: ticker || screener?.name,
            },
          ]
        : null,
    benchmarkTicker: benchmark_ticker
      ? [
          {
            type: "benchmark_ticker",
            id: benchmark_ticker,
            name: benchmark_ticker,
          },
        ]
      : null,
    screeners: screener?.id ? [screener?.id] : [],
    tickers: ticker ? [ticker] : [],
    benchmark_ticker,
    early_close_days_from: convertEdtEstToLocal(early_close_days_from),
    early_close_days_to: convertEdtEstToLocal(early_close_days_to),
    regular_days_from: convertEdtEstToLocal(regular_days_from),
    regular_days_to: convertEdtEstToLocal(regular_days_to),
    use_stop_trail: useStopTrailDefaultValue,
    ...rest,
  });

  const copyToCurrentAlgo = () => {
    window.scrollTo({ top: 0 });
    const source = _objectWithoutProperties(transformValues(record), ["serialized_result"]);
    source.id = record?.algo?.id;
    dispatch({ type: RERUN_BACKTEST, payload: { backtest: source } });
    redirect({ ...location });
    notify(t("backtest.load_to_current_algo"), "info");
  };

  const copyToNewAlgo = () => {
    const source = _objectWithoutProperties(transformValues(record), DISABLED_FIELDS);
    redirect(
      `/algos/create?${new URLSearchParams({
        source: JSON.stringify(source),
      }).toString()}`
    );
    notify(t("backtest.copy_to_new_algo"), "info");
  };

  const bootstrapTrading = (isPaper = true) => {
    customFetch(`tradings/clone-backtest${isPaper ? "-paper" : ""}/${record.id}`, "POST", null, "text")
      .then((tradingJSON) => {
        const trading = JSON.parse(tradingJSON);
        redirect(`/tradings/${trading.id}`);
      })
      .catch(() => {
        notify(t("tradings.clone_failed"));
      });
  };

  function archiveBacktest() {
    dataProvider("DELETE", "backtests", {
      id: record.id,
    })
      .then(() => {
        notify(t("backtest.successfully_archived"), "info");
        dataProvider.getList("backtests", {
          pagination: { page, perPage },
          sort: { field, order },
          filter: { ...filterValues, algo: record.algo.id },
        });
      })
      .catch(() => {
        notify(t("common.cant_save"), "error");
      });
  }

  function copyClipboard() {
    const keys = ["ticker",
      "created_date",
      "start_date",
      "end_date",
      "initial_capital",
      "status",
      "return_pct",
      "return_value",
      "alpha",
      "mdd",
      "wins",
      "losses",
      "money_made",
      "money_lost"]
    let tableView = "|" + keys.map(key => t("backtest." + key)).join("|") + "|";
    tableView = tableView + "\n" + "|" + keys.map(key => "-------- ").join("|") + "|";
    tableView = tableView + "\n" + "|" + keys.map(key => record[key]).join("|") + "|";

    navigator.clipboard.writeText(tableView);
  }

  function shareBacktest(shared) {
    (async () => {
      if (record.screener?.id) {
        try {
          const { data } = await dataProvider.getOne("screeners", {
            id: record.screener.id,
          });
          if (shared && !data.shared) {
            notify(t("backtest.screener_not_shared"), "error");
            return;
          }
        } catch (e) {
          console.error(e);
        }
      }
      try {
        await dataProvider.update("backtests", {
          data: { shared },
          id: record.id,
        });
        notify(t("backtest.successfully_shared"), "info");
        refresh();
        dispatch({ type: GET_SHARED_BACKTESTS });
      } catch (e) {
        notify(t("common.cant_save"), "error");
      }
    })();
  }

  const launchTrading = (basePath) => () => {
    const source = { ...transformTime(record), broker: ENGINES.CB.id };
    if (basePath === TRADING_RESOURCE.PAPER && !record?.broker) source.broker = ENGINES.BE.id;

    redirect(
      `/${basePath}/create?${new URLSearchParams({
        source: JSON.stringify(_objectWithoutProperties(source, DISABLED_FIELDS)),
      }).toString()}`
    );
  };

  const addGaId = (id) => setGaId(ga?.category, ga?.action ? `${ga.action}-${id}` : id);

  let menuItems = [];
  if (loaded) {
    menuItems = [
      {
        condition: items.includes(actions.launch_live) && permissions?.includes(LIVE_TRADING),
        onClick: launchTrading(TRADING_RESOURCE.LIVE),
        icon: <ZapIcon size={18} />,
        gaId: addGaId("launch-live"),
        id: "launch-live",
        label: t("actions.launch_live"),
        disabled: disableByLimit.live_tradings,
        tooltip: disableByLimit.live_tradings
          ? t("subscriptions.disable_creating", { entity: ENTITY.LIVE_TRADING })
          : null,
      },
      {
        condition: items.includes(actions.launch_paper) && permissions?.includes(PAPER_TRADING),
        onClick: launchTrading(TRADING_RESOURCE.PAPER),
        icon: <LayersIcon size={18} />,
        gaId: addGaId("launch-paper"),
        id: "launch-paper",
        label: t("actions.launch_paper"),
        disabled: disableByLimit.paper_tradings,
        tooltip: disableByLimit.paper_tradings
          ? t("subscriptions.disable_creating", { entity: ENTITY.PAPER_TRADING })
          : null,
      },
      {
        condition: items.includes(actions.share) && record?.shared && permissions?.includes(CREATE_SHARED_ALGOS),
        onClick: () => shareBacktest(false),
        icon: <CancelIcon size={18} />,
        gaId: addGaId("stop-sharing"),
        id: "stop-sharing",
        label: t("actions.stop_sharing"),
      },
      // deep copy into trading
      {
        condition: permissions?.includes(PAPER_TRADING) && permissions?.includes(CREATE_SHARED_ALGOS),
        onClick: () => bootstrapTrading(true),
        icon: <LayersIcon size={18} />,
        gaId: addGaId("bootstrap-paper"),
        id: "bootstrap-paper",
        label: t("actions.bootstrap_paper"),
      },
      {
        condition: permissions?.includes(LIVE_TRADING) && permissions?.includes(CREATE_SHARED_ALGOS),
        onClick: () => bootstrapTrading(false),
        icon: <ZapIcon size={18} />,
        gaId: addGaId("bootstrap-live"),
        id: "bootstrap-live",
        label: t("actions.bootstrap_live"),
      },
      {
        condition:
          items.includes(actions.share) &&
          !record?.shared &&
          record?.status === statuses.completed.id &&
          permissions?.includes(CREATE_SHARED_ALGOS),
        onClick: () => shareBacktest(true),
        icon: <ShareIcon size={18} />,
        gaId: addGaId("share"),
        id: "share",
        label: t("actions.share"),
      },
      {
        condition: items.includes(actions.copy_to_current_algo),
        onClick: copyToCurrentAlgo,
        icon: <UploadIcon size={18} />,
        gaId: addGaId("copy-to-current-algo"),
        id: "copy-to-current-algo",
        label: t("actions.rerun_backtest"),
        disabled: disableByLimit.backtests,
        tooltip: disableByLimit.backtests ? t("subscriptions.disable_creating", { entity: ENTITY.BACKTEST }) : null,
      },
      {
        condition: items.includes(actions.copy_to_new_algo),
        onClick: copyToNewAlgo,
        icon: <CloneIcon size={18} />,
        gaId: addGaId("copy-to-new-algo"),
        id: "copy-to-new-algo",
        label: t("actions.copy_to_new_algo"),
        disabled: disableByLimit.algos,
        tooltip: disableByLimit.algos ? t("subscriptions.disable_creating", { entity: ENTITY.ALGO }) : null,
      },
      {
        condition: items.includes(actions.archive),
        onClick: archiveBacktest,
        icon: <ArchiveIcon size={18} />,
        gaId: addGaId("archive"),
        id: "archive",
        label: t("actions.archive"),
      },
      {
        condition: items.includes(actions.copy_clipboard),
        onClick: copyClipboard,
        icon: <ClipboardIcon size={18} />,
        gaId: addGaId("copy_clipboard"),
        id: "copy-to-clipboard",
        label: t("actions.copy_clipboard"),
      },
    ];
  }

  return loaded && !readOnly && record?.status !== statuses.archived.id ? (
    <PopupMenu menuItems={menuItems} id={resource} />
  ) : null;
};

export const BacktestList = ({ algo, backtestsLoading, setBacktestsLoading, ...props }) => {
  const backtestClick = (id, basePath) => "/" + basePath + "/" + id + "/show";
  const t = useTranslate();
  const [switcher, setSwitch] = useState(statuses.live.id);

  const readOnly = props.record && props.record.owner ? props.record.owner.id !== Storage.getItem("profile") : true;

  const tableName = TABLES.BACKTESTS;
  const perPage = useGetItemsPerPage(tableName);

  const ga = { category: GA_CATEGORIES.ALGO_EDIT, action: "backtest" };

  const enableArchive = () => switcher === statuses.live.id;
  const isMobile = new MobileDetect(navigator.userAgent).mobile()

  return (
    <Card header={t("algos.blocks.backtest_list")}>
      <List
        {...props}
        filters={<BacktestFilter {...props} ga={ga} />}
        filterDefaultValues={{ status: liveStatuses }}
        actions={
          <BacktestToolbar
            ga={ga}
            switcher={switcher}
            setSwitch={setSwitch}
            record={props.record}
            backtestsLoading={backtestsLoading}
            setBacktestsLoading={setBacktestsLoading}
          />
        }
        filter={{ algo: (props.id || algo?.id) ?? null }}
        sort={{ field: "created_date", order: "DESC" }}
        basePath="backtests"
        resource="backtests"
        exporter={false}
        bulkActionButtons={enableArchive() && <BulkActionButtons {...props} ga={ga} />}
        perPage={perPage}
        pagination={<ListPagination {...props} tableName={tableName} />}
        className={isMobile ? styles.listMobile : styles.listDesktop}
      >
        {!isMobile ? (
          <CustomizableDatagrid
            ga={ga}
            defaultColumns={[
              "ticker",
              "created_date",
              "start_date",
              "end_date",
              "initial_capital",
              "status",
              "return_pct",
              "return_value",
              "alpha",
              "mdd",
            ]}
            className={styles.backtestList}
            rowClick={backtestClick}
            enableArchive={enableArchive()}
            resource="backtests"
            basePath="/backtests"
            size="medium"
          >
            <AlgoType source="algo_type" label={t("tradings.algo_type")} />

            <TickerBenchmarkField
              ticket="ticker"
              benchmark="benchmark_ticker"
              label={t("backtest.ticker")}
              source="ticker"
              headerClassName="Other-Col"
              cellClassName="Other-Col"
            />
            <MomentField source="created_date" label={t("backtest.created")} fromNow={true} cellClassName="Other-Col" />

            <MomentField
              label={t("backtest.start_date")}
              source="start_date"
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />
            <MomentField
              label={t("backtest.to_date")}
              source="end_date"
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />

            <TimeField
              source="regular_days_from"
              label={t("backtest.regular_days_from")}
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />
            <TimeField
              source="regular_days_to"
              label={t("backtest.regular_days_to")}
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />

            <TimeField
              source="early_close_days_from"
              label={t("backtest.early_close_days_from")}
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />
            <TimeField
              source="early_close_days_to"
              label={t("backtest.early_close_days_to")}
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />

            <NumberField
              label={t("backtest.capital")}
              source="initial_capital"
              locales="en-EN"
              options={{
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
                style: "currency",
                currency: "USD",
              }}
            />

            <StatusField
              label={t("backtest.status")}
              source="status"
              cellClassName="Status-Col"
              headerClassName="Status-Col"
            />

            <ColoredNumber
              label={t("backtest.return_pct")}
              locales="en-EN"
              style={{ percent: "percent" }}
              source="return_pct"
              cellClassName="Status-Col MuiTableCell-alignRight"
              headerClassName="Status-Col"
            />

            <NumberField
              label={t("backtest.return")}
              source="return_value"
              locales="en-EN"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }}
            />

            <WinLossRatio
              label={t("backtest.win_loss")}
              source="winsLossRatio"
              cellClassName="Status-Col"
              headerClassName="Status-Col"
              sortable={false}
            />

            <RewardRiskRatio
              label={t("backtest.reward_risk")}
              source="rewardRiskRatio"
              cellClassName="Status-Col"
              headerClassName="Status-Col"
              sortable={false}
            />

            <NumberField
              label={t("backtest.trades")}
              source="trades"
              options={{
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }}
            />

            <NumberField
              label={t("backtest.wins")}
              source="wins"
              options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
              cellClassName="Status-Col"
              headerClassName="Status-Col"
            />

            <NumberField
              label={t("backtest.losses")}
              source="losses"
              options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
              cellClassName="Status-Col"
              headerClassName="Status-Col"
            />

            <NumberField
              label={t("backtest.risk")}
              source="money_lost"
              locales="en-EN"
              options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
              cellClassName="Status-Col"
              headerClassName="Status-Col"
            />

            <NumberField
              label={t("backtest.reward")}
              source="money_made"
              locales="en-EN"
              options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
              cellClassName="Status-Col"
              headerClassName="Status-Col"
            />

            <NumberField
              label={t("backtest.alpha")}
              source="alpha"
              locales="en-EN"
              options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
              cellClassName="Status-Col"
              headerClassName="Status-Col"
            />
            <NumberField
              label={t("backtest.beta")}
              source="beta"
              locales="en-EN"
              options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
              cellClassName="Status-Col"
              headerClassName="Status-Col"
            />
            <NumberField
              label={t("backtest.mdd")}
              source="mdd"
              locales="en-EN"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "percent",
              }}
              cellClassName="Status-Col"
              headerClassName="Status-Col"
            />

            <IntervalField
              source="enter_interval"
              label={t("fieldsName.enter_interval")}
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />

            <IntervalField
              source="exit_interval"
              label={t("fieldsName.exit_interval")}
              cellClassName="Other-Col"
              headerClassName="Other-Header"
            />

            <RiskField
              label={t("fieldsName.max_risk_per_day")}
              source="max_risk_per_day"
              type_source="max_risk_per_day_value_type"
              enabled_source="max_risk_per_day_enabled"
              cellClassName="Status-Col"
              headerClassName="Status-Col"
            />
            <RiskField
              label={t("fieldsName.max_risk_per_trade")}
              source="max_risk_per_trade"
              type_source="max_risk_per_trade_value_type"
              enabled_source="max_risk_per_trade_enabled"
              trail_source="use_stop_trail"
              cellClassName="Status-Col"
              headerClassName="Status-Col"
            />

            <AutoExitField
              label={t("fieldsName.force_exit_at")}
              source="force_exit_at"
              cellClassName="Status-Col"
              headerClassName="Status-Col"
            />

            {/*<NumberField*/}
            {/*  label={t("backtest.sharpe_ratio")}*/}
            {/*  source="sharpe_ratio"*/}
            {/*  locales="en-EN"*/}
            {/*  options={{*/}
            {/*    maximumFractionDigits: 2,*/}
            {/*    minimumFractionDigits: 2,*/}
            {/*  }}*/}
            {/*  cellClassName="Status-Col"*/}
            {/*  headerClassName="Status-Col"*/}
            {/*/>*/}

            <BacktestActions
              cellClassName="Actions-Col"
              ga={ga}
              items={
                props.record.portfolio_type !== "INTRADAY"
                  ? [actions.share, actions.copy_to_current_algo, actions.copy_to_new_algo, actions.archive, actions.copy_clipboard]
                  : [
                      actions.launch_live,
                      actions.launch_paper,
                      actions.share,
                      actions.copy_to_current_algo,
                      actions.copy_to_new_algo,
                      actions.archive,
                      actions.copy_clipboard
                    ]
              }
              readOnly={readOnly}
            />
          </CustomizableDatagrid>
        ) : (
          <BacktestMobileList />
        )}
      </List>
    </Card>
  );
};

export const BacktestViewEdit = (props) => {
  return (
    <Show {...props} className={styles.backtestResults}>
      <BacktestResults />
    </Show>
  );
};
