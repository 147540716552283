import React from "react";
import { RATIO_LIMITS } from "../../../shared/variables";
import UserAlmostReachedLimit from "./UserAlmostReachedLimit";
import UserReachedLimit from "./UserReachedLimit";
import PlanIncludesNoLimit from "./PlanIncludesNoLimit";

const LimitAlert = ({ ratio, entity, limits }) => {
  switch (`${ratio}-${entity}`) {
    case `${RATIO_LIMITS.FEW}-${entity}`:
      return <UserAlmostReachedLimit entity={entity} limits={limits} />;
    case `${RATIO_LIMITS.NO}-${entity}`:
      return <UserReachedLimit entity={entity} />;
    case `${RATIO_LIMITS.NOT_INCLUDES}-${entity}`:
      return <PlanIncludesNoLimit entity={entity} />;
    default:
      return null;
  }
};

export default LimitAlert;
