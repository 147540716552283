import React from "react";
import clsx from "clsx";
import classes from "./Rows.module.css";
import PropTypes from "prop-types";

const WideValue = ({ children, initialValueForColor, color, className }) => {
  const valueColor = color || (initialValueForColor > 0 ? "green" : initialValueForColor < 0 ? "red" : "dark");
  return <span className={clsx(classes.wideValue, classes[valueColor], className)}>{children}</span>;
};

export default WideValue;

WideValue.propTypes = {
  color: PropTypes.oneOf(["dark", "green", "red"]),
};
