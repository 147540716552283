import React from "react";
import styles from "./SplitButton.module.css";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import DisableCreatingTooltip from "../DisableCreatingTooltip/DisableCreatingTooltip";

const SplitButton = ({ buttons, ...rest }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  if (buttons.length < 2) {
    console.error("There should be more than 1 button");
    return null;
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const mainButtonWithProps = React.cloneElement(buttons[0], rest);

  const buttonsSliceWithProps = buttons.slice(1).map((button, index) =>
    React.cloneElement(button, {
      ...rest,
      onClick: () => {
        setOpen(false);
        button.props.onClick();
      },
      key: index,
    })
  );

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        className={styles.buttonGroup}
      >
        <DisableCreatingTooltip
          disabled={mainButtonWithProps?.props?.disabled}
          entity={mainButtonWithProps?.props?.entity}
        >
          {mainButtonWithProps}
        </DisableCreatingTooltip>
        <Button
          color="primary"
          size="small"
          onClick={handleToggle}
          data-testid="split-button-open"
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={styles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  {buttonsSliceWithProps.map((button) => (
                    <DisableCreatingTooltip
                      key={button?.props?.label}
                      disabled={button?.props?.disabled}
                      entity={button?.props?.entity}
                    >
                      {button}
                    </DisableCreatingTooltip>
                  ))}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SplitButton;
