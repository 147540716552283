import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useTranslate } from "react-admin";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import ReactFlagsSelect from "react-flags-select";
import countryTelData from "country-telephone-data";
import styles from "./PhoneInput.module.css";
import cn from "clsx";
import fromEntries from "object.fromentries";

function toDigitFormat(format) {
  return format ? format.split(".").join("9") : "";
}

let countryLabels = {};

countryTelData.allCountries
  .sort((a, b) => {
    if (a.iso2 === "us" || a.iso2 === "ca") {
      return -1;
    } else {
      return a.name.localeCompare(b.name);
    }
  })
  .forEach((entry) => (countryLabels[entry.iso2.toUpperCase()] = entry.name));

const countryCodes = countryTelData.allCountries.map((entry) =>
  entry.iso2.toUpperCase()
);
const formats = fromEntries(
  countryTelData.allCountries.map((entry) => {
    const entryFormat = entry.format ? entry.format.substring(entry.dialCode.length + 1).trim() : null;
    return [
      entry.iso2.toUpperCase(),
      toDigitFormat(entryFormat),
    ];
  })
);

const PhoneInput = ({
  phoneValue,
  countryValue,
  onPhoneChange,
  onCountryChange,
  className,
  isRequired,
  component,
  variant,
  ...props
}) => {
  const t = useTranslate();
  ValidatorForm.addValidationRule("isValidPhone", (value) => {
    return /^[\d\s\-\\+()]*$/.test(value);
  });

  const defaultCountry =
    countryValue ||
    (props.record ? props.record[props.countrySource] : "US") ||
    "US";
  const defaultPhone =
    phoneValue || (props.record ? props.record[props.phoneSource] : "");

  const [country, setCountry] = useState(defaultCountry);
  function countryChange(country) {
    setCountry(country);
    onCountryChange(country);
  }

  let validators = [];
  let errorMessages = [];
  if (isRequired) {
    validators.push("required");
    errorMessages.push(t("phone.validate.required"));
  }
  validators.push("isValidPhone");
  errorMessages.push(t("phone.validate.format"));

  return (
    <div className={cn(styles.phoneCountry, className)}>
      <div className={styles.phoneNumber}>
        <InputMask
          mask={formats[country]}
          value={defaultPhone}
          onChange={(e) => onPhoneChange(e.target.value)}
        >
          {() => (
            <TextValidator
              size="small"
              variant={variant}
              name="phone"
              value={defaultPhone}
              validators={validators}
              errorMessages={errorMessages}
              className={styles.input}
              label={t("authorization.phone")}
              instantValidate={false}
            />
          )}
        </InputMask>
      </div>

      <div className={styles.countryList}>
        <ReactFlagsSelect
          searchable
          showSelectedLabel={false}
          onSelect={countryChange}
          countries={countryCodes}
          customLabels={countryLabels}
          selected={defaultCountry}
          className={"flag-select"}
          selectButtonClassName={"flag-select__btn"}
        />
      </div>
    </div>
  );
};

export default PhoneInput;
