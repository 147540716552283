// data fetching / updating etc statuses
export const STATUSES = {
  INIT: "INIT",
  LOADING: "LOADING",
  LOAD_FAIL: "LOAD_FAIL",
  LOAD_SUCCESS: "LOAD_SUCCESS",
  UPDATING: "UPDATING",
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_FAIL: "UPDATE_FAIL",
  REFRESH: "REFRESH",
};
