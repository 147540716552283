import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./DateInput.module.css";
import { useInput, useTranslate } from "react-admin";
import cn from "clsx";
import { Tooltip } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { isMobile } from "react-device-detect";
import classes from "../../pages/Algo/components/BacktestParameters/BasktestParameters.module.css";
import Config from "../../config";
import CalendarIcon from "react-feather/dist/icons/calendar";
import { TimeZones } from "../../locales/timezones";
import moment from "moment";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { 500: "#2196f3" }, // blue
  },
});

function invalidDate(value) {
  return value?.includes("_") || value?.includes("/");
}

export const DateRange = (props) => {
  const t = useTranslate();
  const timezone = useSelector((store) => store.profile.timezone);
  const { data_end_date, data_start_date } = useSelector((store) => store.backtestInfo);

  const { h12 } = TimeZones.find((item) => item.text === timezone) || {};
  const format = h12 ? Config.US_DATE_FORMAT : Config.EU_DATE_FORMAT;

  const minMaxDate = (value) => {
    if (data_start_date && new Date(value) < new Date(data_start_date)) {
      return t("backtest.validate.min_date", { date: moment(data_start_date).format(format) });
    }

    if (data_end_date && new Date(value) > new Date(data_end_date)) {
      return t("backtest.validate.max_date", { date: moment(data_end_date).format(format) });
    }
  };

  const datesValid = (value, allValues) => {
    const { start_date, end_date } = allValues;

    if (!value) {
      return t("backtest.validate.date_required");
    }
    if (invalidDate(value)) {
      return t("backtest.validate.date_invalid");
    }
    if (new Date(value) > new Date()) {
      return t("backtest.validate.date_future");
    }
    if (start_date && end_date && new Date(start_date) > new Date(end_date)) {
      return t("backtest.validate.date_start_after_end");
    }
    return null;
  };

  return (
    <>
      <DateInput
        id={`${props.id}-from`}
        label={props.label_from}
        tooltip={props.tooltip_from}
        source={props.source_from}
        validate={[datesValid, minMaxDate]}
        data-testid="date-input-from"
      />

      <DateInput
        id={`${props.id}-to`}
        label={props.label_to}
        tooltip={props.tooltip_to}
        source={props.source_to}
        validate={[datesValid, minMaxDate]}
        data-testid="date-input-to"
      />
    </>
  );
};

export const DateInput = (props) => {
  const {
    input: { value, name, onChange, onBlur },
    meta: { error, touched },
  } = useInput(props);

  const timezone = useSelector((store) => store.profile.timezone);
  const { data_end_date, data_start_date } = useSelector((store) => store.backtestInfo);

  const { h12 } = TimeZones.find((item) => item.text === timezone) || {};
  const format = h12 ? Config.US_DATE_FORMAT : Config.EU_DATE_FORMAT;
  const initialMomentDate = moment(value, Config.INT_DATE_FORMAT);

  const [inputValue, setInputValue] = useState(initialMomentDate?.isValid() ? initialMomentDate.format(format) : "");

  const [active, setActive] = useState(false);
  const label = `${props.label} (${format.toLowerCase()})`;

  useEffect(() => {
    const momentDate = !invalidDate(value) ? moment(value, Config.INT_DATE_FORMAT) : null;
    const changedInputValue = momentDate?.isValid() ? momentDate.format(format) : value;
    if (changedInputValue !== inputValue && !Array.isArray(changedInputValue)) setInputValue(changedInputValue);
  }, [value]);

  return (
    <Tooltip
      title={props.tooltip && !active ? props.tooltip : ""}
      PopperProps={{ className: classes.popper }}
      enterDelay={Config.TOOLTIP_DELAY}
      enterNextDelay={Config.TOOLTIP_DELAY}
    >
      <div>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              id={props.id}
              data-test-id={name}
              variant={isMobile ? "dialog" : "inline"}
              label={label}
              format={format}
              minDate={props.minDate || data_start_date}
              maxDate={data_end_date}
              autoOk={true}
              disableFuture={true}
              value={inputValue || ""}
              inputValue={inputValue || ""}
              onBlur={onBlur}
              onChange={(momentDate, value) => {
                setInputValue(value);
                onChange(momentDate?.isValid() ? momentDate.format(Config.INT_DATE_FORMAT) : value);
              }}
              // capture the state for tooltip rendering
              onOpen={() => setActive(true)}
              onClose={() => setActive(false)}
              error={!!(touched && error)}
              helperText={touched && error}
              inputVariant="outlined"
              className={cn(styles.picker, props.className)}
              keyboardIcon={<CalendarIcon className={styles.clockIcon} />}
              InputLabelProps={{ shrink: !!inputValue?.trim() }}
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </div>
    </Tooltip>
  );
};

export default DateInput;
