import React, { useEffect } from "react";
import { Edit } from "react-admin";
import TradingForm from "./TradingForm";
import { useDispatch } from "react-redux";
import { GET_SCREENERS } from "../../store/sagas/screenersSaga";
import { GET_SCREENERS_ALL } from "../../store/sagas/screenersAllSaga";

const EditTrading = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_SCREENERS });
    dispatch({ type: GET_SCREENERS_ALL });
  }, []);

  return (
    <Edit {...props} actions={null}>
      <TradingForm {...props} />
    </Edit>
  );
};

export default EditTrading;
