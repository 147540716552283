import { Show } from "react-admin";
import React from "react";
import ScreenerBacktest from "./ScreenerBacktest";

export default (props) => {
  return (
    <Show {...props}>
      <ScreenerBacktest />
    </Show>
  );
};
