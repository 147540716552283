import React from "react";
import styles from "./WatchlistCriteriaBuilder.module.scss";
import WatchlistCriteriaInput from "./WatchlistCriteriaInput";
import WatchlistSelect from "./WatchlistSelect";

const WatchlistCriteriaBuilder = ({readOnly = false, ...props}) => {

    return (
        <>
            <div className={styles.whiteBlock}>
                <WatchlistSelect source="watchlist" readOnly={readOnly} {...props}/>
                <WatchlistCriteriaInput source={["enter_on_day", "exit_on_day"]} readOnly={readOnly}  {...props}/>
            </div>
        </>
    );
};

export default WatchlistCriteriaBuilder;

