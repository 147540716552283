import React, { useState } from "react";
import { useRedirect, useTranslate } from "react-admin";
import { useSelector } from "react-redux";
import Alert from "../Alert";
import { ALERT, SUBSCRIPTIONS_URL } from "../variables";
import { getPlanState } from "../../../store/selectors";
import { PLANS } from "../../../shared/variables";

const UserAlmostReachedLimit = ({ limits, entity }) => {
  const [isOpen, setOpenAlert] = useState(true);
  const { name: planName } = useSelector(getPlanState) || {};
  const isProfessional = planName === PLANS.PROFESSIONAL;
  const t = useTranslate();
  const redirect = useRedirect();
  const toSubscriptions = () => redirect(SUBSCRIPTIONS_URL);

  const data = {
    type: ALERT.WARNING,
    data: [
      {
        header: "subscriptions.limit_close",
        description: [t("subscriptions.limit_close_desc", { limits, entity })],
      },
    ],
    action: isProfessional ? null : { label: "subscriptions.upgrade_plan", onClick: toSubscriptions },
  };

  return isOpen && planName ? <Alert data={data} setOpenAlert={setOpenAlert} /> : null;
};

export default UserAlmostReachedLimit;
