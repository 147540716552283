import Config from "./config";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { GET_PLANS } from "./store/sagas/plansSaga";
import { GET_USAGE } from "./store/sagas/usageSaga";
import { useAuthState } from "react-admin";
import { UPDATE_PROFILE } from "./store/reducers/profileReducer";
import { LOCALSTORAGE } from "./shared/variables";
import { TICKERS_RECEIVED } from "./store/reducers/tickerReducer";
import { INDEXES_RECEIVED } from "./store/reducers/indexesReducer";
import { APP_LOADING } from "./store/reducers/appReducer";
import { BACKTEST_INFO_RECEIVED } from "./store/reducers/backtestInfoReducer";
import { GET_SHARED_BACKTESTS } from "./store/sagas/sharedBacktestsSaga";
import { GET_SHARED_STRATEGIES } from "./store/sagas/sharedStrategiesSaga";

window.INDICATORS = [];

const DataController = () => {
  const { authenticated } = useAuthState();

  const dispatch = useDispatch();

  useEffect(() => {
    Promise.allSettled([
      fetch(Config.UDF_HOST + "/symbol_info", { method: "GET" }).then((res) => res.json()),
      fetch(Config.UDF_HOST + "/index_info", { method: "GET" }).then((res) => res.json()),
      fetch(Config.UDF_HOST + "/backtest_info", { method: "GET" }).then((res) => res.json()),
      fetch(Config.DSL_API_HOST + "/backtest/indicators/list", { method: "GET" }).then((res) => res.json()),
    ])
      .then((data) => {
        dispatch({
          type: TICKERS_RECEIVED,
          payload: { tickers: data[0].value.symbol.sort().map((item) => ({ id: item, name: item })) },
        });
        dispatch({
          type: INDEXES_RECEIVED,
          payload: { indexes: data[1].value.map(({ symbol, ...rest }) => ({ id: symbol, ...rest })) },
        });
        dispatch({ type: BACKTEST_INFO_RECEIVED, payload: { backtestInfo: data[2].value } });
        window.INDICATORS = data[3].value;
        dispatch({ type: APP_LOADING, payload: { loading: false } });
      })
      .catch((err) => console.error(err));
    dispatch({ type: GET_PLANS });
    dispatch({ type: GET_USAGE });

    // only requesting shared backtests if there is a corresponding permission
    //permissions && permissions.includes(BACKTEST_ALGOS) && dispatch({ type: GET_SHARED_BACKTESTS });
    dispatch({ type: GET_SHARED_BACKTESTS });
    dispatch({ type: GET_SHARED_STRATEGIES });
    window.growsurf && window.growsurf.init({});
  }, []);

  // update timezone on authentication
  useEffect(() => {
    if (authenticated) {
      const timezone = localStorage.getItem(LOCALSTORAGE.TIMEZONE);
      if (timezone) {
        dispatch({ type: UPDATE_PROFILE, payload: { timezone } });
      } else {
        localStorage.setItem(LOCALSTORAGE.TIMEZONE, "(UTC-05:00) Eastern Time (US & Canada)");
      }
    }
  }, [authenticated]);

  return null;
};

export default DataController;
