import React from "react";
import { useTranslate } from "react-admin";
import classes from "./NoResultsFound.module.css";
import noRecords from "../../assets/no-records.svg";

const NoResultsFound = ({ messages, createFromASampleButton }) => {
  const t = useTranslate();
  return (
    <div className={classes.container}>
      <img src={noRecords} alt="" />
      <div className={classes.hint}>
        {messages ? (
          messages?.map((text, index) => <span key={index}>{t(text)}</span>)
        ) : (
          <span>{t("not_found.no_records")}</span>
        )}
      </div>
      {createFromASampleButton}
    </div>
  );
};

export default NoResultsFound;
