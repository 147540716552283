import React from "react";
import clsx from "clsx";
import classes from "./Rows.module.css";
import Label from "./Label";
import NarrowValue from "./NarrowValue";

const NarrowLabelVal = ({ label, value, className }) => {
  return (
    <div className={clsx(classes.narrowLabelVal, className)}>
      {label ? <Label>{label}</Label> : null}
      <NarrowValue>{value}</NarrowValue>
    </div>
  );
};

export default NarrowLabelVal;
