import { useTranslate, Loading } from "react-admin";
import React, { useEffect, useRef, useState } from "react";
import Alert from "../../../../components/Alert/Alert";
import styles from "./Referral.module.scss";
import { ALERT } from "../../../../components/Alert/variables";

const MAX_NUMBER_OF_ATTEMPTS = 5;

const Referral = (props) => {
  const ref = useRef();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let numberOfAttempts = 0;
    const interval = setInterval(() => {
      if (!ref.current?.childNodes?.length && numberOfAttempts < MAX_NUMBER_OF_ATTEMPTS) {
        ++numberOfAttempts;
        window.growsurf && window.growsurf.init({});
        window.growsurf && window.growsurf.initElements();
        window.growsurf && window.growsurf.addParticipant(props.record.email);
      } else {
        clearInterval(interval);
        setLoading(false);
        numberOfAttempts >= MAX_NUMBER_OF_ATTEMPTS && setError(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const t = useTranslate();
  let referralMessage = error
    ? {
        id: "referralErrorMessage",
        type: ALERT.DANGER,
        data: [
          {
            header: t("referral.error_header"),
            description: [t("referral.error_desc")],
          },
        ],
      }
    : {
        id: "referralMessage",
        type: ALERT.BRAND,
        data: [
          {
            description: [
              <div>
                <p>{t("referral.explanation_1")}</p>
                <p>{t("referral.explanation_2")}</p>
              </div>,
            ],
          },
        ],
      };

  return (
    <div className={styles.giftsContainer}>
      {loading && <Loading className={styles.loading} />}
      <div className={styles.shareWrapper}>
        <div
          ref={ref}
          data-grsf-block-form
          data-grsf-label-style="{'color': '#383838', 'font-size': '14px'}"
          data-grsf-gdpr-paragraph-style="{'color': '#383838', 'font-size': '14px'}"
          data-grsf-gdpr-checkbox-style="{'color': '#383838', 'font-size': '14px'}"
          data-grsf-button-style="{'background-color': '#2196F3', 'color': '#fcfcfc', 'font-family': 'Roboto', 'font-size': '14px', 'text-transform': 'uppercase', 'height': '40px'}"
          data-grsf-link-style="{'color': '#2196F3'}"
          data-grsf-facebook-button-style="{'background-color': '#2196F3', 'font-family': 'Roboto', 'font-size': '14px', 'text-transform': 'uppercase', 'padding': '13px 16px', 'font-weight': '500'}"
          data-grsf-facebook-button-text="facebook"
          data-grsf-facebook-button-message={t("referral.social_message")}
          data-grsf-twitter-button-style="{'background-color': '#2196F3', 'font-family': 'Roboto', 'font-size': '14px', 'text-transform': 'uppercase', 'padding': '13px 16px', 'font-weight': '500'}"
          data-grsf-twitter-button-text="twitter"
          data-grsf-twitter-button-message={t("referral.social_message")}
          data-grsf-linkedin-button-style="{'background-color': '#2196F3', 'font-family': 'Roboto', 'font-size': '14px', 'text-transform': 'uppercase', 'padding': '13px 16px', 'font-weight': '500'}"
          data-grsf-linkedin-button-text="linkedin"
          data-grsf-linkedin-button-message={t("referral.social_message")}
          data-grsf-share-instructions=""
          data-grsf-share-instructions-style="{'font-size': '14px', 'font-family': 'Roboto'}"
          data-grsf-copy-link-button-text={t("referral.copy_link")}
          data-grsf-copy-link-container-style="{'font-size': '14px', 'font-family': 'Roboto', 'font-weight': 'regular', 'height': '40px'}"
        />
      </div>
      <div
        data-grsf-block-stats
        data-grsf-referral-count-style="{'background': 'rgba(0,0,0,0)', 'color': '#383838', 'width': 'fit-content', 'font-size': '16px', 'line-height': '20px', 'box-shadow': 'none '}"
        data-grsf-referral-count-text=" "
      />
      {!loading ? (
        <div className={error ? styles.referralErrorMessage : styles.referralMessage}>
          <Alert data={referralMessage} />
        </div>
      ) : null}
    </div>
  );
};

export default Referral;
