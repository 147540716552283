import React from "react";
import { setGaId } from "../../shared/utils";
import styles from "./ToggleButtonField.module.css";
import clsx from "clsx";
import { isMobile } from "react-device-detect";

export const ToggleButtonsField = ({
  id,
  inputNames,
  inputLabels,
  icons,
  value,
  defaultValue,
  onChange,
  ga,
  className,
}) => {
  return (
    <div className={clsx({ [styles.wrapper]: isMobile }, className)}>
      <div className={styles.buttons}>
        {inputNames.map((inputName, index) => {
          const checked = value ? { checked: value === inputName } : {};
          return (
            <div key={index} className={styles.button}>
              <input
                className={styles.input}
                name={id || "light-toggle"}
                type="radio"
                id={inputName}
                onChange={onChange}
                value={inputName}
                defaultChecked={defaultValue === inputName}
                {...checked}
              />
              {icons && icons[inputName]}
              <label
                htmlFor={inputName}
                id={setGaId(ga?.category, ga?.action ? `${ga.action}-${inputName}` : inputName)}
              >
                {inputLabels[index]}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ToggleButtonsFieldGroup = ({
  id,
  inputNames,
  inputLabels,
  icons,
  value,
  defaultValue,
  onChange,
  ga,
  className,
}) => {
  return (
    <div className={clsx({ [styles.wrapper]: isMobile }, className)}>
      <div className={styles.buttons}>
        {inputNames.map((inputName, index) => {
          const checked = value ? { checked: value === `${id}${inputName}` } : {};
          return (
            <div key={index} className={styles.button}>
              <input
                className={styles.input}
                name={id}
                type="radio"
                id={`${id}${inputName}`}
                onChange={onChange}
                value={inputName}
                defaultChecked={defaultValue === inputName}
                {...checked}
              />
              {icons && icons[inputName]}
              <label
                htmlFor={`${id}${inputName}`}
                id={setGaId(ga?.category, ga?.action ? `${ga.action}-${inputName}` : inputName)}
              >
                {inputLabels[index]}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
