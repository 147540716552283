import moment from "moment";
import {
  put,
  take,
  takeEvery,
  takeLatest,
  retry,
  select,
  delay,
  call,
  race,
  cancelled,
} from "redux-saga/effects";
import { authFetchAPI } from "../../DataProvider";

import {
  USAGE_LOADING,
  USAGE_RECEIVED,
  USAGE_FAIL,
} from "../reducers/usageReducer";

export const GET_USAGE = "GET_USAGE";
export const GET_USAGE_POLLING = "GET_USAGE_POLLING";
export const CANCEL_USAGE_POLLING = "CANCEL_USAGE_POLLING";

const SECOND = 1000;

export const USER_SUBSCRIPTION_STATUSES = {
  ACTIVE: "ACTIVE",
  PAYMENT_OVERDUE: "PAYMENT_OVERDUE",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  CANCELLED: "CANCELLED",
};

export default function* usageSaga() {
  yield takeEvery(GET_USAGE, function* () {
    yield put({ type: USAGE_LOADING });

    try {
      const res = yield retry(2, 3 * SECOND, authFetchAPI, "usage");
      if (res) {
        const resData = yield res.json();

        const usage = {
          ...resData,
          paymentOverdueFailed: [
            USER_SUBSCRIPTION_STATUSES.PAYMENT_OVERDUE,
            USER_SUBSCRIPTION_STATUSES.PAYMENT_FAILED,
          ].includes(resData.status),
        };

        yield put({type: USAGE_RECEIVED, payload: {usage}});
      }
    } catch (e) {
      console.error(e);
      yield put({ type: USAGE_FAIL });
    }
  });

  yield takeLatest(GET_USAGE_POLLING, function* () {
    yield put({ type: USAGE_LOADING });

    function* polling() {
      const { updated_date } = yield select((store) => store.usage.data);

      try {
        while (true) {
          const res = yield authFetchAPI("usage", { method: "GET" });
          const resData = yield res.json();

          if (moment(updated_date).isBefore(resData.updated_date)) {
            if (resData.upgrade_payment_status === USER_SUBSCRIPTION_STATUSES.PAYMENT_FAILED) {
              yield put({ type: USAGE_FAIL });
              break;
            }

            const usage = {
              ...resData,
              paymentOverdueFailed: [
                USER_SUBSCRIPTION_STATUSES.PAYMENT_OVERDUE,
                USER_SUBSCRIPTION_STATUSES.PAYMENT_FAILED,
              ].includes(res.status),
            };
            yield put({ type: USAGE_RECEIVED, payload: { usage } });
            break;
          }
          yield delay(3000);
        }
      } catch (e) {
        yield put({ type: USAGE_FAIL });
        console.error(e);
      } finally {
        if (yield cancelled()) {
          yield put({ type: USAGE_FAIL });
        }
      }
    }

    yield race({
      task: call(polling),
      cancel: take(CANCEL_USAGE_POLLING),
      timeout: delay(30 * SECOND),
    });
  });
}
