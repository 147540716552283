import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ScreenerIcon from "react-feather/dist/icons/activity";
import styles from "./TickerBenchmarkField.module.scss";
import {USE_SCREENERS} from "../../shared/permissions";

const TickerBenchmarkField = ({ record, ticket, benchmark, permissions }) => {
  if (!record) return null;

  const onClickScreener = (e) => {
    e.stopPropagation();
    window.open(`/#/screeners/${record.screener?.id}`, "_blank");
  };

  let ticker = Array.isArray(record?.[ticket]) ? record?.[ticket][0]?.name : record?.[ticket];

  ticker = ticker ||
    (record?.screener ? (
      <Tooltip title={record?.screener?.name || ""}>
        <ScreenerIcon className={styles.screenerIcon} onClick={permissions?.includes(USE_SCREENERS) ? onClickScreener : false} />
      </Tooltip>
    ) : null);

  return (
    <span className={styles.ticker}>
      {ticker}
      {record[benchmark] ? <span className={styles.benchmark}>/{record[benchmark]}</span> : null}
    </span>
  );
};

export default TickerBenchmarkField;
