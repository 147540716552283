import React from "react";
import { useRedirect, useTranslate } from "react-admin";
import Button from "@material-ui/core/Button";
import classes from "./Carousel.module.css";
import clsx from "clsx";
import BarChart2 from "react-feather/dist/icons/bar-chart-2";
import LayersIcon from "react-feather/dist/icons/layers";
import ZapIcon from "react-feather/dist/icons/zap";

import guideImage1 from "../../assets/dashboard-guide/dashboard-guide-1.png";
import guideImage2 from "../../assets/dashboard-guide/dashboard-guide-2.png";
import guideImage3 from "../../assets/dashboard-guide/dashboard-guide-3.png";
import guideImage4 from "../../assets/dashboard-guide/dashboard-guide-4.png";
import { setGaId } from "../../shared/utils";
import DisableCreatingTooltip from "../DisableCreatingTooltip/DisableCreatingTooltip";
import { ENTITY, GA_CATEGORIES } from "../../shared/variables";

const CreateOrExploreAlgosFooter = ({ exploreAlgosRef, disableCreate }) => {
  const redirect = useRedirect();
  const t = useTranslate();

  const executeScroll = () => exploreAlgosRef?.current?.scrollIntoView({ behavior: "smooth" });
  const handleCreateNewAlgo = () => redirect("/algos/create");

  return (
    <div className={classes.createOrExploreButtons}>
      <Button
        className={classes.exploreButton}
        onClick={executeScroll}
        id={setGaId(GA_CATEGORIES.DASHBOARD, "explore-algos")}
      >
        {t("dashboard.explore_algos")}
      </Button>
      <DisableCreatingTooltip disabled={disableCreate} entity={ENTITY.ALGO}>
        <Button
          className={classes.createButton}
          onClick={handleCreateNewAlgo}
          disabled={disableCreate}
          data-testid="dashboard-from-tips-create-algo"
          id={setGaId(GA_CATEGORIES.DASHBOARD, "from-tips-create-algo")}
        >
          {t("dashboard.create_algo")}
        </Button>
      </DisableCreatingTooltip>
    </div>
  );
};

const tradings = { backtest: "backtest", paper: "paper", live: "live" };

const TradingFooter = ({ active }) => {
  const { backtest, paper, live } = tradings;
  return (
    <div className={classes.tradings}>
      <span
        className={clsx(classes.trading, {
          [classes.activeTrading]: active === backtest,
        })}
      >
        <BarChart2 size={18} /> 10
      </span>
      <span
        className={clsx(classes.trading, {
          [classes.activeTrading]: active === paper,
        })}
      >
        <LayersIcon size={18} /> 0
      </span>
      <span
        className={clsx(classes.trading, {
          [classes.activeTrading]: active === live,
        })}
      >
        <ZapIcon size={18} /> 0
      </span>
    </div>
  );
};

export default [
  {
    image: guideImage1,
    heading: "guidance.slide1.heading",
    text: "guidance.slide1.text",
    footer: ({ exploreAlgosRef, disableCreate }) => <CreateOrExploreAlgosFooter exploreAlgosRef={exploreAlgosRef} disableCreate={disableCreate} />,
  },
  {
    image: guideImage2,
    heading: "guidance.slide2.heading",
    text: "guidance.slide2.text",
    footer: () => <TradingFooter active={tradings.backtest} />,
  },
  {
    image: guideImage3,
    heading: "guidance.slide3.heading",
    text: "guidance.slide3.text",
    footer: () => <TradingFooter active={tradings.paper} />,
  },
  {
    image: guideImage4,
    heading: "guidance.slide4.heading",
    text: "guidance.slide4.text",
    footer: () => <TradingFooter active={tradings.live} />,
  },
];
