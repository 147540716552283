import React, { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import "./App.css";
import { Authorization } from "./pages/Auth/Authorization";
import { AuthProvider } from "./pages/Auth/AuthProvider";

import {
  AnalyticsOutline,
  CartOutline,
  FilterOutline,
  HammerOutline,
  LayersOutline,
  LibraryOutline,
  MedalOutline,
  PeopleOutline,
  PlayBackOutline,
  PulseOutline,
  ReaderOutline,
} from "react-ionicons";

//permissions
import { BACKTEST_ALGOS, LIVE_TRADING, MANAGE_USERS, PAPER_TRADING, USE_SCREENERS } from "./shared/permissions";
import { Registration } from "./pages/Auth/Registration";
import DataController from "./DataController";
import WrappedDataProvider from "./DataProvider";
import BELayout from "./pages/Layout/Layout";
//localisation
import { i18nProvider } from "./locales";
import CreateAlgo from "./pages/Algo/CreateAlgo";
import EditAlgo from "./pages/Algo/EditAlgo";
import ShowAlgo from "./pages/Algo/ShowAlgo";
import { AlgoList } from "./pages/Algo/ShowAlgos";
import { BacktestViewEdit } from "./pages/Backtest/Backtest";
import { DashboardList } from "./pages/Dashboard/Dashboard";
import ProfileEdit from "./pages/Profile/Profile";
//user profile
import TermsAndConditions from "./pages/Profile/TermsAndConditions";
import { ScreenerCreate, ScreenerEdit } from "./pages/Screener/EditScreener";
import { ScreenerList } from "./pages/Screener/ShowScreeners";
import ScreenerBacktestShow from "./pages/ScreenerBacktest/ScreenerBacktestShow";
import CreateTrading from "./pages/Trading/CreateTrading";
import EditTrading from "./pages/Trading/EditTrading";
import ShowTrading from "./pages/Trading/ShowTrading";
import { TradingList } from "./pages/Trading/ShowTradings";
import { UserCreate, UserEdit, UserList } from "./pages/User/User";
import { TRADING_RESOURCE } from "./shared/variables";
import store, { history } from "./store/store";
import { createTheme } from "./theme";
import ShowWatchlistAlgos from "./pages/Watchlist/ShowWatchlistAlgos";
import CreateWatchlistAlgo from "./pages/Watchlist/CreateWatchlistAlgo";
import { StrategiesDashboard } from "./pages/Strategy/StrategiesDashboard";
import { LeaderboardList } from "./pages/Leaderboard/Leaderboard";
import { EditStrategy, ShowStrategy } from "./pages/Strategy/Strategy";
import {getDomain} from "./shared/utils";

const App = () => {
  const theme = createTheme();

  useEffect(() => {
    if (!/localhost/.test(window.location.hostname)) {
      TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID });
    }
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, null, {
      autoConfig: false,
    });
    // BE-337
    window.addEventListener(
      "message",
      (event) => {
          const domain = getDomain();
          if (event.data === "broker" && event.origin.includes(domain) && event.origin !== domain) {
              window.location.href = event.origin + window.location.search;
          }
      },
      false
    );
  }, []);

  return (
    <Provider store={store}>
      <Admin
        disableTelemetry
        authProvider={AuthProvider}
        loginPage={Authorization}
        dataProvider={WrappedDataProvider}
        i18nProvider={i18nProvider}
        layout={BELayout}
        theme={theme}
        history={history}
        customRoutes={[
          // <Route path="watchlist-algos">
          //   <Route path=":id" component={(props) => <EditAlgo basePath="/watchlist-algos" {...props} />} />
          // </Route>,

          <Route path="/profile" component={(props) => <ProfileEdit {...props} />} />,
          <Route exact path="/terms" component={TermsAndConditions} />,
          <Route exact path="/signup-email" component={() => <Registration requestPhone={false} />} noLayout />,
          <Route exact path="/signup" component={() => <Registration requestPhone={true} />} noLayout />,
          <Route
            exact
            path="/marketplace-signup"
            component={() => <Registration requestPhone={false} registrationType="/marketplace" />}
            noLayout
          />,
          // #TODO refactor all routes, permissions
          // <Route component={NotFound} noLayout />
        ]}
      >
        {(permissions) => {
          return [
            <Resource
              name="strategies"
              options={{ label: "Marketplace" }}
              list={StrategiesDashboard}
              show={ShowStrategy}
              edit={EditStrategy}
              icon={CartOutline}
              topLevel={true}
            />,

            <Resource
              name="leaderboard"
              options={{ label: "Leaderboard" }}
              list={LeaderboardList}
              icon={MedalOutline}
              topLevel={true}
            />,

            permissions.includes(LIVE_TRADING) ? (
              <Resource
                name={TRADING_RESOURCE.LIVE}
                options={{ label: "Live Trading" }}
                list={TradingList}
                create={CreateTrading}
                icon={PulseOutline}
              />
            ) : null,

            (permissions.includes(BACKTEST_ALGOS) ||
              permissions.includes(USE_SCREENERS) ||
              permissions.includes(LIVE_TRADING)) && <DataController />,

            //  algo lab parent
            permissions.includes(BACKTEST_ALGOS) ? (
              <Resource name="lab" options={{ label: "Algo Lab", parent: true }} icon={PlayBackOutline} />
            ) : null,

            permissions.includes(PAPER_TRADING) ? (
              <Resource
                name={TRADING_RESOURCE.PAPER}
                options={{ label: "Paper Trading" }}
                list={TradingList}
                create={CreateTrading}
                icon={LayersOutline}
              />
            ) : null,

            permissions.includes(BACKTEST_ALGOS) ? (
              <Resource
                name="dashboard"
                options={{ label: "Sample Algos" }}
                list={DashboardList}
                icon={LibraryOutline}
                topLevel={true}
              />
            ) : null,

            permissions.includes(BACKTEST_ALGOS) ? (
              <Resource
                name="algos"
                options={{ label: "algos.menu" }}
                edit={EditAlgo}
                list={AlgoList}
                show={ShowAlgo}
                create={CreateAlgo}
                icon={AnalyticsOutline}
              />
            ) : null,

            permissions.includes(BACKTEST_ALGOS) ? (
              <Resource
                name="watchlist-algos"
                options={{ label: "watchlists.menu" }}
                edit={EditAlgo}
                list={ShowWatchlistAlgos}
                show={ShowAlgo}
                create={CreateWatchlistAlgo}
                icon={ReaderOutline}
              />
            ) : null,

            permissions.includes(USE_SCREENERS) && permissions.includes(BACKTEST_ALGOS) ? (
              <Resource
                name="screeners"
                options={{ label: "screeners.menu" }}
                list={ScreenerList}
                create={ScreenerCreate}
                edit={ScreenerEdit}
                icon={FilterOutline}
              />
            ) : null,

            permissions.includes(BACKTEST_ALGOS) ? <Resource name="backtests" show={BacktestViewEdit} /> : null,

            permissions.includes(BACKTEST_ALGOS) ? (
              <Resource name="screener-backtests" show={ScreenerBacktestShow} />
            ) : null,

            permissions.includes(BACKTEST_ALGOS) ? <Resource name="screener_backtest_day_result" /> : null,

            //  tradings parent
            // permissions.includes(PAPER_TRADING) ? (
            //     <Resource name="trade" options={{ label: "Trading", parent : true }}  icon={BriefcaseOutline}/>
            // ) : null,

            permissions.includes(PAPER_TRADING) || permissions.includes(LIVE_TRADING) ? (
              <Resource name={TRADING_RESOURCE.TRADINGS} edit={EditTrading} show={ShowTrading} />
            ) : null,

            //  algo lab parent
            permissions.includes(MANAGE_USERS) ? (
              <Resource name="admin" options={{ label: "Admin", parent: true }} icon={HammerOutline} />
            ) : null,
            permissions.includes(MANAGE_USERS) ? (
              <Resource
                name="users"
                options={{ label: "Users" }}
                list={UserList}
                edit={UserEdit}
                icon={PeopleOutline}
                create={UserCreate}
              />
            ) : null,
            permissions.includes(MANAGE_USERS) ? <Resource exact name="roles" /> : null,
            <Resource name="terms" edit={TermsAndConditions} />,
            permissions.includes(BACKTEST_ALGOS) ||
            permissions.includes(USE_SCREENERS) ||
            permissions.includes(LIVE_TRADING) ? (
              <Resource name="profile" edit={ProfileEdit} />
            ) : null,
            permissions.includes(BACKTEST_ALGOS) ? <Resource name="trading-days/backtest" /> : null,
            permissions.includes(PAPER_TRADING) || permissions.includes(LIVE_TRADING) ? (
              <Resource name="trading-days/trading" />
            ) : null,

            permissions.includes(PAPER_TRADING) || permissions.includes(LIVE_TRADING) ? (
              <Resource name="trading-events" />
            ) : null,
          ];
        }}
      </Admin>
    </Provider>
  );
};

export default App;
