import { useNotify, useRedirect, useRefresh, useTranslate } from "react-admin";
import { useDispatch } from "react-redux";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { DISABLED_FIELDS, ORDER_TYPES, TRADING_RESOURCE, TRADING_TYPE } from "../../../shared/variables";
import { statuses } from "../../../shared/statuses";
import { actions } from "../../../shared/moreActions";
import { TRADING_SAVE } from "../../../store/sagas/tradingSaga";
import useDisableByLimit from "../../../shared/hooks/useDisableByLimit";
import { customFetch } from "../../../DataProvider";

export default function useGetTradingActions(record) {
  const redirect = useRedirect();
  const t = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const isPaperTrading = record?.trading_type === TRADING_TYPE.PAPER;
  const actionsResource = TRADING_RESOURCE.TRADINGS;

  const disableByLimit = useDisableByLimit();

  if (!record?.trading_type) return {};

  const tradingEdit = () => redirect(`/${actionsResource}/${record?.id}`);

  const tradingClone = () =>
    redirect(
      `/${isPaperTrading ? TRADING_RESOURCE.PAPER : TRADING_RESOURCE.LIVE}/create?${new URLSearchParams({
        source: JSON.stringify(_objectWithoutProperties(record, DISABLED_FIELDS)),
      }).toString()}`
    );

  const tradingAction = () => {
    const status = record?.status === statuses.active.id ? statuses.exiting.id : statuses.active.id;
    const exiting_target_status = record?.status === statuses.active.id ? statuses.stopped.id : null;
    if (record?.order_type === ORDER_TYPES.MARKET) record.order_validity = null;
    if (status === statuses.active.id) {
      dispatch({
        type: TRADING_SAVE,
        payload: {
          data: { ...record, status, exiting_target_status },
          id: record?.id,
          notify,
          t,
        },
      });
    } else if (status === statuses.exiting.id) {
      const id = record?.id;
      customFetch(`tradings/${id}/stop`, "POST", null, "text")
        .then(() => {
          notify(t("tradings.stop_exit"), "info");
          refresh();
        })
        .catch(() => {
          notify(t("common.cant_save"), "error");
        });
    }
  };

  const getTradngActions = () => {
    const { status } = record;
    switch (status) {
      case statuses.failed.id:
      case statuses.stopped.id:
        return {
          mainActionName: t("actions.edit"),
          mainActionFunc: tradingEdit,
          moreActions: [
            actions.start_stop,
            isPaperTrading ? actions.launch_live : actions.launch_paper,
            actions.copy_to_new_algo,
            actions.clone,
            actions.archive,
          ],
        };
      case statuses.draft.id:
        return {
          mainActionName: t("actions.start"),
          mainActionFunc: tradingAction,
          mainActionTestId: "tradings/start",
          mainActionDisabled: actions.launch_live ? disableByLimit.live_tradings : disableByLimit.paper_tradings,
          moreActions: [
            actions.edit,
            isPaperTrading ? actions.launch_live : actions.launch_paper,
            actions.copy_to_new_algo,
            actions.clone,
            actions.archive,
          ],
        };
      case statuses.active.id:
      case statuses.exiting.id:
        return {
          mainActionName: t("actions.stop"),
          mainActionFunc: tradingAction,
          mainActionTestId: "tradings/stop",
          mainActionDisabled: status === statuses.exiting.id,
          moreActions: [
            actions.edit,
            isPaperTrading ? actions.launch_live : actions.launch_paper,
            actions.copy_to_new_algo,
            actions.clone,
            actions.archive,
          ],
        };
      case statuses.archived.id:
        return {
          mainActionName: t("actions.clone"),
          mainActionFunc: tradingClone,
          moreActions: [isPaperTrading ? actions.launch_live : actions.launch_paper, actions.copy_to_new_algo],
        };

      default:
        return {};
    }
  };

  return getTradngActions();
}
