import { STATUSES } from "./statuses";

export const SHARED_STRATEGIES_LOADING = "SHARED_STRATEGIES_LOADING";
export const SHARED_STRATEGIES_RECEIVED = "SHARED_STRATEGIES_RECEIVED";
export const SHARED_STRATEGIES_FAIL = "SHARED_STRATEGIES_FAIL";

export default (previousState = { data: [], status: STATUSES.INIT }, { type, payload }) => {
  switch (type) {
    case SHARED_STRATEGIES_LOADING: {
      return { data: [], status: STATUSES.LOADING };
    }
    case SHARED_STRATEGIES_RECEIVED: {
      return { data: payload.sharedStrategies, status: STATUSES.LOAD_SUCCESS };
    }
    case SHARED_STRATEGIES_FAIL: {
      return { data: [], status: STATUSES.LOAD_FAIL };
    }
    default:
      return previousState;
  }
};
