import React from "react";
import classes from "./NoResultsFound.module.css";
import CreateFromASample from "../CreateFromASample/CreateFromASample";
import { ENTITY } from "../../shared/variables";
import NoResultsFound from "./NoResultsFound";
import useDisableByLimit from "../../shared/hooks/useDisableByLimit";

const { SCREENER, ALGO, PAPER_TRADING, LIVE_TRADING } = ENTITY;

const NoResultsFoundWithCreateFromASample = ({ basePath, samples, handleCreate, disableCreate, entity }) => {
  const disableByLimit = useDisableByLimit(entity);

  const getText = () => {
    switch (entity) {
      case SCREENER:
        return ["not_found.no_screeners", "create_from_a_sample.screeners_hint"];
      case ALGO:
        return ["not_found.no_algos", "create_from_a_sample.algos_hint"];
      case PAPER_TRADING:
        return ["not_found.no_paper"];
      case LIVE_TRADING:
        return ["not_found.no_money"];
      default:
        return ["not_found.no_records"];
    }
  };

  return (
    <NoResultsFound
      messages={getText()}
      createFromASampleButton={
        <div className={classes.createButton}>
          <CreateFromASample
            handleCreateFromASample={handleCreate}
            basePath={basePath}
            samples={samples}
            entity={entity}
            disabled={disableCreate || disableByLimit}
            noResults
          />
        </div>
      }
    />
  );
};

export default NoResultsFoundWithCreateFromASample;
