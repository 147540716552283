import { isMobile } from "react-device-detect";
import { setSidebarVisibility } from "react-admin";

import { TOUR_ANIM } from "../../../store/reducers/tourReducer";

import { en } from "../../../locales/en";

export const handleAfterOpen = () => {
  document.querySelector("body").style.overflow = "hidden";

  setTimeout(() => {
    if (document.getElementById("___reactour") !== null) {
      const newDiv = document.createElement("div");
      newDiv.className = "noclick";
      const currentDiv = document.getElementById("___reactour");

      currentDiv.append(newDiv);
    }
  });
};

export const handleAfterClose = (notify, dispatch) => {
  document.querySelector("body").style.overflow = "auto";

  if (isMobile) {
    notify(en.tour.rerun_mobile);
  } else {
    notify(en.tour.rerun_desktop);
  }

  dispatch({ type: TOUR_ANIM, payload: true });
  setTimeout(() => {
    dispatch({ type: TOUR_ANIM, payload: false });
  }, 2000);
};

export const handleGetCurrentStep = (curr, dispatch, sidebarOpen, step) => {
  if (document.querySelector(".MuiDrawer-modal") !== null && sidebarOpen && curr === step && isMobile) {
    dispatch(setSidebarVisibility(false));
    setTimeout(() => {
      document.querySelector("body").style.overflow = "hidden";
    });
  }
};
