import { TopToolbar, useListContext, useTranslate } from "react-admin";
import React, { useEffect } from "react";
import { ToggleButtonsField } from "../../../../components/ToggleButtonsField/ToggleButtonsField";
import { statuses } from "../../../../shared/statuses";
import CreateFromASample from "../../../../components/CreateFromASample/CreateFromASample";
import { ENTITY } from "../../../../shared/variables";
import { isDesktop } from "react-device-detect";
import classes from "../../../Dashboard/Dashboard.module.css";
import AddIcon from "@material-ui/icons/Add";
import useDisableByLimit from "../../../../shared/hooks/useDisableByLimit";

const liveStatuses = [statuses.draft.id, statuses.live.id];
const archivedStatuses = [statuses.archived.id];

const filterStatuses = [statuses.live.id, statuses.shared.id, statuses.archived.id];

export const ScreenerToolbar = ({ ga, basePath, setSwitch, samples, handleCreateFromASample }) => {
  const { loading, filterValues, setFilters } = useListContext();
  const t = useTranslate();
  const disableByLimit = useDisableByLimit();

  const selectedSwitch = () => {
    if (filterValues.shared === true) {
      return statuses.shared.id;
    } else if (JSON.stringify(filterValues.status) === JSON.stringify(archivedStatuses)) {
      return statuses.archived.id;
    } else {
      return statuses.live.id;
    }
  };

  useEffect(() => {
    setSwitch(selectedSwitch());
  }, [loading]);

  const handleRequestSwitch = (event) => {
    const checked = event.target.id;

    switch (checked) {
      case statuses.live.id: {
        setFilters({ status: liveStatuses });
        break;
      }
      case statuses.archived.id: {
        setFilters({ status: archivedStatuses });
        break;
      }

      case statuses.shared.id: {
        setFilters({ shared: true, status: liveStatuses });
        break;
      }

      default:
        return;
    }
  };

  return (
    <TopToolbar>
      <ToggleButtonsField
        ga={ga}
        onChange={handleRequestSwitch}
        defaultValue={selectedSwitch()}
        inputNames={filterStatuses}
        inputLabels={filterStatuses}
      />
      <CreateFromASample
        label={isDesktop ? t("screeners.create") : t("screeners.screener")}
        samples={samples}
        basePath={basePath}
        ga={ga}
        handleCreateFromASample={handleCreateFromASample}
        entity={ENTITY.SCREENER}
        disabled={disableByLimit.screeners}
      >
        {!isDesktop ? <AddIcon className={classes.icon} /> : null}
      </CreateFromASample>
    </TopToolbar>
  );
};
