export const TOUR_WELCOME = "TOUR_WELCOME";
export const TOUR_WELCOME_FIRST = "TOUR_WELCOME_FIRST";
export const TOUR_ALGO = "TOUR_ALGO";
export const TOUR_ALGO_FIRST = "TOUR_ALGO_FIRST";
export const TOUR_PAPER_TRADING = "TOUR_PAPER_TRADING";
export const TOUR_PAPER_TRADING_FIRST = "TOUR_PAPER_TRADING_FIRST";
export const TOUR_LIVE_TRADING = "TOUR_LIVE_TRADING";
export const TOUR_LIVE_TRADING_FIRST = "TOUR_LIVE_TRADING_FIRST";
export const TOUR_SCREENERS = "TOUR_SCREENERS";
export const TOUR_ANIM = "TOUR_ANIM";

export default (
  previousState = {
    tourWelcome: false,
    tourWelcomeFirst: false,
    tourAlgo: false,
    tourAlgoFirst: false,
    tourScreeners: false,
    tourPaperTrading: false,
    tourPaperTradingFirst: false,
    tourLiveTrading: false,
    tourLiveTradingFirst: false,
    tourAnim: false,
  },
  { type, payload }
) => {
  switch (type) {
    case TOUR_WELCOME: {
      return {
        ...previousState,
        tourWelcome: payload,
      };
    }
    case TOUR_WELCOME_FIRST: {
      return {
        ...previousState,
        tourWelcome: payload,
        tourWelcomeFirst: payload,
      };
    }
    case TOUR_ALGO: {
      return {
        ...previousState,
        tourAlgo: payload,
      };
    }
    case TOUR_ALGO_FIRST: {
      return {
        ...previousState,
        tourAlgo: payload,
        tourAlgoFirst: payload,
      };
    }
    case TOUR_SCREENERS: {
      return {
        ...previousState,
        tourScreeners: payload,
      };
    }
    case TOUR_PAPER_TRADING: {
      return {
        ...previousState,
        tourPaperTrading: payload,
      };
    }
    case TOUR_PAPER_TRADING_FIRST: {
      return {
        ...previousState,
        tourPaperTrading: payload,
        tourPaperTradingFirst: payload,
      };
    }
    case TOUR_LIVE_TRADING: {
      return {
        ...previousState,
        tourLiveTrading: payload,
      };
    }
    case TOUR_LIVE_TRADING_FIRST: {
      return {
        ...previousState,
        tourLiveTrading: payload,
        tourLiveTradingFirst: payload,
      };
    }
    case TOUR_ANIM: {
      return {
        ...previousState,
        tourAnim: payload,
      };
    }
    default:
      return previousState;
  }
};
