import { useEffect, useState } from "react";
import { customFetch } from "../../../DataProvider";

export default function useQuickStats(resource, includeArchived) {
  const [stats, setStats] = useState({});

  useEffect(() => {
    customFetch(`${resource}/stats?activeOnly=${!includeArchived}`, "GET").then((stats) => {
      stats && setStats(stats);
    });
  }, [resource, includeArchived]);

  return stats;
}
