export default {
  overrides: {
    RaFilterForm: {
      form: {
        minHeight: 20,
      },
    },
    RaCardContentInner: {
      root: {
        padding: "0 !important",
      },
    },
    RaAppBar: {
      menuButton: {
        color: "var(--gray_icon)",
        marginLeft: 20,
        marginRight: 14,
      },
      toolbar: {
        color: "var(--black)",
        minHeight: 64,
        paddingRight: 14,
        backgroundColor: "var(--white)",
      },
    },
    RaSidebar: {
      root: {
        height: "initial",
      },
      drawerPaper: {
        backgroundColor: "var(--white)",
        height: "100%",
        boxShadow: "var(--block_shadow)",
        zIndex: 500,
      },
      fixed: {
        position: "initial",
        width: "inherit"
      }
    },
    RaLoadingIndicator: {
      loader: {
        display: "none",
      },
    },
    RaMenuItemLink: {
      root: {
        font: "var(--text_4)",
        lineHeight: "23px",
        color: "var(--gray_icon)",
        padding: "11px 16px",
        minHeight: 46,
        "& svg": {
          width: 20,
          height: 20,
        },
        "&:hover svg": {
          color: "var(--blue)",
        },
        "&:hover": {
          backgroundColor: "var(--back)",
          color: "var(--blue)",
        },
      },
      icon: {
        minWidth: "auto",
        marginRight: 10,
        marginLeft: 10,
        color: "var(--gray_icon)",
      },
      active: {
        color: "var(--blue)",
        "& svg": {
          color: "var(--blue)",
        },
      },
    },
    RaMenu: {
      main: {
        margin: "0 !important",
        paddingTop: 16,
      },
    },
    RaLayout: {
      root: {
        backgroundColor: "var(--back)",
      },
      appFrame: {
        marginTop: "64px !important",
      },
      content: {
        padding: "24px !important",
        position: "relative",
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        color: "var(--blue)",
        zIndex: 4,
        paddingRight: 24,
        paddingLeft: 24,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        boxShadow: "inset 0 -1px 0 var(--gray_border)",
      },
      icon: {
        color: "inherit",
      },
    },
    RaList: {
      bulkActionsDisplayed: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
    },
    RaListToolbar: {
      actions: {
        paddingTop: 16,
      },
      toolbar: {
        alignItems: "center",
      },
    },
    RaTopToolbar: {
      root: {
        paddingTop: 10,
        position: "initial",
      },
    },
    RaFilterFormInput: {
      spacer: {
        display: "none",
      },
    },
    RaToolbar: {
      toolbar: {
        backgroundColor: "var(--white)",
      },
      spacer: {
        height: "0 !important",
      },
    },
    RaShow: {
      card: {
        backgroundColor: "transparent",
        overflow: "unset",
      },
      noActions: {
        margin: "0 !important",
      },
    },
    RaCreate: {
      card: {
        backgroundColor: "transparent",
        overflow: "unset",
      },
      noActions: {
        margin: "0 !important",
      },
    },
    RaEdit: {
      card: {
        backgroundColor: "transparent",
        overflow: "unset",
      },
      noActions: {
        margin: "0 !important",
      },
    },
    RaTabbedShowLayout: {
      content: {
        padding: "0 !important",
        margin: 0,
      },
    },
    RaFormInput: {
      input: {
        width: "100%",
      },
    },
  },
};
