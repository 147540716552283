import React from "react";
import classes from "./StrategiesDashboard.module.css";
import { StrategyCard } from "./StrategiesDashboard";
import { GA_CATEGORIES, TABLES } from "../../shared/variables";
import TradesDetails from "../../components/TradesDetails/TradesDetails";


const StrategyEdit = (props) => {
  const strategy = props.record;

  const ga = { category: GA_CATEGORIES.LIVE_TRADING_VIEW };

  return (
    <div>

        <div>
            <StrategyCard strategy={strategy}
                          showViewAction={false}
                          showTradeAction={false}
                          showImage={false}
                          actionsClassName={classes.actionsLeft}
            />
        </div>

        <TradesDetails
            {...props}
            ga={ga}
            filter={{ trading: props.record.id }}
            tableName={TABLES.LIVE_TRADING_TRADES}
            tradesOrdersResource="trading_orders"
            tradesListResource="trading-days/trading"
            tickersResource="trading/tickers"
        />
    </div>
  );
};

export default StrategyEdit;
