import React, { useCallback, useEffect, useRef, useState, useContext } from "react";
import {
  SimpleForm,
  useNotify,
  useRedirect,
  useTranslate,
  DataProviderContext,
  setSidebarVisibility,
} from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { Storage } from "../../config";
import { authFetchAPI, customFetch } from "../../DataProvider";
import { AdvancedBlock, AdvancedLine } from "../../components/AdvancedBlock/AdvancedBlock";
import Alert from "../../components/Alert/Alert";
import LimitAlert from "../../components/Alert/alerts/LimitAlert";
import CardHeader from "../../components/Card/CardHeader";
import FormHelper from "../../components/FormHelper";
import FullScreenEditor from "../../components/FullScreenEditor/FullScreenEditor";
import HelpTooltip from "../../components/HelpTooltip/HelpTooltip";
import WarnWhenUnsavedChanges from "../../components/WarnWhenUnsavedChanges/WarnWhenUnsavedChanges";
import { getPlanState, getUsageState } from "../../store/selectors";
import { formatLimitValue, getEndDate, getLimitRatio, getStartDate } from "../../shared/utils";
import { ENTITY, GA_CATEGORIES, LOCALSTORAGE, RATIO_LIMITS } from "../../shared/variables";
import { GET_USAGE } from "../../store/sagas/usageSaga";
import { BacktestList } from "../Backtest/Backtest";
import styles from "./Algo.module.scss";
import { AdditionalExitBlock, AdditionalLine } from "../../components/AdditionalExit/AdditionalExit";
import AlgoToolbar from "./components/AlgoToolbar/AlgoToolbar";
import { BacktestParameters } from "./components/BacktestParameters/BacktestParameters";
import { CriteriaBlock, CriteriaCollapseBlock } from "../../components/CriteriaBlock/CriteriaBlock";
import { InitialBlock, InitialLine } from "./components/Initial/Initial";
import fromEntries from "object.fromentries";
import { ALERT } from "../../components/Alert/variables";
import { isDesktop } from "react-device-detect";
import Card from "../../components/Card/Card";
import MobileVersionAlert from "../../components/Alert/alerts/MobileVersionAlert";
import { TOUR_ALGO_FIRST } from "../../store/reducers/tourReducer";
import { RESET_RERUN_BACKTEST } from "../../store/reducers/rerunBacktestReducer";
import {PortfolioType, transformAlgoValues} from "./utils";
import WatchlistCriteriaBuilder from "../Watchlist/components/WatchlistCriteriaBuilder";

const alert = {
  type: ALERT.BRAND,
  data: [
    {
      header: "helpers.help_analasyng_backtests",
      description: ["helpers.algo_doc_1", "helpers.algo_doc_2"],
    },
  ],
  action: {
    label: "helpers.backtest_user_guide",
    id: "user-guide",
    onClick: () =>
      window.open(
        "https://breakingequity.atlassian.net/wiki/spaces/BED/pages/616103945/Backtesting+User+Guide",
        "_blank"
      ),
  },
};

export const BACKTEST_VALUES = [
  "tickers",
  "screeners",
  "benchmark_ticker",
  "start_date",
  "end_date",
  "initial_capital",
];

const AlgoForm = (props) => {
  const { tourAlgo } = useSelector((state) => state.tour);
  const t = useTranslate();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const usage = useSelector(getUsageState) || {};
  const { algos, backtests: backtests_usage, status } = usage;
  const { algos_limit, backtests_limit } = useSelector(getPlanState) || {};
  const rerunBacktest = useSelector((state) => state.rerunBacktest);

  const [backtestsLoading, setBacktestsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(null);
  const [limitAlerts, setLimitAlerts] = useState();
  const [checkingUnsavedChanges, checkUnsavedChanges] = useState(true);
  const [isLoadingRecent, setLoadingRecent] = useState(false);
  const owner = localStorage.getItem(LOCALSTORAGE.PROFILE);

  const formRef = useRef(null);

  const { record } = props;
  if (rerunBacktest?.algo?.id && rerunBacktest?.algo?.id === record?.id) {
    Object.keys(transformAlgoValues(rerunBacktest)).forEach(key => {
      record[key] = rerunBacktest[key];
    });
  }

  const ga = { category: GA_CATEGORIES.ALGO_EDIT };

  const [showCode, setShowCode] = useState(false);
  const toggleCode = function (show) {
    setShowCode(show || !showCode);
  };

  useEffect(() => {
    if (tourAlgo) {
      setShowCode(true);
    }
  }, [tourAlgo]);

  useEffect(() => {
    if (window.location.href.includes("create?source")) {
      notify(t("algos.clone"), "info");
    }
    customFetch("profile").then((res) => {
      if (res && !res.algo_tour_completed) {
        dispatch({ type: TOUR_ALGO_FIRST, payload: true });
        dispatch(setSidebarVisibility(true));
      }
    });
    return () => dispatch({ type: RESET_RERUN_BACKTEST });
  }, []);

  const showAlert = useCallback(() => {
    let backtestsLimitAlert;

    const limitRatio = getLimitRatio(backtests_usage, backtests_limit, status);
    if (limitRatio !== RATIO_LIMITS.A_LOT) {
      const leftLimits = formatLimitValue(backtests_limit) - backtests_usage;
      backtestsLimitAlert = {
        ratio: limitRatio,
        entity: ENTITY.BACKTESTS,
        limits: leftLimits,
      };
    }

    if (record?.id) {
      setLimitAlerts([backtestsLimitAlert]);
    } else {
      const alerts = [];
      const limitAlgos = getLimitRatio(algos, algos_limit, status);

      if (limitAlgos !== RATIO_LIMITS.A_LOT) {
        const leftLimits = formatLimitValue(algos_limit) - algos;
        alerts.push({
          ratio: limitAlgos,
          entity: ENTITY.ALGOS,
          limits: leftLimits,
        });
      }

      if (backtestsLimitAlert) alerts.push(backtestsLimitAlert);
      alerts.length && setLimitAlerts(alerts);
    }
  }, [backtests_usage, backtests_limit, algos, algos_limit, status, record]);

  useEffect(() => {
    if (!record || !(record.id || record.id_for_getting_backtests)) return;
    if (record.backtests_in_progress > 0) {
      setBacktestsLoading(true);
    }
  }, [record]);

  useEffect(() => {
    showAlert();
  }, [backtests_usage, backtests_limit, algos, algos_limit, showAlert]);

  const handleSave = async ({ values, redirectOnSave = false }) => {
    let algoId = null;
    try {
      if (!record?.id) {
        // create
        const {
          data: { id },
        } = await dataProvider.create("algos", {
          data: { ...values, status:  null },
        });
        algoId = id;
        dispatch({ type: GET_USAGE });
        checkUnsavedChanges(false);
        redirectOnSave && redirect("edit", "/algos", algoId, values);
      } else { // update
        algoId = record.id;
        // pristine check removed
        // if (formRef.current.getState().pristine) {
        //   return algoId;
        // }
        const submitValues = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== ""));
        await dataProvider.update("algos", {
          data: { ...submitValues, status: null },
          id: record.id,
        });

      }
      notify(t("algos.saved"), "info");
      return algoId;
    } catch (error) {
      notify(error.message ? `Error: ${error.message}` : "ra.notification.http_error", "warning");
      return null;
    }
  };

  const handleValidateSaveAndLaunch = async (values) => {

    // shared screeners validation
    if (values.screeners) {
      try {
        const screenersPromises = values.screeners.map((screenerId) =>
          dataProvider.getOne("screeners", {
            id: screenerId,
          })
        );
        const screenersRes = await Promise.allSettled(screenersPromises);
        for (const res of screenersRes) {
          const {
            value: { data },
          } = res;
          if (!data.shared && data.owner.id !== Storage.getItem("profile")) {
            notify(t("backtest.screener_not_owned_shared"), "error");
            return;
          }
        }
      } catch (e) {
        notify(e.message ? `Error: ${e.message}` : "ra.notification.http_error", "warning");
        return;
      }
    }

    const algoId = await handleSave({ values });
    if (algoId === null) {
      return;
    }

    const backtestsValues = fromEntries(BACKTEST_VALUES.map((key) => [key, values[key]]));
    localStorage.setItem(LOCALSTORAGE.BACKTESTS_RECENT, JSON.stringify(backtestsValues));

    try {
      const res = await authFetchAPI("backtests/launch", {
        method: "POST",
        body: JSON.stringify({ algo: { id: algoId } }),
      });
      if (!res.ok) throw new Error((await res.json()).message);
      notify(t("backtest.successfully_launched"), "info");
      // workaround to start backtests polling if we are on the edit page
      if (record.id) setBacktestsLoading(true);
      dispatch({ type: GET_USAGE });
      !values?.id && redirect("edit", "/algos", algoId, values);
    } catch (e) {
      notify(e?.message || t("common.cant_save"), "warning");
      console.error(e);
    }
  };

  const loadRecent = async () => {
    setLoadingRecent(true);
    dataProvider
      .getList("algos", {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: { field: "updated_date", order: "DESC" },
        filter: {
          status: ["LIVE", "READY", "DRAFT"],
          owner,
        },
      })
      .then(({ data }) => {
        formRef.current.batch(() => {
          BACKTEST_VALUES.forEach((key) => {
            formRef.current.change(key, data?.[0][key]);
          });
        });
        setLoadingRecent(false);
      })
      .catch((e) => {
        console.error(e);
        setLoadingRecent(false);
      });
  };

  const { save, saving, ...backtestListProps } = props;

  return (
    <>
      {record.portfolio_type !== PortfolioType.WATCHLIST ? < MobileVersionAlert /> : null}
      {limitAlerts?.map((item, index) => (
          <LimitAlert key={index} {...item} />
      ))}

      <SimpleForm
        submitOnEnter={false}
        record={{
          ...(rerunBacktest?.algo?.id && rerunBacktest?.algo?.id === record?.id
            ? transformAlgoValues(rerunBacktest)
            : record),
        }}
        initialValues={{ start_date: getStartDate(), end_date: getEndDate() }}
        save={handleValidateSaveAndLaunch}
        toolbar={
          <AlgoToolbar ga={ga} handleSave={handleSave} loadRecent={loadRecent} isLoadingRecent={isLoadingRecent} />
        }
      >
        <FormHelper ref={formRef} />
        {openAlert && <Alert ga={ga} data={alert} setOpenAlert={setOpenAlert} />}
        {checkingUnsavedChanges && <WarnWhenUnsavedChanges />}
        {isDesktop || record.portfolio_type === PortfolioType.WATCHLIST ? (
          <>
            <CardHeader>
              {t("algos.blocks.algo_params")}
              <HelpTooltip ga={ga} title={t("helpers.algo_parameters")} setOpenAlert={() => setOpenAlert(true)} />
            </CardHeader>
            <div className={styles.algoParams}>
              <InitialBlock {...props} ga={ga} />
              {props.record.portfolio_type === "WATCHLIST" ? <WatchlistCriteriaBuilder readOnly={false} {...props}/> :
                  <>
                      <div style={{display: showCode ? "block" : "none"}}>
                        <FullScreenEditor
                            ga={{category: GA_CATEGORIES.ALGO_FULLSCREEN}}
                            criteriaInputs={<CriteriaBlock {...props} />}
                            additionalBlock={<AdditionalExitBlock {...props} />}
                            handleSave={handleSave}
                            entity={record?.id ? null : ENTITY.ALGO}
                        />
                      </div>

                      <CriteriaCollapseBlock ga={ga} onExpand={toggleCode} onCollapse={toggleCode} {...props} />
                      <AdditionalExitBlock ga={ga} {...props} />
                      <AdvancedBlock ga={ga} {...props} />
                  </>
              }
            </div>
          </>
        ) : (
          <Card header={t("algos.blocks.algo_params")}>
            <InitialLine values={record} />
            <SimpleForm {...props} toolbar={null}>
              <CriteriaBlock {...props} readOnly />
            </SimpleForm>
            <AdditionalLine values={record} />
            <AdvancedLine values={record} />
          </Card>
        )}
        <BacktestParameters ga={ga} {...props} />
      </SimpleForm>
      <BacktestList
        {...backtestListProps}
        algo={record}
        backtestsLoading={backtestsLoading}
        setBacktestsLoading={setBacktestsLoading}
      />
    </>
  );
};

export default AlgoForm;
