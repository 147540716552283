import { put, takeEvery, retry } from "redux-saga/effects";
import { customFetch } from "../../DataProvider";
import { Storage } from "../../config";
import { statuses } from "../../shared/statuses";
import {
  SCREENERS_LOADING,
  SCREENERS_RECEIVED,
} from "../reducers/screenersReducer";
import {USE_SCREENERS} from "../../shared/permissions";

export const GET_SCREENERS = "GET_SCREENERS";
const SECOND = 1000;

export default function* screenersSaga() {
  yield takeEvery(GET_SCREENERS, function* () {
    const screenersFilter = encodeURIComponent(
      JSON.stringify({
        owner: { id: Storage.getItem("profile") },
        status: [statuses.draft.id, statuses.live.id],
      })
    );

    const permissions = JSON.parse(Storage.getItem("permissions"));

    yield put({ type: SCREENERS_LOADING });
    try {
      let screeners = [];
      if(permissions.includes(USE_SCREENERS)) {
        screeners = yield retry(
            2,
            3 * SECOND,
            customFetch,
            `screeners?filter=${screenersFilter}`
        );
      }
      const sharedScreeners = yield retry(
          2,
          3 * SECOND,
          customFetch,
          `screeners/shared`
      );
      sharedScreeners.forEach((screener) => {
        if (screeners.some((e) => e.id === screener.id)) return false;
        screeners.push(screener);
      });
      yield put({
        type: SCREENERS_RECEIVED,
        payload: { screeners },
      });
    } catch (e) {
      //
    }
  });
}
