import React from "react";
import classes from "./ExpandPanel.module.css";
import { Grid } from "@material-ui/core";
import { MomentField } from "../../../../MomentField";
import Config from "../../../../../config";
import { OrderStatus, OrderType } from "../../Trades";
import { isDesktop } from "react-device-detect";
import { useTranslate } from "react-admin";
import NoResultsFound from "../../../../NoResultsFound/NoResultsFound";

export const getOrderDetails = (props) => {
  const marketLabel = props.marketLabel || "MARKET";
  const order = props.order;
  const createSide = props.side !== "fill";
  const trail = order.trail_offset
    ? `-${order.trail_offset.toFixed(4)}$`
    : order.trail_percent
    ? `-${(order.trail_percent * 100).toFixed(4)}%`
    : null;

  let price = createSide ? order.created_price || order.limit_price || order.stop_price : order.price;
  price = price ? price.toFixed(4) : marketLabel;

  if (createSide && trail) price = trail;

  let size = createSide ? order.created_size : order.size;
  size = size ? Number(size).toLocaleString("en-EN") : "";

  return { size, ticker: order.ticker, price, createSide };
};

const OrderDetails = (props) => {
  const { order, parentId } = props;
  const { size, ticker, price, createSide } = getOrderDetails(props);

  if (!createSide && !size) return "";
  const clickedTicker = (
    <span className={classes.clickedTicker} onClick={() => props.onTickerClick({ parentId, order })}>
      {ticker}
    </span>
  );

  return (
    <span>
      {size} {isDesktop ? clickedTicker : ticker} @ {price}
    </span>
  );
};

const OrdersList = (props) => {
  const orders = props.orders;
  const t = useTranslate();

  if (orders.length) {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={false}>
            <div className={classes.status} />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.open}>open</div>
          </Grid>
          <Grid item xs={false}>
            <div className={classes.status} />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.close}>close</div>
          </Grid>
        </Grid>
        {orders.map((order) => (
          <Grid container spacing={1} className={order.type + " " + order.status} key={order.id}>
            <Grid item xs={false}>
              <div className={classes.status}>
                <OrderType type={order.type} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <span>
                <span>
                  <MomentField
                    record={order}
                    format={Config.DATETIME_FORMAT}
                    source="created_datetime"
                    timezone={Config.STOCK_TZ}
                  />
                </span>
                <span className={classes.orderData}>
                  <OrderDetails
                    parentId={props.chartId}
                    order={order}
                    side="create"
                    marketLabel={t("common.market").toUpperCase()}
                    onTickerClick={props.onTickerClick}
                  />
                </span>
              </span>
            </Grid>
            <Grid item xs={false}>
              <div className={classes.status}>
                <OrderStatus status={order.status} slippage={order.slippage} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <span>
                <span>
                  <MomentField
                    record={order}
                    format={Config.DATETIME_FORMAT}
                    source="datetime"
                    timezone={Config.STOCK_TZ}
                  />
                </span>
                <span className={classes.orderData}>
                  <OrderDetails
                    parentId={props.chartId}
                    order={order}
                    side="fill"
                    marketLabel={t("common.market").toUpperCase()}
                    onTickerClick={props.onTickerClick}
                  />
                </span>
              </span>
            </Grid>
          </Grid>
        ))}
      </>
    );
  }

  return <NoResultsFound />;
};

export default OrdersList;
