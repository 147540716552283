import React from "react";
import { SimpleList, useTranslate } from "react-admin";
import styles from "./BacktestMobileList.module.css";
import mobileStyles from "../../../../components/MobileList.module.css";
import { toDateFormat } from "../../../../shared/utils";
import Moment from "react-moment";
import StatusField from "../../../../components/StatusField/StatusField";
import { ActiveDaysTextField } from "../../Backtest";

const BacktestMobileList = () => {
  const t = useTranslate();
  return (
    <SimpleList
      linkType={false}
      className={mobileStyles.list}
      primaryText={({ start_date, end_date, created_date }) => (
        <div className={mobileStyles.date} style={{ flexDirection: "column" }}>
          <span>
            {toDateFormat(start_date)}
            {" - "}
            {toDateFormat(end_date)}
          </span>
          <span>
            {t("common.created")} <Moment fromNow>{created_date}</Moment>
          </span>
        </div>
      )}
      secondaryText={(record) => (
        <div className={mobileStyles.items}>
          <span className={styles.activeDays}>
            {t("backtest.active_days")}: <ActiveDaysTextField record={record} />
          </span>
          <StatusField record={record} source="status" />
        </div>
      )}
    />
  );
};

export default BacktestMobileList;
