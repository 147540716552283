import React, { useState } from "react";
import { useNotify, useRedirect, useTranslate } from "react-admin";
import { isMobile } from "react-device-detect";
import { Button, CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import styles from "../ViewActions.module.scss";
import DisableCreatingTooltip from "../../DisableCreatingTooltip/DisableCreatingTooltip";
import { ENTITY, GA_CATEGORIES } from "../../../shared/variables";
import { setGaId } from "../../../shared/utils";
import { customFetch } from "../../../DataProvider";
import { TOUR_ALGO, TOUR_WELCOME, TOUR_WELCOME_FIRST } from "../../../store/reducers/tourReducer";
import { GET_USAGE } from "../../../store/sagas/usageSaga";
import useDisableByLimit from "../../../shared/hooks/useDisableByLimit";

const TryAlgoButton = ({ record }) => {
  const { tour } = useSelector((state) => state);
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const disableByLimit = useDisableByLimit();
  const [creatingInProgress, setCreatingInProgress] = useState();

  const disableTryAlgo = creatingInProgress || disableByLimit.backtests || disableByLimit.algos;

  const tryAlgo = (id) => {
    try {
      setCreatingInProgress(true);
      customFetch(`backtests/shared/${id}/clone`, "POST", null, "text").then((backtest) => {
        dispatch({ type: TOUR_WELCOME, payload: false });
        if (tour.tourWelcomeFirst) {
          customFetch("profile", "PUT", {
            guided_tour_completed: true,
          }).then((res) => {
            if (res && res.guided_tour_completed) {
              dispatch({ type: TOUR_WELCOME_FIRST, payload: false });
              dispatch({ type: TOUR_ALGO, payload: true });
            }
          });
        }
        dispatch({ type: GET_USAGE });
        const algoId = JSON.parse(backtest).algo.id;
        redirect(`/algos/${algoId}`);
      });
    } catch {
      setCreatingInProgress(false);
      dispatch({ type: GET_USAGE });
      notify(t("algos.try_algo_error"));
    }
  };

  return creatingInProgress ? (
    <div className={styles.tryProgress}>
      <CircularProgress style={{ width: 20, height: 20 }} />
    </div>
  ) : (
    <DisableCreatingTooltip disabled={disableTryAlgo} entity={ENTITY.ALGO}>
      <Button
        className={clsx(styles.button, { [styles.mobileTryAlgo]: isMobile })}
        onClick={() => tryAlgo(record?.id)}
        disabled={disableTryAlgo}
        id={setGaId(GA_CATEGORIES.BACKTEST, "try-algo")}
        data-testid="try-algo"
      >
        {t("dashboard.try_algo")}
      </Button>
    </DisableCreatingTooltip>
  );
};

export default TryAlgoButton;
