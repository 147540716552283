import React, { useEffect } from "react";
import { Create } from "react-admin";
import AlgoForm from "./AlgoForm";
import { GET_SCREENERS } from "../../store/sagas/screenersSaga";
import { GET_SCREENERS_ALL } from "../../store/sagas/screenersAllSaga";
import { useDispatch } from "react-redux";
import { convertEdtEstToLocal } from "../../shared/utils";
import {PortfolioType} from "./utils";

export const getAlgoInitialData = () => ({
  enter_interval: "60",
  exit_interval: "60",
  algo_type: "LONG",
  order_type: "LIMIT",
  max_risk_per_day: 0.02,
  max_risk_per_day_value_type: "PERCENTAGE",
  max_risk_per_day_enabled: true,
  max_risk_per_trade: 0.01,
  max_risk_per_trade_value_type: "PERCENTAGE",
  max_risk_per_trade_enabled: true,
  max_entry_trades: 10,
  max_entry_trades_enabled: true,
  order_validity: "0",
  regular_days_from: convertEdtEstToLocal("09:31:00"),
  regular_days_to: convertEdtEstToLocal("16:00:00"),
  early_close_days_from: convertEdtEstToLocal("09:31:00"),
  early_close_days_to: convertEdtEstToLocal("09:31:00"),
  force_exit_at: "END_OF_TRADING_HOURS",
});

const CreateAlgo = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_SCREENERS });
    dispatch({ type: GET_SCREENERS_ALL });
  }, []);

  const initialData = { name: "New Algo", portfolio_type: PortfolioType.INTRADAY};
  //might be good to check in older phones for Object.assign support
  //see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
  Object.assign(initialData, getAlgoInitialData());

  return (
    <Create {...props} record={initialData}>
      <AlgoForm {...props} />
    </Create>
  );
};

export default CreateAlgo;
