import React from "react";
import { Dialog, MenuItem, MenuList } from "@material-ui/core";
import { useTranslate } from "react-admin";
import classes from "../CreateFromASample.module.css";
import { setGaId } from "../../../shared/utils";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useSelector } from "react-redux";

const MobileDialog = ({ ga, samples, isOpen, onClickMenuItem }) => {
  const t = useTranslate();
  const { discount, email, referral, payment, banner } = useSelector((store) => store.header);

  return (
    <Dialog
      disablePortal
      fullScreen
      open={isOpen}
      className={classes.root}
      style={(discount || email || referral || payment || banner) && { marginTop: 122 }}
      BackdropProps={{ open: false }}
    >
      <div className={classes.dialogTitle}>{t("create_from_a_sample.label")}</div>

      <div className={classes.mobileMenu}>
        <MenuList>
          {samples?.map((sample) => (
            <MenuItem
              key={sample.id}
              onClick={onClickMenuItem(sample)}
              className={classes.mobileMenuItem}
              color="primary"
              id={setGaId(ga?.category, "create-from-sample")}
            >
              <div className={classes.mobileMenuItems}>
                <div className={classes.mobileMenuItemText}>{sample.name}</div>
                <div className={classes.mobileMenuItemArrow}>
                  <ChevronRightIcon />
                </div>
              </div>
            </MenuItem>
          ))}
        </MenuList>
      </div>
    </Dialog>
  );
};

export default MobileDialog;
