import React from "react";
import MDEditor, { commands } from '@uiw/react-md-editor';
import { useInput } from "react-admin";
import styles from "./Markdown.module.css";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedDiv = ({ children, label, className }) => {
  return (
    <TextField
      className={clsx(styles.inputWrapper, className)}
      variant="outlined"
      label={label}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
      }}
      inputProps={{ children: children }}
    />
  );
};

export const MarkdownInput = (props) => {
  const { input } = useInput(props);
  return (
    <OutlinedDiv label={props.label} className={props.className}>
    <div data-color-mode="light">
      <MDEditor
        className={styles.mdEditor}
        value={input.value}
        preview="edit"
        extraCommands={[commands.codeEdit, commands.codePreview, commands.codeLive]}
        onChange={(value) => {
          input.onChange(value);
        }}
      />
    </div>
    </OutlinedDiv>
  );
};

export const MarkdownField = (props) => {
  const value = props.record[props.source];
  return (
    <div className={clsx(styles.inputWrapper, styles.readOnly)} data-color-mode="light">
        <MDEditor.Markdown className={styles.mdView} source={value} />
    </div>
  );
};
