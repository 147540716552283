import ReactFlagsSelect from "react-flags-select";
import React from "react";
import countryTelData from "country-telephone-data";
import styles from "./PhoneField.module.css";

let countryLabels = {};

countryTelData.allCountries
  .sort((a, b) => {
    if (a.iso2 === "us" || a.iso2 === "ca") {
      return -1;
    } else {
      return a.name.localeCompare(b.name);
    }
  })
  .forEach((entry) => (countryLabels[entry.iso2.toUpperCase()] = entry.name));

const countryCodes = countryTelData.allCountries.map((entry) => entry.iso2.toUpperCase());

export const CountryField = ({ record, source, showLabel, ifEmpty }) => {
  const country = record ? (record[source] || record.country) :  null;

  return country ? (
    <>
      <ReactFlagsSelect
        showSelectedLabel={false}
        countries={countryCodes}
        customLabels={countryLabels}
        selected={country}
        onSelect={() => {}}
        disabled
        className={styles.reactFlagsSelect}
      />
      <span>{showLabel ? country : ""}</span>
    </>
  ) : (
    ifEmpty
  );
};

export const PhoneField = ({ record, source }) => {
  return (
    <div className={styles.container}>
      <CountryField record={record} source="country" showLabel={false} ifEmpty=""/>
      <span>{record?.[source]}</span>
    </div>
  );
};
