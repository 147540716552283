import React from "react";

import X from "react-feather/dist/icons/x";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { useTranslate } from "react-admin";

import classes from "../../Carousel/Carousel.module.css";
import style from "./TourPopupLayout.module.scss";

export const TourPopupLayout = (propsLayout) => {
  const t = useTranslate();
  const { children } = propsLayout;

  return (
    <div className={style.popupTour}>
      {children}
      {propsLayout.btnNext === "default" && (
        <Button
          className={clsx(style.exploreButton, classes.exploreButton)}
          onClick={() => propsLayout.propsLayout.goTo(propsLayout.btnNextStep)}
          data-testid="tour/next-step"
        >
          {t("tour.next")}
        </Button>
      )}
      {propsLayout.btnNext !== "default" && propsLayout.btnNext}
      <X onClick={() => propsLayout.propsLayout.close()} className={style.close} />
    </div>
  );
};

export default TourPopupLayout;
