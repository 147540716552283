import React from "react";
import { useTranslate } from "react-admin";
import classes from "./BasktestParameters.module.css";
import Card from "../../../../components/Card/Card";
import { setGaId } from "../../../../shared/utils";
import { DateRange } from "../../../../components/DateInput/DateInput";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

export const BacktestParameters = ({ ga }) => {
  const t = useTranslate();

  return (
    <Card header={t("screeners.blocks.backtest_params")}>
      <div className={clsx(classes.gridLayout, { [classes.mobile]: isMobile })}>
        <div className={classes.dates}>
          <DateRange
            id={setGaId(ga?.category, "backtest-date")}
            label_from={t("backtest.from")}
            label_to={t("backtest.to")}
            tooltip_from={t("helpers.input_from")}
            tooltip_to={t("helpers.input_to")}
            source_from="start_date"
            source_to="end_date"
          />
        </div>
      </div>
    </Card>
  );
};
