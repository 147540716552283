import React, { useEffect, useState } from "react";
import {
  BulkDeleteButton,
  Filter,
  List,
  NumberField,
  SearchInput,
  SimpleList,
  useNotify,
  usePermissions,
  useRedirect,
  useRefresh,
  useTranslate,
} from "react-admin";
import { MomentField } from "../../components/MomentField";
import Tooltip from "@material-ui/core/Tooltip";
import AlgoType from "../../components/AlgoType";
import { actions } from "../../shared/moreActions";
import MoreActions from "../../components/MoreActions/MoreActions";
import styles from "./ShowTradings.module.scss";
import mobileStyles from "../../components/MobileList.module.css";
import { statuses } from "../../shared/statuses";
import cn from "clsx";
import clsx from "clsx";
import { formatLimitValue, getLimitRatio, setGaId } from "../../shared/utils";
import {
  DISABLED_FIELDS,
  ENGINES,
  ENTITY,
  GA_CATEGORIES,
  RATIO_LIMITS,
  TABLES,
  TRADING_RESOURCE,
  TRADING_TYPE,
} from "../../shared/variables";
import { useDispatch, useSelector } from "react-redux";
import { ActionsToolbar } from "../../components/ActionsToolbar/ActionsToolbar";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { getTradingsResource, isPaper } from "./utils";
import { getPlanState, getUsageState } from "../../store/selectors";
import LimitAlert from "../../components/Alert/alerts/LimitAlert";
import { isDesktop, isMobile } from "react-device-detect";
import StatusField from "../../components/StatusField/StatusField";
import { Link } from "ra-ui-materialui";
import CustomizableDatagrid from "../../components/DataDrid/CustomizableDatagrid";

import { BrokerIcon } from "./components/Brokers/BrokersBlock";
import { ColoredNumber } from "../../components/ColoredNumber/ColoredNumber";
import { transformTime } from "../Algo/utils";
import ListPagination from "../../components/ListPagination/ListPagination";
import useGetItemsPerPage from "../../components/ListPagination/hooks/useGetItemsPerPage";
import TickerBenchmarkField from "../../components/TickerBenchmarkField/TickerBenchmarkField";
import { STATUSES } from "../../store/reducers/statuses";
import { TRADING_CLEAR } from "../../store/reducers/tradingReducer";
import QuickStats from "../../components/QuickStats/QuickStats";
import Card from "../../components/Card/Card";
import ArchiveIcon from "react-feather/dist/icons/archive";
import { Storage } from "../../config";
import { BACKTEST_ALGOS } from "../../shared/permissions";
import { RewardRiskRatio, WinLossRatio } from "../../components/AlgolFields/AlgoFields";

const BulkActionButtons = ({ ga, ...rest }) => {
  const t = useTranslate();
  return (
    <BulkDeleteButton
      {...rest}
      id={setGaId(ga?.category, "paper-checkbox-archive")}
      icon={<ArchiveIcon />}
      label={t("actions.archive")}
      data-testid="tradings-archive"
    />
  );
};

const TradingFilter = ({ ga, record }) => {
  return (
    <Filter variant="standard" record={record}>
      <SearchInput source="q" variant="standard" alwaysOn id={setGaId(ga?.category, "search")} />
    </Filter>
  );
};

const NameField = ({ record, source }) => {
  return (
    <Tooltip title={record?.[source]}>
      <span>{record?.[source]}</span>
    </Tooltip>
  );
};

export const TradingList = (props) => {
  const t = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const [limitAlert, setLimitAlert] = useState({});
  const [switcher, setSwitch] = useState(statuses.active.id);
  const isPaperTrading = isPaper(props);
  const tradingsResource = getTradingsResource(isPaperTrading);
  const { loaded, permissions } = usePermissions();

  const { money_tradings, paper_tradings, status } = useSelector(getUsageState) || {};
  const { money_tradings_limit, paper_tradings_limit } = useSelector(getPlanState) || {};
  const { status: tradingStatus, errorMessage: tradingErrorMessage } = useSelector((store) => store.trading);
  const sharedBacktests = useSelector((state) => state.sharedBacktests.data);

  const tableName = isPaperTrading ? TABLES.PAPER_TRADINGS : TABLES.LIVE_TRADINGS;
  const perPage = useGetItemsPerPage(tableName);

  const ga = {
    category: isPaperTrading ? GA_CATEGORIES.PAPER_TRADING : GA_CATEGORIES.LIVE_TRADING,
  };

  useEffect(() => {
    if (tradingStatus === STATUSES.UPDATE_SUCCESS) refresh();
    if (tradingStatus === STATUSES.UPDATE_FAIL) notify(t(tradingErrorMessage), "warning");
    return () => dispatch({ type: TRADING_CLEAR });
  }, [tradingStatus, tradingErrorMessage]);

  useEffect(() => {
    const limitRatio = isPaperTrading
      ? getLimitRatio(paper_tradings, paper_tradings_limit, status)
      : getLimitRatio(money_tradings, money_tradings_limit, status);
    if (limitRatio !== RATIO_LIMITS.A_LOT) {
      const leftLimits = isPaperTrading
        ? formatLimitValue(paper_tradings_limit) - paper_tradings
        : formatLimitValue(money_tradings_limit) - money_tradings;
      setLimitAlert({
        ratio: limitRatio,
        entity: isPaperTrading ? ENTITY.PAPER_TRADINGS : ENTITY.LIVE_TRADINGS,
        limits: leftLimits,
      });
    }
  }, [isPaperTrading, paper_tradings, paper_tradings_limit, money_tradings, money_tradings_limit]);

  const handleCreateFromASample = ({ id }) => {
    const foundBacktest = sharedBacktests.find((backtest) => backtest?.id === id);
    const backtestDraftStatus = { ...foundBacktest, status: statuses.draft.id };
    const formattedRecord = transformTime(backtestDraftStatus);
    formattedRecord.broker = isPaperTrading ? ENGINES.BE.id : ENGINES.CB.id;

    redirect(
      `/${tradingsResource}/create?${new URLSearchParams({
        source: JSON.stringify(_objectWithoutProperties(formattedRecord, DISABLED_FIELDS)),
      }).toString()}`
    );
  };
  let buttonLabels = isDesktop ? "tradings.create_paper" : "tradings.paper";

  if (!isPaperTrading) {
    buttonLabels = isDesktop ? "tradings.create_money" : "tradings.money";
  }

  const actionsToolbar = (ga) => {
    return (
      <ActionsToolbar
        ga={ga}
        switcher={switcher}
        setSwitch={setSwitch}
        samples={sharedBacktests}
        basePath={`/${tradingsResource}`}
        entity={isPaperTrading ? ENTITY.PAPER_TRADING : ENTITY.LIVE_TRADING}
        createButtonLabel={buttonLabels}
        handleCreateFromASample={handleCreateFromASample}
        readOnly={!permissions?.includes(BACKTEST_ALGOS)}
        limits={
          isPaperTrading
            ? { usage: paper_tradings, planLimit: paper_tradings_limit, status }
            : { usage: money_tradings, planLimit: money_tradings_limit, status }
        }
        filterStatuses={[statuses.active.id, statuses.archived.id]}
        additionalSwitchFilters={{
          trading_type: isPaperTrading ? TRADING_TYPE.PAPER : TRADING_TYPE.LIVE,
        }}
      />
    );
  };

  const tradingClick = (id, resource, record) =>
    record?.status === statuses.draft.id
      ? `/${TRADING_RESOURCE.TRADINGS}/${record?.id}`
      : `/${TRADING_RESOURCE.TRADINGS}/${record?.id}/show`;

  const enableArchive = () => switcher === statuses.active.id;

  return (
    <div className={clsx({ [styles.mobileWrapper]: isMobile })}>
      <LimitAlert {...limitAlert} />
      <QuickStats resource={tradingsResource} ga={ga} />
      <Card header={t("tradings.tradings")}>
        <List
          {...props}
          sort={{ field: "created_date", order: "DESC" }}
          exporter={false}
          filterDefaultValues={{
            statusNot: statuses.archived.id,
            trading_type: isPaperTrading ? TRADING_TYPE.PAPER : TRADING_TYPE.LIVE,
          }}
          actions={actionsToolbar(ga)}
          filter={{ owner: Storage.getItem("profile") }}
          filters={<TradingFilter ga={ga} {...props} />}
          className={styles.list}
          perPage={perPage}
          pagination={
            <ListPagination
              {...props}
              tableName={tableName}
              entity={isPaperTrading ? ENTITY.PAPER_TRADING : ENTITY.LIVE_TRADING}
              samples={sharedBacktests}
              handleCreate={handleCreateFromASample}
            />
          }
          bulkActionButtons={enableArchive() && <BulkActionButtons ga={ga} {...props} />}
          resource={tradingsResource}
          basePath={`/${tradingsResource}`}
        >
          {isDesktop ? (
            <CustomizableDatagrid
              ga={ga}
              defaultColumns={[
                "name",
                "ticker",
                "created_date",
                "status",
                "last_trade",
                "trades_today",
                "initial_capital",
                "return_pct",
                "gain_today_pct",
                "broker",
              ]}
              rowClick={tradingClick}
              size="medium"
            >
              <NameField source="name" cellClassName="Other-Col Name-Col" headerClassName="Other-Col Name-Col" />
              <TickerBenchmarkField
                ticket="ticker"
                benchmark="benchmark_ticker"
                label={t("tradings.ticker")}
                headerClassName="Other-Col"
                cellClassName="Other-Col"
              />

              <MomentField
                source="created_date"
                fromNow={true}
                label={t("tradings.created")}
                cellClassName="Other-Col"
                headerClassName="Other-Header"
              />

              <StatusField source="status" cellClassName="Other-Col" headerClassName="Other-Header" />

              <MomentField
                source="last_trade"
                fromNow={true}
                label={t("tradings.last_trade")}
                cellClassName="Other-Col"
                headerClassName="Other-Header"
              />

              <NumberField
                source="trades_today"
                label={t("tradings.trades_today")}
                cellClassName="Other-Col"
                headerClassName="Other-Header"
                emptyText={"0"}
                options={{
                  maximumFractionDigits: 2,
                }}
              />

              <NumberField
                label={t("tradings.capital")}
                source="initial_capital"
                cellClassName="Other-Col"
                headerClassName="Other-Header"
                emptyText={t("common.not_available")}
                options={{
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                  style: "currency",
                  currency: "USD",
                }}
              />

              <ColoredNumber
                label={t("tradings.return_pct")}
                locales="en-EN"
                style={{ percent: "percent" }}
                source="return_pct"
                cellClassName="Other-Col MuiTableCell-alignRight"
                headerClassName="Other-Header"
              />

              <ColoredNumber
                label={t("tradings.gain_today_pct")}
                locales="en-EN"
                style={{ percent: "percent" }}
                source="gain_today_pct"
                cellClassName="Other-Col"
                headerClassName="Other-Header"
              />

              <ColoredNumber
                label={t("tradings.gain_prior_day_pct")}
                locales="en-EN"
                style={{ percent: "percent" }}
                source="gain_prior_day_pct"
                cellClassName="Other-Col"
                headerClassName="Other-Header"
              />

              <AlgoType
                label={t("tradings.algo_type")}
                source="algo_type"
                cellClassName={cn("Status-Col", styles.noVPadding)}
              />

              <NumberField
                label={t("tradings.wins")}
                source="wins"
                options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
                cellClassName="Status-Col"
                headerClassName="Status-Col"
              />

              <NumberField
                label={t("tradings.losses")}
                source="losses"
                options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
                cellClassName="Status-Col"
                headerClassName="Status-Col"
              />

              <NumberField
                label={t("tradings.risk")}
                source="money_lost"
                locales="en-EN"
                options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
                cellClassName="Other-Col"
                headerClassName="Status-Col"
              />

              <NumberField
                label={t("tradings.reward")}
                source="money_made"
                locales="en-EN"
                options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
                cellClassName="Other-Col"
                headerClassName="Status-Col"
              />

              <NumberField
                label={t("tradings.trades")}
                source="trades"
                options={{
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }}
              />

              <NumberField
                label={t("tradings.return")}
                source="return_value"
                locales="en-EN"
                options={{
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }}
              />

              <NumberField
                label={t("tradings.gain_today")}
                source="gain_today"
                locales="en-EN"
                options={{
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }}
                cellClassName="Other-Col"
                headerClassName="Other-Header"
              />

              <NumberField
                label={t("tradings.gain_prior_day")}
                source="gain_prior_day"
                locales="en-EN"
                options={{
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }}
                cellClassName="Other-Col"
                headerClassName="Other-Header"
              />

              <NumberField
                label={t("tradings.alpha")}
                source="alpha"
                locales="en-EN"
                options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
                cellClassName="Status-Col"
                headerClassName="Status-Col"
              />

              <NumberField
                label={t("tradings.beta")}
                source="beta"
                locales="en-EN"
                options={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
                cellClassName="Status-Col"
                headerClassName="Status-Col"
              />

              <NumberField
                label={t("tradings.mdd")}
                source="mdd"
                locales="en-EN"
                options={{
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "percent",
                }}
                cellClassName="Status-Col"
                headerClassName="Status-Col"
              />

              {/*<NumberField*/}
              {/*  label={t("tradings.sharpe_ratio")}*/}
              {/*  source="sharpe_ratio"*/}
              {/*  locales="en-EN"*/}
              {/*  options={{*/}
              {/*    maximumFractionDigits: 2,*/}
              {/*    minimumFractionDigits: 2,*/}
              {/*  }}*/}
              {/*  cellClassName="Status-Col"*/}
              {/*  headerClassName="Status-Col"*/}
              {/*/>*/}

              <WinLossRatio
                label={t("tradings.win_loss")}
                source="winsLossRatio"
                cellClassName="Status-Col"
                headerClassName="Status-Col"
                sortable={false}
              />

              <RewardRiskRatio
                label={t("tradings.reward_risk")}
                source="rewardRiskRatio"
                cellClassName="Status-Col"
                headerClassName="Status-Col"
                sortable={false}
              />

              <BrokerIcon label={isPaperTrading ? t("tradings.engine") : t("tradings.broker")} source="broker" />

              <MoreActions
                cellClassName="Actions-Col"
                {...props}
                ga={ga}
                items={[
                  actions.edit,
                  isPaperTrading ? actions.launch_live : actions.launch_paper,
                  actions.clone,
                  actions.share,
                  actions.start_stop,
                  actions.copy_to_new_algo,
                  actions.archive,
                ]}
                actionsResource={TRADING_RESOURCE.TRADINGS}
              />
            </CustomizableDatagrid>
          ) : (
            <SimpleList
              linkType={false}
              className={mobileStyles.list}
              primaryText={(record) => (
                <div className={mobileStyles.container}>
                  <Link to={() => tradingClick(record?.id, "tradings", record)}>
                    <div className={mobileStyles.name}>
                      <NameField record={record} source="name" />
                    </div>
                    <div className={mobileStyles.date}>
                      {t("tradings.created")}&nbsp;
                      <MomentField record={record} source="created_date" fromNow={true} />
                    </div>
                    <div className={mobileStyles.items}>
                      <ColoredNumber
                        record={record}
                        locales="en-EN"
                        style={{ percent: "percent" }}
                        source="return_pct"
                        className={mobileStyles.gain}
                      />
                      <AlgoType record={record} source="algo_type" />
                      <StatusField record={record} source="status" />
                    </div>
                  </Link>

                  <div className={mobileStyles.actions}>
                    <MoreActions
                      {...props}
                      record={record}
                      ga={ga}
                      items={[
                        actions.edit,
                        isPaperTrading ? actions.launch_live : actions.launch_paper,
                        actions.clone,
                        actions.share,
                        actions.start_stop,
                        actions.copy_to_new_algo,
                        actions.archive,
                      ]}
                      actionsResource={TRADING_RESOURCE.TRADINGS}
                    />
                  </div>
                </div>
              )}
            />
          )}
        </List>
      </Card>
    </div>
  );
};
