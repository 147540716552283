import React, { useEffect, useState } from "react";
import { useNotify, useTranslate } from "react-admin";
import { createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { customFetch } from "../../DataProvider";
import logo from "../../assets/logo.svg";
import { Box, Button, Link, Typography } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { FirebaseScreen } from "./Authorization";
import styles from "./Authorization.module.scss";
import PhoneInput from "../../components/PhoneInput/PhoneInput";
import { Storage } from "../../config";
import { showReferral } from "../../shared/utils";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { auth } from "./firebaseConfig";

export const Registration = (props) => {
  const notify = useNotify();
  const prefilledData = JSON.parse(sessionStorage.getItem("prefilledData"));
  const t = useTranslate();
  const [error, setError] = useState("");

  const signupURL = `users/sign-up${props.registrationType || ""}`;

  const defaultUserData = {
    email: prefilledData ? prefilledData.email : "",
    password: "",
    name: prefilledData ? prefilledData.displayName : "",
    phone: "",
    country: "US",
  };
  const [userData, setUserData] = useState(defaultUserData);

  const EMAIL_VALIDATE_LENGTH = 254;
  const NAME_VALIDATE_LENGTH = 100;

  useEffect(() => {
    if (prefilledData && userData.email !== prefilledData?.email) {
      setUserData(defaultUserData);
    }
  }, [prefilledData]);

  const setData = (field, value) => {
    setUserData({
      ...userData,
      [field]: value,
    });
  };

  const sendSignUp = (data) => {
    customFetch(signupURL, "POST", data).then(() => {
      TagManager.dataLayer({ dataLayer: { event: "CompleteRegistration", eventProps: { value: data.email } } });
      ReactPixel.trackCustom("CompleteRegistration");
      showReferral && window.growsurf && window.growsurf.triggerReferral(data.email);
    });
  };

  const signUp = async () => {
    await createUserWithEmailAndPassword(auth, userData.email, userData.password)
      .catch(function (error) {
        setError(error.message);
      });
    let unsubscribe = await onAuthStateChanged(auth, function (user) {
      if (user) {
        const data = {
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
          country: userData.country,
        };
        user.getIdToken().then(function (idToken) {
          Storage.setItem("token", idToken);
          sendSignUp(data);
          Storage.setItem("permissions", JSON.stringify([]));
          Storage.setItem("roles", JSON.stringify([]));
          document.location.replace("/#/terms");
        });
      }
    });
    unsubscribe();
  };

  const simpleSignUp = () => {
    const data = {
      name: userData.name,
      email: userData.email,
      phone: userData.phone,
      country: userData.country,
    };
    sendSignUp(data);
    document.location.replace("/#/terms");
    sessionStorage.removeItem("prefilledData");
    Storage.removeItem("permissions");
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
      </div>
      <Box className={styles.box}>
        <div className={styles.content}>
          <Typography variant="h6" className={styles.title}>
            {t("authorization.sign_up")}
          </Typography>

          {!prefilledData && (
            <>
              <FirebaseScreen />
              <div className={styles.or}>
                <Typography variant="overline">{t("odd.or")}</Typography>
              </div>
            </>
          )}
          <ValidatorForm onSubmit={prefilledData ? simpleSignUp : signUp} className={styles.form}>
            <TextValidator
              size="small"
              variant="standard"
              name="name"
              data-testid="signup-name"
              onChange={(e) => setData(e.target.name, e.target.value)}
              value={userData.name}
              validators={["required", `maxStringLength:${NAME_VALIDATE_LENGTH}`]}
              errorMessages={[
                t("name.validate.required"),
                `${t("common.maxLength")} ${userData.name?.length}/${NAME_VALIDATE_LENGTH}`,
              ]}
              className={styles.input}
              label={t("authorization.full_name")}
            />

            <TextValidator
              size="small"
              variant="standard"
              name="email"
              data-testid="signup-email"
              onChange={(e) => setData(e.target.name, e.target.value)}
              value={userData.email}
              validators={["required", "isEmail", `maxStringLength:${EMAIL_VALIDATE_LENGTH}`]}
              errorMessages={[
                t("email.validate.required"),
                t("email.validate.format"),
                `${t("common.maxLength")} ${userData.email?.length}/${EMAIL_VALIDATE_LENGTH}`,
              ]}
              className={styles.input}
              disabled={prefilledData && true}
              label={t("authorization.email")}
            />

            {props?.requestPhone ? (
              <PhoneInput
                variant="standard"
                data-testid="signup-phone"
                phoneValue={userData.phone}
                countryValue={userData.country}
                onPhoneChange={(value) => setData("phone", value)}
                onCountryChange={(value) => setData("country", value)}
                isRequired={true}
                defaultCountry={userData.country}
                component="TextValidator"
              />
            ) : null}
            <div className={styles.passwordContainer}>
              {!prefilledData && (
                <TextValidator
                  size="small"
                  variant="standard"
                  type="password"
                  name="password"
                  data-testid="signup-password"
                  onChange={(e) => setData(e.target.name, e.target.value)}
                  value={userData.password}
                  validators={["required", "minStringLength:6"]}
                  errorMessages={[t("password.validate.required"), t("password.validate.format")]}
                  instantValidate={false}
                  className={styles.input}
                  label={t("authorization.password")}
                />
              )}
              {error ? (
                <Typography className={styles.error} variant="caption" color="error">
                  {error}
                </Typography>
              ) : null}
            </div>

            <Button
              type="submit"
              variant="contained"
              data-testid="signup-button"
              color="primary"
              className={styles.submit}
            >
              {t("ra.auth.sign_up")}
            </Button>
          </ValidatorForm>

          <div className={styles.linksGroup}>
            <span className={styles.linkText}>{t("authorization.have_account")}</span>
            <Link href="#/login">{t("authorization.login")}</Link>
          </div>
        </div>
      </Box>
    </div>
  );
};
