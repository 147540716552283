import React, { useState } from "react";
import { useTranslate } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classes from "./NeedHelp.module.css";
import {
  TOUR_ALGO,
  TOUR_LIVE_TRADING,
  TOUR_PAPER_TRADING,
  TOUR_SCREENERS,
  TOUR_WELCOME,
} from "../../store/reducers/tourReducer";
import clsx from "clsx";
import { TRADING_RESOURCE } from "../../shared/variables";

const NeedHelp = () => {
  const t = useTranslate();
  const history = useHistory();
  const {
    ui: { sidebarOpen },
    resources,
  } = useSelector((state) => state.admin);
  const { tour } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { pathname } = history.location;

  /* A full compatability script from MDN: */
  const supportPageOffset = window.pageXOffset !== undefined;
  const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";

  //capture scroll position and move "Need Help"
  const [x, setX] = useState(0);
  window.addEventListener("scroll", function () {
    const x = supportPageOffset
      ? window.pageXOffset
      : isCSS1Compat
      ? document.documentElement.scrollLeft
      : document.body.scrollLeft;
    setX(-x);
  });

  /*
   * check: page check by url
   * type: dispatch type
   * msg: message from translation
   */
  const checks = [];
  checks.push(
    {
      check: pathname.includes("/dashboard"),
      type: TOUR_WELCOME,
      msg: t("helpers.start_overview_tour"),
    },
    {
      check: pathname.includes("/algos/"),
      type: TOUR_ALGO,
      msg: t("helpers.start_algo_lab_tour"),
    },
    {
      check: pathname.includes("/screeners/"),
      type: TOUR_SCREENERS,
      msg: t("helpers.start_screeners_tour"),
    },
    {
      check:
        pathname.includes(`/${TRADING_RESOURCE.PAPER}/create`) ||
        (pathname.includes(`/${TRADING_RESOURCE.TRADINGS}`) && !pathname.includes("show")),
      type: TOUR_PAPER_TRADING,
      msg: t("helpers.start_paper_trading_tour"),
    },
    {
      check:
        pathname.includes(`/${TRADING_RESOURCE.LIVE}/create`) ||
        (pathname.includes(`/${TRADING_RESOURCE.TRADINGS}`) && !pathname.includes("show")),
      type: TOUR_LIVE_TRADING,
      msg: t("helpers.start_live_trading_tour"),
    }
  );

  const handleTour = () => {
    checks.forEach(({ check, type }) => {
      if (check) {
        dispatch({ type, payload: true });
      }
    });
  };

  const renderTourButton = () => {
    let render = "";
    checks.forEach(({ check, msg }) => {
      if (check) {
        render = (
          <span data-testid="start-tour" className={classes.text} onClick={handleTour}>
            {msg}
          </span>
        );
      }
    });
    return render;
  };

  return (
    <div className={classes.container} style={{ left: x }}>
      {sidebarOpen && (
        <div
          rel="noopener noreferrer"
          className={clsx(classes.link, {
            [classes.anim_help]: tour.tourAnim,
          })}
        >
          <span className={classes.header}>{t("helpers.need_help")}</span>
          {renderTourButton()}
          <a
            href="https://discord.gg/V3YQsYPnzW"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.text, classes.check_our_docs)}
          >
            {t("helpers.join_discord_community")}
          </a>
          <a
            href="https://breakingequity.atlassian.net/wiki/spaces/BED/overview"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(classes.text, classes.check_our_docs)}
          >
            {t("helpers.check_our_docs")}
          </a>
        </div>
      )}
    </div>
  );
};

export default NeedHelp;
