import React from "react";

import { FieldTitle, useTranslate } from "react-admin";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import { Menu, MenuItem, Tooltip } from "@material-ui/core";
import styles from "./SelectionMenu.module.css";
import SettingsIcon from "react-feather/dist/icons/settings";
import { setGaId } from "../../shared/utils";
import clsx from "clsx";

const SelectionMenu = ({ ga, menuItems, selection, id, resource, onItemClick, tooltip, menuItemsHeader, classes }) => {
  const t = useTranslate();

  return (
    <div
      className={clsx(styles.settingsButton, classes?.settingsButton)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <PopupState variant="popover" popupId={`${id}-popup-menu`}>
        {(popupState) => (
          <React.Fragment>
            <Tooltip title={tooltip ? t(tooltip) : ""}>
              <IconButton
                id={setGaId(ga?.category, "additional-titles")}
                data-testid={`${id}-popup-button`}
                className={styles.icon}
                {...bindTrigger(popupState)}
              >
                <SettingsIcon className={styles.settingIcon} />
              </IconButton>
            </Tooltip>
            <Menu
              {...bindMenu(popupState)}
              className={styles.menu}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
            >
              {menuItemsHeader ? (
                <MenuItem disabled={true} className={styles.itemHeader}>
                  {t(menuItemsHeader)}
                </MenuItem>
              ) : null}
              {menuItems.map(({ source, label }) => (
                <MenuItem
                  key={source}
                  className={styles.item}
                  data-testid={source}
                  onClick={() => onItemClick(source)}
                >
                  <FormControlLabel
                    key={source}
                    control={
                      <Checkbox
                        checked={!!selection[source]}
                        className={styles.checkbox}
                        name={source}
                        id={`${source}-column`}
                        value={source}
                      />
                    }
                    label={<FieldTitle label={label} source={source} resource={resource} />}
                  />
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </div>
  );
};

export default SelectionMenu;
