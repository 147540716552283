import { STATUSES } from "./statuses";
import merge from "lodash/merge";

export const BROKERS_VALIDATE_LOADING = "BROKERS_VALIDATE_LOADING";
export const BROKERS_VALIDATE_LOAD_SUCCESS = "BROKERS_VALIDATE_LOAD_SUCCESS";
export const BROKERS_VALIDATE_LOAD_FAIL = "BROKERS_VALIDATE_LOAD_FAIL";

export const BROKERS_LINKING = "BROKERS_LINKING";
export const BROKERS_LINK_SUCCESS = "BROKERS_LINK_SUCCESS";
export const BROKERS_LINK_FAIL = "BROKERS_LINK_FAIL";

export const BROKERS_UNLINKING = "BROKERS_UNLINKING";
export const BROKERS_UNLINK_SUCCESS = "BROKERS_UNLINK_SUCCESS";
export const BROKERS_UNLINK_FAIL = "BROKERS_UNLINK_FAIL";

export const LINK_STATUSES = {
  UNKNOWN: "UNKNOWN", //todo: this is not needed
  NOT_LINKED: "NOT_LINKED",
  ACCOUNT_NOT_LINKED: "ACCOUNT_NOT_LINKED",
  LINKED: "LINKED",
  ERROR: "ERROR",
  REVOKED: "REVOKED",
  OK: "OK"
};

export default (
  previousState = {
    data: { alpaca: { status: LINK_STATUSES.UNKNOWN }, ameritrade: { status: LINK_STATUSES.UNKNOWN } },
    status: STATUSES.INIT,
  },
  { type, payload }
) => {
  switch (type) {
    case BROKERS_VALIDATE_LOADING: {
      return {
        ...previousState,
        status: STATUSES.LOADING,
      };
    }
    case BROKERS_VALIDATE_LOAD_SUCCESS: {
      return {
        data: Object.assign({}, previousState.data, payload.brokerStatuses),
        status: STATUSES.LOAD_SUCCESS,
      };
    }
    case BROKERS_VALIDATE_LOAD_FAIL: {
      return { data: {}, status: STATUSES.LOAD_FAIL };
    }
    case BROKERS_LINKING: {
      return {
        ...previousState,
        status: STATUSES.UPDATING,
      };
    }
    case BROKERS_LINK_SUCCESS: {
      return {
        data: merge({}, previousState.data, {
          [payload.brokerName]: { status: LINK_STATUSES.LINKED },
        }),
        status: STATUSES.UPDATE_SUCCESS,
      };
    }
    case BROKERS_LINK_FAIL: {
      return {
        data: merge({}, previousState.data, {
          [payload.brokerName]: { status: LINK_STATUSES.ERROR },
        }),
        status: STATUSES.UPDATE_FAIL,
      };
    }
    case BROKERS_UNLINKING: {
      return {
        ...previousState,
        status: STATUSES.UPDATING,
      };
    }
    case BROKERS_UNLINK_SUCCESS: {
      return {
        data: merge({}, previousState.data, {
          [payload.brokerName]: { status: LINK_STATUSES.NOT_LINKED, data: [] },
        }),
        status: STATUSES.UPDATE_SUCCESS,
      };
    }
    case BROKERS_UNLINK_FAIL: {
      return {
        data: merge({}, previousState.data, {
          [payload.brokerName]: { status: LINK_STATUSES.ERROR },
        }),
        status: STATUSES.UPDATE_FAIL,
      };
    }
    default:
      return previousState;
  }
};
