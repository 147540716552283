import alpacaIcon from "../assets/brokers/alpaca.png";
import TDIcon from "../assets/brokers/td.png";
import etradeIcon from "../assets/brokers/etrade.png";
import interactiveIcon from "../assets/brokers/interactive.png";
import robinhoodIcon from "../assets/brokers/robinhood.png";
import stationIcon from "../assets/brokers/station.png";
import fidelityIcon from "../assets/brokers/fidelity.png";
import bepeIcon from "../assets/brokers/bepe.png";
import schwabIcon from "../assets/brokers/schwab.png";

export const RATIO_LIMITS = {
  A_LOT: "A lot limits",
  FEW: "Few limits",
  NO: "No limits",
  NOT_INCLUDES: "Plan includes no limits",
};

export const DISABLE_CREATING_STATUSES = [RATIO_LIMITS.NO, RATIO_LIMITS.NOT_INCLUDES];

export const ENTITY = {
  ALGO: "Algo",
  ALGOS: "Algos",
  SCREENER: "Screener",
  SCREENERS: "Screeners",
  BACKTEST: "Backtest",
  BACKTESTS: "Backtests",
  PAPER_TRADING: "Paper Trading",
  PAPER_TRADINGS: "Paper Tradings",
  LIVE_TRADING: "Live Trading",
  LIVE_TRADINGS: "Live Tradings",
};

export const TRADING_TYPE = {
  PAPER: "PAPER",
  LIVE: "LIVE",
};

export const TRADING_RESOURCE = {
  PAPER: "tradings/paper",
  LIVE: "tradings/live",
  TRADINGS: "tradings",
};

export const DISABLED_FIELDS = ["id", "shared", "owner", "serialized_result", "error", "error_summary"];

export const TIME_INTERVALS = [
  { label: "1 minute", value: "60" },
  { label: "2 minutes", value: "120" },
  { label: "3 minutes", value: "180" },
  { label: "5 minutes", value: "300" },
  { label: "10 minutes", value: "600" },
  { label: "15 minutes", value: "900" },
  { label: "30 minutes", value: "1800" },
  { label: "1 hour", value: "3600" },
];

export const DAY_INTERVALS = [
  { label: "1 day", value: 1 },
  { label: "2 days", value: 2 },
  { label: "3 days", value: 3 },
  { label: "5 days", value: 5 },
  { label: "15 days", value: 15 },
  { label: "30 days", value: 30 },
];

export const MAX_RISK = {
  FRONT_FORMAT: "FRONT",
  BACK_FORMAT: "BACK",
};

export const GA_CATEGORIES = {
  SIDEBAR: "sidebar",
  HEADER: "header",
  DASHBOARD: "dashboard",
  SCREENERS: "screeners",
  SCREENER_EDIT: "screener-edit",
  SCREENER_FULLSCREEN: "screener-fullscreen",
  SCREENER_BACKTEST: "screener-backtest",
  ALGO_LAB: "algo-lab",
  ALGO_EDIT: "algo-edit",
  ALGO_FULLSCREEN: "algo-fullscreen",
  BACKTEST: "backtest",
  PAPER_TRADING: "paper-trading",
  PAPER_TRADING_VIEW: "paper-trading-view",
  PAPER_TRADING_EDIT: "paper-trading-edit",
  PAPER_TRADING_FULLSCREEN: "paper-trading-fullscreen",
  LIVE_TRADING: "live-trading",
  LIVE_TRADING_VIEW: "live-trading-view",
  LIVE_TRADING_EDIT: "live-trading-edit",
  LIVE_TRADING_FULLSCREEN: "live-trading-fullscreen",
  PROFILE: "profile",
};

export const BROKERS = [
  { id: "ALPACA", name: "Alpaca", shortName: "Alpaca", icon: alpacaIcon },
  { id: "AMERITRADE", name: "TD Ameritrade", shortName: "TDA", icon: TDIcon },
  { id: "IBKR", name: "Interactive Brokers", shortName: "IBKR", icon: interactiveIcon },
  { name: "Charles Schwab", icon: schwabIcon, disabled: true, status: "profile.coming_soon" },
  { name: "E-Trade", icon: etradeIcon, disabled: true, status: "profile.under_consideration" },
  { name: "Robinhood", icon: robinhoodIcon, disabled: true, status: "profile.under_consideration" },
  { name: "Trade Station", icon: stationIcon, disabled: true, status: "profile.under_consideration" },
  { name: "Fidelity Investments", icon: fidelityIcon, disabled: true, status: "profile.under_consideration" },
];

export const ENGINES = {
  BE: { id: "BREAKING_EQUITY", name: "tradings.breaking_equity_engine", icon: bepeIcon },
  CB: { id: "ALPACA", name: "tradings.custom_broker" },
};

export const LOCALSTORAGE = {
  TIMEZONE: "timezone",
  TOKEN: "token",
  PERMISSIONS: "permissions",
  PROFILE: "profile",
  BACKTESTS_RECENT: "backtestsRecent",
  TRADING_RECENT: "tradingRecent",
  SCREENER_BACKTESTS_NEW: "screenerBacktestsNew",
  ROLES: "roles",
  ITEMS_PER_PAGE: "itemsPerPage",
  SUBSCRIPTION_UPDATED_DATE: "subscriptionUpdatedDate",
  QUICK_STATS_PAPER: `quickStats_${TRADING_RESOURCE.PAPER}`,
  QUICK_STATS_LIVE: `quickStats_${TRADING_RESOURCE.LIVE}`,
  ORDER_CHARTS: "orderCharts",
  TV_STUDIES: "tradingview.studies",
  LAST_CHARTS_ID: "tradingview.lastChartsId",
};

export const PLANS = {
  FREE: "Free",
  STARTER: "Starter",
  BASIC: "Advanced",
  PROFESSIONAL: "Professional",
  MARKETPLACE: "Marketplace",
  ALGOLAB: "Algo Lab",
  ALGOLABPRO: "Algo Lab Pro",
};

export const ORDER_TYPES = {
  LIMIT: "LIMIT",
  MARKET: "MARKET",
};

export const TABLES = {
  SCREENERS: "screeners",
  ALGOS: "algos",
  PAPER_TRADINGS: "paper_tradings",
  LIVE_TRADINGS: "live_tradings",
  BACKTESTS: "backtests",
  SCREENER_BACKTESTS: "screener_backtests",
  SCREENER_DAY_RESULTS: "screener_day_results",
  BACKTEST_TRADES: "backtest_trades",
  PAPER_TRADING_TRADES: "paper_trading_trades",
  LIVE_TRADING_TRADES: "live_trading_trades",
  USERS: "users",
};

export const CUTOFF_VALUES = {
  DAILY_VOLUME_DESC: "DAILY_VOLUME-DESC",
  DAILY_VOLUME_ASC: "DAILY_VOLUME-ASC",
  SHARE_PRICE_DESC: "SHARE_PRICE-DESC",
  SHARE_PRICE_ASC: "SHARE_PRICE-ASC",
  DAILY_PRICE_MOVEMENT_DESC: "DAILY_PRICE_MOVEMENT-DESC",
  DAILY_PRICE_MOVEMENT_ASC: "DAILY_PRICE_MOVEMENT-ASC",
};

export const getTickerLimitValue = (value, num = 20, t) => {
  if (isNaN(num)) num = 20;
  switch (value) {
    case CUTOFF_VALUES.DAILY_VOLUME_DESC:
      return t("cutoff_values.volume_desc", { num });
    case CUTOFF_VALUES.DAILY_VOLUME_ASC:
      return t("cutoff_values.volume_asc", { num });
    case CUTOFF_VALUES.SHARE_PRICE_DESC:
      return t("cutoff_values.price_desc", { num });
    case CUTOFF_VALUES.SHARE_PRICE_ASC:
      return t("cutoff_values.price_asc", { num });
    case CUTOFF_VALUES.DAILY_PRICE_MOVEMENT_DESC:
      return t("cutoff_values.range_desc", { num });
    case CUTOFF_VALUES.DAILY_PRICE_MOVEMENT_ASC:
      return t("cutoff_values.range_asc", { num });

    default:
      return t("cutoff_values.volume_desc", { num });
  }
};

export const ORDER_EXIT_TYPES = {
  STOP: { label: "Stop Loss", value: "STOP" },
  STOP_TRAIL: { label: "Trailing Stop", value: "STOP_TRAIL" },
};
