import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslate } from "react-admin";
import { useForm } from "react-final-form";
import { formatMaxRisk } from "../../shared/utils";
import { MAX_RISK } from "../../shared/variables";

const WarnWhenUnsavedChanges = () => {
  const form = useForm();
  const history = useHistory();
  const t = useTranslate();

  useEffect(() => {
    const unblock = history.block((redirectUrl) => {
      const formState = form.getState();
      const { values, initialValues } = formState;
      const valuesKeys = Object.keys(values);

      const unsavedChanges = valuesKeys.filter((key) => {
        if (initialValues[key]?.toString() !== values[key]?.toString()) {

          if (
            Array.isArray(values[key]) &&
            values[key][0] === initialValues[key]
          )
            return false;

          // ignore inner intermediate backtest params fields
          return !(
            !initialValues.hasOwnProperty(key) && Array.isArray(values[key])
          );
        }
      });

      if (
        history?.location?.pathname === redirectUrl?.pathname ||
        !unsavedChanges.length ||
        (unsavedChanges.length &&
          window.confirm(t("ra.message.unsaved_changes")))
      ) {
        // we can now unblock
        unblock();
        // proceed with the blocked navigation
        history.push(redirectUrl);
      }
      return false;
    });

    // just in case theres an unmount we can unblock if it exists
    return unblock;
  }, [history?.location]);

  return null;
};

export default WarnWhenUnsavedChanges;
