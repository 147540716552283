import InputMask from "react-input-mask";
import React from "react";
import { useInput, useTranslate } from "react-admin";
import TextField from "@material-ui/core/TextField";

const MaskedInput = ({ mask, ...props }) => {
  const {
    input: { name, onChange, onBlur, value },
    meta: { touched, error },
  } = useInput(props);
  const t = useTranslate();

  return (
    <InputMask
      mask={mask}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      className={props.className}
      {...props}
    >
      {() => {
        return (
          <TextField
            name={name}
            className={props.className}
            required={props.validate && props.validate.isRequired}
            label={t(props.label)}
            variant={props.variant}
            onChange={onChange}
            onBlur={onBlur}
            margin="dense"
            error={!!(touched && error)}
            helperText={(touched && error ? t(error) : error) || " "}
          />
        );
      }}
    </InputMask>
  );
};

export default MaskedInput;
