import { STATUSES } from "./statuses";

export const PLANS_LOADING = "PLANS_LOADING";
export const PLANS_RECEIVED = "PLANS_RECEIVED";
export const PLANS_FAIL = "PLANS_FAIL";

export default (
  previousState = { data: [], status: STATUSES.INIT },
  { type, payload }
) => {
  switch (type) {
    case PLANS_LOADING: {
      return {
        data: [],
        status: STATUSES.LOADING,
      };
    }
    case PLANS_RECEIVED: {
      return { data: payload.plans, status: STATUSES.LOAD_SUCCESS };
    }
    case PLANS_FAIL: {
      return { data: [], status: STATUSES.LOAD_FAIL };
    }
    default:
      return previousState;
  }
};
