import Alert from "../Alert";
import React from "react";
import { isMobile } from "react-device-detect";
import { ALERT } from "../variables";

const alert = {
  type: ALERT.BRAND,
  data: [
    {
      description: ["helpers.mobile_version"],
    },
  ],
};

const MobileVersionAlert = () => {
  return isMobile ? <Alert data={alert} /> : null;
};

export default MobileVersionAlert;
