import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";

import Reactour from 'reactour'
import Button from "@material-ui/core/Button";
import {
  useNotify,
} from 'react-admin';
import {isMobile} from 'react-device-detect';

import {TOUR_SCREENERS} from "../../store/reducers/tourReducer";
import TourPopupLayout from "./components/TourPopupLayout";
import {handleAfterClose, handleAfterOpen, handleGetCurrentStep} from "./components/Functions";

import style from "./TourWelcome.module.scss";
import Translate from "./components/Translate";
import classes from "../Carousel/Carousel.module.css";

const steps = [
  {
    selector: '',
    content: (props) => {
      const {goTo} = props
      return (
        <TourPopupLayout
          propsLayout={props}
          btnNext={
            <div className={style.popupTour__btns}>
              <Button
                className={style.btnGrey}
                onClick={() => props.close()}
              >
                <Translate path="tour.skip_tour" />
              </Button>
              <Button
                className={classes.exploreButton}
                onClick={() => goTo(1)}
                data-testid="tour/start"
              >
                <Translate path="tour.start_tour" />
              </Button>
            </div>
          }
        >
          <h2><Translate path="tourScreeners.step0.title" /></h2>
          <p data-testid="tour/description"><Translate path="tourScreeners.step0.text" /></p>
        </TourPopupLayout>
      )
    },
  },
  {
    selector: '[class*="StockInput_input"]',
    content: (props) => (
      <TourPopupLayout
        propsLayout={props}
        btnNext="default"
        btnNextStep={2}
      >
        <h2><Translate path="tourScreeners.step1.title" /></h2>
        <p data-testid="tour/description"><Translate path="tourScreeners.step1.text" /></p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="CriteriaBlock_container"]',
    content: (props) => (
      <TourPopupLayout
        propsLayout={props}
        btnNext="default"
        btnNextStep={3}
      >
        <h2><Translate path="tourScreeners.step2.title" /></h2>
        <p data-testid="tour/description"><Translate path="tourScreeners.step2.text" /></p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="gridLayout"]',
    content: (props) => (
      <TourPopupLayout
        propsLayout={props}
        btnNext="default"
        btnNextStep={4}
      >
        <h2><Translate path="tourScreeners.step3.title" /></h2>
        <p data-testid="tour/description"><Translate path="tourScreeners.step3.text" /></p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="list-page"]',
    content: (props) => (
      <TourPopupLayout
        propsLayout={props}
        btnNext={
          <Button
            className={classes.exploreButton}
            onClick={() => props.close()}
            data-testid="tour/finish"
          >
            <Translate path="tour.finish_tour" />
          </Button>
        }
      >
        <h2><Translate path="tourScreeners.step4.title" /></h2>
        <p data-testid="tour/description"><Translate path="tourScreeners.step4.text" /></p>
      </TourPopupLayout>
    ),
  },
]

const stepsMobile = [
  {
    selector: '',
    content: (props) => {
      const {goTo} = props
      return (
        <TourPopupLayout
          propsLayout={props}
          btnNext={
            <div className={style.popupTour__btns}>
              <Button
                className={style.btnGrey}
                onClick={() => props.close()}
              >
                <Translate path="tour.skip_tour" />
              </Button>
              <Button
                className={classes.exploreButton}
                onClick={() => goTo(1)}
              >
                <Translate path="tour.start_tour" />
              </Button>
            </div>
          }
        >
          <h2><Translate path="tourScreenersMobile.step0.title" /></h2>
          <p><Translate path="tourScreenersMobile.step0.text" /></p>
        </TourPopupLayout>
      )
    },
  },
  {
    selector: '[class*="Card_card__i2o_G"]',
    content: (props) => (
      <TourPopupLayout
        propsLayout={props}
        btnNext="default"
        btnNextStep={2}
      >
        <h2><Translate path="tourScreenersMobile.step1.title" /></h2>
        <p><Translate path="tourScreenersMobile.step1.text" /></p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="BasktestParameters_mobile"]',
    content: (props) => (
      <TourPopupLayout
        propsLayout={props}
        btnNext="default"
        btnNextStep={3}
      >
        <h2><Translate path="tourScreenersMobile.step2.title" /></h2>
        <p><Translate path="tourScreenersMobile.step2.text" /></p>
      </TourPopupLayout>
    ),
  },
  {
    selector: '[class*="list-page"]',
    content: (props) => (
      <TourPopupLayout
        propsLayout={props}
        btnNext={
          <Button
            className={clsx(classes.exploreButton, style.finish)}
            onClick={() => props.close()}
          >
            <Translate path="tour.finish_tour" />
          </Button>
        }
      >
        <h2><Translate path="tourScreenersMobile.step3.title" /></h2>
        <p><Translate path="tourScreenersMobile.step3.text" /></p>
      </TourPopupLayout>
    ),
  },
]

export const TourScreeners = () => {
  const tourScreeners = useSelector((state) => state.tour.tourScreeners);
  const {sidebarOpen} = useSelector((state) => state.admin.ui);
  const dispatch = useDispatch();
  const notify = useNotify();

  useEffect(() => {
    return () => {
      handleAfterClose(notify, dispatch)
    };
  }, []);

  const handleRequestClose = () => dispatch({type: TOUR_SCREENERS, payload: false})

  return (
    <Reactour
      steps={isMobile ? stepsMobile : steps}
      isOpen={tourScreeners}
      onRequestClose={handleRequestClose}
      rounded={5}
      className={style.reactour}
      startAt={0}
      getCurrentStep={(curr) => handleGetCurrentStep(curr, dispatch, sidebarOpen, 1)}
      onAfterOpen={handleAfterOpen}
      showCloseButton={false}
      showButtons={false}
      showNavigation={false}
    />
  )
}

export default TourScreeners;
