import React, { useEffect } from "react";
import { useDataProvider, useNotify, usePermissions, useRedirect, useRefresh, useTranslate } from "react-admin";
import CloneIcon from "react-feather/dist/icons/copy";
import ArchiveIcon from "react-feather/dist/icons/archive";
import CancelIcon from "react-feather/dist/icons/x-circle";
import ShareIcon from "react-feather/dist/icons/share-2";
import LayersIcon from "react-feather/dist/icons/layers";
import ZapIcon from "react-feather/dist/icons/zap";
import PlayCircleIcon from "react-feather/dist/icons/play-circle";
import StopCircleIcon from "react-feather/dist/icons/stop-circle";
import EditIcon from "react-feather/dist/icons/edit";
import UploadIcon from "react-feather/dist/icons/upload";
import LinkIcon from "react-feather/dist/icons/link";
import RefreshIcon from "react-feather/dist/icons/refresh-ccw";

import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { actions } from "../../shared/moreActions";
import PopupMenu from "../PopupMenu/PopupMenu";
import { statuses } from "../../shared/statuses";
import {
  BACKTEST_ALGOS,
  CREATE_SHARED_ALGOS,
  LIVE_TRADING,
  PAPER_TRADING,
  USE_SCREENERS,
} from "../../shared/permissions";
import { Storage } from "../../config";
import { setGaId } from "../../shared/utils";
import { DISABLED_FIELDS, ENGINES, ENTITY, ORDER_TYPES, TRADING_RESOURCE } from "../../shared/variables";
import { useDispatch, useSelector } from "react-redux";
import { transformTime } from "../../pages/Algo/utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CANCEL_TRADINGS_POLLING, TRADING_SAVE, TRADINGS_POLLING } from "../../store/sagas/tradingSaga";
import { STATUSES } from "../../store/reducers/statuses";
import { TRADING_CLEAR } from "../../store/reducers/tradingReducer";
import useDisableByLimit from "../../shared/hooks/useDisableByLimit";
import { TRADING_LONG_EXITING_ADD } from "../../store/reducers/tradingLongExitingReducer";
import { UNLINK_BROKER_ACCOUNT, VALIDATE_BROKER } from "../../store/sagas/brokersSaga";
import { RERUN_BACKTEST } from "../../store/reducers/rerunBacktestReducer";
import { customFetch } from "../../DataProvider";

const MoreActions = (props) => {
  const { button, basePath, record = {}, resource, actionsResource, items = [], ga = {} } = props;
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { loaded, permissions } = usePermissions();
  const currentResource = actionsResource || resource;
  const dispatch = useDispatch();

  const { status: tradingStatus } = useSelector((store) => store.trading);
  const tradingExiting = useSelector((store) => store.tradingExiting.data);
  const tradingLongExiting = useSelector((store) => store.tradingLongExiting.data);
  const disableByLimit = useDisableByLimit();
  const iconSize = 18;

  const getEntity = () => {
    if (basePath === "/algos") return ENTITY.ALGO;
    if (basePath === "/screeners") return ENTITY.SCREENER;

    if (resource === TRADING_RESOURCE.PAPER) return ENTITY.PAPER_TRADING;
    if (resource === TRADING_RESOURCE.LIVE) return ENTITY.LIVE_TRADING;

    return false;
  };

  const disableByEntityLimit = useDisableByLimit(getEntity());

  useEffect(() => {
    if (record?.status === statuses.exiting.id && record?.exit_estimation && !tradingLongExiting.includes(record?.id)) {
      notify(
        t("tradings.exit_estimation.message", { estimate: t(`tradings.exit_estimation.${record?.exit_estimation}`) }),
        "info"
      );
      dispatch({ type: TRADING_LONG_EXITING_ADD, payload: record?.id });
    }
    if (record?.status === statuses.exiting.id && !tradingExiting.includes(record?.id)) {
      dispatch({ type: TRADINGS_POLLING, payload: record?.id });
    }
  }, [record, tradingExiting, tradingLongExiting]);

  useEffect(() => {
    if (tradingStatus === STATUSES.REFRESH) {
      if (record?.status === statuses.archived.id) {
        notify(t(`${currentResource}.successfully_stopped_and_archived`), "info");
      }

      if (record?.status === statuses.stopped.id) {
        notify(t(`${currentResource}.successfully_stopped`), "info");
      }

      dispatch({ type: TRADING_CLEAR });
      refresh();
    }
  }, [tradingStatus]);

  useEffect(() => {
    return () => dispatch({ type: CANCEL_TRADINGS_POLLING });
  }, []);

  const launchTrading = (basePath) => () => {
    const formattedRecord = transformTime(record);
    if (basePath === TRADING_RESOURCE.PAPER && !record?.broker) formattedRecord.broker = ENGINES.BE.id;
    if (basePath === TRADING_RESOURCE.LIVE && (!record?.broker || record.broker === ENGINES.BE.id))
      formattedRecord.broker = ENGINES.CB.id;

    redirect(
      `/${basePath}/create?${new URLSearchParams({
        source: JSON.stringify(_objectWithoutProperties(formattedRecord, [...DISABLED_FIELDS, "trading_type"])),
      }).toString()}`
    );
  };

  const copyToCurrentAlgo = () => {
    const source = _objectWithoutProperties(record, ["serialized_result"]);
    source.id = record?.algo?.id;
    const formattedSource = transformTime(source);
    dispatch({ type: RERUN_BACKTEST, payload: { backtest: formattedSource } });
    redirect(`/${currentResource}/${source.id}`);
    notify(t("backtest.load_to_current_algo"), "info");
  };

  const copyToNewAlgo = () => {
    const formattedRecord = transformTime(record);
    if (record?.trading_type) {
      formattedRecord.start_date = record.first_trading_day;
      formattedRecord.end_date = record.last_trading_day;
      if (record.ticker || record.screener) {
        formattedRecord.tickers = [record.ticker].filter(Boolean);
        formattedRecord.screeners = [record.screener?.id].filter(Boolean);
      }
    }

    const disabledTradingFields = ["broker", "trading_days", "ticker", "screener"];
    redirect(
      `/${record?.trading_type ? "algos" : currentResource}/create?${new URLSearchParams({
        source: JSON.stringify(
          _objectWithoutProperties(formattedRecord, [...DISABLED_FIELDS, ...disabledTradingFields])
        ),
      }).toString()}`
    );
    !record?.trading_type && notify(t("backtest.copy_to_new_algo"), "info");
  };

  const copyToCurrentScreener = () => {
    const source = _objectWithoutProperties({ ...props.screener, ...record }, ["serialized_result"]);
    source.id = record?.screener?.id;
    dispatch({ type: RERUN_BACKTEST, payload: { backtest: source } });
    redirect(`/screeners/${source.id}`);
    notify(t("backtest.load_to_current_screener"), "info");
  };

  const copyToNewScreener = () => {
    redirect(
      `/screeners/create?${new URLSearchParams({
        source: JSON.stringify(_objectWithoutProperties({ ...props.screener, ...record }, DISABLED_FIELDS)),
      }).toString()}`
    );
  };

  const clone = () => {
    record.id_for_getting_backtests = record.id;
    const formattedRecord = transformTime(record);
    formattedRecord.parentId = record.id;
    redirect(
      `/${resource}/create?${new URLSearchParams({
        source: JSON.stringify(_objectWithoutProperties(formattedRecord, DISABLED_FIELDS)),
      }).toString()}`
    );
  };

  const archive = () => {
    dataProvider("DELETE", currentResource, {
      id: record.id,
    })
      .then(() => {
        if (record?.status === statuses.archived.id) {
          notify(t(`${currentResource}.successfully_archived`), "info");
        } else if (record?.status === statuses.exiting.id) {
          notify(t(`${currentResource}.stop_exit`), "info");
        }
        refresh();
      })
      .catch(() => {
        notify(t("common.cant_save"), "error");
      });
  };

  const share = (shared) => () => {
    dataProvider("SHARE", currentResource, {
      data: { shared },
      id: record?.id,
    })
      .then(() => {
        notify(t(`${currentResource}.successfully_shared`), "info");
        refresh();
      })
      .catch(() => {
        notify(t("common.cant_save"), "error");
      });
  };

  const edit = () => {
    redirect(`/${currentResource}/${record?.id}`);
  };

  const stopAndExit = () => {
    const id = record.id;
    customFetch(`tradings/${id}/stop`, "POST", null, "text")
      .then(() => {
        notify(t(`${currentResource}.stop_exit`), "info");
        refresh();
      })
      .catch(() => {
        notify(t("common.cant_save"), "error");
      });
  };

  const launch = () => () => {
    if (record?.order_type === ORDER_TYPES.MARKET) record.order_validity = null;
    let ticker = record?.tickers?.[0] || "";
    let screenerId = record?.screeners?.[0];
    let screener = screenerId ? { id: screenerId } : null;
    const { ["tickers"]: deletedTickers, ["screeners"]: deletedScreeners, ...formattedRecord } = record;
    let resultRecord = record?.tickers && record?.screeners ? { ...formattedRecord, ticker, screener } : record;
    let status = statuses.active.id;
    dispatch({
      type: TRADING_SAVE,
      payload: {
        data: { ...resultRecord, status },
        id: record?.id,
        notify,
        t,
      },
    });
  };

  const unlink = (record) => () => {
    dispatch({ type: UNLINK_BROKER_ACCOUNT, payload: { ...record, notify, t } });
  };

  const linkMoreAccounts = (broker) => () => {};

  const validateAccount =
    ({ record: { id }, setValid }) =>
    () => {
      dispatch({ type: VALIDATE_BROKER, payload: { id, setValid } });
    };

  const addGaId = (id) => setGaId(ga?.category, ga?.action ? `${ga.action}-${id}` : id);

  const hasExitingStatus = (exiting_target_status) =>
    record?.status === statuses.exiting.id && record?.exiting_target_status === exiting_target_status;

  let menuItems = [];
  if (loaded) {
    menuItems = [
      {
        condition:
          items.includes(actions.start_stop) &&
          ![statuses.active.id, statuses.exiting.id, statuses.archived.id].includes(record?.status),
        onClick: launch(),
        icon: <PlayCircleIcon size={iconSize} />,
        gaId: addGaId("start"),
        id: "start",
        label: t("actions.start"),
      },
      {
        condition:
          items.includes(actions.start_stop) &&
          [statuses.active.id, statuses.exiting.id].includes(record?.status) &&
          record?.status !== statuses.archived.id,
        onClick: stopAndExit,
        icon: hasExitingStatus(statuses.stopped.id) ? (
          <CircularProgress size={16} />
        ) : (
          <StopCircleIcon size={iconSize} />
        ),
        gaId: addGaId("stop"),
        id: "stop",
        label: t("actions.stop"),
        disabled: record?.status === statuses.exiting.id,
      },
      {
        condition: items.includes(actions.edit) && record?.status !== statuses.archived.id,
        onClick: edit,
        icon: <EditIcon size={iconSize} />,
        gaId: addGaId("edit"),
        id: "edit",
        label: t("actions.edit"),
        disabled: [statuses.active.id, statuses.exiting.id].includes(record?.status),
        tooltip: [statuses.active.id, statuses.exiting.id].includes(record?.status) ? t("tradings.stop_to_edit") : null,
      },
      {
        condition: items.includes(actions.launch_live) && permissions?.includes(LIVE_TRADING),
        onClick: launchTrading(TRADING_RESOURCE.LIVE),
        icon: <ZapIcon size={iconSize} />,
        gaId: addGaId("launch-live"),
        id: "launch-live",
        label: t("actions.launch_live"),
        disabled: disableByLimit.live_tradings,
        tooltip: disableByLimit.live_tradings
          ? t("subscriptions.disable_creating", { entity: ENTITY.LIVE_TRADING })
          : null,
      },
      {
        condition: items.includes(actions.launch_paper) && permissions?.includes(PAPER_TRADING),
        onClick: launchTrading(TRADING_RESOURCE.PAPER),
        icon: <LayersIcon size={iconSize} />,
        gaId: addGaId("launch-paper"),
        id: "launch-paper",
        label: t("actions.launch_paper"),
        disabled: disableByLimit.paper_tradings,
        tooltip: disableByLimit.paper_tradings
          ? t("subscriptions.disable_creating", { entity: ENTITY.PAPER_TRADING })
          : null,
      },
      {
        condition: items.includes(actions.copy_to_current_algo) && permissions?.includes(BACKTEST_ALGOS),
        onClick: copyToCurrentAlgo,
        icon: <UploadIcon size={iconSize} />,
        gaId: addGaId("copy-to-current-algo"),
        id: "copy-to-current-algo",
        label: t("actions.rerun_backtest"),
        disabled: disableByLimit.backtests,
        tooltip: disableByLimit.backtests ? t("subscriptions.disable_creating", { entity: ENTITY.BACKTEST }) : null,
      },
      {
        condition: items.includes(actions.copy_to_new_algo) && permissions?.includes(BACKTEST_ALGOS),
        onClick: copyToNewAlgo,
        icon: record?.trading_type ? <UploadIcon size={iconSize} /> : <CloneIcon size={iconSize} />,
        gaId: addGaId("copy-to-new-algo"),
        id: "copy-to-new-algo",
        label: t("actions.copy_to_new_algo"),
        disabled: disableByLimit.algos,
        tooltip: disableByLimit.algos ? t("subscriptions.disable_creating", { entity: ENTITY.ALGO }) : null,
      },
      {
        condition: items.includes(actions.copy_to_current_screener) && permissions?.includes(USE_SCREENERS),
        onClick: copyToCurrentScreener,
        icon: <UploadIcon size={iconSize} />,
        gaId: addGaId("copy-to-current-screener"),
        id: "copy-to-current-screener",
        label: t("actions.rerun_backtest"),
        disabled: disableByLimit.backtests,
        tooltip: disableByLimit.backtests ? t("subscriptions.disable_creating", { entity: ENTITY.BACKTEST }) : null,
      },
      {
        condition: items.includes(actions.copy_to_new_screener) && permissions?.includes(USE_SCREENERS),
        onClick: copyToNewScreener,
        icon: <CloneIcon size={iconSize} />,
        gaId: addGaId("copy-to-new-screener"),
        id: "copy-to-new-screener",
        label: t("actions.copy_to_new_screener"),
        disabled: disableByLimit.screeners,
        tooltip: disableByLimit.screeners ? t("subscriptions.disable_creating", { entity: ENTITY.SCREENER }) : null,
      },
      {
        condition: items.includes(actions.clone),
        onClick: clone,
        icon: <CloneIcon size={iconSize} />,
        gaId: addGaId("clone"),
        id: "clone",
        label: t("actions.clone"),
        disabled: disableByEntityLimit,
        tooltip: disableByEntityLimit ? t("subscriptions.disable_creating", { entity: getEntity() }) : null,
      },
      {
        condition:
          items.includes(actions.archive) &&
          record?.status !== statuses.archived.id &&
          (record?.owner ? record?.owner?.id === Storage.getItem("profile") : true),
        onClick: archive,
        icon: hasExitingStatus(statuses.archived.id) ? <CircularProgress size={16} /> : <ArchiveIcon size={iconSize} />,
        gaId: addGaId("archive"),
        id: "archive",
        label: t("actions.archive"),
        disabled: record?.status === statuses.exiting.id,
      },
      {
        condition:
          items.includes(actions.share) &&
          !record?.shared &&
          record?.status !== statuses.archived.id &&
          permissions?.includes(CREATE_SHARED_ALGOS),
        onClick: share(true),
        icon: <ShareIcon size={iconSize} />,
        gaId: addGaId("share"),
        id: "share",
        label: t("actions.share"),
      },
      {
        condition:
          items.includes(actions.share) &&
          record?.shared &&
          record?.status !== statuses.archived.id &&
          permissions.includes(CREATE_SHARED_ALGOS),
        onClick: share(false),
        icon: <CancelIcon size={iconSize} />,
        gaId: addGaId("stop-sharing"),
        id: "stop-sharing",
        label: t("actions.stop_sharing"),
      },
      {
        condition: items.includes(actions.unlink),
        onClick: unlink(record),
        icon: <CancelIcon size={iconSize} />,
        gaId: addGaId("unlink"),
        id: "unlink",
        label: t("actions.unlink"),
      },
      {
        condition: items.includes(actions.link_more),
        onClick: linkMoreAccounts(record?.id),
        icon: <LinkIcon size={iconSize} />,
        gaId: addGaId("link-more"),
        id: "link-more",
        label: t("actions.link_more"),
      },
      {
        condition: items.includes(actions.validate),
        onClick: validateAccount(props),
        icon: <RefreshIcon size={iconSize} />,
        gaId: addGaId("validate"),
        id: "validate",
        label: t("actions.validate"),
      },
    ];
  }

  return <PopupMenu button={button} menuItems={menuItems} id={resource} />;
};

export default MoreActions;
