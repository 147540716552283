import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { customFetch } from "../../DataProvider";
import { AuthProvider } from "../Auth/AuthProvider";
import { Loading, useRedirect } from "react-admin";
import styles from "./TermsAndConditions.module.css";
import { Storage } from "../../config";
import { sendEmailVerification } from "../../shared/utils";

export const ShowTermsAndConditions = ({ noAction }) => {
  const [termText, setTermText] = useState("");

  useEffect(() => {
    customFetch("terms", "GET", null, "text").then((res) => setTermText(res.replace(/(&nbsp;){2,}/gi, " ")));
  }, []);

  const approve = async () => {
    await customFetch("accept-terms", "POST");
    const profile = await customFetch("profile", "GET");
    if (profile) {
      Storage.setItem("profile", profile.id);
    }

    const permissions = await customFetch("profile/permissions", "GET");
    if (permissions) {
      Storage.setItem("permissions", JSON.stringify(permissions));
      Storage.setItem("roles", JSON.stringify(profile.roles.map((role) => role.name)));
      window.scrollTo({ top: 0 });
      document.location.reload();
    }
    sendEmailVerification();
  };

  if (!termText) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.tosContainer}>
        <div className={styles.tos}>
          <div dangerouslySetInnerHTML={{ __html: termText }} />
          {!noAction ? (
            <div className={styles.acceptButtonContainer}>
              <Button
                className={styles.acceptButton}
                variant="contained"
                data-testid="terms-accept"
                color="primary"
                onClick={approve}
              >
                Accept Terms of Service
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const TermsAndConditions = ({ noAction }) => {
  const redirect = useRedirect();
  AuthProvider.getPermissions().then((permissions) => {
    const prefilledData = JSON.parse(sessionStorage.getItem("prefilledData"));
    if (prefilledData) {
      redirect("/signup");
    }
    if (permissions) {
      const permissionsArray = JSON.parse(permissions);
      if (permissionsArray.length > 0) {
        redirect("/strategies");
      }
    }
  });

  return <ShowTermsAndConditions noAction={noAction} />;
};

export default TermsAndConditions;
