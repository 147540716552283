import React from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import CloseIcon from "react-feather/dist/icons/x";
import styles from "../AppBar.module.css";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { isDesktop } from "react-device-detect";
import { Link } from "react-admin";
import { Storage } from "../../../config";

const HeaderSlideIn = ({ show, message, linkMessage, action, icon, color, closable, id, disabled }) => {
  const dispatch = useDispatch();
  const close = () => {
    Storage.setItem("HeaderSlideIn_" + id, 0);
    dispatch({ type: id, payload: false });
  };

  const containerClass = styles[color + "Container"];
  const messageClass = styles[color + "Message"];

  return show ? (
    <Slide in>
      <div className={clsx(styles.banner, containerClass)}>
        {isDesktop ? icon : null}
        <span className={messageClass}>{message}</span>
        {typeof action === "string" ? (
          <Link to={action} className={styles.link}>
            {linkMessage}
          </Link>
        ) : (
          <Button variant="text" disabled={disabled} onClick={action} className={styles.link}>
            {linkMessage}
          </Button>
        )}

        {closable ? (
          <IconButton edge="start" color="inherit" onClick={close} className={styles.closeBanner}>
            <CloseIcon size={18} color={"var(--gray_icon)"} />
          </IconButton>
        ) : null}
      </div>
    </Slide>
  ) : null;
};

export default HeaderSlideIn;
