import { CREATE, DELETE, DELETE_MANY, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE } from "react-admin";
import { convertEdtEstToLocal } from "./shared/utils";
import SimpleRestProvider from "ra-data-simple-rest";
import config from "./config";
import { transformAlgoValues } from "./pages/Algo/utils";
import fetchJson from "./shared/fetchJson";
import { LOCALSTORAGE } from "./shared/variables";
import { auth } from "./pages/Auth/firebaseConfig";

const { TOKEN } = LOCALSTORAGE;

export const SHARE = "SHARE";

// A function decorating a dataProvider for handling user profiles
const WrappedDataProvider = (dataProvider) => (verb, resource, params) => {
  // transform record data
  if (["algos", "tradings", "backtests"].includes(resource)) {
    if (verb === GET_ONE) {
      return dataProvider.getOne(resource, params).then((res) => ({
        ...res,
        data: {
          ...res.data,
          early_close_days_from: convertEdtEstToLocal(res.data.early_close_days_from),
          early_close_days_to: convertEdtEstToLocal(res.data.early_close_days_to),
          regular_days_from: convertEdtEstToLocal(res.data.regular_days_from),
          regular_days_to: convertEdtEstToLocal(res.data.regular_days_to),
        },
      }));
    }
    if (verb === GET_LIST) {
      return dataProvider.getList(resource, params).then((res) => ({
        ...res,
        data: res.data.map((item) => ({
          ...item,
          early_close_days_from: convertEdtEstToLocal(item.early_close_days_from),
          early_close_days_to: convertEdtEstToLocal(item.early_close_days_to),
          regular_days_from: convertEdtEstToLocal(item.regular_days_from),
          regular_days_to: convertEdtEstToLocal(item.regular_days_to),
        })),
      }));
    }
  }

  if (resource === "algos") {
    if (verb === CREATE) {
      return dataProvider.create(resource, {
        ...params,
        data: transformAlgoValues(params.data),
      });
    }
    if (verb === UPDATE) {
      return dataProvider.update(resource, {
        ...params,
        data: transformAlgoValues(params.data),
      });
    }
  }

  // We know we only GET or UPDATE the profile as there is only one for the current user
  // To showcase how we can do something completely different here, we'll store it in local storage
  // You can replace this with a customized fetch call to your own API route too
  if (resource === "profile" && verb === GET_ONE) {
    return dataProvider.getOne(resource, { id: "" });
  }
  if (["tradings/paper", "tradings/live"].includes(resource)) {
    if (verb === DELETE_MANY) return dataProvider.deleteMany("tradings", params);
  }

  // Fallback to the dataProvider default handling
  if (verb === GET_LIST) {
    return dataProvider.getList(resource, params);
  } else if (verb === GET_ONE) {
    return dataProvider.getOne(resource, params);
  } else if (verb === UPDATE) {
    return dataProvider.update(resource, params);
  } else if (verb === CREATE) {
    return dataProvider.create(resource, params);
  } else if (verb === GET_MANY_REFERENCE) {
    return dataProvider.getManyReference(resource, params);
  } else if (verb === GET_MANY) {
    return dataProvider.getMany(resource, params);
  } else if (verb === DELETE) {
    return dataProvider.delete(resource, params);
  } else if (verb === DELETE_MANY) {
    return dataProvider.deleteMany(resource, params);
  } else if (verb === SHARE) {
    return share(resource, params.id, params.data.shared);
  }
};

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set("Authorization", "Bearer " + await getAuthToken());
  return fetchJson(url, options);
};

const getAuthToken = async () => {
  return (await auth?.currentUser?.getIdToken()) || localStorage.getItem(TOKEN);
};

export default WrappedDataProvider(SimpleRestProvider(config.BACKEND_API, httpClient));

export const customFetch = async (url, method = "GET", body = null, type = "JSON") => {
  try {
    const response = await fetch(`${config.BACKEND_API}/${url}`, {
      method,
      headers: {
        Authorization: `Bearer ${await getAuthToken()}`,
        "Content-Type": "application/json",
      },
      cache: "default",
      body: body ? JSON.stringify(body) : null,
    });
    if (response?.ok && type === "JSON") {
      return await response.json();
    } else if (response?.ok) {
      return await response.text();
    }
    if (response?.status === 401) {
      localStorage.removeItem(TOKEN);
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const share = async (resource, id, flag) => {
  return httpClient(`${config.BACKEND_API}/${resource}/share/${id}`, { method: "POST", body: flag }).then(
    ({ headers, body }) => ({
      data: body,
    }),
  );
};

export const authFetch = (url, options) =>
  getAuthToken().then(token => fetch(url, {
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    cache: "default",
  }));

export const authFetchAPI = (url, options) => authFetch(`${config.BACKEND_API}/${url}`, options);
