import React from "react";
import { ast, isImage } from "commonmark-helpers";

export const getMDImages = (md) => {
  function matchAll(input) {
    const matcher = isImage;
    let result = [];
    if (!input) return result;
    const walker = ast(input).walker();
    let event;
    while ((event = walker.next())) {
      if (matcher(event.node, event)) {
        if (result.indexOf(event.node) === -1) {
          result.push(event.node);
        }
      }
    }
    return result;
  }

  return matchAll(md);
};

export default getMDImages;
