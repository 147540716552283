import React, { useEffect } from "react";
import { Edit } from "react-admin";
import AlgoForm from "./AlgoForm";
import { useDispatch } from "react-redux";
import { GET_SCREENERS } from "../../store/sagas/screenersSaga";
import { GET_SCREENERS_ALL } from "../../store/sagas/screenersAllSaga";

const EditAlgo = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_SCREENERS });
    dispatch({ type: GET_SCREENERS_ALL });
  }, []);

  return (
    <Edit
      {...props}
      resource="algos"
      actions={null}
    >
      <AlgoForm {...props} />
    </Edit>
  );
};

export default EditAlgo;
