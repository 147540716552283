import React from "react";
import { SimpleList, useTranslate } from "react-admin";
import mobileStyles from "../MobileList.module.css";
import { toDateFormat } from "../../shared/utils";
import Moment from "react-moment";
import { ColoredNumber } from "../ColoredNumber/ColoredNumber";
import StatusField from "../StatusField/StatusField";
import { Link } from "ra-ui-materialui";
import TickerBenchmarkField from "../TickerBenchmarkField/TickerBenchmarkField";

const BacktestMobileList = () => {
  const t = useTranslate();
  const backtestOnClick = (id) => "/backtests/" + id + "/show";
  return (
    <SimpleList
      linkType={false}
      className={mobileStyles.list}
      primaryText={(record) => (
        <Link to={() => backtestOnClick(record?.id)}>
          <div
            className={mobileStyles.date}
            style={{ flexDirection: "column" }}
          >
            <span>
              {toDateFormat(record.start_date)}
              {" - "}
              {toDateFormat(record.end_date)}
            </span>
            <span>
              {t("common.created")}{" "}
              <Moment fromNow>{record.created_date}</Moment>
            </span>
          </div>
          <div className={mobileStyles.items}>
            <TickerBenchmarkField
              record={record}
              ticket="ticker"
              benchmark="benchmark_ticker"
            />
            <ColoredNumber
              record={record}
              locales="en-EN"
              style={{ percent: "percent" }}
              source="return_pct"
              className={mobileStyles.gain}
            />
            <StatusField record={record} source="status" />
          </div>
        </Link>
      )}
    />
  );
};

export default BacktestMobileList;